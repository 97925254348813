import { createContext } from "react";

export const ApplicationStateContext = createContext({
  password: "",
  setPassword: (_password) => {},
  themesHaveBeenSuggested: false,
  setThemesHaveBeenSuggested: (_suggested) => {},
  hasDonePreregistrationCheckin: false,
  setHasDonePreregistrationCheckin: (_hasDone) => {},
});
