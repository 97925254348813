import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import backgroundImageRightTop from "@src/resources/backgrounds/neutral-top-right.svg";
import backgroundImageLeftBottom from "@src/resources/backgrounds/neutral-bottom-left.svg";
import colors from "@src/theme/colors";
import LayoutThemePage from "./LayoutThemePage";

const ThemePageMenuLayout = () => (
  <ThemeProvider
    theme={(theme) =>
      createTheme({
        ...theme,
        typography: {
          ...theme.typography,
          h1: {
            ...theme.typography.h1,
            [theme.breakpoints.down("sm")]: {
              fontSize: "35px",
            },
          },
          h3: {
            ...theme.typography.h3,
            fontSize: "35px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "25px",
            },
          },
          h6: {
            ...theme.typography.h6,
            fontSize: "16px",
            letterSpacing: "0.5px",
            opacity: 0.7,
          },
        },
      })
    }
  >
    <LayoutThemePage
      backgroundColor={colors.primarySkin}
      bottomLeftImageUrl={backgroundImageLeftBottom}
      topRightImageUrl={backgroundImageRightTop}
    />
  </ThemeProvider>
);

export default ThemePageMenuLayout;
