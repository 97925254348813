import { Box, Icon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "20px",
    marginRight: theme.spacing(2),
    "&>span": {
      fontSize: "20px",
    },
  },
  typography: {
    display: "flex",
    alignItems: "center",
  },
}));

const TypographyWithIcon = ({
  mb = 3,
  variant = "caption",
  boxClass = undefined,
  classesIcon = "",
  typographyClass = undefined,
  outlined = false,
  iconLigature,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box mb={mb} display="flex" className={boxClass}>
      <Typography
        variant={variant}
        className={clsx(classes.typography, typographyClass)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <Icon className={classesIcon || classes.icon}>
          {outlined ? (
            <span className="material-icons-outlined">{iconLigature}</span>
          ) : (
            iconLigature
          )}
        </Icon>
        {children}
      </Typography>
    </Box>
  );
};

export default TypographyWithIcon;
