import { useState, useEffect } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import useTheme from "@mui/styles/useTheme";
import { connect } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import {
  loginAfterTwoFactorVerification,
  twoFactorSuccessAction,
} from "@src/actions/userActions";
import queryString from "query-string";
import { twoFactorCodeExpiryPeriod } from "@src/queries/staletimes";
import authService, { useAuthentication } from "@src/services/auth.service";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import {
  RESPONSE_STATUS,
  guestUserLoginActions,
  timeSnackbar,
  TWO_FACTOR_REQUEST_STATES,
  AuthenticatorTwoFactorCodeRequestMethod,
  PhoneTwoFactorCodeRequestMethod,
} from "@src/utils/constants";
import { getReadableTimeFromSeconds } from "@src/utils/formatting";
import { withSuspense } from "@src/components/wrappers/Suspendable";
import CodeInput from "@src/components/Inputs/CodeInput";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { ReactComponent as GoogleAuthenticatorImage } from "@src/resources/images/logoGoogleAuthentificator.svg";
import { ReactComponent as GoogleAuthenticatorExample } from "@src/resources/images/googleAutheticatorAccount.svg";
import {
  useRequestTwoFactorCode,
  get2FAAuthenticationPreference,
  isPhoneTwoFactorAuthenticationEnabled,
  useVerifyTwoFactorCode,
  TWO_FACTOR_OPTIONS,
} from "@src/queries/twoFactorAuthentication";

const CODE_LENGTH = 6;

/**
 * View for entering SMS 2 Factor Code
 */
const TwoFactorCodeRequestView = ({
  loginAction,
  authReducers,
  insideModal = false,
  onSuccess = () => {},
  onCloseModal = () => {},
  viewPurpose = TWO_FACTOR_OPTIONS.LOGIN,
  // eslint-disable-next-line no-shadow
  twoFactorSuccessAction,
  ...props
}) => {
  const theme = useTheme();
  const isSmallScreen =
    useMediaQuery(theme.breakpoints.down("md")) || insideModal;
  const currentUser = authService.getUserFromStorage();
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [error, setError] = useState(false);

  const [code, setCode] = useState("");
  const [twoFactorVerificationStatus, setTwoFactorVerificationStatus] =
    useState(TWO_FACTOR_REQUEST_STATES.IDLE);

  const urlSearch = new URLSearchParams(search);
  const action = urlSearch.get("action");
  const id = urlSearch.get("id");

  const { isLoggedIn } = useAuthentication();

  const onError = () => {
    setTwoFactorVerificationStatus(TWO_FACTOR_REQUEST_STATES.INVALID);
    setTimeout(() => {
      setTwoFactorVerificationStatus(TWO_FACTOR_REQUEST_STATES.IDLE);
    }, timeSnackbar);
  };

  const onErrorSnackbar = (errorMessage) => {
    props.snackbarShowMessage(errorMessage, "error");
  };

  useEffect(() => {
    if (!currentUser) navigate(pathT("route.login"));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  const { data: twoFactorRequest, refetch: refetchTwoFactorCode } =
    useRequestTwoFactorCode({
      errorCallback: onError,
      errorCallbackSnackbar: onErrorSnackbar,
      // disabled a call to send SMS if a user with only Authenticator is logging in, or if the current user is not set
      disabled:
        (viewPurpose === TWO_FACTOR_OPTIONS.LOGIN &&
          get2FAAuthenticationPreference(currentUser?.userTokenProviders) ===
            AuthenticatorTwoFactorCodeRequestMethod) ||
        !currentUser,
    });

  const { data: twoFactorVerification, refetch: refetchTwoFactorVerification } =
    useVerifyTwoFactorCode({
      code,
      method:
        viewPurpose === TWO_FACTOR_OPTIONS.LOGIN
          ? get2FAAuthenticationPreference(currentUser?.userTokenProviders)
          : PhoneTwoFactorCodeRequestMethod,
      enable: !isPhoneTwoFactorAuthenticationEnabled(currentUser),
      purpose: viewPurpose,
    });

  useEffect(() => {
    if (twoFactorVerificationStatus === TWO_FACTOR_REQUEST_STATES.PENDING) {
      return;
    }
    setTimeout(() => {
      setTwoFactorVerificationStatus(TWO_FACTOR_REQUEST_STATES.IDLE);
    }, 3000);
  }, [twoFactorVerificationStatus]);

  const handleSubmit = async (submitEvent) => {
    submitEvent.preventDefault();
    setTwoFactorVerificationStatus(TWO_FACTOR_REQUEST_STATES.PENDING);
    const response = await refetchTwoFactorVerification();
    if (response?.data?.status === RESPONSE_STATUS.OK) {
      if (action) {
        await twoFactorSuccessAction();
        switch (action) {
          case guestUserLoginActions.sessionNotes:
            navigate(
              `${pathT("route.mySessions")}/${t(
                "route.mySessions.consult",
              )}/${id}`,
              {
                replace: true,
              },
            );
            break;
          case guestUserLoginActions.medicalCheckIn:
            navigate(
              `${pathT("route.medical")}/${t("route.medical-checkin-results")}`,
              {
                replace: true,
              },
            );
            break;
          case guestUserLoginActions.checkIn:
            navigate(pathT("route.checkins"), { replace: true });
            break;
          case guestUserLoginActions.explicitConsent:
            navigate(`${pathT("route.register.explicit-consent")}`, {
              replace: true,
            });
            break;
          case guestUserLoginActions.videoCall:
            navigate(`${pathT("route.call")}/${id}`, {
              replace: true,
            });
            break;
          default:
            break;
        }
      }
      switch (viewPurpose) {
        case TWO_FACTOR_OPTIONS.LOGIN:
          await loginAction(authReducers.user.email);
          break;
        case TWO_FACTOR_OPTIONS.TOGGLE:
          await twoFactorSuccessAction();
          onSuccess();
          break;
        default:
          break;
      }
    }
  };

  // twoFactorVerification error handling
  useEffect(() => {
    if (!twoFactorVerification) {
      return;
    }
    if (
      twoFactorVerification?.response?.status === RESPONSE_STATUS.BAD_REQUEST ||
      twoFactorVerification?.response?.status ===
        RESPONSE_STATUS.UNAUTHORISED ||
      twoFactorVerification?.response?.status === RESPONSE_STATUS.FORBIDDEN
    ) {
      setTwoFactorVerificationStatus(TWO_FACTOR_REQUEST_STATES.INVALID);
      if (
        twoFactorVerification?.response?.data ===
        "InvalidTwoFactorAuthenticationCode"
      ) {
        setTwoFactorVerificationStatus(TWO_FACTOR_REQUEST_STATES.INVALID);
      } else if (
        twoFactorVerification?.response?.data ===
        "ExpiredTwoFactorAuthenticationCode"
      ) {
        setTwoFactorVerificationStatus(TWO_FACTOR_REQUEST_STATES.EXPIRED);
      }
    }
  }, [twoFactorVerification]);

  const handleRefetchTwoFactorCode = () => {
    refetchTwoFactorCode();
  };

  const [seconds, setSeconds] = useState(twoFactorRequest?.timeout || -1);

  useEffect(() => {
    if (twoFactorRequest?.timeout) setSeconds(twoFactorRequest?.timeout);
  }, [twoFactorRequest?.timeout]);

  useEffect(() => {
    if (seconds <= -1) {
      return undefined;
    }
    const myInterval = setInterval(() => {
      if (seconds === 0) {
        // do nothing, return
      }

      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  if (isLoggedIn && viewPurpose === TWO_FACTOR_OPTIONS.LOGIN) {
    const { redirect } = queryString.parse(search);
    return <Navigate to={redirect?.toString() || pathT("route.account")} />;
  }

  const isCodeExpired = () =>
    twoFactorRequest?.timeout >= twoFactorCodeExpiryPeriod;
  const showAuthenticatorInput = () => {
    if (isCodeExpired()) return false;
    return (
      viewPurpose === TWO_FACTOR_OPTIONS.LOGIN &&
      currentUser?.userTokenProviders &&
      get2FAAuthenticationPreference(currentUser?.userTokenProviders) ===
        AuthenticatorTwoFactorCodeRequestMethod
    );
  };
  const showPhoneNumberInput = () => {
    if (isCodeExpired()) return false;
    if (showAuthenticatorInput()) return false;
    return true;
  };

  const onFocus = () => {
    setError(false);
  };

  return (
    <div>
      {viewPurpose === TWO_FACTOR_OPTIONS.LOGIN && (
        <Typography
          variant={isSmallScreen ? "h2" : "h1"}
          className="whitespace-normal w-full mb-8"
        >
          {t("TwoFactorAuthentication.Login.Title")}
        </Typography>
      )}
      {viewPurpose === TWO_FACTOR_OPTIONS.TOGGLE && (
        <Typography
          variant={isSmallScreen ? "h5" : "h1"}
          className="whitespace-normal w-full mb-8"
        >
          {isPhoneTwoFactorAuthenticationEnabled(currentUser)
            ? t("TwoFactorAuthentication.Deactivation.Title")
            : t("TwoFactorAuthentication.Activation.Title")}
        </Typography>
      )}
      {isCodeExpired() && (
        <Typography variant="body1" className="whitespace-normal w-full mb-8">
          {`${
            t("TwoFactorAuthentication.ExceededMaximumTries") +
            getReadableTimeFromSeconds(seconds)
          }.`}
        </Typography>
      )}
      {showPhoneNumberInput() && (
        <Typography variant="body1" className="whitespace-normal w-full mb-8">
          {t("TwoFactorAuthentication.Information.PhoneNumber.Part1")}{" "}
          {twoFactorRequest?.phoneNumber ||
            t("TwoFactorAuthentication.Information.PhoneNumber.Part2")}
        </Typography>
      )}
      {showAuthenticatorInput() && (
        <>
          <Box display="flex" mt={3}>
            <Typography variant="body1">
              {t("LoginView.GoogleAuthenticatorPage.FirstElementText")}
              <GoogleAuthenticatorImage className="mr-4" />
              {t("LoginView.GoogleAuthenticatorPage.FirstElementBold")}
              {t("LoginView.GoogleAuthenticatorPage.FirstElementText2")}
            </Typography>
          </Box>
          <Box mt={1} mb={1}>
            <GoogleAuthenticatorExample className="flex mx-auto" />
          </Box>
          <Box mt={1} mb={1}>
            <Typography variant="body1" className="ml-12">
              {t("LoginView.GoogleAuthenticatorPage.SecondElementText")}
              <Box display="inline" ml={0.5} fontWeight="bold">
                {t("LoginView.GoogleAuthenticatorPage.SecondElementTextBold")}
              </Box>
              {t("LoginView.GoogleAuthenticatorPage.SecondElementText2")}
            </Typography>
          </Box>
        </>
      )}

      <form onSubmit={handleSubmit}>
        <CodeInput
          code={code}
          codeLength={CODE_LENGTH}
          onInput={setCode}
          onFocus={onFocus}
          isErrorState={error}
        />

        {error && (
          <p className="text-red-500 flex justify-center items-center text-xs">
            {t("LoginView.GoogleAuthenticatorPage.Error")}
          </p>
        )}

        {twoFactorVerificationStatus === TWO_FACTOR_REQUEST_STATES.PENDING && (
          <span className="float-right">
            {t("TwoFactorAuthentication.Information.VerifyingCode")}
          </span>
        )}

        {twoFactorVerificationStatus === TWO_FACTOR_REQUEST_STATES.SUCCESS && (
          <span className="float-right text-green-500">
            {t("TwoFactorAuthentication.Information.VerifiedCodeSuccess")}
          </span>
        )}

        {twoFactorVerificationStatus === TWO_FACTOR_REQUEST_STATES.EXPIRED && (
          <span className="float-left text-red-500">
            {t("TwoFactorAuthentication.Information.CodeTimeout")}
          </span>
        )}

        {twoFactorVerificationStatus === TWO_FACTOR_REQUEST_STATES.INVALID && (
          <span className="float-left text-red-500">
            {t("TwoFactorAuthentication.Information.InvalidCode")}
          </span>
        )}

        {showPhoneNumberInput() && (
          <span className="flex justify-end text-sm">
            {twoFactorVerificationStatus === TWO_FACTOR_REQUEST_STATES.IDLE && (
              <span className="self-center">
                {t("TwoFactorAuthentication.Information.NoSMS")}
              </span>
            )}

            {seconds <= 0 && (
              <Button
                className="bg-transparent hover:bg-transparent text-blue-500"
                onClick={handleRefetchTwoFactorCode}
                type="button"
              >
                <span className="inline-block underline opacity-100 pl-1">
                  {t("TwoFactorAuthentication.Information.NewCode")}
                </span>
              </Button>
            )}

            {seconds > 0 && (
              <span className="pl-2">
                <span className="inline-block line-through opacity-100 pl-1">
                  {t("TwoFactorAuthentication.Information.NewCode")}
                </span>{" "}
                <span className="font-bold pl-2">
                  {getReadableTimeFromSeconds(seconds)}
                </span>
              </span>
            )}
          </span>
        )}

        <div
          className={`mt-8 ${
            viewPurpose !== TWO_FACTOR_OPTIONS.TOGGLE
              ? "text-center"
              : "flex justify-between align-middle"
          } mb-8`}
        >
          {viewPurpose === TWO_FACTOR_OPTIONS.TOGGLE && (
            <Button
              onClick={onCloseModal}
              className="text-xl rounded-none bg-white hover:bg-white text-blue-500"
            >
              {t("Common.Cancel")}
            </Button>
          )}

          <Button
            type="submit"
            className={`px-6 sm:px-24 py-6 w-90 rounded-full hover:bg-indigo-800  ${
              viewPurpose !== TWO_FACTOR_OPTIONS.TOGGLE ? "w-full" : ""
            }`}
            disabled={code.length !== CODE_LENGTH}
          >
            <Typography
              component="span"
              variant="subtitle2"
              className="text-white tracking-wider"
            >
              {isPhoneTwoFactorAuthenticationEnabled(currentUser)
                ? t("TwoFactorAuthentication.Deactivation.Confirm")
                : t("Common.Submit")}
            </Typography>
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loginAction: (userEmail) =>
    dispatch(loginAfterTwoFactorVerification(userEmail)),
  twoFactorSuccessAction: () => dispatch(twoFactorSuccessAction()),
});

const mapStateToProps = (state) => state;
export default withSuspense(
  withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(TwoFactorCodeRequestView),
  ),
);
