import { useEffect } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useNavigate } from "react-router-dom";
import {
  RESPONSE_STATUS,
  timeSnackbar,
  guestUserLoginActions,
} from "@src/utils/constants";
import AppleLogo from "@src/resources/images/apple-logo.svg";
import AppleLogin from "react-apple-login";
import { Button, Icon } from "@mui/material";
import { connect } from "react-redux";
import {
  getExternalUser as getExternalUserAction,
  signUpExternalUser as signUpExternalUserAction,
  clearMessages as clearMessagesAction,
} from "@src/actions/userActions";
import { getLocaleDomain } from "@src/i18n";
import i18n from "i18next";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { env } from "@src/env";

const AppleSignInSignUpButton = ({
  authReducers,
  removeMessages,
  className = "",
  getExternalUser,
  signUpExternalUser,
  isSignUpForm = false,
  fullWidth = true,
  onLoginCallback,
  buttonText,
  onValidate,
  isOnboarding = false,
  ...props
}) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authReducers.twoFactorPending || authReducers.isLoggedIn) {
      return;
    }

    if (isOnboarding) {
      navigate(
        `${pathT("route.twoFactorCodeRequest")}?action=${
          guestUserLoginActions.explicitConsent
        }`,
        {
          replace: true,
        },
      );
    } else {
      navigate(pathT("route.twoFactorCodeRequest"), {
        replace: true,
      });
    }
    removeMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [authReducers.twoFactorPending]);

  const signIn = async (res) => {
    try {
      const response = await getExternalUser(
        res.authorization.id_token,
        "Apple",
      );
      props.snackbarShowMessage(response.message, "success");
      if (response?.twoFactorVerification === "pending") {
        // do nothing
        return;
      }
      setTimeout(() => {
        if (onLoginCallback) {
          onLoginCallback();
        }
      }, timeSnackbar);
    } catch (error) {
      if (error.response.status === RESPONSE_STATUS.NOT_FOUND) {
        props.snackbarShowMessage(t("LoginView.AccountNotFound"));
        setTimeout(() => {
          navigate(pathT("route.register"), { replace: true });
        }, timeSnackbar);
      } else {
        props.snackbarShowMessage("");
      }
    }
  };

  const signUp = async (res) => {
    try {
      const response = await signUpExternalUser(
        res.authorization.id_token,
        "Apple",
      );

      props.snackbarShowMessage(response.message, "success");
      if (response?.twoFactorVerification === "pending") {
        // do nothing, there's a useEffect that will redirect
        return;
      }

      setTimeout(() => {
        if (isOnboarding) {
          onLoginCallback();
          return;
        }
        if (onLoginCallback) {
          onLoginCallback();
        }
      }, timeSnackbar);
    } catch (error) {
      props.snackbarShowMessage("");
    }
  };

  const handleAppleResponse = async (res) => {
    if (!res.error) {
      if (isSignUpForm) {
        await signUp(res);
      } else {
        await signIn(res);
      }
    } else if (res?.error.error !== "popup_closed_by_user") {
      props.snackbarShowMessage(res.error.error);
    }
  };

  const validateLogin = (renderProps) => {
    if (onValidate) {
      const response = onValidate();
      if (!response) {
        return;
      }
    }
    renderProps.onClick();
  };

  return env.REACT_APP_CHAT_FEATURE_ENABLED ? (
    <AppleLogin
      clientId={env.REACT_APP_APPLE_CLIENT_ID}
      redirectURI={getLocaleDomain(i18n.language.substring(0, 2))}
      scope="email name"
      usePopup
      callback={handleAppleResponse}
      render={(renderProps) => (
        <Button
          variant="outlined"
          fullWidth={fullWidth}
          size="large"
          className={className}
          startIcon={<AppleIcon />}
          onClick={() => validateLogin(renderProps)}
        >
          <p className="font-semibold text-sm tracking-wide leading-6">
            {buttonText}
          </p>
        </Button>
      )}
    />
  ) : (
    <div />
  );
};

const AppleIcon = () => {
  return (
    <Icon>
      <img alt="" src={AppleLogo} />
    </Icon>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getExternalUser: (idToken, provider) =>
    dispatch(getExternalUserAction(idToken, provider)),
  signUpExternalUser: (idToken, provider) =>
    dispatch(signUpExternalUserAction(idToken, provider)),
  removeMessages: () => {
    dispatch(clearMessagesAction());
  },
});

const mapStateToProps = (state) => state;
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(AppleSignInSignUpButton),
);
