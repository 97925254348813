import { useState } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { Question } from "@src/models/QuestionList";
import Logo from "@src/components/Logo";
import ResilienceCheckinProgressBar from "@src/views/resilience-checkin/components/ResilienceCheckinProgressBar";
import sessionStorageService from "@src/services/sessionStorage.service";
import ResilienceCheckinMultipleChoiceQuestionCard from "@src/views/resilience-checkin/components/ResilienceCheckinMultipleChoiceQuestionCard";

interface ResilienceCheckinQuestionsProps {
  questions: Question[] | undefined;
  goToNextStep: () => void;
}

const ResilienceCheckinQuestions: React.FC<ResilienceCheckinQuestionsProps> = ({
  questions,
  goToNextStep,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslatedNavigate();

  const [currentQuestionIndex, setCurrentQuestionNumber] = useState(0);

  const [answers, setAnswers] = useState({});

  if (!questions) {
    return <div>loading...</div>;
  }

  const goToPreviousQuestion = () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    if (currentQuestionIndex <= 0) {
      return;
    }
    setCurrentQuestionNumber((prev) => prev - 1);
  };

  const goToNextQuestion = () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    if (questions && currentQuestionIndex === questions?.length - 1) {
      goToNextStep();
    }
    setCurrentQuestionNumber((prev) => prev + 1);
  };

  const saveAnswerToStorage = (question: Question, selectedAnswer) => {
    setAnswers((prev) => ({ ...prev, [question.questionKey]: selectedAnswer }));
    sessionStorageService.saveResilienceCheckInAnswer({
      questionListKey: "resilience-checkin",
      languageCode: language,
      answers: [
        {
          questionKey: question.questionKey,
          questionText: question.questionText,
          questionType: question.questionType,
          answers: [selectedAnswer],
        },
      ],
    });
  };

  return (
    <div className="w-screen h-screen overflow-clip">
      {/* Logo */}
      <div className="hidden md:flex justify-center pt-8">
        <Logo />
      </div>
      {/* Progress Indicator */}
      <ResilienceCheckinProgressBar
        goToPreviousQuestion={goToPreviousQuestion}
        totalNumberOfQuestions={questions.length}
        currentQuestionIndex={currentQuestionIndex}
      />
      {/* Question Section */}
      <div className="relative md:flex justify-center">
        {questions.map((question, index) => {
          return (
            <ResilienceCheckinMultipleChoiceQuestionCard
              key={question.questionKey}
              question={question}
              isDisabled={index !== currentQuestionIndex}
              questionNumber={index + 1}
              totalQuestions={questions.length}
              goToNextQuestion={goToNextQuestion}
              saveAnswerToStorage={saveAnswerToStorage}
              className={`${
                index > currentQuestionIndex
                  ? "transition opacity-0 translate-x-full duration-700 "
                  : "transition scale-100 duration-700 "
              }${
                index < currentQuestionIndex
                  ? "transition scale-25 duration-700 "
                  : ""
              }${
                index === currentQuestionIndex
                  ? "transition opacity-100 translate-x-0 scale-100 duration-700 "
                  : ""
              }
                  ${
                    questions.length - 1 === currentQuestionIndex
                      ? "scale-100 "
                      : ""
                  }`}
            />
          );
        })}
      </div>

      {/* Sticky Footer Finish Button for mobile */}
      <div
        className={`md:hidden ${
          currentQuestionIndex === questions.length - 1 ? "" : "hidden"
        }`}
      >
        <div className="fixed bg-white bottom-0 md:static w-full flex justify-center py-4 border-t-2 border-t-gray-100 shadow-2xl">
          <button
            type="button"
            onClick={goToNextStep}
            disabled={
              answers[questions[currentQuestionIndex].questionKey] === undefined
            }
            className="w-5/6 font-display text-sm tracking-[.031em] font-medium rounded-full py-3 px-6 bg-blue-500 text-white hover:bg-blue-500 hover:bg-opacity-90 border-0 disabled:hover:bg-blue-500 disabled:opacity-40"
          >
            {t("ResilienceCheckin.Question.Finish")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ResilienceCheckinQuestions;
