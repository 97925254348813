import CenteredLoader from "@src/components/CenteredLoader";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

type SegmentClasses = {
  low: string;
  medium: string;
  high: string;
};

const LOW_THRESHOLD = 1.69;
const MEDIUM_THRESHOLD = 3.29;

const colourObject = {
  low: "bg-indigo-800",
  medium: "bg-blue-500",
  high: "bg-green-500",
};

const getColorByScore = (score: number, classes: SegmentClasses) => {
  if (score <= LOW_THRESHOLD) {
    return classes.low;
  }
  if (score <= MEDIUM_THRESHOLD) {
    return classes.medium;
  }
  return classes.high;
};

const Segment = ({ score, total = 5 }) => {
  return (
    <div className="flex-grow rounded-xl  relative">
      <div className="absolute w-full bg-gray-200 h-2 rounded-xl" />
      <div
        className={`absolute ${getColorByScore(
          score,
          colourObject,
        )} h-2 rounded-xl`}
        style={{ width: `${(score * 100) / total}%` }}
      />
    </div>
  );
};

const LowSegment = ({ score }) => {
  if (score < LOW_THRESHOLD) {
    return <Segment score={score} />;
  }
  return (
    <div
      className={`flex-grow rounded-xl ${getColorByScore(
        score,
        colourObject,
      )} ease-in`}
    />
  );
};

const MediumSegment = ({ score }) => {
  if (score <= LOW_THRESHOLD) {
    return <div className="flex-grow rounded-xl bg-gray-200 ease-in" />;
  }
  if (score <= MEDIUM_THRESHOLD) {
    return <Segment score={score} />;
  }
  return (
    <div
      className={`flex-grow rounded-xl ${getColorByScore(
        score,
        colourObject,
      )} ease-in`}
    />
  );
};

const LargeSegment = ({ score }) => {
  if (score <= MEDIUM_THRESHOLD) {
    return <div className="flex-grow rounded-xl bg-gray-200 ease-in" />;
  }
  return <Segment score={score} />;
};

const ResilienceCheckinScoreLowMediumHigh = ({
  results,
  goToNextStep,
}: any) => {
  const { t } = useTranslatedNavigate();

  const getScoreLabel = (score: number) => {
    if (score <= LOW_THRESHOLD) {
      return t("ResilienceCheckin.Results.Low");
    }
    if (score <= MEDIUM_THRESHOLD) {
      return t("ResilienceCheckin.Results.Medium");
    }
    return t("ResilienceCheckin.Results.High");
  };

  if (!results) {
    return <CenteredLoader />;
  }

  return (
    <div className="my-6 bg-white rounded-xl shadow-xl">
      <div className="px-5 pt-5">
        <div className="flex justify-between mb-5">
          <p className="text-lg font-semibold leading-[24px] tracking-[0.15px] text-indigo-800">
            {t("ResilienceCheckin.Results.WellBeingScore")}
          </p>
          <p
            className={`font-semibold leading-[24px] tracking-[0.15px] ${getColorByScore(
              results.score,
              {
                low: "text-indigo-800",
                medium: "text-blue-500",
                high: "text-green-500",
              },
            )}`}
            data-cy="resilience-checkin-score-label"
          >
            {getScoreLabel(results.score)}
          </p>
        </div>
        <div className="flex h-2 mb-3 justify-between gap-1">
          {/* Low Segment */}
          <LowSegment score={results.score} />
          {/* Medium Segment */}
          <MediumSegment score={results.score} />
          {/* High Segment */}
          <LargeSegment score={results.score} />
        </div>
        <div className="flex h-3 justify-between md:mb-6">
          <p className="text-xs tracking-[0.4px]  text-gray-500">
            {t("ResilienceCheckin.Results.Low")}
          </p>
          <p className="text-xs tracking-[0.4px]  text-gray-500">
            {t("ResilienceCheckin.Results.High")}
          </p>
        </div>
      </div>
      <hr className="hidden md:flex h-px bg-gray-200 border-0" />
      <div className="px-5 pb-5">
        <div className="hidden md:flex justify-center pt-4 border-t-2 border-t-gray-100">
          <button
            type="button"
            onClick={goToNextStep}
            className="w-full font-display text-sm tracking-[.031em] font-medium rounded-full py-3 px-6 bg-blue-500 text-white hover:bg-opacity-90 border-0 disabled:hover:bg-blue-500 disabled:opacity-40 hover:cursor-pointer"
          >
            {t("ResilienceCheckin.Results.CheckYourProgress")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ResilienceCheckinScoreLowMediumHigh;
