import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Logo from "@src/components/Logo";
import LanguageSelector from "@src/components/LanguageSelector";
import { useAuthentication } from "@src/services/auth.service";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { isMobileAppView } from "@src/utils/mobileHelper";

const useStyles = makeStyles(({ colors, breakpoints, spacing }) => ({
  centeredContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    [breakpoints.up("sm")]: {
      paddingTop: spacing(6.25),
      marginTop: 0,
    },
    [breakpoints.down("sm")]: {
      paddingTop: spacing(3),
      marginTop: 0,
    },
  },
  container: {
    justifyContent: "space-between",
    width: "50%",
    [breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  logoCenter: {
    color: colors.primaryDarkBlue,
    marginTop: spacing(1.8),
    minHeight: 28,
  },
}));

const FlowLayoutTopBar = ({ step }) => {
  const classes = useStyles();
  const { isLoggedIn } = useAuthentication();
  const { t, pathT } = useTranslatedNavigate();

  const linkToRedirectTo = isLoggedIn
    ? pathT("route.account")
    : t("SharedStrings.HomepageLink");

  return (
    <Box className={`${classes.centeredContent} ${classes.root}`}>
      <Box className={`${classes.centeredContent} ${classes.container}`}>
        {step === 0 ? <Box width={76} /> : <Box />}
        <a href={linkToRedirectTo} aria-label="OpenUp">
          <Logo className={classes.logoCenter} />
        </a>
        {step === 0 && isMobileAppView() === false ? (
          <LanguageSelector />
        ) : (
          <Box />
        )}
      </Box>
    </Box>
  );
};

export default FlowLayoutTopBar;
