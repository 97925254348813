import { PureComponent, useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getGuestUser } from "@src/actions/userActions";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { ApplicationStateContext } from "@src/utils/ApplicationStateContext";
import { guestUserLoginActions, timeSnackbar } from "@src/utils/constants";
import { withSnackbar } from "@src/components/SnackBarComponent";

class AuthorizedGuestView extends PureComponent {
  async componentDidMount() {
    const {
      dispatch,
      navigate,
      snackbarShowMessage,
      location,
      pathT,
      t,
      setHasDonePreregistrationCheckin,
    } = this.props;

    const urlSearch = new URLSearchParams(location.search);

    const token = urlSearch.get("token");
    const email = urlSearch.get("email").replace(/ /g, "+");
    const action = urlSearch.get("action");
    const id = urlSearch.get("id");

    try {
      const user = await dispatch(getGuestUser(token, email));
      setHasDonePreregistrationCheckin(true);
      switch (action) {
        case guestUserLoginActions.reschedule:
          navigate(`${pathT("route.booking")}/${t("route.reschedule")}/${id}`, {
            replace: true,
          });
          break;
        case guestUserLoginActions.cancel:
          navigate(
            `${pathT("route.booking")}/${t("route.cancellation")}/${id}`,
            {
              replace: true,
            },
          );
          break;
        case guestUserLoginActions.rebook:
          navigate(`${pathT("route.booking")}/${t("route.rebook")}/${id}`, {
            replace: true,
          });
          break;
        case guestUserLoginActions.bookSession:
          navigate(`${pathT("route.booking")}`, { replace: true });
          break;
        case guestUserLoginActions.confirmSpace:
          navigate(
            `${pathT("route.spaces")}/${t("route.confirmSpace")}/${id}`,
            {
              replace: true,
            },
          );
          break;
        case guestUserLoginActions.followup:
          if (id) {
            navigate(
              `${pathT("route.booking")}/${id}/${t("route.follow_up")}`,
              {
                replace: true,
              },
            );
          } else {
            navigate(`${pathT("route.booking")}/${t("route.follow_up")}`, {
              replace: true,
            });
          }
          break;
        case guestUserLoginActions.intro:
          navigate(`${pathT("route.booking")}/${t("route.introductory")}`, {
            replace: true,
          });
          break;
        case guestUserLoginActions.medicalCheckIn:
          if (
            user.twoFactorVerification &&
            user.twoFactorVerification === "pending"
          ) {
            navigate(
              `${pathT("route.twoFactorCodeRequest")}?action=${action}`,
              {
                replace: true,
              },
            );
          } else {
            navigate(
              `${pathT("route.medical")}/${t("route.medical-checkin-results")}`,
              {
                replace: true,
              },
            );
          }
          break;
        case guestUserLoginActions.checkIn:
          if (
            user.twoFactorVerification &&
            user.twoFactorVerification === "pending"
          ) {
            navigate(
              `${pathT("route.twoFactorCodeRequest")}?action=${action}`,
              {
                replace: true,
              },
            );
          } else {
            navigate(pathT("route.checkins"), { replace: true });
          }
          break;
        case guestUserLoginActions.sessionNotes:
          if (
            user.twoFactorVerification &&
            user.twoFactorVerification === "pending"
          ) {
            navigate(
              `${pathT(
                "route.twoFactorCodeRequest",
              )}?action=${action}&id=${id}`,
              {
                replace: true,
              },
            );
          } else {
            navigate(
              `${pathT("route.mySessions")}/${t(
                "route.mySessions.consult",
              )}/${id}`,
              {
                replace: true,
              },
            );
          }
          break;
        case guestUserLoginActions.videoCall:
          if (
            user.twoFactorVerification &&
            user.twoFactorVerification === "pending"
          ) {
            navigate(
              `${pathT(
                "route.twoFactorCodeRequest",
              )}?action=${action}&id=${id}`,
              {
                replace: true,
              },
            );
          } else {
            navigate(`${pathT("route.call")}/${id}`, {
              replace: true,
            });
          }
          break;
        default:
          // OI-653
          navigate(
            `${pathT("route.twoFactorCodeRequest")}?action=${
              guestUserLoginActions.checkIn
            }`,
            {
              replace: true,
            },
          );
          navigate(pathT("route.checkins"), { replace: true });
          break;
      }
    } catch (error) {
      snackbarShowMessage(t("Validations.TokenExpired"));
      setTimeout(() => {
        switch (action) {
          case guestUserLoginActions.medicalCheckIn:
            navigate(
              `${pathT("route.medical")}/${t(
                "route.medical.checkIn.notFound",
              )}`,
              {
                replace: true,
              },
            );
            break;
          default:
            navigate(`${pathT("route.booking")}`, { replace: true });
            break;
        }
      }, timeSnackbar);
    }
  }

  render() {
    return null;
  }
}

AuthorizedGuestView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  pathT: PropTypes.func.isRequired,
};

const WrappedAuthorizedGuestView = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathT, t } = useTranslatedNavigate();
  const { setHasDonePreregistrationCheckin } = useContext(
    ApplicationStateContext,
  );

  return (
    <AuthorizedGuestView
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      navigate={navigate}
      dispatch={dispatch}
      location={location}
      pathT={pathT}
      t={t}
      setHasDonePreregistrationCheckin={setHasDonePreregistrationCheckin}
    />
  );
};

export default withSnackbar(WrappedAuthorizedGuestView);
