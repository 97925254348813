import { Select, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  LanguageCodeDutchNl,
  LanguageCodeEnglishGb,
  LanguageCodeFrenchFr,
  LanguageCodeGermanDe,
  LanguageCodeSpanishEs,
} from "@src/utils/languageHelpers";
import clsx from "clsx";
import axios from "axios";
import authService, { useAuthentication } from "@src/services/auth.service";
import { getBestMatchingRouteForLocale, isAzure } from "@src/i18n";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { apiPaths } from "@src/utils/constants";
import { useLocation } from "react-router-dom";

const LanguageSelector = ({
  className = "",
  classNameSelect = "",
  classNameDropdown = "",
  isMedical = false,
}) => {
  const { i18n, t } = useTranslatedNavigate();
  const { isLoggedIn } = useAuthentication();
  const { pathname } = useLocation();

  const user = authService.getUserFromStorage();

  const getPath = () => {
    const pathKey =
      pathname === "/"
        ? ""
        : getBestMatchingRouteForLocale(
            pathname,
            i18n.language.substring(0, 2),
          );
    return btoa(JSON.stringify(pathKey));
  };

  const changeLanguage = async (event) => {
    if (isAzure()) {
      const pathEncoded = getPath();

      const slug = t("route.changeLanguage");
      if (isLoggedIn) {
        await axios.put(apiPaths.updateLanguage, {
          languageCode: event.target.value,
        });
        const userFromStorage = authService.getUserFromStorage();
        window.location.href = `/${slug}?language=${event.target.value}&email=${userFromStorage.email}&state=${pathEncoded}`;
      } else {
        window.location.href = `/${slug}?language=${event.target.value}&state=${pathEncoded}`;
      }
    } else {
      await i18n.changeLanguage(event.target.value);
    }
  };

  const userEmail = user?.email || "";

  const getMenuItem = (languageCode, languageName) => (
    <MenuItem
      className="rounded-full pl-4 h-12 py-0 text-sm font-medium text-indigo-800 hover:bg-blue-500 hover:bg-opacity-10 [&.Mui-selected]:bg-blue-500 [&.Mui-selected]:bg-opacity-10"
      value={languageCode}
    >
      {isAzure() ? (
        <a
          href={`/${t(
            "route.changeLanguage",
          )}?language=${languageCode}&email=${userEmail}&state=${getPath()}`}
          target="_self"
          className="text-indigo-800"
        >
          {languageName}
        </a>
      ) : (
        languageName
      )}
    </MenuItem>
  );

  return (
    <Select
      variant="standard"
      disableUnderline
      className={clsx("h-12 w-[76px] text-sm !bg-transparent", className)}
      slotProps={{
        input: {
          className: `text-center bg-orange-100 ${classNameSelect}`,
        },
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        slotProps: {
          paper: {
            className: `{p-0 m-0 w-[76px] rounded-[24px] ${classNameDropdown}`,
          },
        },
        MenuListProps: {
          className: "pt-0 pb-0",
        },
      }}
      IconComponent={KeyboardArrowDownIcon}
      value={i18n.language}
      onChange={changeLanguage}
    >
      {getMenuItem(LanguageCodeEnglishGb, "EN")}
      {getMenuItem(LanguageCodeDutchNl, "NL")}
      {getMenuItem(LanguageCodeGermanDe, "DE")}
      {!isMedical && getMenuItem(LanguageCodeFrenchFr, "FR")}
      {!isMedical && getMenuItem(LanguageCodeSpanishEs, "ES")}
    </Select>
  );
};

export default LanguageSelector;
