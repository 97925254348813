import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Logo from "@src/components/Logo";
import HeaderText from "@src/components/Typography/HeaderText";
import { QuestionListScoreResponse } from "@src/models/QuestionListScoreResponse";
import ResilienceCheckinScoreLowMediumHigh from "@src/views/resilience-checkin/components/ResilienceCheckinScoreLowMediumHigh";

interface ResilienceCheckinResultsViewProps {
  results: QuestionListScoreResponse;
  goToNextStep: () => void;
}

const getTitleFromAnswerDescription = (scoreDescription: string) => {
  if (scoreDescription.includes("!")) {
    return `${scoreDescription.split("!")[0] ?? ""}!`;
  }
  return `${scoreDescription.split(".")[0] ?? ""}.`;
};
const getDescriptionFromAnswerDescription = (scoreDescription: string) => {
  if (scoreDescription.includes("!")) {
    return scoreDescription.slice(scoreDescription.indexOf("!") + 1) ?? "";
  }
  return scoreDescription.slice(scoreDescription.indexOf(".") + 1) ?? "";
};

const ResilienceCheckinResultsView = ({
  results,
  goToNextStep,
}: ResilienceCheckinResultsViewProps) => {
  const { t } = useTranslatedNavigate();

  return (
    <div className="w-screen h-screen md:h-full">
      {/* Logo */}
      <div className="flex justify-center pt-8">
        <Logo />
      </div>
      <div className="px-6 md:max-w-xl mx-auto mt-8 mb-8">
        <HeaderText
          text={t("ResilienceCheckin.Results.Title")}
          classNameText="text-indigo-800"
        />
        <ResilienceCheckinScoreLowMediumHigh
          results={results}
          goToNextStep={goToNextStep}
        />
      </div>
      <div className="px-6 md:max-w-xl mx-auto mt-12 mb-6">
        <p className="text-xl font-semibold tracking-[0.25px] leading-[24px] text-indigo-800">
          {getTitleFromAnswerDescription(results.scoreDescription)}
        </p>
        <p className="mt-2 tracking-[0.5px] leading-[24px] text-indigo-800">
          {getDescriptionFromAnswerDescription(results.scoreDescription)}
        </p>
      </div>
      {/* Video */}
      <div className="w-full md:max-w-xl mx-auto px-6 pb-8">
        <div className="bg-white rounded-xl shadow-xl">
          <iframe
            width="100%"
            src={`${t("ResilienceCheckin.Results.Video.BaseUrl")}`}
            title={t("ResilienceCheckin.Results.Video.Title")}
            className="rounded-t-xl border-none m-0 h-[190px] md:h-[290px]"
          />
          <div className="p-4">
            <p className="font-semibold tracking-[0.15px] mb-1">
              {t("ResilienceCheckin.Results.Video.Title")}
            </p>
            <p className="text-gray-500">
              {t("ResilienceCheckin.Results.Video.Duration")} •{" "}
              {t("ResilienceCheckin.Results.Video.Description")}
            </p>
          </div>
        </div>
      </div>
      {/* Sticky Footer Continue Button */}
      <div className="md:hidden">
        <div className="fixed bg-white bottom-0 md:static w-full md:top-2/3 md:w-1/3 md:max-w-md md:rounded-b-xl md:mx-auto flex justify-center py-4 border-t-2 border-t-gray-100 shadow-2xl">
          <button
            type="button"
            onClick={goToNextStep}
            className="w-5/6 font-display text-sm tracking-[.031em] font-medium rounded-full py-3 px-6 bg-blue-500 text-white hover:bg-opacity-90 border-0"
          >
            {t("ResilienceCheckin.Results.CheckYourProgress")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResilienceCheckinResultsView;
