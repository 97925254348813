import { useRef, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { withSnackbar } from "@src/components/SnackBarComponent";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import theme from "@src/theme";
import clsx from "clsx";
import medicalTheme from "@src/theme/medicalTheme";
import { ThemeProvider, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackgroundImageTopRight from "@src/resources/backgrounds/neutral-top-right.svg";
import BackgroundImageBottomLeft from "@src/resources/backgrounds/neutral-bottom-left.svg";
import { closeWebview, isMobileAppView } from "@src/utils/mobileHelper";
import MedicalFlowTopBar from "./TopBars/MedicalFlowTopBar";
import FlowLayoutTopBar from "./TopBars/FlowLayoutTopBar";

const useStyles = makeStyles(() => ({
  containerWithBackgroundWaveElements: {
    minHeight: "100%",
    backgroundImage: `url(${BackgroundImageTopRight}), url(${BackgroundImageBottomLeft})`,
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: "right top, left bottom",
  },
  smallScreenBackgroundAdjustment: {
    backgroundSize: "30%, 70%",
  },
}));

const FlowLayout = ({ isMedical = false, hasBackground = true }) => {
  const currentTheme = isMedical ? medicalTheme : theme;
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("md"));

  const classes = useStyles();
  const step = useRef(0);
  const [isFlowSetup, setIsFlowSetup] = useState(false);

  const stepsToSkip = useRef([]);
  const preventGoingBack = useRef(false);
  const stepsMap = useRef(new Map());
  const reverseStepsMap = useRef(new Map());
  const baseUrl = useRef(window.location.pathname);

  const { t } = useTranslatedNavigate();
  const navigate = useNavigate();

  const [, setValue] = useState(0); // Used to force a rerender, read below
  const forceRerender = () => setValue((value) => value + 1);

  // Please note:
  // This is being used because having the step as a state does not interact well with the new back button implementation
  // What was happening was that fetching the step value inside the listener would retrieve it's initial value (0) and not the current one
  // Switching it to useRef seems to work properly. The manual re-rendering is so that the UI updates - this was implicit with useState
  // With the exception of isFlowSetup, the step is the only thing that needs to rerender the FlowLayout, hence the forceRerender being used only here
  const setStep = (value) => {
    step.current = value;
    forceRerender();
  };

  const setupFlowSteps = (steps) => {
    const stepsArray = Object.keys(steps).map((key) => steps[key]);

    const temporaryMap = new Map();
    const temporaryReverseMap = new Map();

    stepsArray.forEach((value, index) => {
      temporaryMap.set(index, value);
      temporaryReverseMap.set(value, index);
    });

    stepsMap.current = temporaryMap;
    reverseStepsMap.current = temporaryReverseMap;

    stepsArray.forEach((key) => {
      if (key === "" || key === null) return;
      if (baseUrl.current.includes(`/${t(key)}`)) {
        window.location.replace(baseUrl.current.replace(`/${t(key)}`, ""));
      }
    });

    setIsFlowSetup(true);
  };

  const getStepNumber = (value) => {
    if (reverseStepsMap.current.has(value))
      return reverseStepsMap.current.get(value);
    if (stepsMap.current.has(value)) return value;
    return null;
  };

  const getStepName = (value) => {
    if (stepsMap.current.has(value)) return stepsMap.current.get(value);
    if (reverseStepsMap.current.has(value)) return value;
    return null;
  };

  const nextStep = () => {
    let stepToGoTo = step.current + 1;
    if (!stepsMap.current.has(stepToGoTo)) return;

    while (stepsToSkip.current.includes(stepToGoTo)) {
      stepToGoTo += 1;
    }
    setStep(stepToGoTo);
  };

  const goToPreviousStep = () => {
    let stepToGoTo = step.current - 1;

    if (preventGoingBack.current) return;

    while (stepsToSkip.current.includes(stepToGoTo)) {
      stepToGoTo -= 1;
    }

    if (stepToGoTo >= 0) {
      setStep(stepToGoTo);
    } else if (isMobileAppView()) {
      closeWebview();
    } else {
      navigate(-1);
    }
  };

  const goToStep = (newStep) => {
    const stepNumber = getStepNumber(newStep);
    if (!stepsMap.current.has(stepNumber)) return;
    setStep(stepNumber);
  };

  const addStepToSkip = (stepToSkip) => {
    const stepNumber = getStepNumber(stepToSkip);
    stepsToSkip.current.push(stepNumber);
    if (step.current === stepNumber) {
      nextStep();
    }
  };

  const removeStepToSkip = (stepToSkip) => {
    const stepNumber = getStepNumber(stepToSkip);
    stepsToSkip.current = stepsToSkip.current.filter(
      (item) => item !== stepNumber,
    );
  };

  const setPreventGoingBack = (value) => {
    preventGoingBack.current = value;
  };

  const resetLayoutState = () => {
    stepsToSkip.current = [];
    preventGoingBack.current = false;
    reverseStepsMap.current = new Map();
    stepsMap.current = new Map();
    setIsFlowSetup(false);
    setStep(0);
  };

  const navigateToUrl = (newUrl) => {
    if (!newUrl) return;

    baseUrl.current = newUrl;
    resetLayoutState();
    navigate(newUrl, { replace: true });
  };

  useEffect(() => {
    if (stepsMap.current.size > 0) {
      window.history.pushState(
        null,
        "",
        `${baseUrl.current}/${t(getStepName(step.current))}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step.current, stepsMap.current]);

  const goBackHandler = () => {
    goToPreviousStep();
  };

  useEffect(() => {
    window.addEventListener("popstate", goBackHandler);

    // Removes the listener on clean up
    return () => window.removeEventListener("popstate", goBackHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  const containerClassname = () => {
    const screenSizeStyling = isSmallScreen
      ? classes.smallScreenBackgroundAdjustment
      : "";
    const backgroundStyling = hasBackground
      ? classes.containerWithBackgroundWaveElements
      : "";

    return clsx(screenSizeStyling, backgroundStyling);
  };

  return (
    <div className={containerClassname()}>
      <ThemeProvider theme={currentTheme}>
        {isMedical ? (
          <MedicalFlowTopBar />
        ) : (
          <FlowLayoutTopBar step={step.current} />
        )}
        <Outlet
          context={{
            step: getStepName(step.current),
            nextStep,
            goToPreviousStep,
            goToStep,
            navigateToUrl,
            addStepToSkip,
            removeStepToSkip,
            setupFlowSteps,
            isFlowSetup,
            setPreventGoingBack,
            stepsMap,
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default withSnackbar(FlowLayout);
