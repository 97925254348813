import { Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ONBOARDING_OVERVIEW_CURRENT_PAGE } from "@src/utils/constants";
import sessionStorageService from "@src/services/sessionStorage.service";
import { isPasswordRegisterProhibitedOnBlur } from "@src/utils/helpers";
import { useNavigate } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import authService from "@src/services/auth.service";
import { Formik } from "formik";
import { getValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import WizardContainer from "./WizardContainer";
import WizardActions from "./WizardActions";

const useStyles = makeStyles(({ breakpoints, spacing, customShadows }) => ({
  content: {
    boxShadow: customShadows.bookingContainerBackgroundShadow,
    padding: "0 0 100px 0",
    [breakpoints.up("sm")]: {
      boxShadow: "none",
      padding: spacing(4, 3),
    },
  },
}));

const EmailPage = ({ moveToNextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathT } = useTranslatedNavigate();

  const onSubmit = (values) => {
    authService.saveUserToStorage({
      email: values.email || "",
    });
    moveToNextStep();
  };

  return (
    <WizardContainer
      title={t("OnBoarding.Email.Title")}
      currentPage={ONBOARDING_OVERVIEW_CURRENT_PAGE.EMAIL}
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          email: sessionStorageService.getUser()?.email || "",
        }}
        validationSchema={getValidationSchema(t, {
          email: true,
        })}
      >
        {({
          handleSubmit,
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className={classes.content}>
              <TextField
                className={classes.textFieldEmail}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                label={t("RegisterView.SignUp.Email")}
                helperText={touched.email && errors.email}
                margin="normal"
                name="email"
                onBlur={(blurEvent) => {
                  isPasswordRegisterProhibitedOnBlur(blurEvent, (email) => {
                    navigate(
                      `${pathT("route.singleSignOn")}?IsSignUp=true&email=${email}`,
                    );
                  });
                  handleBlur(blurEvent);
                }}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="standard"
                inputProps={{ autoFocus: true, "data-cy": "email" }}
              />
            </Box>
            <WizardActions
              disabled={isSubmitting}
              nextStepButtonLabel={t("OnBoarding.Next")}
            />
          </form>
        )}
      </Formik>
    </WizardContainer>
  );
};

export default EmailPage;
