import { env } from "@src/env";
import { t } from "i18next";
import { apiPaths } from "./constants";

export const getApiBaseUrl = () => {
  let hostUrl = window.location.host;
  const urlParts = new URL(`https://${hostUrl}`).hostname.split(".");

  if (urlParts.length > 1) {
    hostUrl = urlParts.slice(1).join(".");
    return `https://${env.REACT_APP_API_SUBDOMAIN}.${hostUrl}/api/`;
  }
  return `https://${hostUrl}/api/`;
};

export const getExternalLoginUrl = () => {
  return `${getApiBaseUrl()}${apiPaths.providerChallenge}`;
};

export const getMySessionsUrl = () => {
  return `${window.location.origin}/${t("route.mySessions")}`;
};

export const getSessionBookingUrl = (sessionType) => {
  return `${t("route.booking")}/${t("route.introductory")}?${t(
    "route.booking.sessionType",
  )}=${sessionType}`;
};
