import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ONBOARDING_OVERVIEW_CURRENT_PAGE } from "@src/utils/constants";
import WizardContainer from "./WizardContainer";
import WizardActions from "./WizardActions";

const useStyles = makeStyles(({ colors, breakpoints, spacing }) => ({
  content: {
    padding: "0 0 100px 0",
    [breakpoints.up("sm")]: {
      padding: spacing(3),
    },
  },
  li: {
    backgroundColor: colors.trueWhite,
    width: "100%",
    gap: spacing(3),
    borderRadius: 16,
    border: `${colors.separator} solid 1px`,
    overflow: "hidden",
    margin: spacing(2, 0),
    "&:first-child": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  description: {
    width: "100%",
    padding: spacing(1, 0, 1, 3),
  },
  title: {
    fontSize: 16,
    lineHeight: "18px",
    fontWeight: 600,
  },
  text: {
    opacity: 0.7,
    marginTop: spacing(1),
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 500,
  },
  [breakpoints.up("sm")]: {
    title: {
      fontSize: 18,
    },
    text: {
      fontSize: 16,
    },
  },
}));

const ListElement = ({ title, text, imageSrc }) => {
  const classes = useStyles();

  return (
    <Box element="li" display="flex" alignItems="center" className={classes.li}>
      <Box element="p" className={classes.description}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="subtitle1" className={classes.text}>
          {text}
        </Typography>
      </Box>
      <img alt="" src={imageSrc} width={88} height={122} />
    </Box>
  );
};

const ThanksPage = ({ moveToNextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <WizardContainer
      title={t("OnBoarding.Thanks.Title")}
      subtitle={t("OnBoarding.Thanks.Subtitle")}
      currentPage={ONBOARDING_OVERVIEW_CURRENT_PAGE.THANKS}
    >
      <Box className={classes.content} element="ul">
        <ListElement
          title={t("OnBoarding.Thanks.Item1.Title")}
          text={t("OnBoarding.Thanks.Item1.Text")}
          imageSrc="/images/onboarding/thanks-1.jpg"
        />

        <ListElement
          title={t("OnBoarding.Thanks.Item2.Title")}
          text={t("OnBoarding.Thanks.Item2.Text")}
          imageSrc="/images/onboarding/thanks-2.jpg"
        />

        <ListElement
          title={t("OnBoarding.Thanks.Item3.Title")}
          text={t("OnBoarding.Thanks.Item3.Text")}
          imageSrc="/images/onboarding/thanks-3.jpg"
        />
      </Box>
      <WizardActions
        nextStepButtonLabel={t("OnBoarding.Continue")}
        onMoveToNextStep={moveToNextStep}
      />
    </WizardContainer>
  );
};

export default ThanksPage;
