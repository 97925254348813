import { useAuthentication } from "@src/services/auth.service";
import NonLoggedIntroductoryFlow from "@src/views/booking/introductory/NonLoggedIntroductoryFlow";
import LoggedIntroductoryFlow from "@src/views/booking/introductory/LoggedIntroductoryFlow";

const IntroductoryFlow = () => {
  const { isLoggedInOrTwoFactorPending } = useAuthentication();

  return isLoggedInOrTwoFactorPending ? (
    <LoggedIntroductoryFlow />
  ) : (
    <NonLoggedIntroductoryFlow />
  );
};

export default IntroductoryFlow;
