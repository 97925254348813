import {
  useMediaQuery,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ReactComponent as ChildBoat } from "@src/resources/images/child_boat_mini.svg";
import { ReactComponent as WomanLaptop } from "@src/resources/images/woman_laptop.svg";
import { ReactComponent as Astronaut } from "@src/resources/images/astronaut.svg";
import { ReactComponent as ManRocket } from "@src/resources/images/man_rocket.svg";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    boxShadow: theme.customShadows.bookingContainerBackgroundShadow,
    borderRadius: theme.spacing(3),
  },
  cardContent: {
    display: "flex",
    position: "absolute",
    zIndex: "1",
    width: "100%",
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3.5, 2),
    },
  },
  messageTextContainer: {
    width: "100%",
  },
  messageText: {
    whiteSpace: "pre-line",
    maxWidth: 240,
    opacity: 1,
  },
  secondText: {
    whiteSpace: "pre-line",
    maxWidth: 240,
    opacity: 1,
    marginTop: theme.spacing(1),
  },
  arrow: {
    fontSize: 20,
    color: theme.colors.primaryBlue,
    justify: "flex-end",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: 360,
    [theme.breakpoints.down("sm")]: {
      height: 202,
    },
  },
  card: {
    position: "relative",
    boxShadow: "none",
  },
  womanLaptopImage: ({ isSmallScreen }) => ({
    height: isSmallScreen ? 130 : 212,
    width: isSmallScreen ? 160 : 258,
  }),
  childBoatImage: ({ isSmallScreen }) => ({
    height: isSmallScreen ? 117 : 170,
    width: isSmallScreen ? 156 : 226,
  }),
  astronautImage: ({ isSmallScreen }) => ({
    position: "relative",
    height: isSmallScreen ? 243 : 349,
    width: isSmallScreen ? 243 : 349,
    top: isSmallScreen ? 100 : 70,
    left: isSmallScreen ? 80 : 55,
  }),
  rocketManImage: ({ isSmallScreen }) => ({
    position: "relative",
    height: isSmallScreen ? 150 : 288,
    width: isSmallScreen ? 150 : 288,
    left: isSmallScreen ? 20 : -60,
    bottom: isSmallScreen ? -20 : -30,
  }),
}));

const ClickableCard = ({ imageName, messageText, secondText }) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const classes = useStyles({ isSmallScreen });

  const selectImage = () => {
    switch (imageName) {
      case "WomanLaptop":
      case "general":
        return <WomanLaptop className={classes.womanLaptopImage} />;
      case "ChildBoat":
      case "mindfulness":
        return <ChildBoat className={classes.childBoatImage} />;
      case "Astronaut":
        return <Astronaut className={classes.astronautImage} />;
      case "ManRocket":
        return <ManRocket className={classes.rocketManImage} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.cardContainer}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box className={classes.messageTextContainer}>
            <Typography variant="h5" className={classes.messageText}>
              {messageText}
            </Typography>
            {secondText && (
              <Typography variant="body1" className={classes.secondText}>
                {secondText}
              </Typography>
            )}
          </Box>
          <ArrowForwardIcon className={classes.arrow} />
        </CardContent>
        <CardMedia>
          <div className={classes.imageContainer}>{selectImage()}</div>
        </CardMedia>
      </Card>
    </div>
  );
};

export default ClickableCard;
