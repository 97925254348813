export const seconds = (amount) => amount * 1000 * 2;
export const hours = (amount) => amount * 1000 * 60 * 60;
export const minutes = (amount) => amount * 1000 * 60;

// 15 mins after which a 2FA code becomes invalid
export const twoFactorCodeExpiryPeriod = minutes(15);

// Stale time of 5 minutes is aimed at information needed by the scheduler
// The goal is to have information fresh when entering and while in a booking flow, becoming stale by the next time the user visits the flow
export const shorterStaleTime = minutes(5);

// Stale time for information that is never updated (at least in the scope of a single regular session)
export const longerStaleTime = minutes(60);

// Stale time for session-related information
// The most plausible cause of the sessions' state changing while the user is logged in is having an actual session
// Because sessions last 25 minutes, this assumes the use case of a user consulting his/her sessions right before and after a session takes place
export const consultStaleTime = minutes(20);

export const spaceStaleTime = minutes(0);
