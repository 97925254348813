import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { noop } from "lodash";

import { Typography } from "@mui/material";
import { LOW_SCORE_THRESHOLD, VALUE_LABEL_HEIGHT } from "@src/utils/constants";
import { getScoreLayout } from "@src/utils/helpers";
import clsx from "clsx";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { Icon } from "../Icon";

const useStyles = makeStyles(({ colors }) => ({
  valueLabel: ({
    innerBarColor,
    offset,
    barWidth,
    barTextColor,
    valueLabelHeight,
  }) => ({
    height: `${valueLabelHeight}px`,
    background: innerBarColor,
    // marginTop: `${offset}px`,
    width: `${barWidth}px`,
    color: barTextColor,
    // display: 'inline-block',
    // lineHeight: '25px',
    borderRadius: "30px",
    textAlign: "center",
    position: "absolute",
    top: offset,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "36px",
  }),
  image: {
    width: "20px",
    height: "20px",
    marginTop: "5px",
  },
  rotatedLabelText: ({ labelColor }) => ({
    fontSize: "14px",
    // Make text overflow to the left instead of right
    width: 0,
    whiteSpace: "nowrap",
    direction: "rtl",
    // /Make text overflow to the left instead of right
    transform: "rotate(-60deg) translate(-20px, 28px)",
    transformOrigin: "bottom right",
    color: labelColor,
    fontWeight: "bold",
    position: "absolute",
    bottom: 0,
  }),
  labelText: ({ labelColor }) => ({
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "bold",
    bottom: -24,
    color: labelColor,
    whiteSpace: "nowrap",
    position: "absolute",
  }),
  barGroup: ({ barHeight, barSpacingWidth, barWidth }) => ({
    height: `${barHeight}px`,
    margin: `0px ${barSpacingWidth}px`,
    background: colors.primarySkin,
    lineHeight: "100px",
    borderRadius: "30px",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: barWidth,
    minWidth: barWidth,
  }),
  valueText: ({ barTextColor }) => ({
    color: barTextColor,
    fontSize: 14,
    opacity: 1,
  }),
}));

export const BarGroup = ({
  xAxisLabelHeight,
  barWidth,
  barSpacingWidth,
  barHeight,
  rotateXAxisLabels,
  item,
  labelText,
  scoreText,
  labelPosition,
  labelComponent,
  onClick,
}) => {
  const {
    barColor: innerBarColor,
    textColor: barTextColor,
    offset,
    labelColor,
  } = getScoreLayout({
    score: item.score,
    maxOffset: barHeight - VALUE_LABEL_HEIGHT,
  });

  // Calculate the offset for the item on the bar graph
  const classes = useStyles({
    innerBarColor,
    offset,
    barWidth,
    barTextColor,
    barHeight,
    xAxisLabelHeight,
    barSpacingWidth,
    labelColor,
    valueLabelHeight: VALUE_LABEL_HEIGHT,
    labelPosition,
  });

  const { pathT } = useTranslatedNavigate();

  return (
    <Link
      onClick={onClick || noop}
      to={onClick ? pathT("route.themes", { key: item.key }) : ""}
      className={clsx(classes.barGroup, "themebar-groupbar")}
    >
      <div className={classes.valueLabel}>
        {item.score >= LOW_SCORE_THRESHOLD ? (
          <Icon iconKey={item.key} className={classes.image} />
        ) : (
          <Icon iconKey={item.key} />
        )}
        <Typography className={classes.valueText}>{scoreText}</Typography>
      </div>
      {!labelComponent && (
        <Typography
          className={
            rotateXAxisLabels ? classes.rotatedLabelText : classes.labelText
          }
        >
          {labelText}
        </Typography>
      )}
      {labelComponent && labelComponent}
    </Link>
  );
};

BarGroup.propTypes = {
  barWidth: PropTypes.number,
  barSpacingWidth: PropTypes.number,
  barHeight: PropTypes.number,
};
