import { useState, useEffect } from "react";
import { useSchedulerBookConsult } from "@src/queries/booking";
import { TAG_TYPES } from "@src/utils/constants";
import authService from "@src/services/auth.service";
import { updateUserLanguageCode } from "@src/queries/user";
import { useLocation, useOutletContext } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { verifyLanguage, verifySessionType } from "@src/utils/helpers";
import { IsGuestTwoFactorAuthenticationEnabled } from "@src/queries/twoFactorAuthentication";
import PhoneNumberComponentContainer from "@src/views/booking/components/PhoneNumberComponentContainer";
import { withSnackbar } from "@src/components/SnackBarComponent.jsx";
import BookingConfirmation from "../components/BookingConfirmation";
import TimeSlotPicker from "../components/TimeSlotPicker";
import Language from "../components/Language";
import SessionType from "./components/SessionType";

const STEPS = {
  SESSION_TYPE: "route.booking.sessionType",
  LANGUAGE: "route.booking.language",
  TIMESLOTS: "route.booking.timeslots",
  PHONE_NUMBER: "route.booking.phoneNumber",
  CONFIRMATION: "route.booking.confirmation",
};

const LoggedIntroductoryFlow = ({ ...props }) => {
  const currentUser = authService.getUserFromStorage();
  const { t } = useTranslatedNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const URLSessionType = verifySessionType(
    urlSearch.get(t("route.query.session_type")),
  );
  const URLLanguage = verifyLanguage(urlSearch.get(t("route.query.language")));

  const {
    step,
    goToStep,
    nextStep,
    setPreventGoingBack,
    setupFlowSteps,
    isFlowSetup,
    addStepToSkip,
  } = useOutletContext();
  const { i18n } = useTranslatedNavigate();

  const [consult, setConsult] = useState("");
  const [sessionType, setSessionType] = useState(URLSessionType);
  const [language, setLanguage] = useState(URLLanguage);
  const [selectedDateTime, setSelectedDateTime] = useState();
  const updateUserLanguage = (languageCode, logout) =>
    updateUserLanguageCode(languageCode, logout);

  useEffect(() => {
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (URLSessionType) {
      addStepToSkip(STEPS.SESSION_TYPE);
    }
    if (URLLanguage) {
      addStepToSkip(STEPS.LANGUAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [isFlowSetup]);

  const onFailureBookConsult = () => {
    setIsSubmitting(false);
    props.snackbarShowMessage(t("InvalidActionView.SomethingWentWrong"));
  };
  const { mutateAsync: bookConsultQuery } = useSchedulerBookConsult({
    onFailureBookConsult,
  });
  const bookConsult = () => {
    if (!sessionType || !selectedDateTime || !language) {
      return undefined;
    }
    const sessionTypeTagName = sessionType;
    const sessionTypeTagTypeName = TAG_TYPES.SESSION_TYPE;
    const languageTagName = language;
    const languageTypeTagTypeName = TAG_TYPES.SESSION_LANGUAGE;
    const tags = [
      {
        tagName: sessionTypeTagName,
        tagType: sessionTypeTagTypeName,
      },
      {
        tagName: languageTagName,
        tagType: languageTypeTagTypeName,
      },
    ];
    return bookConsultQuery({
      time: selectedDateTime.startTime,
      timezone: selectedDateTime.timezone,
      tags,
    });
  };

  useEffect(() => {
    if (step === STEPS.CONFIRMATION) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  const selectLoggedInStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.SESSION_TYPE:
        return (
          <SessionType
            moveToNextStep={nextStep}
            setSessionType={setSessionType}
          />
        );
      case STEPS.LANGUAGE:
        return (
          <Language
            moveToNextStep={nextStep}
            setLanguage={setLanguage}
            sessionType={sessionType}
          />
        );
      case STEPS.TIMESLOTS:
        return (
          <TimeSlotPicker
            moveToNextStep={async () => {
              updateUserLanguage(i18n.language, false);
              if (
                !!currentUser.hasValidPhoneNumber ||
                IsGuestTwoFactorAuthenticationEnabled(currentUser)
              ) {
                // skipping the next step
                setIsSubmitting(true);
                const bookedConsult = await bookConsult();
                setConsult(bookedConsult);
                goToStep(STEPS.CONFIRMATION);
              } else {
                nextStep();
              }
            }}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
            sessionType={sessionType}
            language={language}
            isSubmitting={isSubmitting}
          />
        );
      case STEPS.PHONE_NUMBER:
        // this step is skipped when the existing phone number is already valid
        return (
          <PhoneNumberComponentContainer
            moveToNextStep={async () => {
              const bookedConsult = await bookConsult();
              setConsult(bookedConsult);
              nextStep();
            }}
          />
        );
      case STEPS.CONFIRMATION:
        return <BookingConfirmation consult={consult} />;
      default:
        return null;
    }
  };

  return <>{selectLoggedInStep(step)}</>;
};

export default withSnackbar(LoggedIntroductoryFlow);
