import React from "react";
import YouTube from "react-youtube";
import getYouTubeId from "get-youtube-id";

interface LessonVideoProps {
  videoUrl: string;
}

const LessonVideo: React.FC<LessonVideoProps> = ({ videoUrl }) => {
  return (
    <YouTube
      videoId={getYouTubeId(videoUrl) || undefined}
      iframeClassName="rounded-lg overflow-hidden w-full h-auto aspect-video"
      className="p-4 pb-24 sm:p-0"
    />
  );
};

export default LessonVideo;
