import { useEffect, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import MedicalCheckInDetails from "@src/views/medical/components/MedicalCheckInDetails";
import sessionStorageService from "@src/services/sessionStorage.service";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import MedicalMagicLink from "@src/views/medical/components/MedicalMagicLink";
import ExplicitConsent from "@src/components/ExplicitConsent";
import { registerGuest } from "@src/actions/userActions";
import { connect } from "react-redux";
import CenteredLoader from "@src/components/CenteredLoader";

const STEPS = {
  DETAILS: "details",
  EXPLICIT_CONSENT: "explicit_consent",
  MAGIC_LINK: "magic_link",
};

const PostMedicalCheckInFlow = ({ registerGuestUser }) => {
  const { pathT, t } = useTranslatedNavigate();
  const { step, setPreventGoingBack, setupFlowSteps, navigateToUrl, nextStep } =
    useOutletContext();

  const firstName = useRef("");
  const lastName = useRef("");
  const email = useRef("");

  const tripettoAnswers = sessionStorageService.getCheckinLocal();

  useEffect(() => {
    if (!tripettoAnswers) {
      navigateToUrl(`${pathT("route.medical")}/${t("route.medical-checkin")}`);
    }
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (step === STEPS.MAGIC_LINK) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  const setEmail = (userEmail) => {
    email.current = userEmail;
  };

  const selectStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.DETAILS:
        return (
          <MedicalCheckInDetails
            setEmail={setEmail}
            moveToNextStep={(values) => {
              firstName.current = values.firstName;
              sessionStorageService.saveNameToStorage(values.firstName);
              lastName.current = values.lastName;
              email.current = values.email;
              nextStep();
            }}
          />
        );

      case STEPS.EXPLICIT_CONSENT:
        return (
          <ExplicitConsent
            moveToNextStep={async (
              termsAndConditions,
              medicalDataProcessing,
            ) => {
              await registerGuestUser(
                firstName.current,
                lastName.current,
                email.current,
                termsAndConditions,
                medicalDataProcessing,
              );
              nextStep();
            }}
          />
        );

      case STEPS.MAGIC_LINK:
        return <MedicalMagicLink email={email.current} />;

      default:
        return <CenteredLoader />;
    }
  };

  return <>{selectStep(step)}</>;
};

const mapDispatchToProps = (dispatch) => ({
  registerGuestUser: (
    firstName,
    lastName,
    email,
    termsAndConditions,
    medicalDataProcessing,
  ) =>
    dispatch(
      registerGuest(
        firstName,
        lastName,
        email,
        null,
        null,
        null,
        termsAndConditions,
        medicalDataProcessing,
        true,
        false,
        true,
      ),
    ),
});

export default connect(null, mapDispatchToProps)(PostMedicalCheckInFlow);
