import Layout from "@src/layouts/Layout";
import { ThemeProvider } from "@mui/material";
import medicalTheme from "@src/theme/medicalTheme";
import theme, { colors as themeColors } from "@src/theme";

const LibraryLayout = ({ showFooter = true, isMedical = false }) => {
  const currentTheme = isMedical ? medicalTheme : theme;

  return (
    <ThemeProvider theme={currentTheme}>
      <Layout
        backgroundColor={themeColors.primaryOffWhite}
        showFooter={showFooter}
        isMedical={isMedical}
      />
    </ThemeProvider>
  );
};

export default LibraryLayout;
