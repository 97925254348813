import { useAuthentication } from "@src/services/auth.service";
import NonLoggedBookingFlow from "@src/views/booking/NonLoggedBookingFlow";
import LoggedBookingFlow from "@src/views/booking/LoggedBookingFlow";

const BookingView = () => {
  const { isLoggedInOrTwoFactorPending } = useAuthentication();

  if (isLoggedInOrTwoFactorPending) {
    return <LoggedBookingFlow />;
  }
  return <NonLoggedBookingFlow />;
};

export default BookingView;
