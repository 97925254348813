import { Box, Button, Icon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as ManShouting } from "@src/resources/images/man_shouting.svg";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { CONSULT_TYPE } from "@src/utils/constants";
import { getSessionBookingUrl } from "@src/utils/urlHelpers.js";
import BookingContainer from "../../booking/components/BookingContainer";
import BookingCard from "../../booking/components/BookingCard";

const useStyles = makeStyles((theme) => ({
  emailText: {
    marginTop: theme.spacing(2),
    opacity: 0.7,
  },
  image: {
    marginTop: theme.spacing(15.5),
    marginLeft: theme.spacing(10),
    marginBottom: theme.spacing(-1),
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      height: 210,
      width: 210,
      top: 60,
      marginTop: theme.spacing(-5),
      left: "30%",
    },
  },
  buttonBooking: {
    margin: theme.spacing(0, 10),
    width: "100%",
  },
  buttonText: {
    letterSpacing: 1.25,
    fontWeight: 500,
    opacity: 1,
  },
  bulletPoints: {
    display: "flex",
    marginTop: theme.spacing(1.5),
  },
  bulletText: {
    opacity: 0.7,
  },
  bulletCheck: {
    fontSize: "18px",
    width: "30px",
    lineHeight: "32px",
    height: "100%",
    color: "rgb(176, 216, 157)",
  },
  bulletUncheck: {
    fontSize: "5px",
    width: "30px",
    lineHeight: "32px",
    height: "100%",
    paddingLeft: theme.spacing(1),
  },
}));

const MedicalMagicLink = ({ email }) => {
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();

  const bookSession = () => {
    const url = pathT(getSessionBookingUrl(CONSULT_TYPE.PHYSICAL_WELLBEING));
    window.open(url, "_blank");
  };

  const bulletPoint = (text, isCheckMark) => (
    <Box className={classes.bulletPoints}>
      {isCheckMark ? (
        <Icon className={classes.bulletCheck}>check</Icon>
      ) : (
        <Icon className={classes.bulletUncheck}>circle</Icon>
      )}
      <Typography variant="body1" className={classes.bulletText}>
        {text}
      </Typography>
    </Box>
  );

  return (
    <BookingContainer title={t("Medical.CheckIn.MagicLink.Title")}>
      <BookingCard
        image={<ManShouting className={classes.image} />}
        actions={
          <Button
            className={classes.buttonBooking}
            type="submit"
            onClick={bookSession}
          >
            <Typography variant="body2" className={classes.buttonText}>
              {t("Medical.CheckIn.MagicLink.CTA")}
            </Typography>
          </Button>
        }
      >
        <Typography variant="subtitle1">
          {t("Medical.CheckIn.MagicLink.Subtitle")}
        </Typography>
        <Typography variant="body1" className={classes.emailText}>
          {email}
        </Typography>
        {bulletPoint(t("Medical.CheckIn.MagicLink.BulletPoint_1"), true)}
        {bulletPoint(t("Medical.CheckIn.MagicLink.BulletPoint_2"), false)}
        {bulletPoint(t("Medical.CheckIn.MagicLink.BulletPoint_3"), false)}
      </BookingCard>
    </BookingContainer>
  );
};

export default MedicalMagicLink;
