import { getValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import { Formik } from "formik";
import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import imageWomanSitting from "@src/resources/images/womansit.png";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import sessionStorageService from "@src/services/sessionStorage.service";

const useStyles = makeStyles(() => ({
  pageMargin: {
    "@media (max-width: 599px)": {},
    "@media (min-width: 600px)": {
      width: "360px !important",
      margin: "auto",
    },
  },
  imageWomanSitting: {
    "@media (max-width: 599px)": {
      position: "absolute",
      right: "0px",
    },
    "@media (min-width: 600px)": {
      position: "relative",
      right: "-125px",
    },
  },
}));

const CreateAccountStep1 = ({ moveToNextStep }) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const onSubmit = (values) => {
    moveToNextStep(values);
  };

  return (
    <Formik
      initialValues={{
        email: sessionStorageService.getUserEmail() || "",
        firstName: sessionStorageService.getNameFromStorage() || "",
      }}
      validationSchema={getValidationSchema(t, {
        email: true,
        firstName: true,
      })}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} className={classes.pageMargin}>
          <Box>
            <Box mb={3}>
              <Typography variant={isSmallScreen ? "h3" : "h1"}>
                {t("CreateAccountView.StepFirstHeading")}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body2" style={{ fontSize: "16px" }}>
                {t("CreateAccountView.StepFirstTitleMessage")}
              </Typography>
            </Box>
            <TextField
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              label={t("CreateAccountView.FirstName")}
              margin="normal"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              variant="standard"
            />
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label={t("CreateAccountView.Email")}
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="standard"
            />
            <Box>
              <Button fullWidth size="large" type="submit" variant="contained">
                <Typography
                  variant="body2"
                  style={{ color: "#ffffff", opacity: "100" }}
                >
                  {t("CreateAccountView.Next")}
                </Typography>
              </Button>
            </Box>
            <img
              className={classes.imageWomanSitting}
              alt=""
              src={imageWomanSitting}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateAccountStep1;
