import AuthService, { getUserProfile } from "@src/services/auth.service";
import { pushPreferencesFromStorageToServer } from "@src/services/preferences.service";
import { pushCurrentMoodsFromStorageToServer } from "@src/services/currentMoods.service";
import { saveUnconfirmedCheckin } from "@src/services/tripetto.service";
import sessionStorageService from "@src/services/sessionStorage.service";
import localStorageService from "@src/services/localStorage.service";

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  TWO_FACTOR_PENDING,
  TWO_FACTOR_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_FAIL,
  VERIFY_SUCCESS,
  VERIFY_FAIL,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  CLEAR_MESSAGE,
  REGISTER_GUEST_SUCCESS,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  REGISTER_EXTERNAL_SUCCESS,
} from "./typeActions";

const processPostRegistrationData = async (
  email,
  unconfirmedDataToken,
  isGuestUser = false,
) => {
  const tripettoAnswers = sessionStorageService.getCheckinLocal();
  if (tripettoAnswers && tripettoAnswers.formModules) {
    await saveUnconfirmedCheckin(
      email,
      unconfirmedDataToken,
      tripettoAnswers.formModules,
      tripettoAnswers.formLanguage,
      tripettoAnswers.answers,
      isGuestUser,
    );
  }
  await pushPreferencesFromStorageToServer(email, unconfirmedDataToken);
  await pushCurrentMoodsFromStorageToServer(email, unconfirmedDataToken);
};

export const registerGuest =
  (
    firstName,
    lastName,
    email,
    yearOfBirth = null,
    employerId = null,
    phoneNumber = null,
    termsAndConditions = false,
    medicalDataProcessing = false,
    isCheckIn = false,
    isPartner = false,
    isMedicalCheckIn = false,
  ) =>
  async (dispatch) => {
    try {
      const unconfirmedDataToken = await AuthService.registerGuestUser(
        firstName,
        lastName,
        email,
        yearOfBirth,
        employerId,
        phoneNumber,
        termsAndConditions,
        medicalDataProcessing,
        isCheckIn,
        isPartner,
        isMedicalCheckIn,
      );
      localStorageService.setUnconfirmedDataSignature(unconfirmedDataToken);
      await processPostRegistrationData(email, unconfirmedDataToken);
      dispatch({
        type: REGISTER_GUEST_SUCCESS,
        payload: {
          user: {
            email,
          },
        },
      });
    } catch (error) {
      // TODO - ??
    }
  };

export const register =
  (
    firstName,
    lastName,
    email,
    yearOfBirth,
    password,
    employerId,
    termsAndConditions,
    medicalDataProcessing,
    isPartner,
  ) =>
  async (dispatch) => {
    try {
      const unconfirmedDataToken = await AuthService.registerUser(
        firstName,
        lastName,
        email,
        yearOfBirth,
        password,
        employerId,
        termsAndConditions,
        medicalDataProcessing,
        isPartner,
      );
      localStorageService.setUnconfirmedDataSignature(unconfirmedDataToken);
      await processPostRegistrationData(
        email,
        unconfirmedDataToken?.unconfirmedDataId,
      );
      dispatch({
        type: REGISTER_SUCCESS,
        payload: {
          user: {
            email,
          },
        },
      });
    } catch (error) {
      return Promise.reject(error);
    }
    return undefined;
  };

export const update =
  (
    firstName,
    lastName,
    email,
    yearOfBirth,
    signUpToNewsletter,
    employerId,
    termsAndConditions,
    medicalDataProcessing,
    isPartner,
  ) =>
  async (dispatch) => {
    try {
      const response = await AuthService.updateUser({
        firstName,
        lastName,
        email,
        yearOfBirth,
        signUpToNewsletter,
        employerId,
        termsAndConditions,
        medicalDataProcessing,
        isPartner,
      });
      await processPostRegistrationData(email);
      dispatch({
        type: UPDATE_SUCCESS,
        payload: { user: response },
      });
      return response;
    } catch (error) {
      dispatch({
        type: UPDATE_FAIL,
        payload: {
          user: null,
          error: error.request.responseText || error.message,
        },
      });
    }
    return undefined;
  };

export const verifyEmail = (code, email) => async (dispatch) => {
  const codeWithPlusInsteadOfSpace = code?.replaceAll(" ", "+");
  try {
    const response = await AuthService.verifyUserAccount(
      codeWithPlusInsteadOfSpace,
      email,
    );
    dispatch({
      type: VERIFY_SUCCESS,
      payload: {
        user: response,
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: VERIFY_FAIL,
      payload: {
        user: error.response.data ? error.response.data : null,
        error: error.request.responseText || error.message,
      },
    });
    return Promise.reject(error);
  }
};

export const resendCodeEmail = (email) => async (dispatch) => {
  try {
    const response = await AuthService.resendVerificationEmail(email);

    dispatch({
      type: RESEND_CODE_SUCCESS,
      payload: {
        successMessage: response,
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: RESEND_CODE_FAIL,
      payload: {
        error: error.request.responseText || error.message,
      },
    });
    return Promise.reject(error);
  }
};

export const getGuestUser = (token, email) => async (dispatch) => {
  try {
    const data = await AuthService.getGuestUser(token, email);
    if (data?.twoFactorVerification === "pending") {
      dispatch({
        type: TWO_FACTOR_PENDING,
        payload: {
          user: data,
        },
      });
    } else {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
    }
    return Promise.resolve(data);
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      error: error.request.responseText || error.message,
    });
    return Promise.reject();
  }
};

export const getExternalUser = (idToken, provider) => async (dispatch) => {
  try {
    const data = await AuthService.getExternalUser(idToken, provider);
    if (data?.twoFactorVerification === "pending") {
      dispatch({
        type: TWO_FACTOR_PENDING,
        payload: {
          user: data,
        },
      });
    } else {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
    }
    return Promise.resolve(data);
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      error: error.request.responseText || error.message,
    });
    return Promise.reject(error);
  }
};

export const getExternalUserCodeFlow = (code, provider) => async (dispatch) => {
  try {
    const data = await AuthService.getExternalUserCodeFlow(code, provider);
    if (data?.twoFactorVerification === "pending") {
      dispatch({
        type: TWO_FACTOR_PENDING,
        payload: {
          user: data,
        },
      });
    } else {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
    }
    return Promise.resolve(data);
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      error: error.request.responseText || error.message,
    });
    return Promise.reject(error);
  }
};

export const signUpExternalUserCodeFlow =
  (code, provider) => async (dispatch) => {
    try {
      const data = await AuthService.signUpExternalUserCodeFlow(code, provider);
      if (
        data.twoFactorVerification &&
        data.twoFactorVerification === "pending"
      ) {
        dispatch({
          type: TWO_FACTOR_PENDING,
          payload: {
            user: data,
          },
        });
      } else {
        dispatch({
          type: REGISTER_EXTERNAL_SUCCESS,
          payload: { user: data },
        });
      }
      return Promise.resolve(data);
    } catch (error) {
      dispatch({
        type: REGISTER_FAIL,
        error: error.request.responseText || error.message,
      });
      return Promise.reject(error);
    }
  };

export const signUpExternalUser = (idToken, provider) => async (dispatch) => {
  try {
    const data = await AuthService.signUpExternalUser(idToken, provider);

    if (data?.twoFactorVerification === "pending") {
      dispatch({
        type: TWO_FACTOR_PENDING,
        payload: {
          user: data,
        },
      });
    } else {
      dispatch({
        type: REGISTER_EXTERNAL_SUCCESS,
        payload: { user: data },
      });
    }
    return Promise.resolve(data);
  } catch (error) {
    dispatch({
      type: REGISTER_FAIL,
      error: error.request.responseText || error.message,
    });
    return Promise.reject(error);
  }
};

export const refreshAccessToken = (email, token) => async (dispatch) => {
  try {
    await AuthService.refreshAccessToken(email, token);
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: { error: error.request.responseText || error.message },
    });
  }
};

export const login = (userName, password) => async (dispatch) => {
  try {
    const data = await AuthService.login(userName, password);
    if (data?.twoFactorVerification === "pending") {
      dispatch({
        type: TWO_FACTOR_PENDING,
        payload: {
          user: data,
        },
      });
    } else {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
    }
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: {
        user: {
          email: userName,
        },
        error: {
          message: error.request.responseText || error.message,
          status: error.response.status,
        },
      },
    });
  }
};

export const twoFactorSuccessAction = () => async (dispatch) => {
  try {
    const data = await getUserProfile();
    dispatch({
      type: TWO_FACTOR_SUCCESS,
      payload: { user: data },
    });
    return Promise.resolve(data);
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: { error: error.request.responseText || error.message },
    });
    return Promise.reject(error);
  }
};

export const loginAfterTwoFactorVerification =
  (userEmail) => async (dispatch) => {
    try {
      const data = await getUserProfile();
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
    } catch (error) {
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          user: {
            email: userEmail,
          },
          error: {
            message: error.request.responseText || error.message,
            status: error.response.status,
          },
        },
      });
    }
  };

export const resetPasswordEmail = (email) => async (dispatch) => {
  try {
    const response = await AuthService.sendForgotPasswordAccessMail(email);
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: {
        user: { email },
        successMessage: response.message,
        warningMessage: response.isSuccess ? "" : response.message,
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: { error: error.request?.responseText || error.message },
    });
    return Promise.reject(error);
  }
};

export const updateUserPassword =
  (code, userid, password) => async (dispatch) => {
    const codeWithPlusInsteadOfSpace = code?.replaceAll(" ", "+");
    try {
      const response = await AuthService.updateUserPassword(
        codeWithPlusInsteadOfSpace,
        userid,
        password,
      );
      dispatch({
        type: VERIFY_SUCCESS,
        payload: {
          user: response,
          successMessage: response.message,
          warningMessage: response.isSuccess ? "" : response.message,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      dispatch({
        type: VERIFY_FAIL,
        payload: {
          user: error.response.data ? error.response.data : null,
          error: error.request.responseText || error.message,
        },
      });
      return Promise.reject();
    }
  };

export const logout = () => async (dispatch) => {
  await AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const clearMessages = () => (dispatch) => {
  dispatch({
    type: CLEAR_MESSAGE,
    payload: { successMessage: null, warningMessage: null },
  });
};
