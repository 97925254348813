import { useEffect } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useLocation, useOutletContext } from "react-router-dom";
import { useGetLastConfirmedConsultQuery } from "@src/queries/booking";
import { verifyLanguage, verifySessionType } from "@src/utils/helpers";

const LoggedBookingFlow = () => {
  const { t, pathT } = useTranslatedNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const URLSessionType = verifySessionType(
    urlSearch.get(t("route.query.session_type")),
  );
  const URLLanguage = verifyLanguage(urlSearch.get(t("route.query.language")));

  const { data: lastConsult } = useGetLastConfirmedConsultQuery();

  const { navigateToUrl } = useOutletContext();

  useEffect(() => {
    if (lastConsult) {
      navigateToUrl(`${pathT("route.booking")}/${t("route.follow_up")}`);
    } else if (URLSessionType || URLLanguage) {
      navigateToUrl(
        `${pathT("route.booking")}/${t("route.introductory")}?${urlSearch}`,
      );
    } else {
      navigateToUrl(`${pathT("route.booking")}/${t("route.introductory")}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  return null;
};

export default LoggedBookingFlow;
