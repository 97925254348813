import axios from "axios";
import { apiPaths } from "@src/utils/constants";
import sessionStorageService from "./sessionStorage.service";

export const saveCheckin = async (formModules, formLanguage, answers) => {
  try {
    const { data } = await axios.post("form/form-answers", {
      formModules,
      formLanguage,
      answers,
    });

    sessionStorageService.removeCheckinLocal();
    return data;
  } catch {
    return sessionStorageService.saveCheckinLocal(
      formModules,
      formLanguage,
      answers,
    );
  }
};

export const saveUnconfirmedCheckin = async (
  email,
  unconfirmedDataId,
  formModules,
  formLanguage,
  answers,
) => {
  try {
    const { data } = await axios.post(
      apiPaths.setTripettoUnconfirmedFormAnswers,
      {
        email,
        unconfirmedDataId,
        formModules,
        formLanguage,
        answers,
      },
    );
    sessionStorageService.removeCheckinLocal();
    return data;
  } catch {
    return sessionStorageService.saveCheckinLocal(
      formModules,
      formLanguage,
      answers,
    );
  }
};
