import { useEffect, useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Language from "@src/views/booking/components/Language";
import {
  usePsychologistTagsQuery,
  useSchedulerBookConsult,
  useSchedulerBookConsultWithPsychologist,
} from "@src/queries/booking";
import TimeSlotPicker from "@src/views/booking/components/TimeSlotPicker";
import { STATUS, TAG_TYPES } from "@src/utils/constants";
import BookingConfirmation from "@src/views/booking/components/BookingConfirmation";
import {
  filterTagsOfType,
  getConsultType,
  isPsychologistInactive,
} from "@src/utils/helpers";
import { useConsultQuery } from "@src/queries/consults";
import { withSnackbar } from "@src/components/SnackBarComponent.jsx";

const STEPS = {
  LANGUAGE: "route.booking.language",
  TIMESLOTS: "route.booking.timeslots",
  CONFIRMATION: "route.booking.confirmation",
};

const RebookFlow = ({ ...props }) => {
  const { rebookConsultId } = useParams();
  const {
    step,
    nextStep,
    navigateToUrl,
    addStepToSkip,
    setPreventGoingBack,
    setupFlowSteps,
    isFlowSetup,
  } = useOutletContext();
  const { pathT } = useTranslatedNavigate();

  const [language, setLanguage] = useState();
  const [sessionType, setSessionType] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState();
  const [consult, setConsult] = useState();
  const [presetLanguages, setPresetLanguages] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslatedNavigate();

  const { data: oldConsult } = useConsultQuery(
    rebookConsultId,
    null,
    STATUS.SCHEDULED,
  );
  const { data: psychologistTags } = usePsychologistTagsQuery(
    oldConsult?.employeeId,
  );

  const onFailureBookConsult = () => {
    setIsSubmitting(false);
    props.snackbarShowMessage(t("InvalidActionView.SomethingWentWrong"));
  };

  const { mutateAsync: bookConsultQuery } = useSchedulerBookConsult({
    onFailureBookConsult,
  });
  const { mutateAsync: bookConsultWithPsychologistQuery } =
    useSchedulerBookConsultWithPsychologist();

  useEffect(() => {
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (step === STEPS.CONFIRMATION) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  useEffect(() => {
    if (!oldConsult) {
      navigateToUrl(pathT("route.booking"));
      return undefined;
    }

    // Old sessions may not have session type and/or language assigned
    setSessionType(getConsultType(oldConsult));
    setPresetLanguages(
      filterTagsOfType(psychologistTags, TAG_TYPES.PSYCHOLOGIST_LANGUAGE),
    );

    if (
      !isPsychologistInactive(psychologistTags) &&
      !!oldConsult.consultLanguage
    ) {
      setLanguage(oldConsult.consultLanguage);
      addStepToSkip(STEPS.LANGUAGE);
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [oldConsult, psychologistTags, isFlowSetup]);

  const bookConsult = () => {
    const tags = [
      {
        tagName: sessionType,
        tagType: TAG_TYPES.SESSION_TYPE,
      },
      {
        tagName: language,
        tagType: TAG_TYPES.SESSION_LANGUAGE,
      },
    ];
    if (!isPsychologistInactive(psychologistTags)) {
      return bookConsultWithPsychologistQuery({
        time: selectedDateTime.startTime,
        timezone: selectedDateTime.timezone,
        tags,
        psychologistId: oldConsult.employeeId,
      });
    }
    return bookConsultQuery({
      time: selectedDateTime.startTime,
      timezone: selectedDateTime.timezone,
      tags,
    });
  };

  const selectStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.LANGUAGE:
        return (
          <Language
            moveToNextStep={nextStep}
            setLanguage={setLanguage}
            available={!isPsychologistInactive(psychologistTags)}
            firstname={oldConsult.employeeFullName.split(" ")[0]}
            sessionType={sessionType}
            presetLanguages={
              !isPsychologistInactive(psychologistTags) ? presetLanguages : null
            }
          />
        );
      case STEPS.TIMESLOTS:
        return (
          <TimeSlotPicker
            moveToNextStep={async () => {
              setIsSubmitting(true);
              const bookedConsult = await bookConsult();
              setConsult(bookedConsult);
              nextStep();
            }}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
            sessionType={sessionType}
            language={language}
            psychologistId={
              !isPsychologistInactive(psychologistTags)
                ? oldConsult.employeeId
                : null
            }
            timezone={oldConsult.clientTimezone}
            isSubmitting={isSubmitting}
          />
        );

      case STEPS.CONFIRMATION:
        return <BookingConfirmation consult={consult} />;

      default:
        return null;
    }
  };

  if (oldConsult) {
    return selectStep(step);
  }

  return null;
};

export default withSnackbar(RebookFlow);
