import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useMediaQuery, Box, Button, Typography } from "@mui/material";
import BookingContainer from "@src/views/booking/components/BookingContainer";
import BookingCard from "@src/views/booking/components/BookingCard";
import { makeStyles } from "@mui/styles";
import { ReactComponent as ManFitness } from "@src/resources/images/man_yoga_pose.svg";
import { createTheme } from "@mui/material/styles";
import BackToMyOpenUpButton from "@src/views/booking/spaces/components/BackToMyOpenUpButton";

import { env } from "@src/env";

const useStyles = makeStyles((theme) => ({
  bookingDetailsMessage: {
    maxWidth: 240,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 296,
    },
    marginBottom: theme.spacing(3),
  },
  typography: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    display: "flex",
    alignItems: "center",
  },
  image: {
    float: "right",
    marginTop: theme.spacing(10.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      width: 152,
      height: 152,
      position: "relative",
      top: theme.spacing(-2.5),
      marginBottom: theme.spacing(-2.5),
      marginRight: theme.spacing(-2.25),
    },
  },
  box: {
    maxWidth: 240,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 296,
    },
    marginBottom: theme.spacing(2),
  },
  boxCalendarWithIcon: {
    maxWidth: 240,
  },
  cancelSessionButton: {
    opacity: 1,
    color: "white",
    fontWeight: 500,
    letterSpacing: theme.spacing(0.15625),
  },
  button: {
    opacity: 1,
    fontWeight: 500,
    width: 240,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0.5),
    },
  },
  rescheduleSessionButton: {
    color: "000000",
    background: "white",
    border: `2px solid ${theme.colors.primaryDarkBlue}`,
    "&:hover": {
      backgroundColor: theme.colors.lightGray,
    },
  },
  rescheduleSessionButtonText: {
    fontWeight: 500,
    letterSpacing: theme.spacing(0.15625),
  },
  boxFooter: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  sessionDetailsText: {
    maxWidth: 296,
    alignItems: "flex-start",
    color: theme.colors.primaryDarkBlue,
  },
  sessionDetailsIcon: {
    fontSize: theme.spacing(3),
    color: theme.colors.primaryDarkBlue,
    marginRight: theme.spacing(2),
  },
}));
const SpaceCancellationConfirmed = () => {
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ isSmallScreen });
  const { t } = useTranslatedNavigate();

  const bookSpace = () => {
    const url = t("route.website.spaces", {
      WebsiteURL: env.REACT_APP_WEBSITE_DOMAIN,
    });

    window.open(url, "_blank");
  };

  return (
    <BookingContainer message="" title={t("Space.UnsubscribeConfirmed.Title")}>
      <BookingCard
        image={<ManFitness className={classes.image} />}
        actions={
          <Box className={classes.boxFooter}>
            <BackToMyOpenUpButton />
            <Button className={classes.button} onClick={bookSpace}>
              <Typography
                variant="body2"
                className={classes.cancelSessionButton}
              >
                {t("Space.UnsubscribeConfirmed.ViewMoreSpaces")}
              </Typography>
            </Button>
          </Box>
        }
      >
        <Typography
          variant="subtitle1"
          className={classes.bookingDetailsMessage}
        >
          {t("Space.UnsubscribeConfirmed.Message")}
        </Typography>
      </BookingCard>
    </BookingContainer>
  );
};

export default SpaceCancellationConfirmed;
