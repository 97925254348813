import { Typography, Paper, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { formatScore } from "@src/utils/formatting";
import colors from "@src/theme/colors";
import { getScoreLayout } from "@src/utils/helpers";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { Icon } from "./Icon";
import FocusThemeTileImage from "./FocusThemeTileImage";

const useStyles = makeStyles((theme) => ({
  wrapper: ({ backgroundColor, color = theme.palette.primary.main }) => ({
    backgroundColor,
    color,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    maxHeight: 352,
    height: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 280,
    },
  }),
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    alignItems: "center",
  },
  icon: ({ iconColorCode, iconOutlineColorCode }) => ({
    width: 24,
    height: 24,
    lineHeight: "12px",
    backgroundColor: iconColorCode,
    borderRadius: 30,
    color: iconOutlineColorCode,
    padding: 3,
  }),
  score: {
    backgroundColor: theme.colors.primarySkin,
    borderRadius: 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: "absolute",
    right: 32,
  },
  themeTitle: ({ color = theme.palette.primary.main }) => ({
    color,
    fontSize: 20,
    marginBottom: theme.spacing(1),
  }),
  themeContent: ({ color = theme.palette.primary.main, isFocused }) => ({
    color,
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxWidth: isFocused && 160,
    },
  }),
  arrow: ({ arrowColor }) => ({
    color: arrowColor,
    fontSize: 22,
    position: "absolute",
    fontWeight: "bold",
    bottom: 5,
    right: 0,
    opacity: 1,
  }),
  imageContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: 352,
    margin: theme.spacing(-3),
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
  },
  textBox: ({ isFocused }) => ({
    position: isFocused && "absolute",
    [theme.breakpoints.up("sm")]: {
      marginRight: isFocused && theme.spacing(3),
    },
  }),
}));

const ThemeTile = ({ theme, titleText, bodyText }) => {
  const props = getScoreLayout({ maxOffset: 0, score: theme.score || 0 });
  const { t } = useTranslatedNavigate();

  const { isFocused } = theme;
  const classesProps = {
    arrowColor: colors.white,
    iconColorCode: "transparent",
    iconOutlineColorCode: colors.white,
    backgroundColor: colors.primaryGreenDark,
    color: colors.white,
    isFocused,
  };
  if (theme.isFocused && theme.score === 0) {
    classesProps.arrowColor = colors.primaryBlue;
    classesProps.iconColorCode = colors.white;
    classesProps.iconOutlineColorCode = colors.primaryDarkBlue;
    classesProps.backgroundColor = colors.trueWhite;
    classesProps.color = colors.primaryDarkBlue;
  } else if (theme.isFocused && theme.score > 0) {
    classesProps.arrowColor = colors.primaryBlue;
    classesProps.iconColorCode = colors.primaryDarkBlue;
    classesProps.iconOutlineColorCode = colors.white;
    classesProps.backgroundColor = colors.trueWhite;
    classesProps.color = colors.primaryDarkBlue;
  } else if (theme.score > 0) {
    classesProps.arrowColor = colors.primaryBlue;
    classesProps.iconColorCode = props.labelColor;
    classesProps.backgroundColor = colors.trueWhite;
    classesProps.color = colors.primaryDarkBlue;
  }
  const classes = useStyles(classesProps);
  const routeThemeKey = t(`route.ThemeKey.${theme.key}`);
  return (
    <Link to={`/${t("route.themes", { key: routeThemeKey })}`}>
      <Paper className={classes.wrapper}>
        {!!theme.score && (
          <Typography variant="body2" className={classes.score}>
            {formatScore(theme.score)} / 5
          </Typography>
        )}
        <Box className={classes.textBox}>
          <div className={classes.topRow}>
            <Icon iconKey={theme.key} className={classes.icon} />
          </div>
          <Typography variant="h6" className={classes.themeTitle} wrap="wrap">
            {titleText}
          </Typography>
          <Typography className={classes.themeContent} wrap="wrap">
            {bodyText}
            {!theme.isFocused && <ArrowForwardIcon className={classes.arrow} />}
          </Typography>
        </Box>
        {theme.isFocused && (
          <div className={classes.imageContainer}>
            <FocusThemeTileImage theme={theme} />
          </div>
        )}
      </Paper>
    </Link>
  );
};

export default ThemeTile;
