import { useAuthentication } from "@src/services/auth.service";
import {
  useGetLastCompletedConsultWithPsychologistQuery,
  useGetPsychologistByUrlHandleQuery,
} from "@src/queries/booking";
import { Navigate, useParams } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import PsychologistUnavailableView from "@src/views/booking/personalLink/components/PsychologistUnavailableView";
import { isPsychologistInactive } from "@src/utils/helpers";
import NonLoggedPersonalFollowUpFlow from "./NonLoggedPersonalFollowUpFlow";
import LoggedPersonalFollowUpFlow from "./LoggedPersonalFollowUpFlow";

const PersonalFollowUpFlow = () => {
  const { isLoggedInOrTwoFactorPending } = useAuthentication();
  const { pathT } = useTranslatedNavigate();
  const { urlHandle } = useParams();

  const { data: psychologist } = useGetPsychologistByUrlHandleQuery(urlHandle);
  const { data: lastConsultWithPsychologist } =
    useGetLastCompletedConsultWithPsychologistQuery(psychologist?.id);

  const reroute = () => {
    if (!psychologist) {
      return <Navigate to={pathT("route.booking")} />;
    }

    if (
      !psychologist.hasLinkedCalendar ||
      isPsychologistInactive(psychologist.tags)
    ) {
      return <PsychologistUnavailableView firstname={psychologist.firstName} />;
    }

    if (isLoggedInOrTwoFactorPending) {
      if (!lastConsultWithPsychologist) {
        return (
          <PsychologistUnavailableView firstname={psychologist.firstName} />
        );
      }

      return <LoggedPersonalFollowUpFlow />;
    }

    return <NonLoggedPersonalFollowUpFlow />;
  };

  return <>{reroute()}</>;
};
export default PersonalFollowUpFlow;
