import { apiPaths } from "@src/utils/constants";
import axios from "axios";

export const getFeatureFlagValue = async (key: string): Promise<boolean> => {
  try {
    const response = await axios.get(`${apiPaths.featureFlags}/${key}`);

    const featureFlagValue = response.data as boolean;
    return featureFlagValue === true;
  } catch (error) {
    return false;
  }
};
