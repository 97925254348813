export enum LessonType {
  Article = "article-lesson",
  Video = "video-lesson",
  Audio = "audio-lesson",
  Quiz = "quiz-lesson",
}

export type EngagementStatus = "started" | "completed" | null;

export enum ContentType {
  Image = "image",
  Text = "text",
  CtaSection = "cta-section",
}

export enum TextStyle {
  H1 = "h1",
  Body = "body",
  H2 = "h2",
}

export interface ContentItem {
  type: ContentType;
  src?: string | null;
  thumbnail?: string | null;
  medium?: string | null;
  large?: string | null;
  textStyle?: TextStyle | null;
  text?: string | null;
  cta?: string | null;
  isDownloadable: boolean;
  url?: string | null;
  variation?: string | null;
  contents: ContentItem[];
}

export type ImageSizes = {
  medium: string;
  large: string;
  thumbnail: string;
  source: string;
};

export type LessonBase = {
  id: number;
  coverImage: ImageSizes;
  duration: number;
  title: string;
  engagementStatus: EngagementStatus;
  introduction: {
    heading: string;
    description: string;
    image: ImageSizes;
  };
};

export type LessonArticleType = LessonBase & {
  type: LessonType.Article;
  contents: ContentItem[];
};

export type LessonVideoType = LessonBase & {
  type: LessonType.Video;
  content: {
    description: string;
    provider: "YouTube";
    title: string;
    url: string;
  };
};

export type LessonAudioType = LessonBase & {
  type: LessonType.Audio;
  content: {
    description: string;
    provider: "Spotify";
    title: string;
    url: string;
  };
};

export type LessonQuizType = LessonBase & {
  type: LessonType.Quiz;
  content: {
    description: string;
    title: string;
    url: string;
  };
  quizContentResponse: QuizContentResponse;
  id: number;
  coverImage: ImageSizes;
  duration: number;
  title: string;
  engagementStatus: string;
  lessonNo: number;
  introduction: string | null;
};

export type QuizQuestion = {
  questionKey: string;
  questionText: string;
  questionType: string;
  answers: string[];
};

export type QuizQuestions = {
  questionListKey: string;
  languageCode: string;
  answers: QuizQuestion[];
};

export type QuizQuestionListAnswers = {
  questionListKey: string;
  languageCode: string;
  questionWithAnswers: QuizQuestion[];
};

type QuizContentResponse = {
  heading: string;
  description: string;
  score: CourseScore | null;
  questionListAnswers: QuizQuestionListAnswers;
};

export type Lesson =
  | LessonArticleType
  | LessonVideoType
  | LessonAudioType
  | LessonQuizType;

export type CourseScore = {
  key: string | null;
  score: number;
  scoreTitle: string;
  scoreDescription: string;
  scoreIconInUnicode: string;
  category: number;
  categoryTitle: string | null;
  createdAt: string;
  userId: string;
};

export type LessonInfo = {
  id: number;
  coverImage: ImageSizes;
  duration: number;
  title: string;
  type: LessonType;
  engagementStatus: EngagementStatus | null;
  description: string;
  score: CourseScore | null;
};

export type Course = {
  id: number;
  title: string;
  learningGoals: string[];
  description: string;
  lessons: LessonInfo[];
  coverImage: ImageSizes;
  duration: number;
  themes: string[];
};

export type QuizResultsResponse = {
  key: string;
  score: number;
  scoreTitle: string;
  scoreDescription: string;
  scoreIconInUnicode: string | null;
  category: number;
  categoryTitle: string | null;
  createdAt: string;
  userId: string;
};
