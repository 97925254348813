import { Button } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { FC } from "react";

interface SingleSignOnButtonProps {
  text: string;
  className: string;
  onClick: () => void;
  fullWidth: boolean;
}

export const SingleSignOnButton: FC<SingleSignOnButtonProps> = ({
  text,
  fullWidth = true,
  className = "",
  onClick = () => {},
}) => {
  return (
    <Button
      variant="outlined"
      fullWidth={fullWidth}
      size="large"
      className={className}
      onClick={onClick}
      startIcon={<LockIcon />}
    >
      <p className="font-semibold text-sm tracking-wide leading-6">{text}</p>
    </Button>
  );
};
