import { alpha } from "@mui/material";

const colors = {
  primaryDarkGrayWithTransparency: "rgba(51, 51, 51, 0.2)",
  primaryDarkBlue: "#152361",
  primaryDarkBlueWithTransparency: "rgba(21, 35, 97, 0.9)",
  primaryDarkBlueWithTransparencyLow: "rgba(21, 35, 97, 0.1)",
  primaryBlue: "#48B4FF",
  primaryGreen: "#00A885",
  primaryGreenTransparency: alpha("#00A885", 0.05),
  primaryGreenDark: "#0C8E77",
  primarySkin: "#FFF8ED",
  tertiaryGreen: "#187363",
  secondarySkin: "#FFFDFB",
  errorMain: "#870000",
  white: "#F4F6F8",
  highlightedBackground: "rgba(51,51,51,.03)",
  darkCyan: "#249985",
  lightGrayishCyan: "#cee8e4",
  lightGrayishBlue: "#edf6fb",
  secondaryBlue: "#3BA5EE",
  veryPaleBlue: "#EDF8FF",
  primaryBlack: "#333333",
  lightGrayinshOrange: "#F9F2E7",
  secondaryGreen: "#0C8E77",
  trueWhite: "#FFFFFF",
  medicalGreenLight: "#82BA8E",
  medicalGreenMiddle: "#2F7564",
  medicalGreenDark: "#064A47",
};

export default colors;
