import { Button, CardActions } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(({ spacing, breakpoints, colors }) => ({
  cardActions: {
    flexDirection: "column",
    gap: spacing(1.5),
    justifyContent: "center",
    borderTop: "solid 1px #dadce6",
    width: "100%",
    backgroundColor: "#ffffff",
    bottom: 0,
    right: 0,
    padding: spacing(2),
    [breakpoints.down("sm")]: {
      boxShadow: "0px 0 16px rgba(21, 35, 97, 30% )",
      position: "fixed",
    },
  },
  continueButton: {
    margin: spacing(2),
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "1.25px",
    width: "100%",
    "@media (hover: none)": {
      "&:hover": {
        backgroundColor: colors.primaryBlue,
      },
    },
  },
}));

const WizardActions = ({
  onMoveToNextStep,
  disabled = false,
  nextStepButtonLabel,
  className = "",
  children,
}) => {
  const classes = useStyles();
  return (
    <CardActions className={`${classes.cardActions} ${className}`}>
      {children ?? (
        <Button
          className={classes.continueButton}
          onClick={onMoveToNextStep}
          disabled={disabled}
          type="submit"
        >
          {nextStepButtonLabel}
        </Button>
      )}
    </CardActions>
  );
};

export default WizardActions;
