import { Container, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Outlet } from "react-router-dom";
import withAppInsights from "@src/appInsights/AppInsights";
import { AppInsightsContextProvider } from "@src/appInsights/AppInsightsContext";
import TopBar from "./TopBar";

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    backgroundColor: "#FFFDFB",
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    [breakpoints.up("sm")]: {
      width: 360,
    },
  },
}));

const MainLayout = withAppInsights(() => {
  const classes = useStyles();
  const smallScreen = useMediaQuery(({ breakpoints }) =>
    breakpoints.down("sm"),
  );

  return (
    <div className={classes.root}>
      <Container
        className={`${classes.container} ${smallScreen ? "" : "px-0"}`}
      >
        <TopBar />
        <Outlet />
      </Container>
    </div>
  );
});

const LayoutWithContext = ({ location, children }) => (
  <AppInsightsContextProvider>
    <MainLayout location={location}>{children}</MainLayout>
  </AppInsightsContextProvider>
);
export default LayoutWithContext;
