import { makeStyles } from "@mui/styles";
import { Icon } from "../Icon";

const useStylesInner = makeStyles((theme) => ({
  yAxisLabel: ({ labelPosition }) => ({
    paddingLeft: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: labelPosition === "left" ? "row" : "row-reverse",
  }),
  icon: ({ color, labelPosition }) => ({
    width: 20,
    height: 20,
    marginRight: labelPosition === "left" && theme.spacing(1),
    marginLeft: labelPosition === "right" && theme.spacing(1),
    color,
  }),
  line: ({ color }) => ({
    width: "100%",
    minHeight: 1,
    marginLeft: -1,
    backgroundColor: color,
    opacity: 0.3,
  }),
  dot: ({ color }) => ({
    color,
    marginBottom: -1,
  }),
}));

const BarGroupYAxisLabels = ({ item, labelPosition }) => {
  const classes = useStylesInner({ color: item.color, labelPosition });
  return (
    <div className={classes.yAxisLabel}>
      <Icon iconKey={item.key} className={classes.icon} fill={item.color} />
      <span className={classes.dot}>&#8226;</span>
      <div className={classes.line} />
    </div>
  );
};

const useStylesOuter = makeStyles((theme) => ({
  yAxisLabels: ({ height = "100%" }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "100%",
    height,
    justifyContent: "space-between",
    left: 0,
  }),
}));

export const YAxisLabels = ({ labels, height, labelPosition }) => {
  const classes = useStylesOuter({ height });
  return (
    <div className={classes.yAxisLabels}>
      {labels.map((label) => (
        <BarGroupYAxisLabels
          key={label.key}
          item={label}
          labelPosition={labelPosition}
        />
      ))}
    </div>
  );
};
