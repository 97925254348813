import { useContext, useMemo } from "react";
import Paper from "@mui/material/Paper";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ilustrationWolan from "@src/resources/images/ilustation_women.svg";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { DashboardContext } from "@src/utils/DashboardContext";
import BottomCTAWithFocusedThemes from "./BottomCTAWithFocusedThemes";
import BottomCTAWithNoFocusedThemes from "./BottomCTAWithNoFocusedThemes";

const useStyles = makeStyles((theme) => ({
  chartOverview: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(8),
    },
  },
  text1: {
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    paddingBottom: theme.spacing(2),
  },
  barChartIcon: {
    marginRight: theme.spacing(1),
  },
  text2: {
    marginBottom: theme.spacing(4),
    color: theme.palette.primary,
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "32px",
    letterSpacing: "0.5px",
    maxWidth: 528,
  },
  ilustrationWoman: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: 296,
      height: 237.04,
    },
  },
  focusedThemesNames: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    border: `1px solid ${theme.colors.primaryDarkBlue}`,
    borderRadius: "12px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  focusedThemeBox: {
    display: "flex",
    justifyContent: "center",
    maxWidth: 528,
    "& :not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    maxWidth: 528,
    margin: `${theme.spacing(4)} 0 ${theme.spacing(4)}`,
  },
  imageBox: {
    display: "flex",
    maxWidth: 528,
    justifyContent: "center",
  },
}));

const ChartFocusedThemesNotCheckedIn = () => {
  const { t } = useTranslatedNavigate();
  const classes = useStyles();
  const { themes } = useContext(DashboardContext);
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const chartThemes = useMemo(
    () =>
      Object.keys(themes)
        .map((k) => {
          const questionList = themes[k].questionLists.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          )[0];

          if (questionList) {
            return {
              ...questionList,
              ...{
                ordinal: themes[k].ordinal,
                isTrackedTheme: themes[k].isTrackedTheme,
                isFocused: themes[k].isFocused,
                key: themes[k].key,
              },
            };
          }
          return {
            ...themes[k],
            ...{
              score: 0,
              createdAt: new Date(0),
            },
          };
        })
        .sort((a, b) => {
          if (a.isFocused === b.isFocused) return a.ordinal - b.ordinal;

          return a.isFocused ? -1 : 1;
        }),
    [themes],
  );

  const focusedThemes = chartThemes
    .filter(
      (focusedTheme) => focusedTheme.isFocused && focusedTheme.score === 0,
    )
    .map((focusedTheme) => focusedTheme.key);

  return (
    <Paper className={classes.chartOverview}>
      <Typography className={classes.text1} variant="h6" color="textPrimary">
        <BarChartIcon className={classes.barChartIcon} />
        {t("Dashboard.FocusedChard.Tile.Title")}
      </Typography>
      <Typography className={classes.text2}>
        {t("Dashboard.FocusedChard.Tile.Message")}
      </Typography>
      <Box className={classes.imageBox}>
        <img
          src={ilustrationWolan}
          className={classes.ilustrationWoman}
          alt="Ilustration Women"
        />
      </Box>
      <Box className={classes.focusedThemeBox}>
        {focusedThemes.map((item) => (
          <Typography
            className={classes.focusedThemesNames}
            variant="h6"
            key={item}
          >
            {t(`Themes.${item}.Name`)}
          </Typography>
        ))}
      </Box>
      <Divider className={classes.divider} />
      {focusedThemes.length > 0 ? (
        <BottomCTAWithFocusedThemes isSmallScreen={isSmallScreen} />
      ) : (
        <BottomCTAWithNoFocusedThemes isSmallScreen={isSmallScreen} />
      )}
    </Paper>
  );
};

export default ChartFocusedThemesNotCheckedIn;
