import { useAuthentication } from "@src/services/auth.service";
import { Navigate, useParams } from "react-router-dom";
import {
  useGetLastCompletedConsultWithPsychologistQuery,
  useGetPsychologistByUrlHandleQuery,
} from "@src/queries/booking";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import {
  isPsychologistActiveNotAccepting,
  isPsychologistInactive,
} from "@src/utils/helpers";
import LoggedPersonalLinkFlow from "@src/views/booking/personalLink/LoggedPersonalLinkFlow";
import NonLoggedPersonalLinkFlow from "@src/views/booking/personalLink/NonLoggedPersonalLinkFlow";
import PsychologistUnavailableView from "@src/views/booking/personalLink/components/PsychologistUnavailableView";
import PsychologistNotAcceptingView from "@src/views/booking/personalLink/components/PsychologistNotAcceptingView";
import LoggedPersonalFollowUpFlow from "@src/views/booking/followUp/personalLink/LoggedPersonalFollowUpFlow";

const PersonalLinkView = () => {
  const { isLoggedInOrTwoFactorPending } = useAuthentication();
  const { pathT } = useTranslatedNavigate();
  const { urlHandle } = useParams();

  const { data: psychologist } = useGetPsychologistByUrlHandleQuery(urlHandle);
  const { data: lastConsultWithPsychologist } =
    useGetLastCompletedConsultWithPsychologistQuery(psychologist?.id);

  const reroute = () => {
    if (!psychologist) {
      return <Navigate to={pathT("route.booking")} />;
    }

    if (!psychologist.hasLinkedCalendar) {
      return <PsychologistUnavailableView firstname={psychologist.firstName} />;
    }

    if (isPsychologistInactive(psychologist.tags)) {
      return <PsychologistUnavailableView firstname={psychologist.firstName} />;
    }

    if (isLoggedInOrTwoFactorPending) {
      if (lastConsultWithPsychologist) {
        return <LoggedPersonalFollowUpFlow />;
      }
    }

    if (isPsychologistActiveNotAccepting(psychologist.tags)) {
      return <PsychologistNotAcceptingView psychologist={psychologist} />;
    }

    if (isLoggedInOrTwoFactorPending) {
      return <LoggedPersonalLinkFlow psychologist={psychologist} />;
    }
    return <NonLoggedPersonalLinkFlow psychologist={psychologist} />;
  };

  return <>{reroute()}</>;
};

export default PersonalLinkView;
