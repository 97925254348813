import { useState, useEffect } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useLocation, useOutletContext } from "react-router-dom";
import { verifyLanguage, verifySessionType } from "@src/utils/helpers";
import SessionType from "./components/SessionType";
import Language from "../components/Language";
import TimeSlotPicker from "../components/TimeSlotPicker";
import EmailConfirmation from "./components/EmailConfirmation";
import MagicLink from "../components/MagicLink";

const STEPS = {
  SESSION_TYPE: "route.booking.sessionType",
  LANGUAGE: "route.booking.language",
  TIMESLOTS: "route.booking.timeslots",
  EMAIL: "route.booking.email",
  MAGIC_LINK: "route.booking.magicLink",
};

const NonLoggedIntroductoryFlow = () => {
  const { t } = useTranslatedNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const URLSessionType = verifySessionType(
    urlSearch.get(t("route.query.session_type")),
  );
  const URLLanguage = verifyLanguage(urlSearch.get(t("route.query.language")));

  const {
    step,
    nextStep,
    setPreventGoingBack,
    setupFlowSteps,
    isFlowSetup,
    addStepToSkip,
  } = useOutletContext();

  const [email, setEmail] = useState("");
  const [sessionType, setSessionType] = useState(URLSessionType);
  const [language, setLanguage] = useState(URLLanguage);
  const [selectedDateTime, setSelectedDateTime] = useState();

  useEffect(() => {
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (step === STEPS.MAGIC_LINK) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  useEffect(() => {
    if (URLSessionType) {
      addStepToSkip(STEPS.SESSION_TYPE);
    }
    if (URLLanguage) {
      addStepToSkip(STEPS.LANGUAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [isFlowSetup]);

  const selectNonLoggedInStep = (newStep) => {
    switch (newStep) {
      case STEPS.SESSION_TYPE:
        return (
          <SessionType
            moveToNextStep={nextStep}
            setSessionType={setSessionType}
          />
        );
      case STEPS.LANGUAGE:
        return (
          <Language
            moveToNextStep={nextStep}
            setLanguage={setLanguage}
            sessionType={sessionType}
          />
        );
      case STEPS.TIMESLOTS:
        return (
          <TimeSlotPicker
            moveToNextStep={nextStep}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
            sessionType={sessionType}
            language={language}
          />
        );
      case STEPS.EMAIL:
        return (
          <EmailConfirmation
            moveToNextStep={(newEmail) => {
              setEmail(newEmail);
              nextStep();
            }}
            selectedDateTime={selectedDateTime}
            sessionType={sessionType}
            language={language}
          />
        );
      case STEPS.MAGIC_LINK:
        return (
          <MagicLink
            email={email}
            title={t("Booking.MagicLinkPage.Introductory.Title")}
            body={t("Booking.MagicLinkPage.Introductory.ConfirmText")}
          />
        );
      default:
        return null;
    }
  };

  return <>{selectNonLoggedInStep(step)}</>;
};

export default NonLoggedIntroductoryFlow;
