import axios from "axios";
import { apiPaths } from "@src/utils/constants";
import sessionStorageService from "./sessionStorage.service";

export const pushPreferencesFromStorageToServer = async (
  email,
  unconfirmedDataId,
) => {
  const preferences = sessionStorageService.getPreferencesFromStorage();

  if (!preferences || preferences.length === 0) {
    return;
  }

  if (!email || !unconfirmedDataId) {
    await axios.post(apiPaths.setUserPreferences, preferences);
  } else {
    await axios.post(apiPaths.setUnconfirmedUserPreferences, {
      email,
      unconfirmedDataId,
      preferences,
    });
  }

  sessionStorageService.clearPreferencesFromStorage();
};
