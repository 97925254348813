import { Box, Typography, Link, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useParams, useNavigate } from "react-router-dom";
import { useConsultsQuery } from "@src/queries/consults";
import { formatConsultDateExtendedWritten } from "@src/utils/formatting";
import {
  canViewConsultNotes,
  getFollowingSessionOfSameType,
} from "@src/utils/helpers";
import CenteredLoader from "@src/components/CenteredLoader";
import TypographyWithIcon from "@src/components/TypographyWithIcon";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SessionNotesPaper from "./components/SessionNotesPaper";
import FollowUpSessionPaper from "./components/FollowUpSessionPaper";
import SessionDetailsPaper from "./components/SessionDetailsPaper";

const useStyle = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    color: theme.colors.primaryDarkBlue,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
  },
  notesContainer: {
    width: "68%",
    marginRight: theme.spacing(3),
  },
  detailsContainer: {
    width: "31%",
  },
}));

const SessionNotes = () => {
  const classes = useStyle();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { pathT, t } = useTranslatedNavigate();
  const { consultId } = useParams();
  const { data: consults } = useConsultsQuery(true);
  const [consult, setConsult] = useState();
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate(pathT("route.mySessions"));
  };

  useEffect(() => {
    const consultWithId = consults.find(
      (currConsult) => currConsult.id.toString() === consultId,
    );
    if (!canViewConsultNotes(consultWithId))
      navigate(pathT("route.mySessions"));
    else setConsult(consultWithId);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [consults]);

  if (!consult) {
    return <CenteredLoader />;
  }

  return (
    <>
      <TypographyWithIcon iconLigature={<ChevronLeftIcon />} variant="body1">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link underline="hover" key="2" color="inherit" onClick={handleClick}>
          {t("MySessionsView.Title")}
        </Link>
      </TypographyWithIcon>
      <Typography className={classes.title} variant="h1">
        {formatConsultDateExtendedWritten(consult)}
      </Typography>
      <Box className={classes.container}>
        {isSmallScreen ? (
          <div>
            <SessionDetailsPaper consult={consult} />
            <SessionNotesPaper consult={consult} />
            <FollowUpSessionPaper
              consult={consult}
              followUpSession={getFollowingSessionOfSameType(consults, consult)}
            />
          </div>
        ) : (
          <>
            <Box className={classes.notesContainer}>
              <SessionNotesPaper consult={consult} />
            </Box>
            <Box className={classes.detailsContainer}>
              <SessionDetailsPaper consult={consult} />
              <FollowUpSessionPaper
                consult={consult}
                followUpSession={getFollowingSessionOfSameType(
                  consults,
                  consult,
                )}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default SessionNotes;
