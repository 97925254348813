import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as ManChairTable } from "@src/resources/images/man_chair_table.svg";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { CONSULT_TYPE } from "@src/utils/constants";
import { getSessionBookingUrl } from "@src/utils/urlHelpers.js";
import BookingContainer from "../booking/components/BookingContainer";

const useStyles = makeStyles(() => ({
  buttonBooking: {
    width: 360,
  },
  buttonText: {
    letterSpacing: 1.25,
    fontWeight: 500,
    opacity: 1,
  },
}));

const MedicalCheckInNotFound = () => {
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();

  const bookSession = () => {
    const url = pathT(getSessionBookingUrl(CONSULT_TYPE.PHYSICAL_WELLBEING));
    window.open(url, "_blank");
  };

  return (
    <BookingContainer title="This checkup result data is not accessible">
      <Box display="flex" alignItems="center" flexDirection="column">
        <ManChairTable />
        <Button
          className={classes.buttonBooking}
          type="submit"
          onClick={bookSession}
        >
          <Typography variant="body2" className={classes.buttonText}>
            {t("Medical.CheckIn.MagicLink.CTA")}
          </Typography>
        </Button>
      </Box>
    </BookingContainer>
  );
};

export default MedicalCheckInNotFound;
