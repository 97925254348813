import type { FC } from "react";
import { useEffect, useState } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useNavigate } from "react-router-dom";
import {
  getExternalUserCodeFlow,
  signUpExternalUserCodeFlow,
  clearMessages,
} from "@src/actions/userActions";
import { Button, Icon } from "@mui/material";
import {
  RESPONSE_STATUS,
  timeSnackbar,
  guestUserLoginActions,
} from "@src/utils/constants";
import MicrosoftLogo from "@src/resources/images/microsoft-logo.svg";
import { connect } from "react-redux";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { env } from "@src/env";
import { getLocaleDomain } from "@src/i18n";
import i18n from "i18next";
import { OidcClient } from "oidc-client-ts";

interface MicrosoftSignInSignUpButtonProps {
  authReducers: any;
  removeMessages: () => void;
  className: string;
  getExternalUserCodeFlow: (code: string, provider: string) => Promise<any>;
  signUpExternalUserCodeFlow: (code: string, provider: string) => Promise<any>;
  isSignUpForm: boolean;
  fullWidth: boolean;
  onLoginCallback: () => void;
  buttonText: string;
  onValidate: () => any;
  isOnboarding: boolean;
  snackbarShowMessage: (message: string, variant?: string) => void;
}

const MicrosoftIcon = () => {
  return (
    <Icon>
      <img alt="" src={MicrosoftLogo} />
    </Icon>
  );
};

const MicrosoftSignInSignUpButton: FC<MicrosoftSignInSignUpButtonProps> = ({
  authReducers,
  removeMessages,
  className = "",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  getExternalUserCodeFlow,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  signUpExternalUserCodeFlow,
  isSignUpForm = false,
  fullWidth = true,
  onLoginCallback,
  buttonText,
  onValidate,
  isOnboarding = false,
  snackbarShowMessage,
}) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const domain = getLocaleDomain(i18n.language);
  const [externalPopup, setExternalPopup] = useState<Window | null>(null);

  const redirectUri = domain
    ? `https://${domain}`
    : env.REACT_APP_MICROSOFT_REDIRECT_URI;
  const microsoftSettings = {
    authority: env.REACT_APP_MICROSOFT_AUTHORITY,
    client_id: env.REACT_APP_MICROSOFT_CLIENT_ID,
    redirect_uri: redirectUri,
    response_type: "code",
    disablePKCE: true,
    scope: "openid profile email offline_access",
    prompt: "select_account",
  };

  const signIn = async (res) => {
    try {
      const response = await getExternalUserCodeFlow(res.code, "Microsoft");
      snackbarShowMessage(response.message, "success");
      if (response?.twoFactorVerification === "pending") {
        // do nothing
        return;
      }
      setTimeout(() => {
        if (onLoginCallback) {
          onLoginCallback();
        }
      }, timeSnackbar);
    } catch (error) {
      if (error.response.status === RESPONSE_STATUS.NOT_FOUND) {
        snackbarShowMessage(t("LoginView.AccountNotFound"));
        setTimeout(() => {
          navigate(pathT("route.register"), { replace: true });
        }, timeSnackbar);
      } else {
        snackbarShowMessage("");
      }
    }
  };

  const signUp = async (res) => {
    try {
      const response = await signUpExternalUserCodeFlow(res.code, "Microsoft");

      snackbarShowMessage(response.message, "success");
      if (response?.twoFactorVerification === "pending") {
        // do nothing, there's a useEffect that will redirect
        return;
      }

      setTimeout(() => {
        if (onLoginCallback) {
          onLoginCallback();
        }
      }, timeSnackbar);
    } catch (error) {
      snackbarShowMessage("");
    }
  };

  const handleMicrosoftResponse = async (res) => {
    if (isSignUpForm) {
      await signUp(res);
    } else {
      await signIn(res);
    }
  };

  useEffect(() => {
    if (!authReducers.twoFactorPending || authReducers.isLoggedIn) {
      return;
    }

    if (isOnboarding) {
      navigate(
        `${pathT("route.twoFactorCodeRequest")}?action=${
          guestUserLoginActions.explicitConsent
        }`,
        {
          replace: true,
        },
      );
    } else {
      navigate(pathT("route.twoFactorCodeRequest"), {
        replace: true,
      });
    }
    removeMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authReducers.twoFactorPending]);

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const interval = setInterval(() => {
      if (externalPopup.closed) {
        clearInterval(interval);
        setExternalPopup(null);
        return;
      }

      try {
        const urlParams = new URLSearchParams(externalPopup.location.search);
        if (urlParams.get("code")) {
          const code = urlParams.get("code");
          externalPopup.close();
          handleMicrosoftResponse({ code });
        } else if (urlParams.get("error")) {
          // eslint-disable-next-line no-console
          console.log(urlParams.get("error"));
          externalPopup.close();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
      }
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalPopup]);

  const login = async () => {
    const oidcClient = new OidcClient(microsoftSettings);
    const signinRequest = await oidcClient.createSigninRequest({
      state: { bar: 15 }, // state is data that you want to roundtrip, it is not used by the protocol
    });
    const width = 500;
    const height = 400;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = `WINDOW TITLE`;
    const { url } = signinRequest;
    const popup = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`,
    );
    setExternalPopup(popup);
  };

  const validateLogin = () => {
    if (onValidate) {
      const response = onValidate();
      if (!response) {
        return;
      }
    }
    login();
  };

  return (
    <Button
      variant="outlined"
      fullWidth={fullWidth}
      size="large"
      startIcon={<MicrosoftIcon />}
      className={className}
      onClick={validateLogin}
    >
      <p className="font-semibold text-sm tracking-wide leading-6">
        {buttonText}
      </p>
    </Button>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getExternalUserCodeFlow: (idToken, provider) =>
    dispatch(getExternalUserCodeFlow(idToken, provider)),
  signUpExternalUserCodeFlow: (idToken, provider) =>
    dispatch(signUpExternalUserCodeFlow(idToken, provider)),
  removeMessages: () => {
    dispatch(clearMessages());
  },
});

const mapStateToProps = (state) => state;
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(MicrosoftSignInSignUpButton),
);
