import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import sessionStorageService from "@src/services/sessionStorage.service";
import { update } from "@src/actions/userActions";
import { useAuthentication } from "@src/services/auth.service";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { withSnackbar } from "@src/components/SnackBarComponent";
import ExplicitConsent from "@src/components/ExplicitConsent";

const ExplicitConsentWrapper = ({ updateUser, ...props }) => {
  const navigate = useNavigate();
  const { t, pathT } = useTranslatedNavigate();
  const { isLoggedIn } = useAuthentication();

  const acceptedTermsAndConditions = useRef(false);
  const acceptedMedicalDataProcessing = useRef(false);
  const signUpToNewsletter = useRef(true);

  const updateAndRedirect = async () => {
    const firstName = sessionStorageService.getNameFromStorage();
    const lastName = sessionStorageService.getUser()?.lastName;
    const yearOfBirth = sessionStorageService.getYearOfBirthFromStorage();
    const email = sessionStorageService.getUser()?.email;
    const referral = sessionStorageService.getReferralFromStorage();
    const employerId = referral?.referral === "no" ? null : referral?.companyId;
    const isPartner = referral?.referral === "friends";

    try {
      if (!isLoggedIn || !firstName || !yearOfBirth) {
        navigate(pathT("route.login"));
      }

      await updateUser(
        firstName,
        lastName,
        email,
        yearOfBirth,
        signUpToNewsletter.current,
        employerId,
        acceptedTermsAndConditions.current,
        acceptedMedicalDataProcessing.current,
        isPartner,
      );

      window.OpenUpMobile?.authenticateUser?.();
      window.webkit?.messageHandlers?.OpenUpMobile?.postMessage(
        "authenticateUser",
      );

      sessionStorageService.clearOnboardingFromStorage();
      sessionStorageService.clearSSOFlowFromStorage();

      navigate(pathT("route.account"), { replace: true });
    } catch (e) {
      if (e.message === "weak_password") {
        props.snackbarShowMessage(
          t("ExplicitConsentWrapper.CompromisedPassword"),
        );
      } else {
        props.snackbarShowMessage(e.request.responseText || e.message);
      }
    }
  };

  return (
    <ExplicitConsent
      moveToNextStep={async (termsAndConditions, medicalDataProcessing) => {
        acceptedTermsAndConditions.current = termsAndConditions;
        acceptedMedicalDataProcessing.current = medicalDataProcessing;
        await updateAndRedirect();
      }}
    />
  );
};

ExplicitConsentWrapper.propTypes = {
  updateUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (
    firstName,
    lastName,
    email,
    yearOfBirth,
    signUpToNewsletter,
    employerId,
    termsAndConditions,
    medicalDataProcessing,
    isPartner,
  ) =>
    dispatch(
      update(
        firstName,
        lastName,
        email,
        yearOfBirth,
        signUpToNewsletter,
        employerId,
        termsAndConditions,
        medicalDataProcessing,
        isPartner,
      ),
    ),
});

const mapStateToProps = (state) => state.authReducers;
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(ExplicitConsentWrapper),
);
