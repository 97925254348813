import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import authService from "@src/services/auth.service";
import { isActivateTwoFactorAuthNotificationVisible } from "@src/queries/twoFactorAuthentication";
import { useState } from "react";
import OpenUpMenuListMobile from "./OpenUpMenuListMobile";

const useStyles = makeStyles(() => ({
  icon: {
    width: "2.5rem",
    height: "2.5rem",
  },
}));

const OpenUpMenu = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const onClose = () => {
    setOpen(false);
  };
  const currentUser = authService.getUserFromStorage();

  return (
    <>
      {open ? (
        <CloseIcon
          className={classes.icon}
          onClick={() => {
            setOpen(false);
          }}
        />
      ) : (
        <Badge
          // hide badge when two factor authentication is already enabled, or if it is a guest user
          badgeContent={" "}
          invisible={!isActivateTwoFactorAuthNotificationVisible(currentUser)}
          variant="dot"
          color="error"
          overlap="circular"
        >
          <MenuIcon
            className={classes.icon}
            onClick={() => {
              setOpen(true);
            }}
          />
        </Badge>
      )}

      <OpenUpMenuListMobile isOpen={open} onClose={onClose} />
    </>
  );
};

export default OpenUpMenu;
