import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useState } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import colors from "@src/theme/colors";

const useClasses = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  ctaButtonActive: {
    fontFamily: "Montserrat",
    lineHeight: 0,
    background: colors.primaryDarkBlue,
    color: "white",
    fontSize: 14,
    fontWeight: 500,
    padding: "9px 48px 9px 24px",
    zIndex: 1,
    height: 46,
    minWidth: 167,
    borderRadius: 30,
    borderWidth: 2,
    border: `solid ${colors.primaryDarkBlue}`,
    borderStyle: "solid",
    "&::after": {
      content: '""',
      position: "absolute",
      top: "calc(50% - 2px)",
      right: 35,
      display: "inline-block",
      padding: 3,
      transform: "rotate(-135deg)",
      border: "solid white",
      borderWidth: "0 2px 2px 0",
    },
  },
  ctaButton: {
    fontFamily: "Montserrat",
    color: colors.primaryDarkBlue,
    fontSize: 14,
    fontWeight: 500,
    background: "white",
    padding: "9px 48px 9px 24px",
    zIndex: 1,
    height: 46,
    minWidth: 167,
    borderRadius: 30,
    borderWidth: 2,
    border: `solid ${colors.primaryDarkBlue}`,
    borderStyle: "solid",
    transition: "all 0.3s",

    "&::after": {
      content: '""',
      position: "absolute",
      top: "calc(50% - 5px)",
      right: 35,
      display: "inline-block",
      padding: 3,
      transform: "rotate(45deg)",
      border: "solid black",
      borderWidth: "0 2px 2px 0",
      transition: "all 0.3s ease-in-out",
    },

    "&:hover": {
      background: colors.primaryDarkBlue,
      color: "white",
      "&::after": {
        transform: "rotate(-135deg)",
        top: "calc(50% - 2px)",
        transition: "all 0.2s ease",
        cursor: "pointer",
        border: "solid white",
        borderWidth: "0 2px 2px 0",
      },
    },
  },
  subMenu: {
    position: "absolute",
    zIndex: -1,
    top: "80%",
    left: 0,
    width: "100%",
    padding: "24px 20px 8px 24px",
    backgroundColor: "white",
    color: colors.primaryDarkBlue,
    "text-decoration": "none",
    borderBottomRightRadius: 23,
    borderBottomLeftRadius: 23,
    "&>li": {
      listStyleType: "none",

      "font-size": 14,
      "font-weight": 500,
      "&>a": {
        fontFamily: "Montserrat",
        color: colors.primaryDarkBlue,
        paddingBottom: theme.spacing(2),
        display: "inline-block",
        opacity: 0.7,
        "&:hover": {
          opacity: 1.0,
        },
      },
    },
  },
  link: {
    fontFamily: "Montserrat",
  },
}));

const LetsOpenUpCTA = ({ className }) => {
  const [isHoveringCTA, setIsHoveringCTA] = useState(false);
  const [isHoveringSubMenu, setIsHoveringSubMenu] = useState(false);
  const { t, pathT } = useTranslatedNavigate();
  const classes = useClasses();

  const leaveButton = () => {
    setTimeout(() => {
      setIsHoveringCTA(false);
    }, 300);
  };
  const enterButton = () => setIsHoveringCTA(true);
  const enterMenu = () => setIsHoveringSubMenu(true);
  const leaveMenu = () => setIsHoveringSubMenu(false);

  return (
    <div className={clsx(classes.container, className)}>
      <button
        type="button"
        className={
          isHoveringSubMenu ? classes.ctaButtonActive : classes.ctaButton
        }
        onMouseEnter={enterButton}
        onMouseLeave={leaveButton}
      >
        Let&apos;s OpenUp
      </button>
      {(isHoveringCTA || isHoveringSubMenu) && (
        <ul
          className={classes.subMenu}
          onMouseEnter={enterMenu}
          onMouseLeave={leaveMenu}
        >
          {[
            {
              href: pathT("route.booking"),
              text: t("OpenUpCta.BtnBookSessionLinkText"),
            },
            {
              href: t("OpenUpCta.BtnBookSessionPhoneNumber"),
              text: t("OpenUpCta.BtnBookSessionPhone"),
            },
            {
              href: t("OpenUpCta.BtnBookSessionChatLink"),
              text: t("OpenUpCta.BtnBookSessionChat"),
            },
          ].map((link) => (
            <li key={link.href}>
              <a
                href={link.href}
                className={clsx("c-main-menu__nav-link", classes.link)}
              >
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LetsOpenUpCTA;
