import axios from "axios";
import { EmployerSso, EmployerSsoRequest } from "@src/models/EmployerSso";
import { useQuery } from "react-query";
import { apiPaths } from "@src/utils/constants";
import { hours } from "@src/queries/staletimes";
import sessionStorageService from "@src/services/sessionStorage.service";

const identifier = "employers";

export const useIsEmployerSso = (employerSsoRequest: EmployerSsoRequest) => {
  return useQuery(
    [identifier, "sso"],
    async () => {
      const { data } = await axios.post<EmployerSso>(
        apiPaths.companySsoSupport,
        employerSsoRequest,
      );
      if (data?.ssoSupported === true) {
        sessionStorageService.saveSSOFlowProviderToStorage(data?.provider);
      }
      return data;
    },
    {
      staleTime: hours(1),
    },
  );
};
