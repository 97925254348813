import { useMediaQuery, Container, Box, Typography } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  titleBoxExpanded: ({ message }) => ({
    textAlign: "center",
    marginBottom: message ? theme.spacing(2) : theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
    },
    marginLeft: -theme.spacing(12),
    marginRight: -theme.spacing(12),
  }),
  titleBoxContainer: {
    paddingBottom: 0,
  },
  titleBox: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
    },
  },
  titleText: {
    display: "flex",
    justifyContent: "center",
  },
  messageBox: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
    },
    textAlign: "center",
  },
  messageText: {
    display: "flex",
    justifyContent: "center",
  },
}));

const BookingContainer = ({
  title,
  children,
  isMediumContainerWanted = false,
  message = "",
  className = "",
}) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(
    (x) => x.breakpoints.up("xs") && x.breakpoints.down("md"),
  );
  const classes = useStyles({ message });

  return (
    <Container
      maxWidth={isMediumContainerWanted ? "md" : "sm"}
      className={className}
    >
      <Container maxWidth="sm" className={classes.titleBoxContainer}>
        {title && (
          <Box
            mb={1}
            className={
              isSmallScreen ? classes.titleBox : classes.titleBoxExpanded
            }
          >
            <Typography
              variant={isMediumScreen || isSmallScreen ? "h2" : "h1"}
              className={classes.titleText}
            >
              {title}
            </Typography>
          </Box>
        )}
        {message && (
          <Box mb={1} className={classes.messageBox}>
            <Typography
              variant={isSmallScreen ? "h4" : "h3"}
              className={classes.messageText}
            >
              {message}
            </Typography>
          </Box>
        )}
      </Container>
      <div>{children}</div>
    </Container>
  );
};

export default BookingContainer;
