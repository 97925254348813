import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQueryThemeCheckins } from "@src/queries/themes";
import useTranslatedNavigate, {
  convertThemeNameToThemeKey,
} from "@src/services/useTranslateNavigate";

import { TOP_SCORE_QUESTION } from "@src/utils/constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ThemeViewOverview from "@src/views/account/ThemeViewOverview";
import ThemeViewInsights from "@src/views/account/ThemeViewInsights";
import ThemeViewEducation from "@src/views/account/ThemeViewEducation";
import ThemeViewBestPractices from "@src/views/account/ThemeViewBestPractices";
import { withSnackbar } from "@src/components/SnackBarComponent";

const ThemeView = ({ ...props }) => {
  const { t } = useTranslatedNavigate();
  const { themeName, slug } = useParams();
  const themeKey = convertThemeNameToThemeKey(themeName, t);
  const { data: themeCheckins = [], error } = useQueryThemeCheckins(themeKey);

  if (error) {
    props.snackbarShowMessage(error);
  }

  const themesSorted = useMemo(() => {
    let sortedThemes = [];
    if (Array.isArray(themeCheckins.questionLists)) {
      const questionLists = [...themeCheckins.questionLists].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      );
      if (questionLists.length > 0)
        sortedThemes = questionLists.map((list) => ({
          ...list,
          ...{
            ordinal: themeCheckins.ordinal,
            isDefaultTheme: themeCheckins.isDefaultTheme,
            isTrackedTheme: themeCheckins.isTrackedTheme,
            key: themeCheckins.key,
          },
        }));
      else {
        sortedThemes = [
          {
            ...{
              ordinal: themeCheckins.ordinal,
              isDefaultTheme: themeCheckins.isDefaultTheme,
              isTrackedTheme: themeCheckins.isTrackedTheme,
              key: themeCheckins.key,
            },
            ...{
              score: 0,
              category: 0,
              createdAt: new Date(0),
            },
          },
        ];
      }
    }
    return sortedThemes;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [themeKey, themeCheckins]);

  const lastCheckin = themesSorted.length
    ? themesSorted[themesSorted.length - 1]
    : undefined;

  const themeScore = themesSorted
    .map(({ score }) => score)
    .filter((score) => score !== 0);

  const firstThreeWorstQuestions = useMemo(() => {
    const numberOfQuestions = 3;
    if (lastCheckin && lastCheckin.questions) {
      const questionsFromLastCheckInSortedByResponse = lastCheckin.questions
        .filter((q) => q.isScored && q.rank)
        .sort((previous, next) => {
          if (
            previous.answers[0]?.intValue > next.answers[0]?.intValue ||
            (previous.answers[0]?.intValue === next.answers[0]?.intValue &&
              previous.rank > next.rank)
          ) {
            return 1;
          }
          return -1;
        });
      return questionsFromLastCheckInSortedByResponse.some((q) => q.goal)
        ? questionsFromLastCheckInSortedByResponse
            .slice(0, numberOfQuestions)
            .filter((item) => item.answers[0]?.intValue < TOP_SCORE_QUESTION)
        : null;
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [themesSorted, lastCheckin]);

  const themeGuidePage = () => {
    switch (slug) {
      case t("route.insights.postfix"):
        return (
          <ThemeViewInsights
            themesSorted={themesSorted}
            lastCheckin={lastCheckin}
            themeScore={themeScore}
            firstThreeWorstQuestions={firstThreeWorstQuestions}
            themeCheckins={themeCheckins}
          />
        );
      case t("route.education.postfix"):
        return <ThemeViewEducation themeKey={themeKey} />;
      case t("route.bestPractices.postfix"):
        return (
          <ThemeViewBestPractices
            themeKey={themeKey}
            lastCheckin={lastCheckin}
          />
        );
      default:
        return (
          <ThemeViewOverview
            firstThreeWorstQuestions={firstThreeWorstQuestions}
            themeKey={themeKey}
            lastCheckin={lastCheckin}
          />
        );
    }
  };

  return <>{themeGuidePage()}</>;
};

export default withSnackbar(ThemeView);
