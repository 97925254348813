/* eslint-disable react/prop-types */
import { Suspense } from "react";
import CenteredLoader from "@src/components/CenteredLoader";

const Suspendable = ({ children }) => (
  <Suspense fallback={<CenteredLoader />}>{children}</Suspense>
);

export const withSuspense =
  (WrappedComponent = {}) =>
  (props) => (
    <Suspendable>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WrappedComponent {...props} />
    </Suspendable>
  );

export default Suspendable;
