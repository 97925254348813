import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Paper, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ChildBoat from "@src/resources/images/child_boat.svg";
import { Link as RouterLink } from "react-router-dom";
import { HOVER_TYPE } from "@src/utils/constants";
import { useState } from "react";
import HoverContent from "./HoverContent";

const useStyle = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(6),
  },
  hoverThing: {
    padding: theme.spacing(2, 2),
    borderRadius: "0px",
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px",
    boxShadow: "none",
    minHeight: 536,
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      borderRadius: "16px",
    },
  },
  boxInsideInsights: ({ isHover }) => ({
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    "&:hover": {
      backgroundColor: theme.colors.veryPaleBlue,
      color: isHover === HOVER_TYPE.INSIGHTS && theme.colors.primaryBlue,
      borderRadius: "16px",
    },
  }),
  boxInsideEducation: ({ isHover }) => ({
    paddingTop: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.colors.veryPaleBlue,
      color: isHover === HOVER_TYPE.EDUCATION && theme.colors.primaryBlue,
      borderRadius: "16px",
    },
  }),
  boxInsideBestPractices: ({ isHover }) => ({
    paddingTop: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.colors.veryPaleBlue,
      color: isHover === HOVER_TYPE.EDUCATION && theme.colors.primaryBlue,
      borderRadius: "16px",
    },
  }),
  insightTitle: ({ isHover }) => ({
    color: isHover === HOVER_TYPE.INSIGHTS && theme.colors.primaryBlue,
  }),
  educationTitle: ({ isHover }) => ({
    color: isHover === HOVER_TYPE.EDUCATION && theme.colors.primaryBlue,
  }),
  bestPracticesTitle: ({ isHover }) => ({
    color: isHover === HOVER_TYPE.BEST_PRACTICES && theme.colors.primaryBlue,
  }),
  insightText: ({ isHover }) => ({
    fontWeight: "400",
    letterSpacing: "0.5px",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4.25),
    color: isHover === HOVER_TYPE.INSIGHTS && theme.colors.primaryBlue,
  }),
  educationText: ({ isHover }) => ({
    fontWeight: "400",
    letterSpacing: "0.5px",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4.25),
    color: isHover === HOVER_TYPE.EDUCATION && theme.colors.primaryBlue,
  }),
  bestPracticesText: ({ isHover }) => ({
    fontWeight: "400",
    letterSpacing: "0.5px",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4.25),
    color: isHover === HOVER_TYPE.BEST_PRACTICES && theme.colors.primaryBlue,
  }),
  arrowIconInsight: ({ isHover }) => ({
    float: "right",
    color:
      isHover === HOVER_TYPE.INSIGHTS
        ? theme.colors.primaryBlue
        : theme.colors.primaryDarkBlue,
  }),
  arrowIconEducation: ({ isHover }) => ({
    float: "right",
    color:
      isHover === HOVER_TYPE.EDUCATION
        ? theme.colors.primaryBlue
        : theme.colors.primaryDarkBlue,
  }),
  arrowIconBestPractices: ({ isHover }) => ({
    float: "right",
    color:
      isHover === HOVER_TYPE.BEST_PRACTICES
        ? theme.colors.primaryBlue
        : theme.colors.primaryDarkBlue,
  }),
  titleBox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  imageNoHover: {
    borderBottomRightRadius: "16px",
    marginBottom: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
  imageContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
}));

const HoverComponentThemeView = ({
  themeKey,
  category,
  lastCheckin,
  firstThreeWorstQuestions,
}) => {
  const { t, pathT } = useTranslatedNavigate();
  const [isHover, setIsHover] = useState(HOVER_TYPE.NONE);
  const classes = useStyle({ isHover });
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("lg"));
  const routeThemeKey = t(`route.ThemeKey.${themeKey}`);

  const onHoverInsights = () => {
    setIsHover(HOVER_TYPE.INSIGHTS);
  };

  const onMouseAway = () => {
    setIsHover(HOVER_TYPE.NONE);
  };

  const onHoverEducation = () => {
    setIsHover(HOVER_TYPE.EDUCATION);
  };

  const onHoverBestPractices = () => {
    setIsHover(HOVER_TYPE.BEST_PRACTICES);
  };

  return (
    <Grid
      container
      className={classes.gridContainer}
      onMouseLeave={onMouseAway}
    >
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Paper className={classes.hoverThing}>
          <RouterLink to={pathT("route.insights", { key: routeThemeKey })}>
            <Box
              mb={2}
              className={classes.boxInsideInsights}
              onMouseEnter={onHoverInsights}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.titleBox}
              >
                <Typography variant="h5" className={classes.insightTitle}>
                  {t("ThemeViewOverview.HoverMenu.Insights")}
                </Typography>
                <ArrowForwardIosIcon className={classes.arrowIconInsight} />
              </Box>
              <Typography variant="body2" className={classes.insightText}>
                {t(`ThemeViewOverview.${themeKey}.InsightsText.${category}`)}
              </Typography>
            </Box>
          </RouterLink>
          <RouterLink to={pathT("route.bestPractices", { key: routeThemeKey })}>
            <Box
              mb={2}
              className={classes.boxInsideBestPractices}
              onMouseEnter={onHoverBestPractices}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.titleBox}
              >
                <Typography variant="h5" className={classes.bestPracticesTitle}>
                  {t("ThemeViewOverview.HoverMenu.BestPractices")}
                </Typography>
                <ArrowForwardIosIcon
                  className={classes.arrowIconBestPractices}
                />
              </Box>
              <Typography variant="body2" className={classes.bestPracticesText}>
                {t(
                  `ThemeViewOverview.${themeKey}.BestPracticesText.${category}`,
                )}
              </Typography>
            </Box>
          </RouterLink>
          <RouterLink to={pathT("route.education", { key: routeThemeKey })}>
            <Box
              mb={2}
              className={classes.boxInsideEducation}
              onMouseEnter={onHoverEducation}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.titleBox}
              >
                <Typography variant="h5" className={classes.educationTitle}>
                  {t("ThemeViewOverview.HoverMenu.Education")}
                </Typography>
                <ArrowForwardIosIcon className={classes.arrowIconEducation} />
              </Box>
              <Typography variant="body2" className={classes.educationText}>
                {t(`ThemeViewOverview.${themeKey}.EducationText.${category}`, {
                  key: themeKey.toLowerCase(),
                })}
              </Typography>
            </Box>
          </RouterLink>
          {isSmallScreen && (
            <Box className={classes.imageContainer}>
              <img
                alt="Child boat"
                className={classes.imageNoHover}
                src={ChildBoat}
              />
            </Box>
          )}
        </Paper>
      </Grid>
      {!isSmallScreen && (
        <HoverContent
          isHover={isHover}
          themeKey={themeKey}
          lastCheckin={lastCheckin}
          firstThreeWorstQuestions={firstThreeWorstQuestions}
        />
      )}
    </Grid>
  );
};

export default HoverComponentThemeView;
