import { USER_DETAILS } from "@src/utils/constants";
import { QuestionList } from "@src/models/QuestionList";

const checkinSessionStorageIdentifier = "checkin";
const userSessionStorageIdentifier = "user";
const emailSessionStorageIdentifier = "onboarding_email";
const nameSessionStorageIdentifier = "onboarding_name";
const yearOfBirthSessionStorageIdentifier = "onboarding_year_of_birth";
const referralSessionStorageIdentifier = "onboarding_referral";
const currentMoodsSessionStorageIdentifier = "onboarding_current_moods";
const generalWellbeingsessionStorageIdentifier = "general_wellbeing";
const resilienceCheckInStorageIdentifier = "resilience_checkin";
const preferencesSessionStorageIdentifier = "user_preferences";
const ssoProviderFlowStorageIdentifier = "sso_flow_provider";
const getUser = () => {
  if (typeof sessionStorage === "undefined") {
    return undefined;
  }

  try {
    const userDetails = sessionStorage.getItem(userSessionStorageIdentifier);
    if (userDetails) {
      return JSON.parse(userDetails);
    }

    return USER_DETAILS;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return USER_DETAILS;
  }
};

const saveEmailToStorage = (email) => {
  sessionStorage.setItem(emailSessionStorageIdentifier, email);
};
const getEmailFromStorage = () =>
  sessionStorage.getItem(emailSessionStorageIdentifier);
const clearEmailFromStorage = () => {
  sessionStorage.removeItem(emailSessionStorageIdentifier);
};

const saveNameToStorage = (name) => {
  sessionStorage.setItem(nameSessionStorageIdentifier, name);
};
const getNameFromStorage = () =>
  sessionStorage.getItem(nameSessionStorageIdentifier);
const clearNameFromStorage = () => {
  sessionStorage.removeItem(nameSessionStorageIdentifier);
};

const saveYearOfBirthToStorage = (yearOfBirth) => {
  sessionStorage.setItem(yearOfBirthSessionStorageIdentifier, yearOfBirth);
};
const getYearOfBirthFromStorage = () =>
  sessionStorage.getItem(yearOfBirthSessionStorageIdentifier);
const clearYearOfBirthFromStorage = () => {
  sessionStorage.removeItem(yearOfBirthSessionStorageIdentifier);
};

const saveResilienceCheckInAnswer = ({
  questionListKey = resilienceCheckInStorageIdentifier,
  languageCode,
  answers,
}: QuestionList) => {
  const currentlySavedQuestionList: QuestionList = JSON.parse(
    sessionStorage.getItem(questionListKey) || "{}",
  );
  const currentlySavedAnswers = currentlySavedQuestionList.answers || [];
  answers.forEach((answer) => {
    const index = currentlySavedAnswers.findIndex(
      (ans) => ans.questionKey === answer.questionKey,
    );
    if (index > -1) {
      currentlySavedAnswers[index] = answer;
    } else {
      currentlySavedAnswers.push(answer);
    }
  });
  sessionStorage.setItem(
    questionListKey,
    JSON.stringify({
      questionListKey,
      languageCode,
      answers: currentlySavedAnswers,
    }),
  );
};

const getResilienceCheckInAnswerByQuestionKey = (
  questionKey: string,
  questionListKey = resilienceCheckInStorageIdentifier,
) => {
  const currentlySavedQuestionList: QuestionList = JSON.parse(
    sessionStorage.getItem(questionListKey) || "{}",
  );
  const currentlySavedAnswers = currentlySavedQuestionList.answers || [];
  const index = currentlySavedAnswers.findIndex(
    (ans) => ans.questionKey === questionKey,
  );
  if (index > -1) {
    return currentlySavedAnswers[index];
  }
  return null;
};

const getResilineceCheckInAnswers = (
  questionListKey = resilienceCheckInStorageIdentifier,
): QuestionList => {
  return JSON.parse(sessionStorage.getItem(questionListKey)!);
};

const saveGeneralWellbeingToStorage = (happy) => {
  sessionStorage.setItem(
    generalWellbeingsessionStorageIdentifier,
    JSON.stringify(happy),
  );
};
const getGeneralWellbeingFromStorage = () => {
  if (!sessionStorage.getItem(generalWellbeingsessionStorageIdentifier)) {
    return {};
  }
  const generalWellbeing = JSON.parse(
    sessionStorage.getItem(generalWellbeingsessionStorageIdentifier) || "{}",
  );
  return generalWellbeing;
};
const clearGeneralWellbeingFromStorage = () => {
  sessionStorage.removeItem(generalWellbeingsessionStorageIdentifier);
};

const saveReferralToStorage = (referral) => {
  sessionStorage.setItem(
    referralSessionStorageIdentifier,
    JSON.stringify(referral),
  );
};
const getReferralFromStorage = () =>
  JSON.parse(sessionStorage.getItem(referralSessionStorageIdentifier)!);
const clearRefferalFromStorage = () => {
  sessionStorage.removeItem(referralSessionStorageIdentifier);
};

// TODO temporary method, user should have in their profile an onboarding
//  property to be checked
const getIsUserHasOnboarding = () => {
  const name = getNameFromStorage();
  const yearOfBirth = getYearOfBirthFromStorage();
  const referral = getReferralFromStorage();

  return name && yearOfBirth && referral;
};

const saveCurrentMoodsToStorage = (emotions) =>
  sessionStorage.setItem(
    currentMoodsSessionStorageIdentifier,
    JSON.stringify(emotions),
  );
const getCurrentMoodsFromStorage = () => {
  const emotionsString = sessionStorage.getItem(
    currentMoodsSessionStorageIdentifier,
  );
  if (emotionsString) {
    return new Set(JSON.parse(emotionsString));
  }
  return new Set();
};
const clearCurrentMoodsFromStorage = () =>
  sessionStorage.removeItem(currentMoodsSessionStorageIdentifier);

const dontLoadTranslations = () =>
  !!sessionStorage.getItem("skip_translations");

const saveUserToStorage = (user) => {
  sessionStorage.setItem(userSessionStorageIdentifier, JSON.stringify(user));
};

const savePreferencesToStorage = (preferences) => {
  sessionStorage.setItem(
    preferencesSessionStorageIdentifier,
    JSON.stringify(preferences),
  );
};
const getPreferencesFromStorage = () => {
  const preferences = JSON.parse(
    sessionStorage.getItem(preferencesSessionStorageIdentifier)!,
  );
  if (preferences) {
    return Object.keys(preferences).filter((key) => preferences[key]);
  }
  return null;
};
const clearPreferencesFromStorage = () => {
  sessionStorage.removeItem(preferencesSessionStorageIdentifier);
};

const clearOnboardingFromStorage = () => {
  clearNameFromStorage();
  clearYearOfBirthFromStorage();
  clearRefferalFromStorage();
  clearCurrentMoodsFromStorage();
};

const getUserEmail = () => {
  const user = getUser();
  return user.email;
};

const saveCheckinLocal = (formModules, formLanguage, answers) => {
  sessionStorage.setItem(
    checkinSessionStorageIdentifier,
    JSON.stringify({
      formModules,
      formLanguage,
      answers,
    }),
  );
};
const getCheckinLocal = () =>
  JSON.parse(sessionStorage.getItem(checkinSessionStorageIdentifier)!);
const hasCheckinLocal = () =>
  !!JSON.parse(sessionStorage.getItem(checkinSessionStorageIdentifier)!)
    ?.answers;
const removeCheckinLocal = () =>
  sessionStorage.removeItem(checkinSessionStorageIdentifier);

const saveSSOFlowProviderToStorage = (provider) => {
  sessionStorage.setItem(ssoProviderFlowStorageIdentifier, provider);
};

const getSSOFlowProviderFromStorage = () =>
  sessionStorage.getItem(ssoProviderFlowStorageIdentifier);
const clearSSOFlowFromStorage = () => {
  sessionStorage.removeItem(ssoProviderFlowStorageIdentifier);
};
const hasSSOFlowInStorage = () => {
  return getSSOFlowProviderFromStorage();
};

const service = {
  getIsUserHasOnboarding,

  saveEmailToStorage,
  getEmailFromStorage,
  clearEmailFromStorage,

  saveNameToStorage,
  getNameFromStorage,
  clearNameFromStorage,

  saveYearOfBirthToStorage,
  getYearOfBirthFromStorage,
  clearYearOfBirthFromStorage,

  saveReferralToStorage,
  getReferralFromStorage,
  clearRefferalFromStorage,

  getUserEmail,
  getUser,
  saveUserToStorage,

  saveResilienceCheckInAnswer,
  getResilienceCheckInAnswerByQuestionKey,
  getResilineceCheckInAnswers,

  saveGeneralWellbeingToStorage,
  getGeneralWellbeingFromStorage,
  clearGeneralWellbeingFromStorage,

  saveCurrentMoodsToStorage,
  getCurrentMoodsFromStorage,
  clearCurrentMoodsFromStorage,

  savePreferencesToStorage,
  getPreferencesFromStorage,
  clearPreferencesFromStorage,

  clearOnboardingFromStorage,

  saveCheckinLocal,
  getCheckinLocal,
  hasCheckinLocal,
  removeCheckinLocal,

  dontLoadTranslations,

  saveSSOFlowProviderToStorage,
  getSSOFlowProviderFromStorage,
  hasSSOFlowInStorage,
  clearSSOFlowFromStorage,
};

export default service;
