import { Box, Card, CardActions, CardContent, CardMedia } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    boxShadow: theme.customShadows.bookingContainerBackgroundShadow,
    backgroundColor: theme.colors.trueWhite,
    borderRadius: theme.spacing(3),
  },
  boxCard: ({ isMultipleCardsShown }) => ({
    overflow: "auto",
    display: !isMultipleCardsShown && "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  }),
  cardContent: ({
    isMultipleCardsShown,
    noImageGap,
    isLanguageFlag,
    noPadding,
  }) => ({
    display: isMultipleCardsShown && "flex",
    justifyContent: isMultipleCardsShown && "space-between",
    flex: 1,
    padding: noPadding ? 0 : theme.spacing(4, 3),
    "&:last-child": {
      paddingBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: isLanguageFlag && theme.spacing(3, 2, 0, 2),
      marginBottom: noImageGap && theme.spacing(-2),
    },
  }),
  cardImage: ({ isMultipleCardsShown }) => ({
    [theme.breakpoints.down("sm")]: {
      marginTop: isMultipleCardsShown && theme.spacing(0),
    },
    flex: 1,
    marginTop: isMultipleCardsShown && theme.spacing(2),
    "& img:nth-child(1)": {
      float: "right",
    },
  }),
  cardActions: {
    padding: theme.spacing(3),
    justifyContent: "center",
    borderTop: "solid 2px rgba(21, 35, 97, .05);",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(4),
      position: "fixed",
      bottom: 0,
      right: 0,
      backgroundColor: "#ffffff",
      padding: theme.spacing(2),
      boxShadow: "0px 0 20px rgba(0, 0, 0, 25%)",
    },
  },
  card: ({ isMultipleCardsShown, useSecondaryColor }) => ({
    backgroundColor: useSecondaryColor ? "#FFF8ED" : "inherit",
    marginBottom: isMultipleCardsShown ? theme.spacing(4) : theme.spacing(8.5),
    boxShadow: "none",
    height: isMultipleCardsShown && 184,
    overflow: "visible",
    [theme.breakpoints.down("sm")]: {
      height: isMultipleCardsShown && 152,
    },
  }),
}));

const BookingCard = ({
  image,
  actions,
  children,
  overflowAllowed = false,
  isMultipleCardsShown = false,
  useSecondaryColor = false,
  noImageGap = false,
  isLanguageFlag = false,
  noPadding = false,
}) => {
  const classes = useStyles({
    isMultipleCardsShown,
    useSecondaryColor,
    overflowAllowed,
    noImageGap,
    isLanguageFlag,
    noPadding,
  });

  return (
    <div className={classes.cardContainer}>
      <Card className={classes.card}>
        <Box className={classes.boxCard}>
          <CardContent className={classes.cardContent}>{children}</CardContent>
          {image && (
            <CardMedia className={classes.cardImage}>{image}</CardMedia>
          )}
        </Box>
        {actions && (
          <CardActions className={classes.cardActions}>{actions}</CardActions>
        )}
      </Card>
    </div>
  );
};

export default BookingCard;
