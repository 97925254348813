import { Box, Button, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import EventRepeatOutlinedIcon from "@mui/icons-material/EventRepeatOutlined";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { SESSION_TYPE } from "@src/utils/constants";

const useStyle = makeStyles(({ spacing, colors }) => ({
  buttonText: {
    color: "white",
    padding: spacing(0.5, 3),
    opacity: 1,
    cursor: "pointer",
  },
  iconBox: {
    width: spacing(4),
    height: spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: spacing(1),
    borderRadius: "4px",
    border: "2px solid",
    "&:hover": {
      opacity: 0.5,
    },
  },
  buttonStatus: {
    height: spacing(4),
    padding: spacing(0.5, 2),
    margin: spacing(0, 0.5),
    borderRadius: "100px",
    border: "2px solid",
    marginBottom: spacing(1),
  },
  lightBlueBackground: {
    backgroundColor: colors.primaryBlue,
    "@media (hover: none)": {
      "&:hover": {
        backgroundColor: colors.primaryBlue,
      },
    },
    color: "white",
    borderColor: colors.primaryBlue,
  },
  whiteBackground: {
    backgroundColor: colors.trueWhite,
    "&:hover": {
      backgroundColor: colors.white,
    },
    color: colors.primaryDarkBlue,
    borderColor: colors.primaryDarkBlue,
  },
  typographyButtonWhite: {
    color: colors.primaryDarkBlue,
    opacity: 1,
  },
  typographyButtonBlue: {
    color: "white",
    opacity: 1,
  },
  fullWidth: {
    width: "100%",
  },
  customWidth: ({ width }) => ({
    width: width === 0 ? `${width}%` : "",
  }),
}));

export const MySessionsButton = ({
  consult,
  onClick,
  isWhite,
  action,
  width = 0,
}) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("md"));
  const { t } = useTranslatedNavigate();
  const classes = useStyle({ width });

  let tooltip;
  let icon;
  let buttonText;

  switch (action) {
    case "view":
      icon = (
        <ArrowForwardIosIcon
          fontSize="medium"
          onClick={(event) => onClick(event, consult)}
        />
      );
      tooltip = t("MySessionsView.SessionsView.Tooltip.ViewNotes");
      buttonText = t("MySessionsView.SessionsView.Tooltip.ViewNotes");
      break;
    case "rebook":
      icon = (
        <EventRepeatOutlinedIcon
          fontSize="medium"
          onClick={(event) => onClick(event, consult)}
        />
      );
      tooltip = t("MySessionsView.SessionsView.Tooltip.BookAgain");
      buttonText = t("MySessionsView.SessionsView.ButtonBookAgain");
      break;
    case "join":
      icon = (
        <PlayCircleOutlinedIcon
          fontSize="medium"
          onClick={(event) => onClick(event, consult)}
        />
      );
      tooltip = t("MySessionsView.SessionsView.Tooltip.JoinSession");
      buttonText = t("MySessionsView.SessionsView.JoinButton");
      break;
    case "cancel":
      icon = (
        <HighlightOffOutlinedIcon
          fontSize="medium"
          onClick={(event) => onClick(event, consult)}
        />
      );
      tooltip =
        consult.consultOrSpace === SESSION_TYPE.SPACE
          ? t("MySessionsView.SessionsView.Tooltip.UnsubscribeSpace")
          : t("MySessionsView.SessionsView.Tooltip.CancelSession");
      buttonText = t("MySessionsView.SessionsView.CancelButton");
      break;
    case "reschedule":
      icon = (
        <UpdateOutlinedIcon
          fontSize="medium"
          onClick={(event) => onClick(event, consult)}
        />
      );
      tooltip = t("MySessionsView.SessionsView.Tooltip.RescheduleSession");
      buttonText = t("MySessionsView.SessionsView.RescheduleButton");
      break;
    default:
      break;
  }

  const colorBackground = isWhite
    ? classes.whiteBackground
    : classes.lightBlueBackground;
  const typographyStyle = isWhite
    ? classes.typographyButtonWhite
    : classes.typographyButtonBlue;

  return isSmallScreen ? (
    <Button
      className={`${classes.buttonStatus} ${colorBackground} ${classes.customWidth}`}
      onClick={(event) => onClick(event, consult)}
      data-cy={action}
    >
      <Typography
        variant="subtitle2"
        className={`${classes.typographyButton} ${typographyStyle}`}
      >
        {buttonText}
      </Typography>
    </Button>
  ) : (
    <Tooltip title={tooltip} placement="top" data-cy={action}>
      <Box className={`${classes.iconBox} ${colorBackground}`}>{icon}</Box>
    </Tooltip>
  );
};
