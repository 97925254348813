const themeIconsMapping = [
  {
    name: "Confidence",
    icon: "ThumbUpOffAlt",
  },
  {
    name: "Purpose",
    icon: "VpnKeyOutlined",
  },
  {
    name: "Lifestyle",
    icon: "FavoriteBorderOutlined",
  },
  {
    name: "Mindfulness",
    icon: "SpaOutlined",
  },
  {
    name: "Relationships",
    icon: "GroupOutlined",
  },
  {
    name: "Work",
    icon: "WorkOutlineOutlined",
  },
  {
    name: "Sleep",
    icon: "NightlightOutlined",
  },
  {
    name: "Stress",
    icon: "BoltOutlined",
  },
  {
    name: "Health",
    icon: "HealthAndSafetyOutlined",
  },
];

export const getThemeIcon = (themeName) => {
  const themeIcon = themeIconsMapping.find((theme) => theme.name === themeName);
  return themeIcon ? themeIcon.icon : "help_outlined";
};
