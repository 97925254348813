import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TypographyWithIcon from "@src/components/TypographyWithIcon";
import { ReactComponent as ManFitness } from "@src/resources/images/man_fitness.svg";
import BookingContainer from "@src/views/booking/components/BookingContainer";
import BookingCard from "@src/views/booking/components/BookingCard";
import BackToMyOpenUpButton from "@src/views/booking/spaces/components/BackToMyOpenUpButton";
import { format } from "date-fns";
import { ISpaceType } from "@src/utils/constants";
import { Icon } from "@src/components/Icon";

interface SpaceCancellationProps {
  space: any;
  nextStep: Function;
  isSubmitting: boolean;
}

const useStyles = makeStyles((theme) => ({
  bookingDetailsMessage: {
    maxWidth: 240,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 296,
    },
    marginBottom: theme.spacing(3),
  },
  typography: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    display: "flex",
    alignItems: "center",
  },
  image: {
    float: "right",
    marginTop: theme.spacing(10.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      width: 152,
      height: 152,
      position: "relative",
      top: theme.spacing(-2.5),
      marginBottom: theme.spacing(-2.5),
      marginRight: theme.spacing(-2.25),
    },
  },
  box: {
    maxWidth: 240,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 296,
    },
    marginBottom: theme.spacing(2),
  },
  boxCalendarWithIcon: {
    maxWidth: 240,
  },
  cancelSessionButton: {
    opacity: 1,
    color: "white",
    fontWeight: 500,
    letterSpacing: theme.spacing(0.15625),
  },
  button: {
    opacity: 1,
    fontWeight: 500,
    width: 240,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0.5),
    },
  },
  rescheduleSessionButton: {
    color: "000000",
    background: "white",
    border: `2px solid ${theme.colors.primaryDarkBlue}`,
    "&:hover": {
      backgroundColor: theme.colors.lightGray,
    },
  },
  rescheduleSessionButtonText: {
    fontWeight: 500,
    letterSpacing: theme.spacing(0.15625),
  },
  boxFooter: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  sessionDetailsText: {
    maxWidth: 296,
    alignItems: "flex-start",
    color: theme.colors.primaryDarkBlue,
  },
  sessionDetailsIcon: {
    fontSize: theme.spacing(3),
    color: theme.colors.primaryDarkBlue,
    marginRight: theme.spacing(2),
  },
}));

const SessionDetailItem = ({ classes, content, iconLigature }) => (
  <TypographyWithIcon
    variant="body2"
    typographyClass={classes.sessionDetailsText}
    classesIcon={classes.sessionDetailsIcon}
    iconLigature={iconLigature}
    outlined
    boxClass={classes.boxCalendarWithIcon}
  >
    {content}
  </TypographyWithIcon>
);

const SpaceCancellation = ({
  space,
  nextStep,
  isSubmitting,
}: SpaceCancellationProps) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();

  return (
    space && (
      <BookingContainer message="" title={t("Space.Unsubscribe.Title")}>
        <BookingCard
          image={<ManFitness className={classes.image} />}
          actions={
            <Box className={classes.boxFooter}>
              <BackToMyOpenUpButton />
              <Button
                className={classes.button}
                disabled={isSubmitting}
                onClick={() => {
                  nextStep();
                }}
              >
                <Typography
                  variant="body2"
                  className={classes.cancelSessionButton}
                >
                  {t("Space.Unsubscribe.Cancel")}
                </Typography>
              </Button>
            </Box>
          }
        >
          <Typography
            variant="subtitle1"
            className={classes.bookingDetailsMessage}
          >
            {t("Space.Unsubscribe.Details.Title")}
          </Typography>
          <div className="flex mb-6 items-center text-indigo-800 opacity-70 gap-4">
            <Icon iconKey={space.spaceType as ISpaceType} />
            <p className="text-sm">{space.spaceTitle}</p>
          </div>
          <SessionDetailItem
            classes={classes}
            content={t(`SessionLanguage.${space.spaceLanguage}`)}
            iconLigature="flag"
          />
          <SessionDetailItem
            classes={classes}
            content={space.spaceExperts}
            iconLigature="psychology"
          />
          <SessionDetailItem
            classes={classes}
            content={format(
              new Date(space?.dateTime ?? new Date().toString()),
              "iiii do MMMM",
            )}
            iconLigature="event_available"
          />
        </BookingCard>
      </BookingContainer>
    )
  );
};

export default SpaceCancellation;
