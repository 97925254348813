import { useParams } from "react-router-dom";
import { useCheckinFormByThemeKeys } from "@src/queries/themes";
import useTranslatedNavigate, {
  convertThemeNameToThemeKey,
} from "@src/services/useTranslateNavigate";
import NotFoundView from "@src/views/errors/NotFoundView";
import TripettoSurvey from "./index";

const ThemeTripettoSurvey = ({ redirectUri, redirectUriOnNotLoggedIn }) => {
  const { themeName } = useParams();
  const { pathT, t } = useTranslatedNavigate();

  const themeKey = convertThemeNameToThemeKey(themeName, t);

  const query = useCheckinFormByThemeKeys([themeKey]);

  const redirect = redirectUri || pathT("route.themes", { key: themeName });

  return query.data ? (
    <TripettoSurvey
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...query}
      redirectUri={redirect}
      redirectUriOnNotLoggedIn={redirectUriOnNotLoggedIn}
    />
  ) : (
    <NotFoundView />
  );
};

export default ThemeTripettoSurvey;
