import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles((theme) => ({
  checkInBox: {
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: theme.spacing(4),
  },
  generalCheckinText: {
    fontSize: "16px",
    fontWeight: "400",
    letterSpacing: "0.5px",
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  globalCheckinBtn: {
    padding: theme.spacing(0, 3.25),
    marginLeft: theme.spacing(4),
    letterSpacing: 1.25,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: theme.spacing(0),
      marginBottom: theme.spacing(3),
    },
  },
}));
const BottomCTAWithFocusedThemes = ({ isSmallScreen }) => {
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();

  if (!isSmallScreen) {
    return (
      <Box
        display={isSmallScreen ? "block" : "flex"}
        className={classes.checkInBox}
      >
        <Link to={`${pathT("route.themeCheckin")}`}>
          <Typography className={classes.generalCheckinText} variant="h6">
            {t("Dashboard.FocusedChart.Tile.FocusedCheckIn.Text")}
          </Typography>
        </Link>
        <Link to={pathT("route.themesFocusCheckin")}>
          <Button classes={{ root: classes.globalCheckinBtn }}>
            {t("Dashboard.FocusedChart.Tile.FocusedCheckIn.CheckinButton")}
          </Button>
        </Link>
      </Box>
    );
  }

  return (
    <Box
      display={isSmallScreen ? "block" : "flex"}
      className={classes.checkInBox}
    >
      <Link to={pathT("route.themesFocusCheckin")}>
        <Button classes={{ root: classes.globalCheckinBtn }}>
          {t("Dashboard.FocusedChart.Tile.FocusedCheckIn.CheckinButton")}
        </Button>
      </Link>
      <Link to={`${pathT("route.themeCheckin")}`}>
        <Typography className={classes.generalCheckinText} variant="h6">
          {t("Dashboard.FocusedChart.Tile.FocusedCheckIn.Text")}
        </Typography>
      </Link>
    </Box>
  );
};

export default BottomCTAWithFocusedThemes;
