import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ManChairTable } from "@src/resources/images/man_chair_table.svg";

const useStyle = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    borderRadius: spacing(2),
    marginTop: spacing(4),
    width: "90%",
    [breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  container: {
    padding: spacing(4, 6),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [breakpoints.down("sm")]: {
      padding: spacing(3, 2),
    },
  },
  button: {
    letterSpacing: 1.25,
    marginTop: spacing(4),
    width: 296,
    [breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  image: {
    height: 218,
    width: 218,
  },
  text: {
    textAlign: "center",
  },
}));

const NoMySessions = ({ hasPastCompleted }) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const classes = useStyle();

  const text = !hasPastCompleted
    ? t("MySessionsView.NoSessions.NoPastCompleted")
    : t("MySessionsView.NoSessions.PastCompleted");

  return (
    <Paper className={classes.root}>
      <Box className={classes.container} data-cy="no-sessions">
        <ManChairTable className={classes.image} />
        <Typography variant="subtitle1" className={classes.text}>
          {text}
        </Typography>
        <Button
          classes={{ root: classes.button }}
          onClick={() => navigate(pathT("route.booking"), { replace: true })}
        >
          {t("Psychologist.Book")}
        </Button>
      </Box>
    </Paper>
  );
};

export default NoMySessions;
