import NotFoundCard from "@openup/shared/components/Cards/NotFoundCard/NotFoundCard";
import DashboardTopBar from "@src/layouts/TopBars/DashboardTopBar";
import BackgroundImageTopRight from "@src/resources/backgrounds/neutral-top-right.svg";
import BackgroundImageBottomLeft from "@src/resources/backgrounds/neutral-bottom-left.svg";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useNavigate } from "react-router-dom";

const NotFoundView = ({ showNavBar = false }) => {
  const navigate = useNavigate();
  const { pathT } = useTranslatedNavigate();

  const navigateToOverview = () => {
    navigate(pathT("route.account"));
  };

  return (
    <div
      className="h-screen"
      style={{
        backgroundImage: `url(${BackgroundImageTopRight}), url(${BackgroundImageBottomLeft})`,
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: "right top, left bottom",
        backgroundSize: "20%, 35%",
      }}
    >
      {showNavBar && <DashboardTopBar />}
      <div className="flex w-full justify-center mt-8 md:mt-16 px-4 md:px-0">
        <NotFoundCard goBackAction={navigateToOverview} />
      </div>
    </div>
  );
};

export default NotFoundView;
