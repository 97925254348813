import React, { FC, useState } from "react";
import LessonQuizCard from "@openup/shared/components/Cards/LessonQuizCard/LessonQuizCard";
import {
  QuizQuestion,
  QuizQuestionListAnswers,
  QuizQuestions,
} from "@src/models/Course";
import Button from "@openup/shared/components/Button/Button";
import { useTranslation } from "react-i18next";
import {
  submitQuizAnswers,
  updateEngagementStatus,
} from "@src/queries/courses";
import { useMutation } from "react-query";
import DefaultError from "@openup/shared/components/DefaultError/DefaultError";

interface LessonQuizProps {
  questionListKey: string;
  languageCode: string;
  answers: QuizQuestionListAnswers;
  lessonId: number;
  isLoading: boolean;
  navigateAfterFinishingLesson: () => void;
  className?: string;
}

const LessonQuiz: FC<LessonQuizProps> = ({
  questionListKey,
  languageCode,
  answers,
  lessonId,
  isLoading,
  navigateAfterFinishingLesson,
  className = "",
}) => {
  const { t } = useTranslation();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizAnswers, setQuizAnswers] = useState<QuizQuestion[]>([]);
  const onQuestionAnswer = (question: QuizQuestion, answer: string) => {
    setQuizAnswers((prev) => [
      ...prev,
      {
        ...question,
        answers: [answer],
      },
    ]);
  };
  const isLastQuestion = answers
    ? currentQuestionIndex === answers.questionWithAnswers.length - 1
    : true;
  const [isLastQuestionAnswered, setIsLastQuestionAnswered] = useState(false);
  const { mutate: submitAnswers, isLoading: isSubmitAnswersLoading } =
    useMutation(submitQuizAnswers);
  const { questionWithAnswers } = answers;
  const { mutate: updateLessonStatus } = useMutation(updateEngagementStatus);

  if (!answers || !languageCode || !questionListKey) {
    return <DefaultError />;
  }

  const onFinalSubmit = async () => {
    const answersResult: QuizQuestions = {
      answers: quizAnswers,
      languageCode,
      questionListKey,
    };
    await submitAnswers(answersResult);
    await updateLessonStatus({
      lessonId,
      status: "completed",
    });
    navigateAfterFinishingLesson();
  };

  const onAnswerSubmit = (question, answerId: number, index: number) => {
    onQuestionAnswer(question, question.answers[answerId]);
    if (!isLastQuestion) {
      setCurrentQuestionIndex(index + 1);
    } else {
      setIsLastQuestionAnswered(true);
    }
  };

  if (!questionWithAnswers || questionWithAnswers.length === 0) {
    return <DefaultError />;
  }

  return (
    <div className={`mx-4 mb-4 sm:mx-0 ${className}`}>
      {questionWithAnswers.map((question, index) => {
        const { questionKey, questionText, answers: questionAnswer } = question;
        return (
          <LessonQuizCard
            number={index + 1}
            total={questionWithAnswers.length}
            isLoading={isLoading}
            key={questionKey}
            className={`p-4 mt-7 sm:mt-0 pb-24 sm:p-0 ${
              currentQuestionIndex === index ? "block" : "hidden"
            }`}
            answers={questionAnswer.map((answer, answerIndex) => ({
              text: answer,
              id: answerIndex,
            }))}
            title={questionText}
            onSubmit={(answerId) => {
              onAnswerSubmit(question, answerId, index);
            }}
          />
        );
      })}

      {isLastQuestion && (
        <Button
          className="w-full max-w-[572px] mt-6 sm:mx-auto !block"
          onClick={onFinalSubmit}
          disabled={!isLastQuestionAnswered || isSubmitAnswersLoading}
        >
          {t("CourseProgress.QuizSubmit")}
        </Button>
      )}
    </div>
  );
};

export default LessonQuiz;
