import axios from "axios";
import { useInfiniteQuery, useQuery } from "react-query";
import { apiPaths, pageSize } from "@src/utils/constants";
import { ConsultSpace } from "@src/views/account/mySessions/models/consultSpace";

const SESSION_TIME = {
  PAST: "past",
  UPCOMING: "upcoming",
};

export const useGetConsultSpaces = ({
  limit = pageSize,
  isPastSession = true,
}: GetSpaceConsultsParams): ReturnType<typeof useQuery> =>
  useInfiniteQuery({
    queryKey: [apiPaths.consultSpaces, isPastSession],
    queryFn: async ({ pageParam = 0 }): Promise<ConsultSpace[]> => {
      const { data } = await axios.get<ConsultSpace[]>(
        `${apiPaths.consultSpaces}?Page=${pageParam}&Limit=${limit}&Search=${
          isPastSession ? SESSION_TIME.PAST : SESSION_TIME.UPCOMING
        }`,
      );

      return data;
    },
    getNextPageParam: (lastPage: any) => {
      if (
        lastPage.pagination.currentPage <
        (lastPage.pagination.total - 1) / lastPage.pagination.limit
      ) {
        return lastPage.pagination.currentPage + 1;
      }
      return undefined;
    },
    refetchOnMount: "always",
  });

type GetSpaceConsultsParams = {
  currentPage: number;
  limit?: number;
  isPastSession: boolean;
};
