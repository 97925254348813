/* eslint-disable react/forbid-prop-types */
import { useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  List,
  Divider,
  ListItem,
  ListItemText,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useQueryThemesCheckins } from "@src/queries/themes";
import { logout } from "@src/actions/userActions";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import clsx from "clsx";
import ThemeMenuListItem from "./ThemeMenuListItem";
import { withSnackbar } from "./SnackBarComponent";

const useStylesForSkinBackground = makeStyles((theme) => ({
  buttonHomeItem: {
    height: "40px",
    "&:hover": {
      backgroundColor: theme.colors.lightGrayishBlue,
      borderRadius: "4px",
    },
    "&:focus": {
      backgroundColor: theme.colors.lightGrayishBlue,
    },
    "&:focus > div > h6": {
      color: theme.colors.primaryBlue,
    },
  },
  textButtonHome: {
    textAlign: "center",
    fontSize: "16px",
    color: theme.colors.primaryDarkBlue,
    marginLeft: theme.spacing(1.5),
  },
  themeButtonItem: ({ open }) => ({
    height: "40px",
    "&:hover": {
      backgroundColor: theme.colors.lightGrayishBlue,
      borderRadius: "4px",
      height: "40px",
    },
    "&:focus": {
      backgroundColor: open
        ? theme.colors.secondarySkin
        : theme.colors.lightGrayishBlue,
    },
    "&:focus > div > h6 > div": {
      color: open ? theme.colors.primaryBlue : theme.colors.primaryDarkBlue,
    },
    "&:focus > p": {
      color: theme.colors.primaryBlue,
    },
    "& > div > h6 > div": {
      marginLeft: theme.spacing(1.5),
    },
  }),
  themeButtonItemChilds: ({ open }) => ({
    height: "38px",
    "&:hover": {
      backgroundColor: theme.colors.lightGrayishBlue,
      borderRadius: "4px",
    },
    "&:focus": {
      backgroundColor: theme.colors.lightGrayishBlue,
    },
    "&:focus > div > h6 > div": {
      color: open ? theme.colors.primaryBlue : theme.colors.primaryDarkBlue,
    },
    "&:focus > p": {
      color: theme.colors.primaryBlue,
    },
    "& > p": {
      marginLeft: theme.spacing(1.5),
    },
  }),
  logoutButtonItem: {
    height: "40px",
    "&:hover": {
      backgroundColor: theme.colors.lightGrayishBlue,
      borderRadius: "4px",
    },
    "&:focus": {
      backgroundColor: theme.colors.lightGrayishBlue,
    },
    "&:focus > h6": {
      color: theme.colors.primaryBlue,
    },
  },
  logoutButtonText: {
    textAlign: "center",
    fontSize: "16px",
    color: theme.colors.primaryDarkBlue,
  },
  exitIcon: {
    color: theme.colors.primaryDarkBlue,
    marginRight: theme.spacing(1.875),
    marginLeft: theme.spacing(1.5),
  },
  themesIcon: {
    color: theme.colors.primaryDarkBlue,
  },
  listItemText: {
    lineHeight: "16px",
    fontSize: "12px",
    fontWeight: "500",
    color: theme.colors.primaryDarkBlueWithTransparency,
    marginLeft: theme.spacing(1.5),
  },
  themeListChilds: {
    opacity: 1,
    fontWeight: "500",
  },
}));

const OpenUpMenuList = ({ logout: logoutUser, isOpen = false, ...props }) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const { data: themes = [], error } = useQueryThemesCheckins();
  const [open, setOpen] = useState(isOpen);
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const classes = useStylesForSkinBackground({ open });
  if (error) {
    props.snackbarShowMessage(error);
  }

  const logoutAndRedirect = () => {
    logoutUser();
    navigate(pathT("route.login"));
  };

  return (
    <Box maxWidth="208px">
      <List component="nav" aria-label="main mailbox folders">
        <RouterLink to={pathT("route.account")}>
          <ListItem button className={classes.buttonHomeItem}>
            <Box>
              <Typography variant="h6" className={classes.textButtonHome}>
                {t("SharedStrings.Home")}
              </Typography>
            </Box>
          </ListItem>
        </RouterLink>
        <Divider />
        <RouterLink to={pathT("route.library")}>
          <ListItem button className={classes.buttonHomeItem}>
            <Box>
              <Typography variant="h6" className={classes.textButtonHome}>
                {t("SharedStrings.Library")}
              </Typography>
            </Box>
          </ListItem>
        </RouterLink>
        <Divider />
        <RouterLink to={pathT("route.mySessions")}>
          <ListItem button className={classes.buttonHomeItem}>
            <Box>
              <Typography variant="h6" className={classes.textButtonHome}>
                {t("SharedStrings.MySessions")}
              </Typography>
            </Box>
          </ListItem>
        </RouterLink>
        <Divider />
        <RouterLink to={pathT("route.checkins")}>
          <ListItem button className={classes.buttonHomeItem}>
            <Box>
              <Typography variant="h6" className={classes.textButtonHome}>
                {t("SharedStrings.Chekins")}
              </Typography>
            </Box>
          </ListItem>
        </RouterLink>
        <Divider />
        <ListItem
          button
          className={classes.themeButtonItem}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <ListItemText>
            <Typography variant={isSmallScreen ? "body2" : "h6"}>
              <Box fontSize="16px">
                <b>{t("SharedStrings.Themes")}</b>
              </Box>
            </Typography>
          </ListItemText>
          {open ? (
            <ExpandLess className={classes.themesIcon} />
          ) : (
            <ExpandMore className={classes.themesIcon} />
          )}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button={false} className={classes.focusItem}>
              <Typography variant="body2" className={classes.listItemText}>
                {t("SharedStrings.Focus")}
              </Typography>
            </ListItem>
            {themes
              .filter((theme) => theme.isFocused)
              .map((item) => (
                <ThemeMenuListItem
                  key={item.key}
                  themeItem={item}
                  listItemClass={classes.themeButtonItemChilds}
                  typographyClass={classes.themeListChilds}
                />
              ))}
            <ListItem button={false} className={classes.otherItem}>
              <Typography variant="body2" className={classes.listItemText}>
                {t("SharedStrings.Other")}
              </Typography>
            </ListItem>
            {themes
              .filter((theme) => !theme.isFocused)
              .map((item) => (
                <ThemeMenuListItem
                  key={item.key}
                  themeItem={item}
                  listItemClass={classes.themeButtonItemChilds}
                  typographyClass={classes.themeListChilds}
                />
              ))}
          </List>
        </Collapse>
        <Divider />

        <ListItem
          button
          className={classes.logoutButtonItem}
          onClick={logoutAndRedirect}
        >
          <span className={clsx("material-icons-outlined", classes.exitIcon)}>
            logout
          </span>
          <Typography variant="h6" className={classes.logoutButtonText}>
            <b>{t("SharedStrings.Logout")}</b>
          </Typography>
        </ListItem>
      </List>
    </Box>
  );
};

OpenUpMenuList.propTypes = {
  menuItemStyle: PropTypes.any,
  menuSelectedItemStyle: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
});

export default withSnackbar(connect(null, mapDispatchToProps)(OpenUpMenuList));
