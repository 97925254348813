import colors from "@src/theme/colors";
import { formatSessionType } from "@src/utils/formatting";
import moment from "moment/moment";
import { createEvent } from "ics";
import {
  MEDIUM_SCORE_THRESHOLD,
  HIGH_SCORE_THRESHOLD,
  TAG_TYPES,
  PSYCHOLOGIST_STATUS,
  CONSULT_TYPE,
  LANGUAGE_TYPES,
  LANGUAGE_TYPES_SHORT_FORMS,
  STATUS,
  USER_ROLES,
} from "./constants";

export const getCategoryString = (categoryNo) => {
  if (categoryNo === 1) {
    return "Low";
  }
  if (categoryNo === 2) {
    return "Medium";
  }
  if (categoryNo === 3) {
    return "High";
  }
  return "Untracked";
};

export const twoWeeksBeforeNow = new Date(Date.now() - 12096e5).valueOf();

export const getScoreLayout = ({ maxOffset, score }) => {
  if (score === 0) {
    return {
      barColor: colors.primarySkin,
      textColor: colors.primaryDarkBlue,
      labelColor: colors.primaryDarkBlue,
      offset: maxOffset / 2,
      svgColor: "white",
    };
  }

  const maxScore = 4;
  const scale = maxOffset / maxScore;
  const offset = scale * (maxScore - (score - 1));

  if (score < MEDIUM_SCORE_THRESHOLD) {
    return {
      barColor: colors.primaryDarkBlue,
      labelColor: colors.primaryDarkBlue,
      textColor: colors.white,
      offset,
    };
  }

  if (score < HIGH_SCORE_THRESHOLD) {
    return {
      barColor: colors.primaryBlue,
      labelColor: colors.primaryBlue,
      textColor: colors.white,
      offset,
    };
  }

  if (score >= HIGH_SCORE_THRESHOLD) {
    return {
      barColor: colors.primaryGreen,
      labelColor: colors.primaryGreen,
      textColor: colors.white,
      offset,
    };
  }

  return undefined;
};

export const getMedicalScoreLayout = ({ maxOffset, score }) => {
  if (score === 0) {
    return {
      barColor: colors.primarySkin,
      textColor: colors.medicalGreenDark,
      labelColor: colors.medicalGreenDark,
      offset: maxOffset / 2,
      svgColor: "white",
    };
  }

  const maxScore = 4;
  const scale = maxOffset / maxScore;
  const offset = scale * (maxScore - (score - 1));

  if (score < MEDIUM_SCORE_THRESHOLD) {
    return {
      barColor: colors.medicalGreenDark,
      labelColor: colors.medicalGreenDark,
      textColor: colors.white,
      offset,
    };
  }

  if (score < HIGH_SCORE_THRESHOLD) {
    return {
      barColor: colors.medicalGreenMiddle,
      labelColor: colors.medicalGreenMiddle,
      textColor: colors.white,
      offset,
    };
  }

  if (score >= HIGH_SCORE_THRESHOLD) {
    return {
      barColor: colors.medicalGreenLight,
      labelColor: colors.medicalGreenLight,
      textColor: colors.white,
      offset,
    };
  }

  return undefined;
};

export const filterTagsOfType = (tagArray, tagType) => {
  if (!Array.isArray(tagArray) || !tagType) return null;
  return tagArray.filter(
    (tag) => tag.tagType.toLowerCase() === tagType.toLowerCase(),
  );
};

export const isAccessRestricted = (currentUser) => {
  if (!currentUser) {
    return true;
  }
  return !!currentUser?.isGuestLogin;
};

export const isGuestUser = (currentUser) => {
  if (!currentUser) {
    return false;
  }
  if (currentUser?.roles && currentUser?.roles.includes(USER_ROLES.GUEST)) {
    return true;
  }
  return false;
};

export const isClientUser = (currentUser) => {
  if (!currentUser) {
    return false;
  }
  return !!(
    currentUser?.roles && currentUser?.roles.includes(USER_ROLES.CLIENT)
  );
};

export const isValidPassword = (str) => {
  if (!str) return false;
  if (str.length < 12) return false;
  const lowerCasePattern = /[a-z]+/;
  const lowerCase = lowerCasePattern.test(str);
  const upperCasePattern = /[A-Z]+/;
  const upperCase = upperCasePattern.test(str);
  const numericPattern = /[0-9]+/;
  const numeric = numericPattern.test(str);
  const nonAlphaNumericPattern = /[@#$%^&+!=]+/;
  const nonAlphaNumeric = nonAlphaNumericPattern.test(str);

  return lowerCase && upperCase && numeric && nonAlphaNumeric;
};

export const isLowerAlphaNumeric = (str) => {
  const lowerCasePattern = /[a-z]+/;
  const lowerCase = lowerCasePattern.test(str);
  const numericPattern = /[0-9]+/;
  const numeric = numericPattern.test(str);

  return lowerCase && numeric;
};

export const findTagOfType = (tagArray, tagType, tagName) => {
  if (!Array.isArray(tagArray)) return null;
  if (!tagType || !tagName) return null;
  return tagArray.find(
    (tag) =>
      tag.tagName.toLowerCase() === tagName.toLowerCase() &&
      tag.tagType.toLowerCase() === tagType.toLowerCase(),
  );
};

export const isPsychologistActive = (tagArray) =>
  !!findTagOfType(
    tagArray,
    TAG_TYPES.PSYCHOLOGIST_STATUS,
    PSYCHOLOGIST_STATUS.ACTIVE_ACCEPTING,
  );

export const isPsychologistActiveNotAccepting = (tagArray) =>
  !!findTagOfType(
    tagArray,
    TAG_TYPES.PSYCHOLOGIST_STATUS,
    PSYCHOLOGIST_STATUS.ACTIVE_NOT_ACCEPTING,
  );

export const isPsychologistInactive = (tagArray) =>
  !!findTagOfType(
    tagArray,
    TAG_TYPES.PSYCHOLOGIST_STATUS,
    PSYCHOLOGIST_STATUS.INACTIVE,
  );

export const getConsultType = (consult) =>
  consult.type || consult.consultType || CONSULT_TYPE.GENERAL;

export const verifySessionType = (sessionType) => {
  if (Object.values(CONSULT_TYPE).includes(sessionType)) {
    return sessionType;
  }
  return undefined;
};

const convertToLongFormLanguage = (language) => {
  switch (language) {
    case LANGUAGE_TYPES_SHORT_FORMS.AR:
      return LANGUAGE_TYPES.AR;
    case LANGUAGE_TYPES_SHORT_FORMS.BS:
      return LANGUAGE_TYPES.BS;
    case LANGUAGE_TYPES_SHORT_FORMS.DE:
      return LANGUAGE_TYPES.DE;
    case LANGUAGE_TYPES_SHORT_FORMS.EN:
      return LANGUAGE_TYPES.EN;
    case LANGUAGE_TYPES_SHORT_FORMS.ES:
      return LANGUAGE_TYPES.ES;
    case LANGUAGE_TYPES_SHORT_FORMS.FL:
      return LANGUAGE_TYPES.FL;
    case LANGUAGE_TYPES_SHORT_FORMS.FR:
      return LANGUAGE_TYPES.FR;
    case LANGUAGE_TYPES_SHORT_FORMS.HR:
      return LANGUAGE_TYPES.HR;
    case LANGUAGE_TYPES_SHORT_FORMS.IT:
      return LANGUAGE_TYPES.IT;
    case LANGUAGE_TYPES_SHORT_FORMS.NL:
      return LANGUAGE_TYPES.NL;
    case LANGUAGE_TYPES_SHORT_FORMS.NO:
      return LANGUAGE_TYPES.NO;
    case LANGUAGE_TYPES_SHORT_FORMS.PL:
      return LANGUAGE_TYPES.PL;
    case LANGUAGE_TYPES_SHORT_FORMS.PT:
      return LANGUAGE_TYPES.PT;
    case LANGUAGE_TYPES_SHORT_FORMS.RU:
      return LANGUAGE_TYPES.RU;
    case LANGUAGE_TYPES_SHORT_FORMS.SR:
      return LANGUAGE_TYPES.SR;
    case LANGUAGE_TYPES_SHORT_FORMS.TR:
      return LANGUAGE_TYPES.TR;
    case LANGUAGE_TYPES_SHORT_FORMS.UK:
      return LANGUAGE_TYPES.UK;
    case LANGUAGE_TYPES_SHORT_FORMS.ZH:
      return LANGUAGE_TYPES.ZH;
    case LANGUAGE_TYPES_SHORT_FORMS.FI:
      return LANGUAGE_TYPES.FI;
    case LANGUAGE_TYPES_SHORT_FORMS.SO:
      return LANGUAGE_TYPES.SO;
    case LANGUAGE_TYPES_SHORT_FORMS.HU:
      return LANGUAGE_TYPES.HU;
    case LANGUAGE_TYPES_SHORT_FORMS.SK:
      return LANGUAGE_TYPES.SK;
    case LANGUAGE_TYPES_SHORT_FORMS.GR:
      return LANGUAGE_TYPES.GR;
    default:
      return LANGUAGE_TYPES.EN;
  }
};

export const isSessionCancelled = (consult) =>
  formatSessionType(consult.status) === STATUS.CANCELLED_BY_CLIENT ||
  formatSessionType(consult.status) === STATUS.CANCELLED_BY_OPENUP;

export const verifyLanguage = (language) => {
  if (Object.values(LANGUAGE_TYPES).includes(language)) {
    return language;
  }
  if (Object.values(LANGUAGE_TYPES_SHORT_FORMS).includes(language)) {
    return convertToLongFormLanguage(language);
  }
  return undefined;
};

/**
 * Boolean method to be used by Formik validation to check if there are no pending errors and no missing values.
 * Can be used to disable a "Submit" button
 * example <Button disabled={hasErrorsOrEmptyValues(errors, values)} />
 * @param {FormikErrorsObject} errors
 * @param {FormikValuesObject} values
 * @return {boolean} true if no errors and no missing values, false otherwise
 */
export const hasErrorsOrEmptyValues = (errors, values) =>
  Object.keys(errors).length > 0 ||
  Object.values(values).some((value) => value === "");

export const removeItemFromArray = (array, item) => {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};

const compareConsultStartedAt = (consult1, consult2) => {
  if (moment(consult1.startedAt).isBefore(moment(consult2.startedAt))) {
    return -1;
  }
  if (moment(consult1.startedAt).isAfter(moment(consult2.startedAt))) {
    return 1;
  }
  return 0;
};

export const getFollowingSessionOfSameType = (allConsults, currentConsult) => {
  if (!allConsults || !currentConsult) return undefined;

  const consultsAfterCurrent = allConsults
    .filter((consult) =>
      moment(currentConsult.startedAt).isBefore(moment(consult.startedAt)),
    )
    .filter((consult) => consult.consultType === currentConsult.consultType)
    .filter((consult) => consult.status === STATUS.SCHEDULED);

  consultsAfterCurrent.sort(compareConsultStartedAt);

  return consultsAfterCurrent[0];
};

export const canViewConsultNotes = (consult) => {
  if (!consult) return false;
  if (!consult.notesAvailableAt) return false;
  if (moment(consult.notesAvailableAt).isAfter(moment.utc())) return false;
  if (!consult.dossierNotes) return false;
  return true;
};

export const createAndDownloadICSEvent = async ({
  title,
  url,
  description,
  location,
  start,
  durationMinutes,
  filenamePrefix,
}) => {
  const event = {
    title,
    description,
    url,
    location,
    start: moment(start).utc().format("YYYYMMDD[T]HHmmss[Z]"),
    duration: { minutes: durationMinutes },
    status: "CONFIRMED",
  };

  const filename = `${filenamePrefix}-${moment(start).format(
    "YYYY-MM-DD",
  )}.ics`;

  const file = await new Promise((resolve, reject) => {
    createEvent(event, (error, value) => {
      if (error) {
        reject(error);
      }
      resolve(new File([value], filename, { type: "text/calendar" }));
    });
  });
  const icsFileUrl = URL.createObjectURL(file);

  const anchor = document.createElement("a");
  anchor.href = icsFileUrl;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(icsFileUrl);
};

export const isPasswordRegisterProhibited = (email) =>
  email.toLowerCase().endsWith("bol.com");

export const isPasswordRegisterProhibitedOnBlur = (blurEvent, callback) => {
  const email = blurEvent.target.value;
  if (isPasswordRegisterProhibited(email)) {
    callback(email);
  }
};
