export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_EXTERNAL_SUCCESS = "REGISTER_EXTERNAL_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_GUEST_SUCCESS = "REGISTER_GUEST_SUCCESS";
export const REGISTER_GUEST_FAIL = "REGISTER_GUEST_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const TWO_FACTOR_PENDING = "TWO_FACTOR_PENDING";
export const TWO_FACTOR_SUCCESS = "TWO_FACTOR_SUCCESS";
export const LOGOUT = "LOGOUT";
export const RESEND_CODE_SUCCESS = "RESEND_CODE_SUCCESS";
export const RESEND_CODE_FAIL = "RESEND_CODE_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAIL = "VERIFY_FAIL";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const EMAIL_VERIFY_SUCCESS = "EMAIL_VERIFY_SUCCESS";
export const EMAIL_VERIFY_FAIL = "EMAIL_VERIFY_FAIL";
export const CHECKIN_COMPLETED = "CHECKIN_COMPLETED";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAIL = "UPDATE_FAIL";
