import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography, Button } from "@mui/material";
import Page from "@src/components/Page";
import PropTypes from "prop-types";

import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const InvalidActionView = ({ authReducers }) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();

  const message =
    authReducers?.user?.message || t("InvalidActionView.SomethingWentWrong");

  return (
    <Page title={t("InvalidActionView.Title")}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      />
      <Container maxWidth="sm">
        <Box ml={1} mt={10}>
          <Typography variant="h4">{message}</Typography>
        </Box>
        <br />
        <Box mt={10}>
          <Button fullWidth onClick={() => navigate(pathT("route.login"))}>
            {t("LoginView.Login")}
          </Button>
          <Button fullWidth onClick={() => navigate(pathT("route.home"))}>
            {t("SharedStrings.Home")}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

InvalidActionView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  authReducers: PropTypes.any,
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps, null)(InvalidActionView);
