import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import ProductCard from "@src/components/ProductCard";
import { PRODUCTS_KEY } from "@src/utils/constants";

const useStyles = makeStyles((theme) => ({
  boxProductOverview: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(4),
    },
  },
  productOverviewTitle: {
    marginTop: theme.spacing(1),
    color: theme.colors.primaryDarkBlue,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      fontSize: 21,
      lineHeight: "32px",
    },
  },
}));

const ProductCards = ({ className = "" }) => {
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();
  const productsList = Object.keys(PRODUCTS_KEY);

  const setLinkToDirect = (product) => {
    switch (product) {
      case PRODUCTS_KEY.Sessions:
        return pathT("route.booking");
      case PRODUCTS_KEY.Spaces:
        return t("route.website.spaces");
      case PRODUCTS_KEY.SelfGuidedCare:
        return t("route.website.self-care");
      default:
        return null;
    }
  };

  return (
    <section className={className}>
      <Box className={classes.boxProductOverview}>
        <Typography variant="h3" className={classes.productOverviewTitle}>
          {t("DashboardView.ProductTile.OverviewTitle")}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {productsList.map((product) => (
          <Grid key={product} item xs={12} sm={6} md={4}>
            <ProductCard
              productKey={product}
              titleText={t(`DashboardView.ProductTile.${product}.Title`)}
              bodyText={t(`DashboardView.ProductTile.${product}.Body`)}
              linkText={t(`DashboardView.ProductTile.${product}.LinkText`)}
              toLink={setLinkToDirect(product)}
            />
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default ProductCards;
