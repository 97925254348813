import { FC } from "react";
import { IConsultType } from "@src/utils/constants";
import { ReactComponent as ManMeditation } from "@src/resources/images/cards/desktop/man-fitness.svg";
import { ReactComponent as ManMeditationMobile } from "@src/resources/images/cards/mobile/man-fitness.svg";
import { ReactComponent as WomanWatering } from "@src/resources/images/cards/desktop/woman_watering.svg";
import { ReactComponent as WomanWateringMobile } from "@src/resources/images/cards/mobile/woman_watering.svg";
import { ReactComponent as WomanLaptopStanding } from "@src/resources/images/cards/desktop/woman-laptop-standing.svg";
import { ReactComponent as WomanLaptopStandingMobile } from "@src/resources/images/cards/mobile/woman-laptop-standing.svg";
import { ReactComponent as WomanCelebrating } from "@src/resources/images/cards/desktop/woman-celebrating.svg";
import { ReactComponent as WomanCelebratingMobile } from "@src/resources/images/cards/mobile/woman-celebrating.svg";
import { useMediaQuery } from "@mui/material";
import { createTheme } from "@mui/material/styles";

interface SessionTypeCardImageProps {
  sessionType: IConsultType | null;
  className?: string;
}

export const SessionTypeCardImage: FC<SessionTypeCardImageProps> = ({
  sessionType = null,
  className = "",
}) => {
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  switch (sessionType) {
    case IConsultType.GENERAL:
      return isSmallScreen ? (
        <WomanLaptopStandingMobile className={className} />
      ) : (
        <WomanLaptopStanding className={className} />
      );
    case IConsultType.MINDFULNESS:
      return isSmallScreen ? (
        <ManMeditationMobile className={className} />
      ) : (
        <ManMeditation className={className} />
      );
    case IConsultType.PHYSICAL_WELLBEING:
      return isSmallScreen ? (
        <WomanCelebratingMobile className={className} />
      ) : (
        <WomanCelebrating className={className} />
      );
    default:
      return isSmallScreen ? (
        <WomanWateringMobile className={className} />
      ) : (
        <WomanWatering className={className} />
      );
  }
};
