import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiPaths } from "@src/utils/constants";
import {
  consultStaleTime,
  longerStaleTime,
  minutes,
  shorterStaleTime,
} from "@src/queries/staletimes";
import { useAuthentication } from "@src/services/auth.service";
import moment from "moment-timezone";
import { getWeekStartQueryKey } from "@src/utils/formatting";

export const useGetPsychologistByUrlHandleQuery = (urlHandle) =>
  useQuery(
    [apiPaths.getPsychologistByUrlHandle, urlHandle],
    async () => {
      try {
        const { data } = await axios.get(
          `${apiPaths.getPsychologistByUrlHandle}/${urlHandle}`,
        );
        return data;
      } catch (error) {
        if (error.response.status === 404) {
          return undefined;
        }
        return null;
      }
    },
    {
      enabled: !!urlHandle,
      staleTime: longerStaleTime,
    },
  );

export const useGetPsychologistByIdQuery = (userId) =>
  useQuery(
    [apiPaths.getPsychologistById, userId],
    async () => {
      try {
        const { data } = await axios.get(
          `${apiPaths.getPsychologistById}/${userId}`,
        );
        return data;
      } catch {
        return null;
      }
    },
    {
      enabled: !!userId,
    },
  );

export const useSendLoginMailToRedirectQuery = () =>
  useMutation(async ({ email, personalUrlHandle }) => {
    const psyUrlHandle = personalUrlHandle
      ? `&psyUrlHandle=${personalUrlHandle}`
      : "";
    const { data } = await axios.post(
      `${apiPaths.loginEmail}?email=${encodeURIComponent(
        email,
      )}${psyUrlHandle}`,
    );
    return data;
  });

export const useConfirmConsultQuery = () =>
  useMutation(async ({ token, emailParsed }) => {
    const { data } = await axios.post(
      `${apiPaths.confirmConsult}?token=${token}&email=${encodeURIComponent(
        emailParsed,
      )}`,
    );
    return data;
  });

const groupByTimeSlotsByDate = (timeSlots, timeZone) => {
  const startDateInTimeZone = moment
    .tz(timeSlots[0].startTime, timeZone)
    .format("LL");

  const startDateOfWeek = moment.utc(startDateInTimeZone).startOf("isoWeek");
  const endDateOfWeek = moment.utc(startDateInTimeZone).endOf("isoWeek");
  let date = startDateOfWeek;

  const result = new Array(7);
  let counter = 0;
  while (endDateOfWeek.isSameOrAfter(date)) {
    const timeSlotsInDay = timeSlots.filter(
      // eslint-disable-next-line no-loop-func
      (timeSlot) =>
        moment.tz(timeSlot.startTime, timeZone).format("LL") ===
        date.format("LL"),
    );
    result[counter] = { date: date.toISOString(), timeSlots: timeSlotsInDay };
    counter += 1;
    date = moment(date).add(1, "days");
  }
  return result;
};

const createWeekWithNoAvailableTimeSlots = (startDateOfWeek) => {
  const endDateOfWeek = moment(startDateOfWeek).utc().endOf("isoWeek");
  const result = [];
  let date = startDateOfWeek;
  while (date <= endDateOfWeek) {
    result.push({ date: date.toISOString(), timeSlots: [] });
    date = moment(date).add(1, "day");
  }
  return result;
};

const updateAvailableSlotsCache = (
  startDate,
  timeZone,
  extraInfoQueryKey,
  data,
  queryClient,
) => {
  if (!Array.isArray(data) || data.length === 0) return;

  const startDateOfWeekWithAvailableSlots = moment(data[0].date);
  if (startDateOfWeekWithAvailableSlots.isAfter(startDate)) {
    let startDateOfWeek = moment(startDate).startOf("isoWeek");

    while (startDateOfWeekWithAvailableSlots.isAfter(startDateOfWeek)) {
      queryClient.setQueryData(
        [
          apiPaths.availableTimeSlots,
          timeZone,
          getWeekStartQueryKey(startDateOfWeek),
          extraInfoQueryKey,
        ],
        createWeekWithNoAvailableTimeSlots(startDateOfWeek),
      );

      startDateOfWeek = moment(startDateOfWeek).add(1, "isoWeek");
    }

    queryClient.setQueryData(
      [
        apiPaths.availableTimeSlots,
        timeZone,
        getWeekStartQueryKey(startDateOfWeekWithAvailableSlots),
        extraInfoQueryKey,
      ],
      data,
    );
  }
};

export const useSchedulerGetAvailableSlots = (
  tags,
  timeZone,
  startDate,
  endDate,
) => {
  const startDateOfWeek = moment(startDate).utc().startOf("isoWeek");
  const queryClient = useQueryClient();
  return useQuery(
    [
      apiPaths.availableTimeSlots,
      timeZone,
      getWeekStartQueryKey(startDate),
      tags,
    ],
    async () => {
      const { data } = await axios.post(apiPaths.availableTimeSlots, {
        tags,
        timeZone,
        startDate: startDateOfWeek.toISOString(),
        endDate,
      });
      if (!endDate && data?.length === 0) {
        // handle no available slots
        return null;
      }
      if (data.length === 0) {
        return createWeekWithNoAvailableTimeSlots(startDateOfWeek);
      }
      return groupByTimeSlotsByDate(data, timeZone);
    },
    {
      onSuccess: (data) => {
        if (data) {
          updateAvailableSlotsCache(
            startDateOfWeek,
            timeZone,
            tags,
            data,
            queryClient,
          );
        } else {
          queryClient.setQueryData(
            [
              apiPaths.availableTimeSlots,
              timeZone,
              getWeekStartQueryKey(startDate),
              tags,
            ],
            data,
          );
        }
      },
      staleTime: consultStaleTime,
      enabled: !!tags,
    },
  );
};

export const useGetAvailableLanguagesQuery = ({
  sessionType,
  hasPresetLanguages,
}) =>
  useQuery(
    [apiPaths.getAvailableLanguages, sessionType],
    async () => {
      const { data } = await axios.get(
        `${apiPaths.getAvailableLanguages}/${sessionType}`,
      );
      return data;
    },
    {
      enabled: !hasPresetLanguages && !!sessionType,
      staleTime: longerStaleTime,
    },
  );

export const useSchedulerGetAvailableSlotsForPsychologist = (
  psychologistId,
  timeZone,
  startDate,
  endDate,
) => {
  const startDateOfWeek = moment(startDate).utc().startOf("isoWeek");
  const queryClient = useQueryClient();
  return useQuery(
    [
      apiPaths.availableTimeSlots,
      timeZone,
      getWeekStartQueryKey(startDate),
      psychologistId,
    ],
    async () => {
      let endDateParameter = "";
      if (endDate !== undefined) {
        endDateParameter = `&endDate=${endDate}`;
      }
      const { data } = await axios.get(
        `${
          apiPaths.availableTimeSlots
        }/${psychologistId}/?timeZone=${timeZone}&startDate=${startDateOfWeek.toISOString()}${endDateParameter}`,
      );
      if (!endDate && data?.length === 0) {
        // handle no available slots
        return null;
      }
      if (data.length === 0) {
        return createWeekWithNoAvailableTimeSlots(startDateOfWeek);
      }
      return groupByTimeSlotsByDate(data, timeZone);
    },
    {
      onSuccess: (data) => {
        if (data) {
          updateAvailableSlotsCache(
            startDateOfWeek,
            timeZone,
            psychologistId,
            data,
            queryClient,
          );
        } else {
          queryClient.setQueryData(
            [
              apiPaths.availableTimeSlots,
              timeZone,
              getWeekStartQueryKey(startDateOfWeek),
              psychologistId,
            ],
            data,
          );
        }
      },
      enabled: !!psychologistId,
      staleTime: shorterStaleTime,
    },
  );
};

export const useConsultQuery = (consultId) =>
  useQuery(
    [apiPaths.getConsult, consultId],
    async () => {
      try {
        const { data } = await axios.get(`${apiPaths.getConsult}/${consultId}`);
        return data;
      } catch (error) {
        if (error.response.status === 404) {
          return undefined;
        }
        return null;
      }
    },
    {
      enabled: !!consultId,
      staleTime: consultStaleTime,
    },
  );

export const useGetLastConfirmedConsultQuery = () => {
  const { isLoggedInOrTwoFactorPending } = useAuthentication();

  return useQuery(
    [apiPaths.lastCompletedConsult],
    async () => {
      try {
        const { data } = await axios.get(apiPaths.lastCompletedConsult);
        return data;
      } catch (error) {
        if (error.response.status === 404) {
          return undefined;
        }
        return null;
      }
    },
    {
      enabled: isLoggedInOrTwoFactorPending,
      staleTime: consultStaleTime,
    },
  );
};

export const useGetLastCompletedConsultWithPsychologistQuery = (employeeId) => {
  const { isLoggedInOrTwoFactorPending } = useAuthentication();

  return useQuery(
    [apiPaths.lastCompletedConsult],
    async () => {
      try {
        const { data } = await axios.get(
          `${apiPaths.lastCompletedConsult}/${employeeId}`,
        );
        return data;
      } catch (error) {
        return null;
      }
    },
    {
      enabled: isLoggedInOrTwoFactorPending && !!employeeId,
    },
  );
};

export const useGetTimezones = () =>
  useQuery([apiPaths.timezones], async () => {
    const { data } = await axios.get(apiPaths.timezones);
    return data;
  });

export const usePsychologistTagsQuery = (employeeId) =>
  useQuery(
    [apiPaths.psychologistTags, employeeId],
    async () => {
      try {
        const { data } = await axios.get(
          `${apiPaths.psychologistTags}/${employeeId}`,
        );
        return data.map((tag) => ({
          tagName: tag.name,
          tagType: tag.tagType.name,
        }));
      } catch (error) {
        return [];
      }
    },
    { enabled: !!employeeId, staleTime: longerStaleTime },
  );

export const useSchedulerBookConsult = ({
  onFailureBookConsult = () => {},
}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ time, timezone, tags, termsAndConditions }) => {
      const { data } = await axios.post(apiPaths.bookConsult, {
        time,
        timezone,
        tags,
        acceptedTermsAndConditions: termsAndConditions,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData([apiPaths.getConsult, data.id], data);
      },
      onError: () => {
        onFailureBookConsult();
      },
      onSettled: (data) => {
        queryClient.invalidateQueries([
          apiPaths.psychologistTags,
          data.employeeId,
        ]);
        queryClient.invalidateQueries([apiPaths.getConsult]);
      },
    },
  );
};

export const useSchedulerBookConsultWithPsychologist = (
  onFailureBookConsult = () => {},
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ time, timezone, tags, psychologistId }) => {
      const { data } = await axios.post(
        `${apiPaths.bookConsult}/${psychologistId}`,
        {
          time,
          timezone,
          tags,
        },
      );
      return data;
    },
    {
      onError: () => {
        onFailureBookConsult();
      },
      onSettled: () => {
        queryClient.invalidateQueries([apiPaths.getConsult]);
      },
    },
  );
};

export const useSchedulerCancelConsult = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (consultId) => {
      const { data } = await axios.delete(
        `${apiPaths.cancelConsult}/${consultId}`,
      );
      return data;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([apiPaths.getConsult]);
      },
    },
  );
};

export const useSchedulerBookNonConfirmedConsult = () =>
  useMutation(async ({ email, time, timezone, tags }) => {
    const { data } = await axios.post(
      `${apiPaths.bookNonConfirmedConsult}?email=${encodeURIComponent(email)}`,
      {
        time,
        timezone,
        tags,
      },
    );
    return data;
  });

export const useSchedulerBookNonConfirmedConsultWithPsychologist = () =>
  useMutation(async ({ email, time, timezone, tags, psychologistId }) => {
    const { data } = await axios.post(
      `${
        apiPaths.bookNonConfirmedConsult
      }/${psychologistId}?email=${encodeURIComponent(email)}`,
      {
        time,
        timezone,
        tags,
      },
    );
    return data;
  });

export const useCheckIfNonConfirmedConsultIsStillActive = (
  emailParsed,
  token,
) =>
  useQuery(
    [apiPaths.nonConfirmedConsult, emailParsed, token],
    async () => {
      try {
        await axios.get(
          `${
            apiPaths.nonConfirmedConsult
          }?token=${token}&email=${encodeURIComponent(emailParsed)}`,
        );
        return true;
      } catch (error) {
        if (error.response.status === 404) {
          return false;
        }
        return null;
      }
    },
    {
      staleTime: minutes(consultStaleTime),
    },
  );

export const useSchedulerReschedulerConsult = () => {
  const queryClient = useQueryClient();

  const rescheduleWithSamePsychologist = useMutation(
    async ({ consultId, time, timezone }) => {
      const { data } = await axios.post(
        apiPaths.rescheduleConsultWithPsychologist,
        {
          consultId,
          time,
          timezone,
        },
      );
      return data;
    },
    {
      onSuccess: (consult) => {
        queryClient.setQueryData([apiPaths.getConsult, consult.id], consult);
        queryClient.invalidateQueries([apiPaths.getConsult]);
      },
    },
  );

  const rescheduleWithNewPsychologist = useMutation(
    async ({ consultId, time, timezone, tags }) => {
      const { data } = await axios.post(apiPaths.rescheduleConsultWithTags, {
        consultId,
        time,
        timezone,
        tags,
      });
      return data;
    },
    {
      onSuccess: (consult) => {
        queryClient.setQueryData([apiPaths.getConsult, consult.id], consult);
        queryClient.invalidateQueries([apiPaths.getConsult]);
      },
    },
  );

  return {
    rescheduleWithSamePsychologist,
    rescheduleWithNewPsychologist,
  };
};
