import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import WizardContainer from "@src/views/onboarding/components/WizardContainer";
import WizardActions from "@src/views/onboarding/components/WizardActions";
import PropTypes from "prop-types";
import { resendCodeEmail } from "@src/actions/userActions";
import Loader from "@src/components/Loader";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useAuthentication } from "@src/services/auth.service";
import { timeSnackbar } from "@src/utils/constants";
import { withSnackbar } from "@src/components/SnackBarComponent";
import sessionStorageService from "@src/services/sessionStorage.service";
import { ReactComponent as MailIcon } from "@src/resources/images/mail.svg";
import { useState } from "react";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  content: {
    padding: "0 0 100px 0",
    textAlign: "center",
    [breakpoints.up("sm")]: {
      padding: spacing(4, 3),
    },
  },
  resendButton: {
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "1.25px",
    width: "100%",
  },
}));

const VerifyEmailPage = ({ resendEmailCode, ...props }) => {
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { user } = useAuthentication();
  const email = user?.email || sessionStorageService.getUserEmail();

  const onSubmit = async (submitEvent) => {
    submitEvent.preventDefault();
    setIsLoading(true);

    if (email) {
      try {
        await resendEmailCode(email);
        setEmailSent(true);
      } catch (e) {
        props.snackbarShowMessage(t("VerifyEmailPage.ResendError"));
      } finally {
        setIsLoading(false);
      }
    } else {
      props.snackbarShowMessage(t("LinkExpiredView.SessionExpired"));
      setIsLoading(false);
      setTimeout(() => {
        navigate(pathT("route.register"));
      }, timeSnackbar);
    }
  };

  return (
    <WizardContainer title={t("VerifyEmailPage.Title")}>
      <form onSubmit={onSubmit}>
        <Box className={classes.content}>
          <div className="flex justify-center">
            <MailIcon />
          </div>
          <Typography color="textSecondary" variant="body1">
            {t("VerifyEmailPage.Text")}
            <p>
              <strong>{email}</strong>
            </p>
          </Typography>
        </Box>
        <WizardActions>
          <Button
            className={classes.resendButton}
            disabled={isLoading || emailSent}
            type="submit"
          >
            {t(
              emailSent
                ? "VerifyEmailPage.ResendEmailSent"
                : "VerifyEmailPage.ResendEmail",
            )}
          </Button>
          {isLoading && <Loader />}
        </WizardActions>
      </form>
    </WizardContainer>
  );
};

VerifyEmailPage.propTypes = {
  resendEmailCode: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  resendEmailCode: (email) => dispatch(resendCodeEmail(email)),
});

const mapStateToProps = (state) => state;
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPage),
);
