import { useState, useContext, useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { noop, keyBy } from "lodash";

import { useMutationUserTheme } from "@src/queries/themes";
import { MAX_FOCUSED_THEMES } from "@src/utils/constants";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import colors from "@src/theme/colors";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { DashboardContext } from "@src/utils/DashboardContext";
import ThemeFocusItem from "./ThemeFocusItem";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 360,
  },
  dialogTitle: {
    "& > h2": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  button: {
    color: theme.colors.primaryBlue,
    background: "transparent",
    lineHeight: "24px",
    fontWeight: 500,
    "&:hover": { color: theme.colors.trueWhite },
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "24px",
  },
  focusedNo: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.primaryBlue,
  },
}));

const FocusModal = ({ open = false, onClose = noop, ...props }) => {
  const { t } = useTranslatedNavigate();
  const classes = useStyles();
  const { refetchThemes, themes } = useContext(DashboardContext);
  const [fThemes, setFThemes] = useState(keyBy(themes, (theme) => theme.key));
  const { mutateAsync: updateTheme } = useMutationUserTheme(refetchThemes);

  useEffect(() => {
    setFThemes(keyBy(themes, (theme) => theme.key));
  }, [themes]);

  const focusedNo = useMemo(
    () => Object.values(fThemes).filter((theme) => theme?.isFocused).length,
    [fThemes],
  );

  const handleSetFocusThemes = async () => {
    const oldThemesMap = themes.reduce(
      (acc, theme) => ({ ...acc, [theme.key]: theme?.isFocused }),
      {},
    );
    const themesToUpdate = Object.keys(fThemes)
      .map((key) => {
        if (!!fThemes?.[key]?.isFocused !== !!oldThemesMap?.[key]) {
          return fThemes[key];
        }
        return null;
      })
      .filter(Boolean);
    const responses = await Promise.all(
      themesToUpdate.map((themeToUpdate) => updateTheme(themeToUpdate)),
    );
    if (responses.find((response) => response.status !== 200)) {
      props.snackbarShowMessage(t("DashboardView.Focused.Update.Error"));
    } else {
      props.snackbarShowMessage(
        t("DashboardView.Focused.Update.Success"),
        "success",
      );
    }
    onClose();
  };

  const handleChangeFThemes = (evt) => {
    const themeKey = evt.target.name;
    const focusedThemesNo = Object.keys(fThemes).filter(
      (key) => fThemes?.[key]?.isFocused,
    ).length;
    if (focusedThemesNo === MAX_FOCUSED_THEMES && evt.target.checked) {
      return;
    }
    setFThemes({
      ...fThemes,
      [themeKey]: { ...fThemes[themeKey], isFocused: evt.target.checked },
    });
  };

  const handleClose = () => {
    setFThemes(keyBy(themes, (theme) => theme.key));
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="themes-focus-dialog"
      open={open}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle
        id="themes-focus-dialog-title"
        onClose={handleClose}
        classes={{ root: classes.dialogTitle }}
      >
        <Typography variant="body2" className={classes.title}>
          {t("ThemeViewInsights.FocusModal.Title")}
        </Typography>
        <Typography className={classes.focusedNo}>
          {t("ThemeViewInsights.FocusModal.FocusedNo", { focusedNo })}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {themes.map(({ key }) => (
          <ThemeFocusItem
            disabled={
              !fThemes?.[key]?.isFocused && focusedNo === MAX_FOCUSED_THEMES
            }
            checked={fThemes?.[key]?.isFocused}
            key={key}
            onChange={handleChangeFThemes}
            id={key}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant="text"
          className={classes.button}
        >
          {t("ThemeViewInsights.FocusModal.Cancel")}
        </Button>
        <Button
          autoFocus
          variant="text"
          color="primary"
          onClick={handleSetFocusThemes}
          className={classes.button}
        >
          {t("ThemeViewInsights.FocusModal.Ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withSnackbar(FocusModal);
