import { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import ConfirmCancellation from "@src/views/booking/cancel/components/ConfirmCancellation";
import CancellationConfirmed from "@src/views/booking/cancel/components/CancellationConfirmed";
import { useSchedulerCancelConsult } from "@src/queries/booking";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useConsultQuery, useHasSessionStarted } from "@src/queries/consults";
import { STATUS } from "@src/utils/constants";
import Button from "@openup/shared/components/Button/Button";

const STEPS = {
  CANCEL: "route.booking.cancel.confirm",
  CONFIRMATION: "",
};

const BookingCancellationFlow = () => {
  const { consultId } = useParams();
  const { t, pathT } = useTranslatedNavigate();

  const { data: consult } = useConsultQuery(consultId, STATUS.SCHEDULED, null);
  const { mutateAsync: cancelConsult } = useSchedulerCancelConsult();

  const { step, nextStep, navigateToUrl, setPreventGoingBack, setupFlowSteps } =
    useOutletContext();
  const { data: isSessionStarted } = useHasSessionStarted({
    consultId,
  });

  useEffect(() => {
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (step === STEPS.CONFIRMATION) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  useEffect(() => {
    if (!consult) {
      navigateToUrl(`${pathT("route.booking")}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [consult]);

  if (isSessionStarted) {
    return (
      <section
        className="
          bg-red-100 border border-red-400 text-red-700 px-4 pt-3 pb-5 rounded-xl
          max-w-screen-sm mx-4 sm:mx-auto text-center mt-4 
        "
        role="alert"
      >
        <p className="my-4">
          {t("Booking.Cancellation.CancellationNotPossible")}
        </p>
        <Button
          onClick={() => {
            navigateToUrl(pathT("route.booking"), { replace: true });
          }}
        >
          {t("Booking.Cancellation.BookNewSession")}
        </Button>
      </section>
    );
  }

  const selectStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.CANCEL:
        return (
          <ConfirmCancellation
            moveToNextStep={async () => {
              await cancelConsult(consultId);
              nextStep();
            }}
            redirectToRebook={() =>
              navigateToUrl(
                `${pathT("route.booking")}/${t(
                  "route.reschedule",
                )}/${consultId}`,
              )
            }
            consult={consult}
          />
        );
      case STEPS.CONFIRMATION:
        return <CancellationConfirmed />;
      default:
        return null;
    }
  };

  return !!consult && selectStep(step);
};

export default BookingCancellationFlow;
