import { useNavigate, useOutletContext } from "react-router-dom";
import Page from "@src/components/Page";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import CenteredLoader from "@src/components/CenteredLoader";
import {
  useSaveQuestionListAnswers,
  useQuestionListByName,
} from "@src/queries/themes";
import { MutableRefObject, useEffect, useState } from "react";
import ResilienceCheckinQuestions from "@src/views/resilience-checkin/ResilienceCheckinQuestionsView";
import ResilienceCheckinCalculatingScore from "@src/views/resilience-checkin/ResilienceCheckinCalculatingScoreView";
import sessionStorageService from "@src/services/sessionStorage.service";
import ResilienceCheckinResultsView from "@src/views/resilience-checkin/ResilienceCheckinResultsView";

// these will not be visible on the browser URL
const STEPS = {
  QUESTIONS: "question",
  CALCULATING_SCORE: "calculating-score",
  RESULTS: "results",
};

interface OutletContext {
  step: string;
  nextStep: () => void;
  setupFlowSteps: (steps: Record<string, string>) => void;
  goToPreviousStep: () => void;
  isFlowSetup: boolean;
  stepsMap: MutableRefObject<Map<number, string>>;
  getStepName: (stepNumber: number) => string;
}

const ResilienceCheckinEntryView: React.FC = () => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const { isFlowSetup, getStepName, step, nextStep, setupFlowSteps } =
    useOutletContext() as OutletContext;
  const { data: wellBeingQuestions, isLoading } =
    useQuestionListByName("resilience-checkin");
  const { saveQuestionList } = useSaveQuestionListAnswers();
  const [results, setResults] = useState<any>(null);

  const onSaveQuestionList = async () => {
    const answers =
      sessionStorageService.getResilineceCheckInAnswers("resilience-checkin");
    const checkInResults = await saveQuestionList(answers);
    setResults(checkInResults);
  };

  useEffect(() => {
    if (!wellBeingQuestions) return;
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wellBeingQuestions]);

  const selectStep = ({ step: newStep }) => {
    if (isLoading) return <CenteredLoader />;
    if (!isFlowSetup) return <CenteredLoader />;
    switch (getStepName(newStep)) {
      case STEPS.QUESTIONS:
        return (
          <ResilienceCheckinQuestions
            questions={wellBeingQuestions?.answers}
            goToNextStep={() => {
              nextStep();
            }}
          />
        );
      case STEPS.CALCULATING_SCORE:
        return (
          <ResilienceCheckinCalculatingScore
            onSaveQuestionList={onSaveQuestionList}
            goToNextStep={nextStep}
          />
        );
      case STEPS.RESULTS:
        if (!results) return <CenteredLoader />;
        return (
          <ResilienceCheckinResultsView
            goToNextStep={() => {
              navigate(pathT("route.account"));
            }}
            results={results}
          />
        );
      default:
        return <CenteredLoader />;
    }
  };

  return <Page title={t("OnBoarding.Title")}>{selectStep({ step })}</Page>;
};
export default ResilienceCheckinEntryView;
