/* eslint-disable react/jsx-props-no-spreading */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles({
  expandButton: {
    display: "flex",
    alignItems: "center",
    textDecoration: "underline",
    background: "none",
    border: "none",
  },
  text: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold !important",
  },
  description: {
    whiteSpace: "pre-line!important",
  },
});

const ExpandableTypography = ({
  text,
  maxLength = 100,
  hasReadMore = false,
  ...props
}) => {
  const { t } = useTranslatedNavigate();
  const [folded, setFolded] = useState(hasReadMore);
  const classes = useStyles();

  const hasMinimumLength = text.length > maxLength;

  useEffect(() => {
    // Reset to folded whenever the translation key changes
    if (hasReadMore) {
      setFolded(true);
    }
  }, [hasReadMore, text]);

  const toggleReadMore = () => setFolded(!folded);

  if (!hasMinimumLength) {
    return <Typography {...props}>{text}</Typography>;
  }

  return (
    <Box mt={2}>
      <Typography {...props} className={classes.description}>
        {folded ? text.slice(0, maxLength).concat("...") : text}
      </Typography>
      {hasReadMore && (
        <button
          type="button"
          className={classes.expandButton}
          onClick={toggleReadMore}
        >
          <Typography variant="h6" className={classes.text}>
            {folded
              ? t("ThemeViewInsights.ReadMore")
              : t("ThemeViewInsights.ReadLess")}
            {folded ? <ExpandMore /> : <ExpandLess />}
          </Typography>
        </button>
      )}
    </Box>
  );
};

export default ExpandableTypography;
