import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { useMemo } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { ReactComponent as ManChair } from "@src/resources/images/man_chair.svg";
import { useEmployers } from "@src/queries/common";
import { useLocation } from "react-router-dom";
import Loader from "@src/components/Loader";
import Autocomplete from "@src/components/Autocomplete";
import BookingCard from "../../components/BookingCard";
import BookingContainer from "../../components/BookingContainer";

const useStyles = makeStyles((theme) => ({
  fillDetails: {
    opacity: 1,
    fontWeight: 500,
    maxWidth: 240,
    letterSpacing: "0.15px",
  },
  image: {
    marginTop: theme.spacing(25.5),
    [theme.breakpoints.down("sm")]: {
      width: 152,
      height: 114,
      marginTop: theme.spacing(0),
    },
  },
  buttonConfirm: {
    width: 264,
  },
  radioButton: {
    padding: theme.spacing(0.5, 1.125, 1.125, 1.125),
  },
  popper: {
    backgroundColor: theme.colors.primarySkin,
    borderRadius: "30px 30px 30px 30px",
  },
}));

const CompleteBookingEmployerInfo = ({
  moveToNextStep,
  employerId,
  isPartner,
}) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();

  const { data: employers, isFetching } = useEmployers();
  const employerSlug = new URLSearchParams(useLocation().search).get(
    t("route.register.employerQueryPostfix"),
  );
  const activeEmployers = employers.filter((e) => e.active);

  const employer =
    employerSlug && activeEmployers.find((e) => e.slug === employerSlug);
  const employersOptions = useMemo(
    () =>
      (activeEmployers || []).map(({ id, name, active }) => ({
        value: id,
        text: name,
        active,
      })),
    [activeEmployers],
  );

  const onSubmit = (values) => {
    employerId.current = values.employerId;
    isPartner.current = values.isPartner;
    moveToNextStep();
  };

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      <BookingContainer
        title={t("Booking.CompleteBooking.Title")}
        message={t("Booking.CompleteBooking.Step", { key: 2 })}
      >
        <Formik
          initialValues={{
            hasEmployer: "true",
            employerId: employer?.id,
            isPartner: false,
          }}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <BookingCard
                image={<ManChair className={classes.image} />}
                actions={
                  <Button
                    disabled={
                      (values.hasEmployer === "true" ||
                        values.hasEmployer === "trueWithPartner") &&
                      !values.employerId
                    }
                    className={classes.buttonConfirm}
                    type="submit"
                  >
                    {t("Booking.CompleteBooking.ConfirmButton")}
                  </Button>
                }
              >
                <Box>
                  <Typography variant="body1" className={classes.fillDetails}>
                    {t("Booking.CompleteBooking.OfferedByEmployer")}
                  </Typography>
                </Box>
                <Box mb={3} mt={3}>
                  <RadioGroup
                    value={values.hasEmployer}
                    onChange={handleChange}
                    name="hasEmployer"
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("RegisterView.OfferedByEmployerYes")}
                    />
                    {values.hasEmployer === "true" && (
                      <Autocomplete
                        id="employerId"
                        options={employersOptions}
                        getOptionDisabled={(option) => !option.active}
                        value={
                          employersOptions.find(
                            (eo) => eo.value === values.employerId,
                          ) || null
                        }
                        onChange={(_, newValue) => {
                          setFieldValue("employerId", newValue?.value || "");
                        }}
                        placeholder={t("RegisterView.WhoIsEmployer")}
                        popperStyle={classes.popper}
                      />
                    )}
                    <FormControlLabel
                      value="trueWithPartner"
                      control={<Radio className={classes.radioButton} />}
                      label={t("RegisterView.OfferedByEmployerYesWithPartner")}
                    />
                    {values.hasEmployer === "trueWithPartner" && (
                      <Autocomplete
                        id="isPartner"
                        options={employersOptions}
                        getOptionDisabled={(option) => !option.active}
                        value={
                          employersOptions.find(
                            (eo) => eo.value === values.employerId,
                          ) || null
                        }
                        onChange={(_, newValue) => {
                          setFieldValue("isPartner", true);
                          setFieldValue("employerId", newValue?.value || "");
                        }}
                        placeholder={t("RegisterView.WhoIsEmployer")}
                        popperStyle={classes.popper}
                      />
                    )}
                    <FormControlLabel
                      value="false"
                      control={<Radio data-cy="no-radio-button" />}
                      label={t("RegisterView.OfferedByEmployerNo")}
                    />
                  </RadioGroup>
                </Box>
              </BookingCard>
            </form>
          )}
        </Formik>
      </BookingContainer>
      )
    </>
  );
};

export default CompleteBookingEmployerInfo;
