import { Box, Typography, Button, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GoogleLoginRegisterButton from "@src/views/auth/GoogleLoginRegisterButton";
import AppleSignInSignUpButton from "@src/components/AppleSignInSignUpButton";
import MicrosoftSignInSignUpButton from "@src/components/MicrosoftSignInSignUpButton";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useNavigate, useOutletContext } from "react-router-dom";
import MailButton from "@src/resources/images/mail-button-icon.svg";
import { SingleSignOnButton } from "@src/components/SingleSignOnButton";
import { useEffect } from "react";
import WizardContainer from "./WizardContainer";
import WizardActions from "./WizardActions";

const useStyles = makeStyles(({ colors, spacing, breakpoints }) => ({
  wizard: {
    backgroundColor: colors.primaryGreenDark,
    [breakpoints.up("sm")]: {
      backgroundColor: "transparent",
    },
  },
  content: {
    minHeight: 413,
    marginTop: spacing(2),
    padding: "31px 20px",
    borderRadius: 16,
    background: `${colors.tertiaryGreen} url('/images/onboarding/registration-page-bg-mobile.jpg') no-repeat 50% 100%`,
    backgroundSize: "552px",
    [breakpoints.up("sm")]: {
      borderRadius: "16px 16px 0 0",
      paddingRight: 90,
      marginTop: 0,
      backgroundImage:
        "url('/images/onboarding/registration-page-bg-desktop.jpg')",
      backgroundSize: "",
    },
  },
  title: {
    fontSize: 32,
    color: colors.trueWhite,
    opacity: 1,
  },
  subtitle: {
    fontSize: 16,
    marginTop: 12,
    color: colors.trueWhite,
  },
  startIcon: {
    display: "flex",
    alignItems: "center",
    overflow: "visible",
  },
  buttonSignup: {
    width: "100%",
    margin: "0 !important",
  },
  buttonGoogleSignup: {
    width: "100%",
    margin: "0 !important",
  },
  buttonAppleSignup: {
    width: "100%",
    margin: "0 !important",
  },
  buttonMicrosoftSignup: {
    width: "100%",
    margin: "0 !important",
  },
}));

const SignupMethodPage = ({ moveToNextStep, ssoSupported }) => {
  const { t, pathT } = useTranslatedNavigate();
  const classes = useStyles();
  const navigate = useNavigate();
  const { navigateToUrl } = useOutletContext();

  const onLoginCallback = () => {
    navigate(pathT("route.register.explicit-consent"), {
      replace: true,
    });
  };

  useEffect(() => {
    if (ssoSupported) {
      navigateToUrl(`${pathT("route.singleSignOn")}`, {
        replace: true,
      });
    }
  });

  return (
    <WizardContainer className={classes.wizard}>
      <Box className={classes.content}>
        <Typography variant="h2" className={classes.title}>
          {t("OnBoarding.Registration.Title")}
        </Typography>
        <Typography variant="subtitle2" className={classes.subtitle}>
          {t("OnBoarding.Registration.Subtitle")}
        </Typography>
      </Box>
      <WizardActions className={classes.actions}>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          startIcon={
            <Icon className={classes.startIcon}>
              <img alt="" src={MailButton} />
            </Icon>
          }
          className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
          onClick={moveToNextStep}
        >
          <p className="font-semibold text-sm tracking-wide leading-6">
            {t("OnBoarding.Registration.SignupEmail")}
          </p>
        </Button>
        <GoogleLoginRegisterButton
          className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
          buttonText={t("OnBoarding.Registration.SignupGoogle")}
          isOnboarding
          onLoginCallback={onLoginCallback}
          isSignUpForm
        />
        <AppleSignInSignUpButton
          className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
          buttonText={t("OnBoarding.Registration.SignupApple")}
          isOnboarding
          onLoginCallback={onLoginCallback}
          isSignUpForm
        />
        <MicrosoftSignInSignUpButton
          className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
          buttonText={t("OnBoarding.Registration.SignupMicrosoft")}
          isOnboarding
          onLoginCallback={onLoginCallback}
          isSignUpForm
        />
        <SingleSignOnButton
          fullWidth
          className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
          text={t("OnBoarding.Registration.SingleSignOn")}
          onClick={() =>
            navigateToUrl(`${pathT("route.singleSignOn")}?IsSignUp=true`, {
              replace: true,
            })
          }
        />
      </WizardActions>
    </WizardContainer>
  );
};

export default SignupMethodPage;
