import { useEffect, useRef, useState } from "react";
import {
  Grid,
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment-timezone";
import { TAG_TYPES } from "@src/utils/constants";
import {
  getClientTimezone,
  convertDateToDateObject,
} from "@src/utils/timezone";
import {
  useGetPsychologistByIdQuery,
  useSchedulerGetAvailableSlots,
  useSchedulerGetAvailableSlotsForPsychologist,
} from "@src/queries/booking";
import {
  formatWeek,
  formatWeekWithYear,
  getYearTime,
  moveBackwardWeeks,
  moveForwardWeeks,
  formatDateWithoutMonth,
} from "@src/utils/formatting";
import { format } from "date-fns";
import { upperFirst } from "lodash";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { withSuspense } from "@src/components/wrappers/Suspendable";
import NoAvailableTimeSlots from "./NoAvailableTimeSlots";
import DayTab from "./DayTab";
import BookingContainer from "./BookingContainer";
import TimeZoneSelector from "./TimeZoneSelector";

const useStyles = makeStyles((theme) => ({
  cardActions: {
    justifyContent: "center",
    borderTop: "solid 2px rgba(21, 35, 97, .05);",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      right: 0,
      backgroundColor: "#ffffff",
      padding: theme.spacing(1),
      boxShadow: theme.customShadows.cardActionBackgroundShadow,
      display: "block",
    },
  },
  continueButton: {
    marginLeft: "auto",
    marginRight: "auto",
    margin: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "1.25px",
    width: 360,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: theme.colors.primaryDarkBlue,
      color: "white",
    },
  },
  cardContent: {
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3, 2),
    },
  },
  topTabs: {
    borderBottom: 1,
    borderColor: "divider",
    backgroundColor: theme.colors.primaryGreen,
    margin: theme.spacing(-3),
    marginTop: theme.spacing(-4),
  },
  dateBox: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
  },
  arrow: {
    color: "white",
    fontSize: 16,
    cursor: "pointer",
  },
  arrowDisabled: {
    color: "white",
    fontSize: 16,
    opacity: 0.5,
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  date: {
    lineHeight: "32px",
    letterSpacing: "0.5",
    color: "white",
    opacity: 1,
  },
  rootTabPanel: {
    [theme.breakpoints.down("sm")]: {
      "&>div": {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(3),
      },
    },
  },
  button: {
    width: 126,
    borderRadius: "8px",
    backgroundColor: "white",
    color: theme.colors.secondaryBlue,
    border: "1px solid",
    [theme.breakpoints.down("sm")]: {
      width: 94,
    },
    "&:hover": {
      backgroundColor: theme.colors.primaryDarkBlue,
      color: "white",
    },
  },
  buttonSelect: {
    border: "1px solid",
    width: 126,
    borderRadius: "8px",
    backgroundColor: theme.colors.primaryDarkBlue,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      width: 94,
    },
    "&:hover": {
      backgroundColor: theme.colors.primaryDarkBlue,
      color: "white",
    },
  },
  card: {
    boxShadow: theme.customShadows.bookingContainerBackgroundShadow,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(16),
    },
  },
  form: {
    display: "grid",
  },
}));

const TimeSlotPicker = ({
  moveToNextStep,
  selectedDateTime,
  setSelectedDateTime,
  sessionType,
  language,
  psychologistId,
  timezone,
  ...props
}) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const [next, setNext] = useState(0);

  const initialDate = useRef(moment.utc().startOf("day"));

  const [timeZoneValue, setTimeZoneValue] = useState(
    timezone ?? getClientTimezone(),
  );
  const [startFetchDate, setStartFetchDate] = useState(initialDate.current);
  const [endFetchDate, setEndFetchDate] = useState();

  const [currentDateTime, setCurrentDateTime] = useState(initialDate.current);
  const [weekDaysAvailabilityStatus, setWeekDaysAvailabilityStatus] = useState(
    [],
  );
  const [firstTimePageLanding, setFirstTimePageLanding] = useState(true);
  const [arrowBackDisabled, setArrowBackDisabled] = useState(false);
  const [arrowForwardDisabled, setArrowForwardDisabled] = useState(false);
  const [startTimes, setStartTimes] = useState([]);

  const tags = psychologistId
    ? null
    : [
        {
          tagName: sessionType,
          tagType: TAG_TYPES.SESSION_TYPE,
        },
        {
          tagName: language,
          tagType: TAG_TYPES.PSYCHOLOGIST_LANGUAGE,
        },
      ];

  const { data: availableSlotsForAllPsychologists } =
    useSchedulerGetAvailableSlots(
      tags,
      timeZoneValue,
      startFetchDate,
      endFetchDate,
    );
  const { data: availableSlotsForPsychologist } =
    useSchedulerGetAvailableSlotsForPsychologist(
      psychologistId,
      timeZoneValue,
      startFetchDate,
      endFetchDate,
    );
  const availableSlotsInWeek =
    availableSlotsForPsychologist || availableSlotsForAllPsychologists;

  const { data: psychologistDetails } =
    useGetPsychologistByIdQuery(psychologistId);

  const psychologistName = () => {
    if (psychologistId)
      return t("Booking.TimeSlot.PsychologistName", {
        firstName: psychologistDetails.firstName,
        lastName: psychologistDetails.lastName,
      });

    return undefined;
  };

  const sortDays = (days) => {
    days.sort(
      (dayA, dayB) => moment(dayA.date).unix() - moment(dayB.date).unix(),
    );
  };

  const resetSelectedDateTime = () => {
    setSelectedDateTime(undefined);
  };

  const goNextWeek = (date) => {
    resetSelectedDateTime();
    const nextWeek = moment(date).utc().add(1, "weeks");
    setStartFetchDate(nextWeek.toISOString());
    setEndFetchDate(nextWeek.endOf("isoWeek").toISOString());
    setNext(next + 1);
  };

  const getCurrentDateWithTimeSlots = () => {
    let currentDateWithTimeSlots = { date: currentDateTime, timeSlots: [] };
    if (firstTimePageLanding) {
      const daysWithAvailableTimeSlots = availableSlotsInWeek.filter(
        (weekDay) => weekDay.timeSlots.length > 0,
      );
      if (daysWithAvailableTimeSlots.length > 0) {
        currentDateWithTimeSlots = daysWithAvailableTimeSlots[0];
        setFirstTimePageLanding(false);
      } else {
        goNextWeek(startFetchDate);
        return undefined;
      }
    } else {
      currentDateWithTimeSlots = availableSlotsInWeek.find((weekDay) =>
        moment(weekDay.date).utc().isSame(startFetchDate),
      );
    }
    return currentDateWithTimeSlots;
  };

  const setAvailableDate = (date) => {
    setCurrentDateTime(moment(date).utc());

    if (moment(date).isoWeek() > moment(startFetchDate).isoWeek()) {
      setNext(moment(date).isoWeek() - moment(startFetchDate).isoWeek());
    }
  };

  const setTimeSlots = (timeSlots) => {
    const slots = timeSlots.map((availableSlot) => ({
      startTime: availableSlot.startTime,
      timezone: timeZoneValue,
    }));
    slots.sort(
      (a, b) => moment(a.startTime).unix() - moment(b.startTime).unix(),
    );

    setStartTimes(slots);
  };

  const initializeWithAllUnavailableSlots = () => {
    setAvailableDate(startFetchDate);
    setStartTimes([]);
    setWeekDaysAvailabilityStatus([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
    setArrowBackDisabled(true);
    setArrowForwardDisabled(true);
    props.snackbarShowMessage(t("Booking.TimeSlot.NoAvailableSlots"));
  };

  useEffect(() => {
    if (availableSlotsInWeek) {
      sortDays(availableSlotsInWeek);

      const currentDateWithTimeSlots = getCurrentDateWithTimeSlots();
      if (!currentDateWithTimeSlots) return;
      setAvailableDate(currentDateWithTimeSlots.date);
      setTimeSlots(currentDateWithTimeSlots.timeSlots);

      setWeekDaysAvailabilityStatus(
        availableSlotsInWeek.map((item) => item.timeSlots.length > 0),
      );
      setArrowBackDisabled(
        moment(availableSlotsInWeek[0].date)
          .utc()
          .isSameOrBefore(moment.utc(), "day"),
      );
    } else {
      initializeWithAllUnavailableSlots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [availableSlotsInWeek]);

  useEffect(() => {
    if (!firstTimePageLanding && availableSlotsInWeek) {
      resetSelectedDateTime();
      const currentDayOfWeek = availableSlotsInWeek.find((weekDay) =>
        moment(weekDay.date).utc().isSame(currentDateTime),
      );
      setAvailableDate(currentDayOfWeek.date);
      setTimeSlots(currentDayOfWeek.timeSlots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [timeZoneValue]);
  const updateCurrentDateTime = (updatedDateTime) => {
    if (!moment(currentDateTime).utc().isSame(moment(updatedDateTime).utc())) {
      resetSelectedDateTime();
    }
    setCurrentDateTime(updatedDateTime);
    if (availableSlotsInWeek) {
      const dayWithTimeSlots = availableSlotsInWeek.find((weekDay) =>
        moment(weekDay.date).utc().isSame(moment(updatedDateTime).utc()),
      );
      if (dayWithTimeSlots) {
        setTimeSlots(dayWithTimeSlots.timeSlots);
        return;
      }
    }
    setTimeSlots([]);
  };

  const goPreviousWeek = (date) => {
    resetSelectedDateTime();
    let previousWeek = moment(date).utc().add(-1, "weeks");
    while (moment().utc().isAfter(previousWeek, "day")) {
      previousWeek = previousWeek.add(1, "days");
    }
    setStartFetchDate(previousWeek.toISOString());
    setEndFetchDate(previousWeek.endOf("isoWeek").toISOString());
    setNext(next - 1);
  };

  const handleSubmit = () => {
    if (!selectedDateTime) return;
    moveToNextStep();
  };

  let weekStart = moveForwardWeeks(next);
  const weekEnd = formatWeekWithYear(moveBackwardWeeks(next));
  const yearWeekStart = getYearTime(weekStart);
  const yearWeekEnd = getYearTime(weekEnd);
  if (yearWeekStart !== yearWeekEnd) {
    weekStart = formatWeekWithYear(weekStart);
  } else {
    weekStart = formatWeek(weekStart);
  }
  const week = moment().add(next, "weeks").format("WW");

  const currentDayWithoutMonth = formatDateWithoutMonth(currentDateTime);

  // eslint-disable-next-line react/no-unstable-nested-components
  const TabPanel = ({ children, value, index, ...other }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
  return (
    <div data-cy="timeslotPicker">
      <BookingContainer
        title={t("Booking.TimeSlot.Title")}
        isMediumContainerWanted
      >
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className={classes.topTabs}
              >
                <Box className={classes.dateBox}>
                  <IconButton
                    className={classes.iconButton}
                    disabled={arrowBackDisabled}
                    onClick={() => goPreviousWeek(currentDateTime)}
                    data-cy="previous-week"
                    size="large"
                  >
                    <ArrowBackIcon
                      className={
                        arrowBackDisabled
                          ? classes.arrowDisabled
                          : classes.arrow
                      }
                    />
                  </IconButton>
                  <Typography variant="body1" className={classes.date}>
                    {t("Booking.TimeSlot.CurrentDateWeek", {
                      weekday: `${upperFirst(
                        format(convertDateToDateObject(currentDateTime), "EEE"),
                      )}`,
                      today: `${currentDayWithoutMonth} ${upperFirst(
                        format(convertDateToDateObject(currentDateTime), "MMM"),
                      )}`,
                      weekNumber: week,
                    })}
                  </Typography>
                  <IconButton
                    className={classes.iconButton}
                    disabled={arrowForwardDisabled}
                    onClick={() => goNextWeek(currentDateTime)}
                    data-cy="next-week"
                    size="large"
                  >
                    <ArrowForwardIcon
                      className={
                        arrowForwardDisabled
                          ? classes.arrowDisabled
                          : classes.arrow
                      }
                      data-cy="arrow-forward"
                    />
                  </IconButton>
                </Box>

                <DayTab
                  next={next}
                  currentDateTime={currentDateTime}
                  setCurrentDateTime={updateCurrentDateTime}
                  weekDaysAvailabilityStatus={weekDaysAvailabilityStatus}
                />
              </Box>
              <TabPanel value={0} index={0} className={classes.rootTabPanel}>
                <TimeZoneSelector
                  sessionType={sessionType}
                  language={language}
                  timeZoneValue={timeZoneValue}
                  setTimeZoneValue={setTimeZoneValue}
                  psychologistName={psychologistName()}
                />
                {startTimes.length === 0 ? (
                  <NoAvailableTimeSlots />
                ) : (
                  <Grid container spacing={2}>
                    {startTimes.map((timeSlot) => (
                      <Grid item xs={4} md={2} key={timeSlot.startTime}>
                        <Button
                          className={
                            timeSlot.startTime === selectedDateTime?.startTime
                              ? classes.buttonSelect
                              : classes.button
                          }
                          onClick={() => {
                            setSelectedDateTime(timeSlot);
                          }}
                          disabled={
                            moment(timeSlot.startTime).tz(timeSlot.timezone) <=
                            moment.utc().tz(timeSlot.timezone)
                          }
                          data-cy="timeslot"
                          data-cy-selected={
                            timeSlot.startTime === selectedDateTime?.startTime
                          }
                        >
                          {moment(timeSlot.startTime)
                            .tz(timeSlot.timezone)
                            .format("HH:mm")}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </TabPanel>
            </Box>
          </CardContent>

          {((isSmallScreen && selectedDateTime) || !isSmallScreen) && (
            <CardActions className={classes.cardActions}>
              <Button
                type="submit"
                disabled={!selectedDateTime || props.isSubmitting}
                className={classes.continueButton}
                onClick={() => handleSubmit()}
              >
                {t("Booking.TimeSlot.BookingSession")}
              </Button>
            </CardActions>
          )}
        </Card>
      </BookingContainer>
    </div>
  );
};

export default withSuspense(withSnackbar(TimeSlotPicker));
