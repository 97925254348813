/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";

import { ReactComponent as IT } from "@src/resources/flags/it-IT.svg";
import { ReactComponent as CH } from "@src/resources/flags/zh-CN.svg";
import { ReactComponent as NL } from "@src/resources/flags/nl-NL.svg";
import { ReactComponent as ES } from "@src/resources/flags/es-ES.svg";
import { ReactComponent as FR } from "@src/resources/flags/fr-FR.svg";
import { ReactComponent as FL } from "@src/resources/flags/nl-BE.svg";
import { ReactComponent as DE } from "@src/resources/flags/de-DE.svg";
import { ReactComponent as EN } from "@src/resources/flags/en-GB.svg";
import { ReactComponent as BA } from "@src/resources/flags/bs-BA.svg";
import { ReactComponent as AR } from "@src/resources/flags/ar-AR.svg";
import { ReactComponent as HR } from "@src/resources/flags/hr-HR.svg";
import { ReactComponent as PT } from "@src/resources/flags/pt-PT.svg";
import { ReactComponent as RU } from "@src/resources/flags/ru-RU.svg";
import { ReactComponent as SR } from "@src/resources/flags/sr-RS.svg";
import { ReactComponent as TR } from "@src/resources/flags/tr-TR.svg";
import { ReactComponent as UK } from "@src/resources/flags/uk-UA.svg";
import { ReactComponent as PL } from "@src/resources/flags/pl-PL.svg";
import { ReactComponent as MA } from "@src/resources/flags/ar-MA.svg";
import { ReactComponent as BG } from "@src/resources/flags/bg-BG.svg";
import { ReactComponent as DA } from "@src/resources/flags/da-DK.svg";
import { ReactComponent as SE } from "@src/resources/flags/sv-SE.svg";
import { ReactComponent as FI } from "@src/resources/flags/fi-FI.svg";
import { ReactComponent as SO } from "@src/resources/flags/so-SO.svg";
import { ReactComponent as NO } from "@src/resources/flags/nb-NO.svg";
import { ReactComponent as TN } from "@src/resources/flags/ar-TN.svg";
import { ReactComponent as CS } from "@src/resources/flags/cs-CZ.svg";
import { ReactComponent as SL } from "@src/resources/flags/sl-SL.svg";
import { ReactComponent as EN_US } from "@src/resources/flags/en-US.svg";
import { ReactComponent as AF } from "@src/resources/flags/af-ZA.svg";
import { ReactComponent as DE_CH } from "@src/resources/flags/de-CH.svg";
import { ReactComponent as JA } from "@src/resources/flags/ja-JP.svg";
import { ReactComponent as ID } from "@src/resources/flags/id-ID.svg";
import { ReactComponent as MFE } from "@src/resources/flags/mfe-MU.svg";

import { LANGUAGE_TYPES } from "@src/utils/constants";

export const IconSessionLanguage = ({ sessionLanguage, className = "" }) => {
  switch (sessionLanguage) {
    case LANGUAGE_TYPES.IT:
      return <IT className={className} />;
    case LANGUAGE_TYPES.ZH:
    case LANGUAGE_TYPES.Zk:
      return <CH className={className} />;
    case LANGUAGE_TYPES.NL:
      return <NL className={className} />;
    case LANGUAGE_TYPES.ES:
      return <ES className={className} />;
    case LANGUAGE_TYPES.FR:
      return <FR className={className} />;
    case LANGUAGE_TYPES.DE:
      return <DE className={className} />;
    case LANGUAGE_TYPES.DE_CH:
      return <DE_CH className={className} />;
    case LANGUAGE_TYPES.EN:
      return <EN className={className} />;
    case LANGUAGE_TYPES.EN_US:
      return <EN_US className={className} />;
    case LANGUAGE_TYPES.BS:
      return <BA className={className} />;
    case LANGUAGE_TYPES.AR:
      return <AR className={className} />;
    case LANGUAGE_TYPES.HR:
      return <HR className={className} />;
    case LANGUAGE_TYPES.PT:
      return <PT className={className} />;
    case LANGUAGE_TYPES.RU:
      return <RU className={className} />;
    case LANGUAGE_TYPES.SR:
      return <SR className={className} />;
    case LANGUAGE_TYPES.TR:
      return <TR className={className} />;
    case LANGUAGE_TYPES.UK:
      return <UK className={className} />;
    case LANGUAGE_TYPES.FL:
      return <FL className={className} />;
    case LANGUAGE_TYPES.PL:
      return <PL className={className} />;
    case LANGUAGE_TYPES.AR_MA:
      return <MA className={className} />;
    case LANGUAGE_TYPES.BG:
      return <BG className={className} />;
    case LANGUAGE_TYPES.DA:
      return <DA className={className} />;
    case LANGUAGE_TYPES.s:
      return <SE className={className} />;
    case LANGUAGE_TYPES.FI:
      return <FI className={className} />;
    case LANGUAGE_TYPES.SO:
      return <SO className={className} />;
    case LANGUAGE_TYPES.NO:
      return <NO className={className} />;
    case LANGUAGE_TYPES.AR_TN:
      return <TN className={className} />;
    case LANGUAGE_TYPES.CS:
      return <CS className={className} />;
    case LANGUAGE_TYPES.SL:
      return <SL className={className} />;
    case LANGUAGE_TYPES.AF:
      return <AF className={className} />;
    case LANGUAGE_TYPES.JA:
      return <JA className={className} />;
    case LANGUAGE_TYPES.ID:
      return <ID className={className} />;
    case LANGUAGE_TYPES.MFE:
      return <MFE className={className} />;
    default:
      return null;
  }
};
IconSessionLanguage.propTypes = {
  sessionLanguage: PropTypes.string,
};
