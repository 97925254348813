import ModifiedDialogTitle from "@src/components/ModifiedDialogTitle";
import { Dialog, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(0),
    maxHeight: 590,
    overflowX: "hidden",
    minWidth: 552,
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
    },
  },
}));

const FloatingDialog = ({ isOpen, onClose, title, children }) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();

  const handleClose = (reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={(event, reason) => handleClose(reason)}>
      <ModifiedDialogTitle onClose={handleClose}>
        {t(title)}
      </ModifiedDialogTitle>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default FloatingDialog;
