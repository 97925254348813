import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { apiPaths } from "@src/utils/constants";
import { useAuthentication } from "@src/services/auth.service";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { QuestionList } from "@src/models/QuestionList";
import { useContext } from "react";
import sessionStorageService from "@src/services/sessionStorage.service";
import { Theme } from "@src/models/Theme";

import { ApplicationStateContext } from "@src/utils/ApplicationStateContext";
import {
  QuestionListScoresRequest,
  QuestionListScoreResponse,
} from "@src/models/QuestionListScoreResponse";
import { ResponseWithPagination } from "@src/models/Pagination";
import client from "./sanityClient";
import { hours } from "./staletimes";

export const useQueryThemesList = () =>
  useQuery([apiPaths.getThemes], async ({ queryKey: [url] }) => {
    const { data } = await axios.get(url);
    return data;
  });

export const useQuerySanityTheme = (themeKey) => {
  const {
    i18n: { language },
  } = useTranslation();
  const formattedLanguageKey = language.replace("GB", "US").replace("-", "_");
  return useQuery(
    ["sanity", "themes", themeKey, formattedLanguageKey],
    async () => {
      const res = await client.fetch(
        `
        *[_type == "theme" && $key == key]{
  key,
  theme_detail_translated_content {
    ${formattedLanguageKey} {
      ...,
      external_links_education {
        ...,
        entries[]->
      }
    },
  },

  education_external_links {
    entries[]->
  },

  theme_exercises[]-> {
    "exercises": exercises_translated_content {
      "exercise": ${formattedLanguageKey}
    },
    "imageUrl": image.asset->url
  }
}
      `,
        { key: themeKey },
      );
      // eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
      const { theme_detail_translated_content, ...theme } = res[0] || {};
      return {
        ...theme,
        translated_content:
          // eslint-disable-next-line camelcase
          theme_detail_translated_content[formattedLanguageKey],
      };
    },
    {
      staleTime: hours(1),
    },
  );
};

export const useQueryThemeCheckins = (themeKey) => {
  const { isLoggedIn } = useAuthentication();
  return useQuery(
    [apiPaths.getUserThemes, themeKey],
    async () => {
      const { data } = await axios.get(`${apiPaths.getUserThemes}/${themeKey}`);
      return data;
    },
    {
      enabled: isLoggedIn && !!themeKey,
    },
  );
};

export const useQueryMedicalThemesCheckins = () => {
  const key = apiPaths.getUserMedicalThemes;
  const { isLoggedIn } = useAuthentication();
  return useQuery(
    [key],
    async ({ queryKey: [url] }) => {
      const { data } = await axios.get(url);
      return data;
    },
    {
      enabled: isLoggedIn,
    },
  );
};

export const useQueryThemesCheckins = () => {
  const key = apiPaths.getUserThemes;
  const { isLoggedIn } = useAuthentication();
  return useQuery(
    [key],
    async ({ queryKey: [url] }) => {
      const { data } = await axios.get(url);
      return data;
    },
    {
      enabled: isLoggedIn,
    },
  );
};

const useTripettoQuery = (url) => {
  const {
    i18n: { language },
  } = useTranslatedNavigate();

  const { refetch: refetchThemeCheckins, data: oldThemeData } =
    useQueryThemesCheckins();
  const { isLoggedIn } = useAuthentication();
  const { setThemesHaveBeenSuggested } = useContext(ApplicationStateContext);

  const query = useQuery(
    [`${url}&language=${language}`],
    async ({ queryKey: [newUrl] }) => {
      const { data } = await axios.get(newUrl);
      const translationObject = JSON.parse(data.translations);
      return {
        definition: JSON.parse(data.definition),
        styles: JSON.parse(data.styles),
        translations: translationObject.translations,
        locale: JSON.parse(data.locale),
        modules: data.modules,
      };
    },
    {
      enabled: Boolean(language),
      refetchOnWindowFocus: false,
    },
  );

  const { data } = query;
  const queryClient = useQueryClient();

  const modules = data?.modules;

  const { mutateAsync: submit } = useMutation(
    async (answers) => {
      if (isLoggedIn) {
        await axios.post(apiPaths.setTripettoFormAnswers, {
          formModules: modules,
          formLanguage: language,
          answers,
        });
      } else {
        sessionStorageService.saveCheckinLocal(modules, language, answers);
      }
    },

    {
      onSuccess: async () => {
        queryClient.invalidateQueries([apiPaths.getUserThemes]);
        if (isLoggedIn) {
          const { data: newThemeData } = await refetchThemeCheckins();

          const oldFocusedThemes = oldThemeData
            .filter((theme) => theme.isFocused)
            .map((theme) => theme.key);
          const newFocusedThemes = newThemeData
            .filter((theme) => theme.isFocused)
            .map((theme) => theme.key);

          const themesChanged =
            oldFocusedThemes.sort().join(",") !==
            newFocusedThemes.sort().join(",");

          setThemesHaveBeenSuggested(themesChanged);
          sessionStorageService.removeCheckinLocal();
        } else {
          setThemesHaveBeenSuggested(true);
        }
      },
    },
  );

  return {
    ...query,
    submit,
  };
};

export const useCheckinFormByFormName = (formName) =>
  useTripettoQuery(
    `${apiPaths.getTripettoFormDefinition}?formName=${formName}`,
  );

export const useGetQuestionListScores = ({
  questionListName,
  limit = 15,
  page = 0,
}: QuestionListScoresRequest): UseQueryResult<
  ResponseWithPagination<QuestionListScoreResponse[]>
> =>
  useQuery<ResponseWithPagination<QuestionListScoreResponse[]>>(
    [apiPaths.getQuestionListScores, questionListName],
    async () => {
      const { data } = await axios.get<
        ResponseWithPagination<QuestionListScoreResponse[]>
      >(
        `${apiPaths.getQuestionListScores}?questionListKey=${questionListName}&limit=${limit}&page=${page}`,
      );

      return data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: hours(1),
    },
  );

export const useQueryQuestionListAnswers = (questionListName) => {
  const { isLoggedIn } = useAuthentication();
  const queryClient = useQueryClient();

  const query = useQuery(
    [apiPaths.getQuestionListAnswers, questionListName],
    async () => {
      const { data } = await axios.get(
        `${apiPaths.getQuestionListAnswers}/${questionListName}`,
      );
      return data;
    },
    {
      enabled: isLoggedIn,
    },
  );

  const { mutateAsync: saveQuestionList } = useMutation(
    async (answers) => {
      await axios.post(apiPaths.setQuestionListAnswers, answers);
    },
    {
      onSuccess: async () => {
        sessionStorageService.clearGeneralWellbeingFromStorage();
        queryClient.invalidateQueries([
          apiPaths.getQuestionListAnswers,
          questionListName,
        ]);
      },
    },
  );

  return {
    questionListAnswers: query.data,
    saveQuestionList,
  };
};

export const useSaveQuestionListAnswers = () => {
  const { mutateAsync: saveQuestionList } = useMutation<
    void,
    Error,
    QuestionList
  >(async (questionList: QuestionList) => {
    try {
      const { data } = await axios.post(
        apiPaths.setQuestionListAnswers,
        questionList,
      );
      return data;
    } catch (error) {
      throw new Error("Failed to save question list");
    }
  });
  return {
    saveQuestionList,
  };
};

export const useQuestionListByName = (
  questionListName: string,
): UseQueryResult<QuestionList> => {
  const {
    i18n: { language },
  } = useTranslatedNavigate();

  return useQuery<QuestionList>(
    [apiPaths.getQuestionListDefinition, questionListName, language],
    async (): Promise<QuestionList> => {
      const { data } = await axios.get(
        `${apiPaths.getQuestionListDefinition}?formName=${questionListName}&language=${language}`,
      );
      return data;
    },
    {
      enabled: Boolean(language),
      refetchOnWindowFocus: true,
    },
  );
};

export const useCheckinFormByThemeKeys = (themeKeys) => {
  const url = Array.isArray(themeKeys)
    ? `${apiPaths.getTripettoThemeFormDefinition}?${themeKeys
        .map((key) => `themeKeys=${key}`)
        .join("&")}`
    : apiPaths.getTripettoThemeFormDefinition;

  return useTripettoQuery(url);
};

export const useCheckinFormFocusThemes = () => {
  const { data } = useQueryThemesCheckins();
  return useCheckinFormByThemeKeys(
    data.filter(({ isFocused }) => isFocused).map(({ key }) => key),
  );
};

export const useMutationUserTheme = (callback) =>
  useMutation(
    async (theme: Theme) => {
      try {
        const result = await axios.put(
          `${apiPaths.updateUserTheme}/${theme.key}`,
          theme,
        );
        return result;
      } catch (error) {
        return null;
      }
    },
    {
      onSettled: () => {
        callback?.();
      },
    },
  );

export const useQueryCheckinsResults = () => {
  const key = apiPaths.getMedicalCheckInResult;
  const { isLoggedIn } = useAuthentication();
  return useQuery(
    [key],
    async () => {
      const { data } = await axios.get(apiPaths.getMedicalCheckInResult);
      return data;
    },
    {
      enabled: isLoggedIn,
    },
  );
};
