import { DialogTitle, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    borderBottom: "1px solid rgba(21, 35, 97, 0.1)",
    padding: theme.spacing(3),
  },
  iconButton: {
    color: theme.colors.primaryDarkBlue,
    height: 14,
    width: 14,
    position: "absolute",
    right: 29,
    top: 30,
  },
}));

const ModifiedDialogTitle = (props) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className={classes.dialogTitle}>
      {children}
      {onClose ? (
        <IconButton
          onClick={onClose}
          className={classes.iconButton}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default ModifiedDialogTitle;
