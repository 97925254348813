import React from "react";

interface HeaderTextProps {
  text: string;
  className?: string;
  classNameText?: string;
}
const HeaderText: React.FC<HeaderTextProps> = ({
  text = "",
  className = "",
  classNameText = "",
}) => {
  return (
    <div className={className}>
      <p
        className={`w-full text-indigo-800 font-serif font-bold text-3xl leading-[36px] ${classNameText}`}
      >
        {text}
      </p>
    </div>
  );
};
export default HeaderText;
