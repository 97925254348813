import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "@src/components/Page";
import PropTypes from "prop-types";
import authService from "@src/services/auth.service";
import Loader from "@src/components/Loader";
import { logout, resetPasswordEmail } from "@src/actions/userActions";
import { getValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { timeSnackbar } from "@src/utils/constants";
import { withSnackbar } from "@src/components/SnackBarComponent";

const useStyles = makeStyles(() => ({
  pageMargin: {
    "@media (max-width: 599px)": {},
    "@media (min-width: 600px)": {
      width: "360px !important",
      margin: "auto",
    },
  },
  bottomMessage: {
    marginTop: "16px",
    "@media (min-width: 600px)": {
      marginBottom: "80px",
    },
  },
}));

const ForgotPasswordView = ({
  resetUserPasswordEmail,
  logoutUser,
  ...props
}) => {
  const { t, pathT } = useTranslatedNavigate();
  const classes = useStyles();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    setStatus();
    authService.logout();
    try {
      await resetUserPasswordEmail(fields.email);
      props.snackbarShowMessage("Success", "success");
      setTimeout(() => {
        navigate(pathT("route.sentPasswordReset"));
      }, timeSnackbar);
    } catch (error) {
      props.snackbarShowMessage(error.request.responseText || error.message);
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 3000);
  };
  const handleLoginClick = () => {
    logoutUser();
    navigate(pathT("route.login"));
  };
  return (
    <Page title={t("ForgotPasswordView.Title")} style={{ textAlign: "left" }}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" className={classes.pageMargin}>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={getValidationSchema(t, { email: true })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} className={classes.pageMargin}>
                <Box>
                  <Typography variant={isSmallScreen ? "h3" : "h1"}>
                    {t("LoginView.ForgotPassword")}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="top" ml={0} mt={6}>
                  <Typography
                    color="textSecondary"
                    style={{ fontSize: "16px", marginTop: "-24px" }}
                  >
                    {t("ForgotPasswordView.Message")}
                  </Typography>
                </Box>
                <Box mt={4}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={t("ForgotPasswordView.Email")}
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="standard"
                  />
                </Box>
                {isSubmitting && <Loader />}
                <Box>
                  <Button
                    disabled={isSubmitting}
                    fullWidth
                    style={{ marginBlock: "8px" }}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    <Typography
                      variant="body2"
                      style={{ color: "#ffffff", opacity: "100" }}
                    >
                      {t("ForgotPasswordView.ResetPassword")}
                    </Typography>
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    fullWidth
                    style={{ marginBlock: "8px" }}
                    size="large"
                    onClick={handleLoginClick}
                  >
                    <Typography variant="body2">
                      <b>{t("ForgotPasswordView.Cancel")}</b>
                    </Typography>
                  </Button>
                </Box>
                <Box alignItems="center" display="flex" justifyContent="center">
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    className={classes.bottomMessage}
                  >
                    {t("ForgotPasswordView.NeedHelp")}{" "}
                    <b>
                      <Link
                        target="_blank"
                        underline="always"
                        href="https://www.openup.care/contactopnemen"
                      >
                        {t("ForgotPasswordView.CustomerSupport")}
                      </Link>
                    </b>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

ForgotPasswordView.propTypes = {
  resetUserPasswordEmail: PropTypes.func.isRequired,
  logoutUser: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  resetUserPasswordEmail: (email) => dispatch(resetPasswordEmail(email)),
  logoutUser: () => {
    dispatch(logout());
  },
});
const mapStateToProps = (state) => state;
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordView),
);
