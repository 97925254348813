import { useEffect, useRef, useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import {
  useCheckIfNonConfirmedConsultIsStillActive,
  useConfirmConsultQuery,
} from "@src/queries/booking";
import { useGuestUserProfile } from "@src/queries/user";
import AuthService from "@src/services/auth.service";
import { IsGuestTwoFactorAuthenticationEnabled } from "@src/queries/twoFactorAuthentication";
import SingleSignOnEmailView from "@src/views/auth/SingleSignOnView/SingleSignOnEmailView";
import PhoneNumberComponentContainer from "@src/views/booking/components/PhoneNumberComponentContainer";
import CenteredLoader from "@src/components/CenteredLoader";
import ExplicitConsent from "@src/components/ExplicitConsent";
import sessionStorageService from "@src/services/sessionStorage.service";
import { useIsEmployerSso } from "@src/queries/employers";
import CompleteBookingEmployerInfo from "./components/CompleteBookingEmployerInfo";
import CompleteBookingPersonalInfo from "./components/CompleteBookingPersonalInfo";
import BookingConfirmation from "../components/BookingConfirmation";

const STEPS = {
  LOADER: "Loader",
  SSO: "sso",
  COMPLETE_BOOKING_1: "User Information Form Step 1",
  COMPLETE_BOOKING_2: "User Information Form Step 2",
  EXPLICIT_CONSENT: "Consent",
  PHONE_NUMBER: "Collect user phone number",
  CONFIRMATION: "Confirmation Screen",
};

const ConfirmSessionFlow = () => {
  const { token, email } = useParams();

  const {
    step,
    nextStep,
    navigateToUrl,
    setPreventGoingBack,
    setupFlowSteps,
    isFlowSetup,
    goToStep,
  } = useOutletContext();

  const { pathT } = useTranslatedNavigate();
  const emailParsed = email.replace(/ /g, "+");

  const [consult, setConsult] = useState();

  const firstName = useRef("");
  const lastName = useRef("");
  const yearOfBirth = useRef("");
  const phoneNumber = useRef("");
  const employerId = useRef();
  const isPartner = useRef(false);
  const acceptedTermsAndConditions = useRef(false);
  const acceptedMedicalDataProcessing = useRef(false);

  const { mutateAsync: confirmConsult } = useConfirmConsultQuery();

  const { data: nonConfirmedConsultIsActive } =
    useCheckIfNonConfirmedConsultIsStillActive(emailParsed, token);
  const { data: guestUserProfile } = useGuestUserProfile(email, token);

  const {
    data: { ssoSupported },
  } = useIsEmployerSso({ email: emailParsed });

  const saveConsult = async () => {
    try {
      const result = await confirmConsult({ token, emailParsed });
      setConsult(result);
      goToStep(STEPS.CONFIRMATION);
    } catch {
      // TODO: Add a error message here
    }
  };

  useEffect(() => {
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  // 1. Check if the unconfirmed consult is still reserved/active. If not, proceed to create a new booking.
  // 2. Check if the use account already exists and is complete. If yes, proceed to confirmation.
  // 3. If only a phone number is missing, proceed to phone number step.
  // 4. If the user does not exist, check if this user email supports SSO. If yes, proceed to login to SSO, and create the external user. Then proceed to *update* the newly created user details.
  // 5. If the user does not exist, and does not support SSO, proceed to user details form, and create the user + confirm the session at the end of the flow.

  useEffect(() => {
    if (nonConfirmedConsultIsActive === undefined) {
      return;
    }
    if (!nonConfirmedConsultIsActive) {
      navigateToUrl(`${pathT("route.booking")}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [nonConfirmedConsultIsActive]);

  const navigateToCorrectStep = () => {
    if (guestUserProfile) {
      if (!guestUserProfile?.isRegistered) {
        goToStep(STEPS.COMPLETE_BOOKING_1);
      } else if (
        guestUserProfile?.hasValidPhoneNumber ||
        IsGuestTwoFactorAuthenticationEnabled(guestUserProfile)
      ) {
        saveConsult();
      } else {
        goToStep(STEPS.PHONE_NUMBER);
      }
    } else {
      goToStep(STEPS.COMPLETE_BOOKING_1);
    }
  };

  useEffect(() => {
    if (isFlowSetup === undefined || isFlowSetup === false) {
      return;
    }

    if (ssoSupported) {
      goToStep(STEPS.SSO);
    } else {
      navigateToCorrectStep();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [isFlowSetup]);

  useEffect(() => {
    if (step === STEPS.CONFIRMATION) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  const saveUserAndConsult = async () => {
    const result = await AuthService.registerBookingUser(
      firstName.current,
      lastName.current,
      emailParsed,
      yearOfBirth.current,
      employerId.current,
      phoneNumber.current,
      acceptedTermsAndConditions.current,
      acceptedMedicalDataProcessing.current,
      isPartner.current,
      token,
    );
    setConsult(result);
    goToStep(STEPS.CONFIRMATION);
  };

  const selectStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.SSO:
        return (
          <SingleSignOnEmailView
            hideUI
            onSuccessfulSso={() => {
              navigateToCorrectStep();
            }}
            onSsoFailure={() => {
              navigateToUrl(`${pathT("route.booking")}`);
            }}
          />
        );
      case STEPS.COMPLETE_BOOKING_1:
        return (
          <CompleteBookingPersonalInfo
            moveToNextStep={(values) => {
              firstName.current = values.firstName;
              sessionStorageService.saveNameToStorage(values.firstName);
              lastName.current = values.lastName;
              yearOfBirth.current = values.yearOfBirth;
              phoneNumber.current = values.phoneNumber;
              nextStep();
            }}
          />
        );
      case STEPS.COMPLETE_BOOKING_2:
        return (
          <CompleteBookingEmployerInfo
            moveToNextStep={nextStep}
            employerId={employerId}
            isPartner={isPartner}
          />
        );
      case STEPS.EXPLICIT_CONSENT:
        return (
          <ExplicitConsent
            moveToNextStep={(termsAndConditions, medicalDataProcessing) => {
              acceptedTermsAndConditions.current = termsAndConditions;
              acceptedMedicalDataProcessing.current = medicalDataProcessing;

              saveUserAndConsult();
            }}
          />
        );
      case STEPS.PHONE_NUMBER:
        // this step is encountered when the user has an account but not a valid phone number associated with it
        return (
          <PhoneNumberComponentContainer
            token={token}
            moveToNextStep={saveConsult}
          />
        );
      case STEPS.CONFIRMATION:
        return <BookingConfirmation consult={consult} />;
      default:
        return <CenteredLoader />;
    }
  };

  return nonConfirmedConsultIsActive && selectStep(step);
};

export default ConfirmSessionFlow;
