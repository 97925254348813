import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import {
  pageSize,
  CONSULT_TYPE,
  STATUS,
  SESSION_TYPE,
} from "@src/utils/constants";
import {
  formatConsultDate,
  formatSessionDateWithTimezone,
  formatSessionType,
} from "@src/utils/formatting";
import { MySessionsButton } from "@src/views/account/mySessions/components/MySessionsButton";
import { useNavigate } from "react-router-dom";
import {
  canViewConsultNotes,
  getConsultType,
  isSessionCancelled,
} from "@src/utils/helpers";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { withSuspense } from "@src/components/wrappers/Suspendable";
import { isAfter } from "date-fns";
import { ConsultSpace } from "@src/views/account/mySessions/models/consultSpace";
import { ReactElement } from "react";

const useStyle = makeStyles(({ spacing, colors, breakpoints }) => ({
  root: {
    padding: spacing(2, 3),
    borderRadius: spacing(2),
    marginTop: spacing(4),
    [breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  consultsIcon: {
    marginRight: spacing(1),
  },
  tablePagination: {
    marginLeft: "auto",
    marginRight: spacing(-1),
  },
  cell: {
    padding: spacing(1, 0, 1, 1),
    backgroundColor: colors.trueWhite,
    [breakpoints.down("sm")]: {
      borderBottom: 0,
    },
  },
  tableHeadingCell: {
    padding: spacing(1, 0, 1, 1),
    backgroundColor: colors.trueWhite,
    [breakpoints.down("sm")]: {
      marginBottom: spacing(2),
      borderBottom: 0,
    },
  },
  gridItemColumns: {
    display: "grid",
  },
  gridContainer: {
    borderTop: `1px solid ${colors.primaryDarkBlueWithTransparencyLow}`,
    alignItems: "flex-start",
  },
  cellContain: {
    fontWeight: 400,
    opacity: 0.7,
    margin: spacing(0, 1, 0, 0),
  },
  cellStrikeThrough: {
    textDecoration: "line-through",
  },
  buttonShowMore: {
    width: "100%",
    backgroundColor: "white",
    border: `1px solid ${colors.primaryDarkBlue}`,
    cursor: "pointer",
    marginTop: spacing(2),
  },
  buttonTextShowMore: {
    color: colors.primaryDarkBlue,
    opacity: 1,
  },
  notesCell: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: spacing(1, 0, 1, 1),
    backgroundColor: colors.trueWhite,
    [breakpoints.down("sm")]: {
      borderBottom: 0,
    },
  },
  notesUnavailableCell: {
    backgroundColor: colors.white,
    borderRadius: spacing(1),
    padding: spacing(1.5, 1),
    gap: spacing(1),
  },
  notesUnavailableCellIcon: {
    alignSelf: "baseline",
  },
}));

const upcomingSessionsColumns = [
  {
    id: "MySessionsView.SessionsView.When",
    minWidth: 80,
    maxWidthPercent: 15,
  },
  {
    id: "MySessionsView.SessionsView.Session",
    minWidth: 105,
  },
  {
    id: "MySessionsView.SessionsView.Experts",
    minWidth: 120,
  },
  {
    id: "MySessionsView.SessionsView.Details",
    minWidth: 185,
  },
];

const pastSessionsColumns = [
  {
    id: "MySessionsView.SessionsView.When",
    minWidth: 80,
  },
  {
    id: "MySessionsView.SessionsView.Session",
    minWidth: 105,
  },
  {
    id: "MySessionsView.SessionsView.Experts",
    minWidth: 120,
  },
  {
    id: "MySessionsView.SessionsView.Notes",
    minWidth: 285,
  },
];

type PaginationData = {
  currentPage: number;
  total: number;
  limit: number;
  hasNextPage: boolean;
};
type SessionsViewTableProps = {
  titleText: string;
  allSessions: ConsultSpace[];
  currentPage: number;
  setCurrentPage: Function;
  isFetching: boolean;
  isPastSessions?: boolean;
  paginationData: PaginationData;
};

const SessionsViewTable = ({
  titleText,
  allSessions,
  currentPage,
  setCurrentPage,
  paginationData,
  isFetching = false,
  isPastSessions = false,
}: SessionsViewTableProps) => {
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t, i18n, pathT } = useTranslatedNavigate();
  const classes = useStyle();
  const navigate = useNavigate();
  const columns = isPastSessions
    ? pastSessionsColumns
    : upcomingSessionsColumns;

  const sessions = allSessions?.filter(
    (_, index) =>
      (isSmallScreen || index >= currentPage * pageSize) &&
      index < pageSize * (currentPage + 1),
  );
  const hasMorePages = paginationData?.total
    ? currentPage < Math.floor((paginationData.total - 1) / pageSize)
    : false;

  const handleClickShowMore = () => setCurrentPage(currentPage + 1);
  const handleClickShowLess = () => setCurrentPage(0);

  const handleClickStartSession = (event, consult) => {
    event.stopPropagation();
    window.open(consult.connectionLink, "_blank");
  };

  const handleClickRescheduleSession = (event, consult) => {
    event.stopPropagation();
    navigate(
      `${pathT("route.booking")}/${t("route.reschedule")}/${consult.sessionId}`,
    );
  };

  const handleClickCancelSession = (event, consult) => {
    if (consult.consultOrSpace === SESSION_TYPE.SPACE) {
      event.stopPropagation();
      navigate(
        `${pathT("route.spaces")}/${t("route.spaceCancellation")}/${
          consult.sessionId
        }`,
      );
    } else {
      event.stopPropagation();
      navigate(
        `${pathT("route.booking")}/${t("route.cancellation")}/${
          consult.sessionId
        }`,
      );
    }
  };

  const onConsultClick = (event, consult: ConsultSpace) => {
    if (consult.consultOrSpace !== SESSION_TYPE.CONSULT) {
      return;
    }
    if (!isPastSessions) return;
    if (!canViewConsultNotes(consult)) return;
    event.stopPropagation();
    window.scrollTo(0, 0);
    navigate(
      `${pathT("route.mySessions")}/${t("route.mySessions.consult")}/${
        consult.sessionId
      }`,
    );
  };

  const viewNotesButton = (session) => (
    <MySessionsButton
      consult={session}
      onClick={(event) => onConsultClick(event, session)}
      action="view"
      isWhite
      width={100}
    />
  );

  const joinButton = (consult) => (
    <MySessionsButton
      consult={consult}
      onClick={handleClickStartSession}
      action="join"
      isWhite={false}
      width={20}
    />
  );

  const cancelButton = (consult) => (
    <MySessionsButton
      consult={consult}
      onClick={handleClickCancelSession}
      action="cancel"
      isWhite
      width={30}
    />
  );

  const rescheduleButton = (consult) => (
    <MySessionsButton
      consult={consult}
      onClick={handleClickRescheduleSession}
      action="reschedule"
      isWhite
      width={50}
    />
  );

  const mobileButton = (onClick, text) => (
    <Button className={classes.buttonShowMore} onClick={onClick}>
      <Typography variant="subtitle2" className={classes.buttonTextShowMore}>
        {text}
      </Typography>
    </Button>
  );

  const typographyTableCell = (dataCyTag, content, strikeThrough = false) => {
    const strikeThroughStyling = strikeThrough
      ? ` ${classes.cellStrikeThrough}`
      : "";
    return (
      <TableCell className={classes.cell} data-cy={dataCyTag}>
        <Typography
          variant="subtitle1"
          className={`${classes.cellContain}${strikeThroughStyling}`}
        >
          {content}
        </Typography>
      </TableCell>
    );
  };

  const consultDateTableCell = (session) =>
    typographyTableCell(
      "consult-startAt",
      isPastSessions
        ? formatConsultDate(session)
        : formatSessionDateWithTimezone(session, t, i18n),
      isSessionCancelled(session),
    );
  const consultEmployeeTableCell = (session) =>
    typographyTableCell(
      "consult-employee",
      session.experts.length > 1
        ? session.experts.join(", ")
        : session.experts.join(""),
      isSessionCancelled(session),
    );

  const consultTypeTableCell = (consult) => {
    if (consult.consultOrSpace === SESSION_TYPE.CONSULT) {
      return typographyTableCell(
        "consult-type",
        formatSessionType(t("MySessionsView.SessionsView.1To1Session")),
        isSessionCancelled(consult),
      );
    }
    return typographyTableCell(
      "consult-type",
      formatSessionType(
        t(`MySessionsView.SessionsView.SpaceType.${getConsultType(consult)}`),
      ),
      isSessionCancelled(consult),
    );
  };

  const ctaSection = (session) => {
    if (!session) return undefined;
    // Consults without 24Sessions assigned will have an empty string in the place of the access key at the end of the connectionLink (for 1:1 sessions)
    // These can still be rebooked, as it does not depend on 24Sessions
    if (
      session.consultOrSpace === SESSION_TYPE.CONSULT &&
      session.connectionLink.split("/").pop() === "" &&
      !isPastSessions
    )
      return undefined;
    if (session.type === CONSULT_TYPE.MEDICAL) return undefined;
    // Space types get join button
    if (session.consultOrSpace === SESSION_TYPE.SPACE) {
      if (session.connectionLink && !isPastSessions) {
        return (
          <div data-cy="cta-section">
            <Box
              display={isSmallScreen ? "block" : "flex"}
              alignItems="center"
              flexWrap="nowrap"
              justifyContent="flex-start"
              id={`join-space-button-${session.sessionId}`}
            >
              {joinButton(session)}
              {cancelButton(session)}
            </Box>
          </div>
        );
      }
    }

    if (session.consultOrSpace !== SESSION_TYPE.CONSULT) return undefined;

    // Consult types get join, cancel and reschedule buttons. They also could have notes
    if (!isPastSessions) {
      return (
        <div data-cy="cta-section">
          <Box
            display={isSmallScreen ? "block" : "flex"}
            alignItems="center"
            flexWrap="nowrap"
            justifyContent="flex-start"
          >
            {joinButton(session)}
            {formatSessionType(session.status) === STATUS.SCHEDULED
              ? rescheduleButton(session)
              : undefined}
            {formatSessionType(session.status) === STATUS.SCHEDULED
              ? cancelButton(session)
              : undefined}
          </Box>
        </div>
      );
    }
    if (isPastSessions) {
      return (
        <div data-cy="cta-section">
          <Box
            display={isSmallScreen ? "block" : "flex"}
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            {viewNotesButton(session)}
          </Box>
        </div>
      );
    }
    return null;
  };

  const sessionDetailsTableCell = (session) => {
    let sessionDetails: ReactElement | null = null;
    if (session.consultOrSpace === SESSION_TYPE.CONSULT) {
      if (!isPastSessions) {
        sessionDetails = (
          <div className="flex justify-between">
            <div>
              <Typography variant="subtitle1" className={classes.cellContain}>
                {t(`Consults.Status.${formatSessionType(session.status)}`)}
              </Typography>
            </div>
            <div>{session && !isSmallScreen && ctaSection(session)}</div>
          </div>
        );
      }
    }
    // Space title and joining link
    if (session.consultOrSpace === SESSION_TYPE.SPACE) {
      sessionDetails = (
        <div className="flex justify-between">
          <div>
            <Typography variant="subtitle1" className={classes.cellContain}>
              {formatSessionType(session.title)}
            </Typography>
          </div>
          <div>{session && !isSmallScreen && ctaSection(session)}</div>
        </div>
      );
    }
    return (
      <TableCell className={classes.cell} data-cy="consult-details">
        {sessionDetails}
      </TableCell>
    );
  };

  const consultNotesTableCell = (session) => {
    if (session.consultOrSpace === SESSION_TYPE.SPACE) {
      return (
        <TableCell className={classes.cell} data-cy="consult-notes">
          {session.title.substring(0, 100)}
        </TableCell>
      );
    }

    // Session not eligible to be viewed
    if (isAfter(new Date(session.notesAvailableAt), new Date())) {
      // Notes not available yet
      return (
        <TableCell
          className={classes.cell}
          data-cy="session-notes-not-available"
        >
          <Box
            className={`${classes.notesCell} ${classes.notesUnavailableCell}`}
          >
            <DescriptionOutlinedIcon
              className={classes.notesUnavailableCellIcon}
            />
            <Typography variant="subtitle2">
              {t("MySessionsView.Notes.NotAvailableYet")}
            </Typography>
          </Box>
        </TableCell>
      );
    }

    if (
      !session.notesAvailableAt ||
      !session.dossierNotes ||
      session.dossierNotes.length === 0
    ) {
      if (session.themeKey) {
        return typographyTableCell(
          "consult-type",
          `${formatSessionType(getConsultType(session))} session, ${t(
            `Themes.${session.themeKey}.Name`,
          )}`,
          isSessionCancelled(session),
        );
      }
      return typographyTableCell(
        "consult-type",
        `${formatSessionType(getConsultType(session))} session`,
        isSessionCancelled(session),
      );
    }

    return (
      <TableCell className={classes.notesCell} data-cy="session-notes">
        <p className="sub1 opacity-70 mr-1">{session.shortDiscussedNotes}</p>
        {!isSmallScreen && <ArrowForwardIosIcon fontSize="small" />}
      </TableCell>
    );
  };

  const mobileTable = () => (
    <Table>
      <TableBody style={{ alignItems: "flex-start" }}>
        {sessions.map((consult) => (
          <TableRow
            key={consult.sessionId}
            data-cy={`consult-${consult.sessionId}`}
          >
            <Divider />
            <Grid container className={classes.gridContainer}>
              <Grid container item xs={6} className={classes.gridItemColumns}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    className={classes.tableHeadingCell}
                  >
                    <Typography variant="subtitle1">{t(column.id)}</Typography>
                  </TableCell>
                ))}
              </Grid>
              <Grid container item xs={6} className={classes.gridItemColumns}>
                {consultDateTableCell(consult)}
                {!isPastSessions && consultTypeTableCell(consult)}
                {consultEmployeeTableCell(consult)}
                {!isPastSessions && sessionDetailsTableCell(consult)}
                {isPastSessions && consultNotesTableCell(consult)}
              </Grid>
            </Grid>
            {ctaSection(consult)}
          </TableRow>
        ))}
        <TableRow>
          {hasMorePages
            ? mobileButton(
                handleClickShowMore,
                t("MySessionsView.SessionsView.Button"),
              )
            : currentPage !== 0 &&
              mobileButton(
                handleClickShowLess,
                t("MySessionsView.SessionsView.Button.ShowLess"),
              )}
        </TableRow>
      </TableBody>
    </Table>
  );
  const desktopTable = () => (
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              style={{ minWidth: column.minWidth }}
              className={classes.cell}
            >
              <div style={{ maxWidth: "15%" }}>
                <p className="sub1">{t(column.id)}</p>
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sessions?.map((session) => (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={session.sessionId}
            classes={{ root: classes.root }}
            data-cy={`consult-${session.sessionId}`}
            onClick={(event) => onConsultClick(event, session)}
          >
            {consultDateTableCell(session)}
            {consultTypeTableCell(session)}
            {consultEmployeeTableCell(session)}
            {!isPastSessions && sessionDetailsTableCell(session)}
            {isPastSessions && consultNotesTableCell(session)}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Paper className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Icon className={classes.consultsIcon}>event_available_outlined</Icon>
          <Typography variant="h5">{titleText}</Typography>
        </Box>
        {!isSmallScreen && (
          <TablePagination
            component="div"
            className={classes.tablePagination}
            count={paginationData?.total || 1}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[pageSize]}
            page={currentPage}
            onPageChange={(_, newPage) => {
              setCurrentPage(newPage);
            }}
            data-cy="table-pagination"
            nextIconButtonProps={{ disabled: isFetching || !hasMorePages }}
            backIconButtonProps={{ disabled: isFetching || currentPage === 0 }}
          />
        )}
      </Box>
      <TableContainer>
        {isSmallScreen ? mobileTable() : desktopTable()}
      </TableContainer>
    </Paper>
  );
};

export default withSuspense(SessionsViewTable);
