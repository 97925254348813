import SanityClientConstructor from "@sanity/client";
import moment from "moment-timezone";
import { env } from "@src/env";

const client = SanityClientConstructor({
  projectId: env.REACT_APP_SANITY_PROJECT_ID,
  dataset: "production",
  apiVersion: moment().format("YYYY-MM-DD"),
  useCdn: false, // `false` if you want to ensure fresh data
});

export default client;
