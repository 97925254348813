import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useOutletContext } from "react-router-dom";
import { ReactComponent as ManFitness } from "@src/resources/images/man_yoga_pose.svg";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import BookingContainer from "@src/views/booking/components/BookingContainer";
import BookingCard from "@src/views/booking/components/BookingCard";
import BoldLink from "@src/components/BoldLink";

const useStyles = makeStyles((theme) => ({
  boxFooter: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  bookingDetailsMessage: {
    maxWidth: 240,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 296,
    },
  },
  image: {
    float: "right",
    marginTop: theme.spacing(10.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      width: 152,
      height: 152,
      position: "relative",
      top: theme.spacing(-2.5),
      marginBottom: theme.spacing(-4),
    },
  },
  button: {
    opacity: 1,
    fontWeight: 500,
    width: 240,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0.5),
      maxWidth: 150,
    },
  },
  tertiaryButtonContainer: {
    "&:hover": {
      background: "none",
    },
    background: "none",
    width: "25%",
  },
  tertiaryButtonText: {
    textDecoration: "underline",
    fontWeight: 500,
    opacity: 1,
    textAlign: "left",
    marginTop: "auto",
    marginBottom: "auto",
  },
  scheduleSessionButtonContainer: ({ isSmallScreen }) => ({
    width: isSmallScreen ? "70%" : "50%",
    maxWidth: "none",
  }),
  scheduleSessionButtonText: {
    opacity: 1,
    fontWeight: 500,
    color: theme.colors.trueWhite,
    letterSpacing: theme.spacing(0.15625),
  },
}));

const CancellationConfirmed = () => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const classes = useStyles({ isSmallScreen });
  const { t, pathT } = useTranslatedNavigate();

  const { navigateToUrl } = useOutletContext();

  return (
    <BookingContainer title={t("Booking.CancellationConfirmed.Title")}>
      <BookingCard
        image={<ManFitness className={classes.image} />}
        actions={
          <Box className={classes.boxFooter}>
            <Typography variant="body2" className={classes.tertiaryButtonText}>
              <BoldLink
                isTargetBlank={false}
                href={t("SharedStrings.HomepageLink")}
              >
                {t("Booking.CancellationConfirmed.GoToOpenUp")}
              </BoldLink>{" "}
            </Typography>
            <Button
              className={classes.scheduleSessionButtonContainer}
              onClick={() => {
                navigateToUrl(`${pathT("route.booking")}`);
              }}
            >
              <Typography
                variant="body2"
                className={classes.scheduleSessionButtonText}
              >
                {t("Booking.CancellationConfirmed.BookNewSession")}
              </Typography>
            </Button>
          </Box>
        }
      >
        <Typography
          variant="subtitle1"
          className={classes.bookingDetailsMessage}
        >
          {t("Booking.CancellationConfirmed.Message")}
        </Typography>
      </BookingCard>
    </BookingContainer>
  );
};

export default CancellationConfirmed;
