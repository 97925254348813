/* eslint-disable import/no-unresolved */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { env } from "@src/env";
import sessionStorageService from "@src/services/sessionStorage.service";

import sharedTranslationsDe from "@openup/shared/i18n/de.json";
import sharedTranslationsEn from "@openup/shared/i18n/en.json";
import sharedTranslationsFr from "@openup/shared/i18n/fr.json";
import sharedTranslationsNl from "@openup/shared/i18n/nl.json";
import sharedTranslationsEs from "@openup/shared/i18n/es.json";

import translationsNl from "@src/locales/nl.json";
import translationsEn from "@src/locales/en.json";
import translationsFr from "@src/locales/fr.json";
import translationsDe from "@src/locales/de.json";
import translationsEs from "@src/locales/es.json";

import {
  LanguageCodeDutchNl,
  LanguageCodeEnglishGb,
  LanguageCodeGermanDe,
  LanguageCodeFrenchFr,
  LanguageCodeSpanishEs,
} from "@src/utils/languageHelpers";

const FALLBACK_LANGUAGE_CODE = "nl";

const languages = ["nl", "en", "de", "fr", "es"];

const sharedTranslations = {
  nl: sharedTranslationsNl,
  en: sharedTranslationsEn,
  fr: sharedTranslationsFr,
  de: sharedTranslationsDe,
  es: sharedTranslationsEs,
};

const localTranslations = {
  nl: translationsNl,
  en: translationsEn,
  fr: translationsFr,
  de: translationsDe,
  es: translationsEs,
};

const combinedTranslations = languages.reduce((acc, lang) => {
  acc[lang] = { ...sharedTranslations[lang], ...localTranslations[lang] };
  return acc;
}, {});

const generateRouteTranslations = (lang) => ({
  translation: Object.keys(combinedTranslations[lang])
    .filter((key) => key.startsWith("route"))
    .reduce((obj, key) => {
      obj[key] = combinedTranslations[lang][key];
      return obj;
    }, {}),
});

const routeTranslations = languages.reduce((acc, lang) => {
  acc[lang] = generateRouteTranslations(lang);
  return acc;
}, {});

const domainMapping = {
  [env.REACT_APP_DUTCH_DOMAIN]: LanguageCodeDutchNl,
  [env.REACT_APP_ENGLISH_DOMAIN]: LanguageCodeEnglishGb,
  [env.REACT_APP_GERMAN_DOMAIN]: LanguageCodeGermanDe,
  [env.REACT_APP_FRENCH_DOMAIN]: LanguageCodeFrenchFr,
  [env.REACT_APP_SPANISH_DOMAIN]: LanguageCodeSpanishEs,
};

export const getLocaleDomain = (locale) =>
  Object.keys(domainMapping).find((domain) => domainMapping[domain] === locale);

const isDebug = () => import.meta.env.MODE === "development";

export const isAzure = () => domainMapping[window.location.hostname];

const domainLanguageDetector = "domainLanguageDetector";
const languageDetector = new LanguageDetector();

const getBestMatchingPartialRouteForLocale = (route, locale) => {
  const routes = routeTranslations[locale].translation;
  const paths = [...new Set(Object.values(routes))].map(
    (path) => `/${path.toLowerCase()}`,
  );

  let longestMatchLength = 1;
  let longestMatchRoute = "";
  let remainder = "";

  if (route) {
    const lowerCaseRoute = route.toLowerCase();
    for (let i = 0; i < paths.length; i += 1) {
      const currentRoute = paths[i];
      if (lowerCaseRoute.startsWith(currentRoute)) {
        if (currentRoute.length > longestMatchLength) {
          longestMatchLength = currentRoute.length;
          longestMatchRoute = Object.keys(routes).find(
            (k) => `/${routes[k].toLowerCase()}` === currentRoute,
          );
          remainder = route.substring(currentRoute.length);
        }
      }
    }
  }
  if (longestMatchRoute) {
    return {
      route: longestMatchRoute,
      remainder,
    };
  }
  return {
    route,
    remainder,
  };
};

const trimRoute = (route) => {
  if (route?.startsWith("/")) {
    return route.slice(1);
  }
  return route;
};

export const getBestMatchingRouteForLocale = (route, locale) => {
  const parts = [];
  let routeReducer = route;
  while (routeReducer) {
    const result = getBestMatchingPartialRouteForLocale(routeReducer, locale);
    result.route = trimRoute(result.route);
    parts.push(result.route);
    routeReducer = result.remainder;
  }

  return parts;
};

languageDetector.addDetector({
  name: domainLanguageDetector,
  lookup: () =>
    isAzure() && !isDebug()
      ? domainMapping[window.location.hostname]
      : sessionStorage.getItem("language"),
  cacheUserLanguage(lng) {
    if (!isAzure()) {
      sessionStorage.setItem("language", lng);
    }
  },
});

const combinedTranslationsForResources = Object.keys(
  combinedTranslations,
).reduce((acc, lang) => {
  acc[lang] = { translation: combinedTranslations[lang] };
  return acc;
}, {});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: sessionStorageService.dontLoadTranslations()
      ? routeTranslations
      : combinedTranslationsForResources,
    fallbackLng: FALLBACK_LANGUAGE_CODE,
    detection: {
      order: [domainLanguageDetector],
      caches: [domainLanguageDetector],
    },
    keySeparator: false,
    interpolation: {
      skipOnVariables: false,
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
