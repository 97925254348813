import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { isValidPassword, isLowerAlphaNumeric } from "@src/utils/helpers";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { passwordStrengths } from "@src/utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.colors.highlightedBackground,
  },
  progressBarContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  progressBarUnfilled: {
    padding: theme.spacing(0.35),
    borderRadius: theme.spacing(5),
    backgroundColor: theme.colors.lightGray,
    textAlign: "center",
  },
  progressBarFilled: {
    padding: theme.spacing(0.35),
    borderRadius: theme.spacing(5),
    backgroundColor: theme.colors.primaryDarkBlue,
    textAlign: "center",
  },
}));

/**
 * Password strength meter. Takes the password as the argument
 */
const PasswordStrengthMeter = ({ password }) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const getPasswordStrength = () => {
    if (isValidPassword(password)) {
      return passwordStrengths.high;
    }
    if (password.length >= 8 && isLowerAlphaNumeric(password)) {
      return passwordStrengths.medium;
    }
    return passwordStrengths.weak;
  };
  return (
    <div className={classes.container}>
      <Grid container spacing={1} className={classes.progressBarContainer}>
        <Grid item xs={4}>
          {password.length === 0 ? (
            <Paper className={classes.progressBarUnfilled} />
          ) : (
            <Paper className={classes.progressBarFilled} />
          )}
        </Grid>
        <Grid item xs={4}>
          {getPasswordStrength() === passwordStrengths.medium ||
          getPasswordStrength() === passwordStrengths.high ? (
            <Paper className={classes.progressBarFilled} />
          ) : (
            <Paper className={classes.progressBarUnfilled} />
          )}
        </Grid>
        <Grid item xs={4}>
          {getPasswordStrength() === passwordStrengths.high ? (
            <Paper className={classes.progressBarFilled} />
          ) : (
            <Paper className={classes.progressBarUnfilled} />
          )}
        </Grid>
      </Grid>
      {password.length === 0 ? (
        <Typography variant="body2">
          {t("ChangePassword.StrongPasswordHelpText")}
        </Typography>
      ) : (
        <> </>
      )}
      {getPasswordStrength() === "weak" && password.length > 0 ? (
        <Typography variant="body2">
          {t("ChangePassword.WeakPassword")}
        </Typography>
      ) : (
        <> </>
      )}
      {getPasswordStrength() === "medium" ? (
        <Typography variant="body2">
          {t("ChangePassword.MediumPassword")}
        </Typography>
      ) : (
        <> </>
      )}
      {getPasswordStrength() === "high" ? (
        <Typography variant="body2">
          {t("ChangePassword.StrongPassword")}
        </Typography>
      ) : (
        <> </>
      )}
    </div>
  );
};

PasswordStrengthMeter.propTypes = {
  password: PropTypes.string,
};

export default PasswordStrengthMeter;
