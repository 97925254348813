import { useState, useEffect, useMemo } from "react";
import { Box, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import Autocomplete from "@src/components/Autocomplete";
import { makeStyles } from "@mui/styles";
import sessionStorageService from "@src/services/sessionStorage.service";
import { useTranslation } from "react-i18next";
import {
  ONBOARDING_OVERVIEW_CURRENT_PAGE,
  ONBOARDING_VIEW_REFERRAL_STATUS,
} from "@src/utils/constants";
import { useEmployers } from "@src/queries/common";
import Loader from "@src/components/Loader";
import { Formik, Form } from "formik";
import WizardActions from "./WizardActions";
import WizardContainer from "./WizardContainer";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  content: {
    padding: "0 0 100px 0",
    [breakpoints.up("sm")]: {
      padding: spacing(4, 3),
    },
  },
  autocomplete: {
    marginTop: -12,
  },
}));

const ReferralPage = ({ moveToNextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data: companies, isFetching } = useEmployers();
  const activeCompanies = companies.filter((e) => e.active);
  const [referral, setReferral] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [isReferralLoaded, setIsReferralLoaded] = useState(false);

  const companiesOptions = useMemo(
    () =>
      (activeCompanies || []).map(({ id, name, active }) => ({
        value: id,
        text: name,
        active,
      })),
    [activeCompanies],
  );

  const onSubmit = (values) => {
    setReferral(values.referral);
    setCompanyId(
      values.referral !== ONBOARDING_VIEW_REFERRAL_STATUS.NO
        ? values.companyId
        : null,
    );

    sessionStorageService.saveReferralToStorage({
      referral: values.referral,
      companyId:
        values.referral !== ONBOARDING_VIEW_REFERRAL_STATUS.NO
          ? values.companyId
          : null,
    });

    moveToNextStep();
  };

  useEffect(() => {
    const referralFromStorage = sessionStorageService.getReferralFromStorage();
    if (!referralFromStorage) {
      setReferral(ONBOARDING_VIEW_REFERRAL_STATUS.COMPANY);
    } else {
      const { referral: referralStorage, companyId: companyIdStorage } =
        referralFromStorage;
      if (referralStorage) {
        setReferral(referralStorage);
      }
      if (companyIdStorage) {
        setCompanyId(companyIdStorage);
      }
    }

    setIsReferralLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  if (!isReferralLoaded) {
    return null;
  }

  return (
    <WizardContainer
      title={t("OnBoarding.Referral.Title")}
      currentPage={ONBOARDING_OVERVIEW_CURRENT_PAGE.NAME}
    >
      <Formik
        initialValues={{
          referral,
          companyId,
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.content}>
              <RadioGroup
                value={values.referral}
                name="referral"
                onChange={handleChange}
                required
              >
                <FormControlLabel
                  value={ONBOARDING_VIEW_REFERRAL_STATUS.COMPANY}
                  control={<Radio />}
                  label={t("OnBoarding.Referral.Yes")}
                />
                {values.referral === ONBOARDING_VIEW_REFERRAL_STATUS.COMPANY &&
                  (isFetching ? (
                    <Loader />
                  ) : (
                    <Autocomplete
                      id="companyId"
                      options={companiesOptions}
                      getOptionDisabled={(option) => !option.active}
                      value={
                        companiesOptions.find(
                          (eo) => eo.value === values.companyId,
                        ) || null
                      }
                      onChange={(_, newValue) => {
                        setFieldValue("companyId", newValue?.value || "");
                      }}
                      className={classes.autocomplete}
                      placeholder={t("RegisterView.WhoIsEmployer")}
                    />
                  ))}
                <FormControlLabel
                  value={ONBOARDING_VIEW_REFERRAL_STATUS.FRIENDS}
                  control={<Radio />}
                  label={t("OnBoarding.Referral.Friends")}
                />
                {values.referral ===
                  ONBOARDING_VIEW_REFERRAL_STATUS.FRIENDS && (
                  <Autocomplete
                    id="companyId"
                    options={companiesOptions}
                    getOptionDisabled={(option) => !option.active}
                    value={
                      companiesOptions.find(
                        (eo) => eo.value === values.companyId,
                      ) || null
                    }
                    onChange={(_, newValue) => {
                      setFieldValue("companyId", newValue?.value || "");
                    }}
                    className={classes.autocomplete}
                    placeholder={t("RegisterView.WhoIsEmployer")}
                  />
                )}
                <FormControlLabel
                  value={ONBOARDING_VIEW_REFERRAL_STATUS.NO}
                  control={<Radio />}
                  label={t("OnBoarding.Referral.No")}
                />
              </RadioGroup>
            </Box>
            <WizardActions
              disabled={
                !values.referral ||
                ((values.referral === ONBOARDING_VIEW_REFERRAL_STATUS.COMPANY ||
                  values.referral ===
                    ONBOARDING_VIEW_REFERRAL_STATUS.FRIENDS) &&
                  !values.companyId)
              }
              nextStepButtonLabel={t("OnBoarding.Next")}
            />
          </Form>
        )}
      </Formik>
    </WizardContainer>
  );
};

export default ReferralPage;
