import { useEffect, useState } from "react";
import ValidateLastConsult from "@src/views/booking/followUp/components/ValidateLastConsult";
import Language from "@src/views/booking/components/Language";
import {
  useGetLastConfirmedConsultQuery,
  usePsychologistTagsQuery,
  useSchedulerBookConsult,
  useSchedulerBookConsultWithPsychologist,
} from "@src/queries/booking";
import { useGetSessionTypes } from "@src/queries/common";
import authService from "@src/services/auth.service";
import TimeSlotPicker from "@src/views/booking/components/TimeSlotPicker";
import { TAG_TYPES } from "@src/utils/constants";
import BookingConfirmation from "@src/views/booking/components/BookingConfirmation";
import { useOutletContext } from "react-router-dom";
import {
  findTagOfType,
  filterTagsOfType,
  isPsychologistInactive,
  getConsultType,
} from "@src/utils/helpers";
import { updateUserLanguageCode } from "@src/queries/user";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { IsGuestTwoFactorAuthenticationEnabled } from "@src/queries/twoFactorAuthentication";
import PhoneNumberComponentContainer from "@src/views/booking/components/PhoneNumberComponentContainer";
import { withSnackbar } from "@src/components/SnackBarComponent.jsx";
import CenteredLoader from "@src/components/CenteredLoader";
import SessionTypeCard from "@openup/shared/components/Cards/SessionTypeCard/SessionTypeCard";
import { ArrowForward } from "@mui/icons-material";
import { SessionTypeCardImage } from "@src/views/booking/introductory/components/SessionTypeCardImage";

const STEPS = {
  SESSION_TYPE: "route.booking.sessionType",
  OTHER_SESSION_TYPES: "route.booking.otherSessionType",
  LANGUAGE: "route.booking.language",
  TIMESLOTS: "route.booking.timeslots",
  PHONE_NUMBER: "route.booking.phoneNumber",
  CONFIRMATION: "route.booking.confirmation",
};

const LoggedFollowUpFlow = ({ ...props }) => {
  const currentUser = authService.getUserFromStorage();
  const {
    step,
    nextStep,
    goToStep,
    addStepToSkip,
    removeStepToSkip,
    setPreventGoingBack,
    setupFlowSteps,
  } = useOutletContext();
  const { i18n } = useTranslatedNavigate();

  const [language, setLanguage] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState();
  const [consult, setConsult] = useState();
  const [sessionType, setSessionType] = useState();
  const [presetLanguages, setPresetLanguages] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslatedNavigate();

  const { data: lastConsult } = useGetLastConfirmedConsultQuery();
  const { data: psychologistTags } = usePsychologistTagsQuery(
    lastConsult.employeeId,
  );
  const { data: allAvailableSessionTypes } = useGetSessionTypes();

  const previousSessionType = getConsultType(lastConsult);

  const otherSessionTypes = allAvailableSessionTypes
    ?.filter(
      (allSessionTypes) =>
        allSessionTypes.name.toLowerCase() !== previousSessionType,
    )
    .map((sessionTypeTag) => sessionTypeTag.name);

  const onFailureBookConsult = () => {
    setIsSubmitting(false);
    props.snackbarShowMessage(t("InvalidActionView.SomethingWentWrong"));
  };

  const { mutateAsync: bookConsultQuery } = useSchedulerBookConsult({
    onFailureBookConsult,
  });
  const { mutateAsync: bookConsultWithPsychologistQuery } =
    useSchedulerBookConsultWithPsychologist(onFailureBookConsult);
  const updateUserLanguage = (languageCode, logout) =>
    updateUserLanguageCode(languageCode, logout);

  // If the client selects to book the same session type again but the psychologist doesn't offer it anymore, we still provide the same psychologist
  const sameSessionType = (selectedSessionType) => {
    if (!selectedSessionType) return false;
    return (
      selectedSessionType.toLowerCase() ===
      getConsultType(lastConsult).toLowerCase()
    );
  };

  const psyProvidesSessionType = (selectedSessionType = sessionType) => {
    if (isPsychologistInactive(psychologistTags)) return false;
    return (
      sameSessionType(selectedSessionType) ||
      !!findTagOfType(
        psychologistTags,
        TAG_TYPES.SESSION_TYPE,
        selectedSessionType,
      )
    );
  };

  useEffect(() => {
    setupFlowSteps(STEPS);

    // Old sessions may not have session type and/or language assigned
    setSessionType(getConsultType(lastConsult));
    setPresetLanguages(
      filterTagsOfType(psychologistTags, TAG_TYPES.PSYCHOLOGIST_LANGUAGE),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [lastConsult, psychologistTags]);

  useEffect(() => {
    if (step === STEPS.CONFIRMATION) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  const bookConsult = () => {
    const tags = [
      {
        tagName: sessionType,
        tagType: TAG_TYPES.SESSION_TYPE,
      },
      {
        tagName: language,
        tagType: TAG_TYPES.SESSION_LANGUAGE,
      },
    ];
    if (psyProvidesSessionType()) {
      setIsSubmitting(true);
      return bookConsultWithPsychologistQuery({
        time: selectedDateTime.startTime,
        timezone: selectedDateTime.timezone,
        tags,
        psychologistId: lastConsult.employeeId,
      });
    }
    setIsSubmitting(true);
    return bookConsultQuery({
      time: selectedDateTime.startTime,
      timezone: selectedDateTime.timezone,
      tags,
    });
  };

  if (!lastConsult) return <CenteredLoader />;

  const selectStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.SESSION_TYPE:
        return (
          <ValidateLastConsult
            nextStepFollowUpSession={(selectedSessionType) => {
              if (
                psyProvidesSessionType(selectedSessionType) &&
                !!lastConsult.consultLanguage
              ) {
                setLanguage(lastConsult.consultLanguage);
                addStepToSkip(STEPS.LANGUAGE);
                addStepToSkip(STEPS.OTHER_SESSION_TYPES);
                nextStep();
              } else {
                addStepToSkip(STEPS.OTHER_SESSION_TYPES);
                removeStepToSkip(STEPS.LANGUAGE);
                goToStep(STEPS.LANGUAGE);
              }
            }}
            moveToNextStep={() => {
              removeStepToSkip(STEPS.OTHER_SESSION_TYPES);
              removeStepToSkip(STEPS.LANGUAGE);
              nextStep();
            }}
            lastConsult={lastConsult}
            setSessionType={setSessionType}
          />
        );

      case STEPS.OTHER_SESSION_TYPES:
        return (
          <div className="container mx-auto mb-4">
            <p className="mt-4 md:mt-8 md:mb-12 text-center font-serif font-black text-4xl md:text-5xl md:leading-tight text-indigo-800 w-11/12 md:w-8/12 max-w-3xl mx-auto">
              {t("SessionType.Followup.OtherSessionType.Title")}
            </p>
            <div className="flex flex-wrap w-11/12 mx-auto justify-center items-center gap-8 md:gap-6 pb-6">
              {otherSessionTypes?.map((sessionTypeName) => (
                <div data-cy="session-type-card" key={sessionTypeName}>
                  <SessionTypeCard
                    onClick={() => {
                      setSessionType(sessionTypeName);
                      if (
                        psyProvidesSessionType(sessionTypeName) &&
                        !!lastConsult.consultLanguage
                      ) {
                        setLanguage(lastConsult.consultLanguage);
                        addStepToSkip(STEPS.LANGUAGE);
                        goToStep(STEPS.TIMESLOTS);
                      } else {
                        removeStepToSkip(STEPS.LANGUAGE);
                        goToStep(STEPS.LANGUAGE);
                      }
                    }}
                    key={sessionTypeName}
                    cardTitle={t(
                      `Booking.SessionType.${sessionTypeName}.Description`,
                    )}
                    cardSubtitle={t(
                      `Booking.SessionType.${sessionTypeName}.Details`,
                    )}
                    icon={<ArrowForward className="text-blue-500" />}
                    image={
                      <SessionTypeCardImage sessionType={sessionTypeName} />
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        );

      case STEPS.LANGUAGE:
        return (
          <Language
            moveToNextStep={nextStep}
            setLanguage={setLanguage}
            firstname={lastConsult.employeeFullName.split(" ")[0]}
            available={psyProvidesSessionType()}
            presetLanguages={psyProvidesSessionType() ? presetLanguages : null}
            sessionType={sessionType}
          />
        );

      case STEPS.TIMESLOTS:
        return (
          <TimeSlotPicker
            moveToNextStep={async () => {
              updateUserLanguage(i18n.language, false);
              if (
                !!currentUser.hasValidPhoneNumber ||
                IsGuestTwoFactorAuthenticationEnabled(currentUser)
              ) {
                // skipping the next step
                const bookedConsult = await bookConsult();
                setConsult(bookedConsult);
                goToStep(STEPS.CONFIRMATION);
              } else {
                nextStep();
              }
            }}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
            sessionType={sessionType}
            language={language}
            psychologistId={
              psyProvidesSessionType() ? lastConsult.employeeId : null
            }
            isSubmitting={isSubmitting}
          />
        );
      case STEPS.PHONE_NUMBER:
        // this step is skipped when the existing phone number is already valid
        return (
          <PhoneNumberComponentContainer
            moveToNextStep={async () => {
              const bookedConsult = await bookConsult();
              setConsult(bookedConsult);
              nextStep();
            }}
          />
        );
      case STEPS.CONFIRMATION:
        return <BookingConfirmation consult={consult} />;

      default:
        return null;
    }
  };

  return <>{selectStep(step)}</>;
};

export default withSnackbar(LoggedFollowUpFlow);
