import { useRef, useEffect, useState, MutableRefObject } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { withSnackbar } from "@src/components/SnackBarComponent";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import theme from "@src/theme";
import { Box, ThemeProvider } from "@mui/material";
import { closeWebview, isMobileAppView } from "@src/utils/mobileHelper";

const ResilienceCheckinLayout = () => {
  const step = useRef(0);
  const [isFlowSetup, setIsFlowSetup] = useState(false);

  const stepsToSkip: MutableRefObject<Array<number>> = useRef([]);
  const preventGoingBack = useRef(false);
  const stepsMap = useRef(new Map());
  const reverseStepsMap = useRef(new Map());
  const baseLocation = useLocation();

  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();

  const [, setValue] = useState(0); // Used to force a rerender, read below
  const forceRerender = () => setValue((value) => value + 1);

  // Please note:
  // This is being used because having the step as a state does not interact well with the new back button implementation
  // What was happening was that fetching the step value inside the listener would retrieve it's initial value (0) and not the current one
  // Switching it to useRef seems to work properly. The manual re-rendering is so that the UI updates - this was implicit with useState
  // With the exception of isFlowSetup, the step is the only thing that needs to rerender the PulseCheckLayout, hence the forceRerender being used only here
  const setStep = (value) => {
    step.current = value;
    forceRerender();
  };

  const setupFlowSteps = (steps) => {
    const stepsArray = Object.keys(steps).map((key) => steps[key]);

    const temporaryMap = new Map();
    const temporaryReverseMap = new Map();

    stepsArray.forEach((value, index) => {
      temporaryMap.set(index, value);
      temporaryReverseMap.set(value, index);
    });

    stepsMap.current = temporaryMap;
    reverseStepsMap.current = temporaryReverseMap;

    stepsArray.forEach((key) => {
      // for the first step
      if (key === "") return;
      if (!baseLocation.pathname.includes(pathT(t(key)))) {
        navigate(baseLocation.pathname.replace(`${pathT(t(key))}`, ""), {
          replace: true,
        });
      }
    });

    setIsFlowSetup(true);
  };

  const getStepNumber = (value) => {
    if (reverseStepsMap.current.has(value))
      return reverseStepsMap.current.get(value);
    if (stepsMap.current.has(value)) return value;
    return null;
  };

  const getStepsAmount = () => stepsMap.current.size;

  const getStepName = (value) => {
    if (stepsMap.current.has(value)) return stepsMap.current.get(value);
    if (reverseStepsMap.current.has(value)) return value;
    return null;
  };

  const goToStep = (newStep) => setStep(newStep);

  const nextStep = () => {
    let stepToGoTo = step.current + 1;
    if (!stepsMap.current.has(stepToGoTo)) return;

    while (stepsToSkip.current.includes(stepToGoTo)) {
      stepToGoTo += 1;
    }
    setStep(stepToGoTo);
  };

  const goToPreviousStep = () => {
    let stepToGoTo = step.current - 1;

    if (preventGoingBack.current) return;

    while (stepsToSkip.current.includes(stepToGoTo)) {
      stepToGoTo -= 1;
    }

    if (stepToGoTo >= 0) {
      setStep(stepToGoTo);
    } else if (isMobileAppView()) {
      closeWebview();
    } else {
      navigate(-1);
    }
  };

  const setPreventGoingBack = (value) => {
    preventGoingBack.current = value;
  };

  const resetLayoutState = () => {
    stepsToSkip.current = [];
    preventGoingBack.current = false;
    reverseStepsMap.current = new Map();
    stepsMap.current = new Map();
    setIsFlowSetup(false);
    setStep(0);
  };

  const navigateToUrl = (newUrl) => {
    if (!newUrl) return;

    // baseLocation.pathname = newUrl;
    resetLayoutState();
    navigate(newUrl, { replace: true });
  };

  useEffect(() => {
    window.addEventListener("popstate", goToPreviousStep);

    // Removes the listener on clean up
    return () => window.removeEventListener("popstate", goToPreviousStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  return (
    <Box
      className="min-h-screen bg-[url('/images/onboarding/bg-top-right.svg')] sm:bg-[url('/images/onboarding/bg-top-right.svg'),_url('/images/onboarding/bg-bottom-left.svg')] bg-no-repeat bg-[position:right_top] sm:bg-[position:right_top,_left_bottom] bg-[195px] sm:bg-[288px,_460px]"
      style={{ backgroundColor: "#FFF8ED" }}
    >
      <ThemeProvider theme={theme}>
        <Outlet
          context={{
            step: getStepName(step.current),
            nextStep,
            goToStep,
            goToPreviousStep,
            navigateToUrl,
            setupFlowSteps,
            isFlowSetup,
            setPreventGoingBack,
            stepsMap,
            getStepNumber,
            getStepName,
            getStepsAmount,
          }}
        />
      </ThemeProvider>
    </Box>
  );
};

export default withSnackbar(ResilienceCheckinLayout);
