import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Loader from "@src/components/Loader";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { getValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import { Formik } from "formik";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  pageMargin: {
    "@media (max-width: 599px)": {},
    "@media (min-width: 600px)": {
      width: "360px !important",
      margin: "auto",
    },
  },
}));

const CreateAccountStep2 = ({ moveToNextStep, isSubmitLocked }) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const emailButtonClick = () => {
    moveToNextStep(null, false);
  };

  const onSubmit = (values) => {
    moveToNextStep(values, true);
  };

  return (
    <Formik
      initialValues={{
        password: "",
      }}
      validationSchema={getValidationSchema(t, { password: true })}
      onSubmit={onSubmit}
      validateOnMount
      validateOnChange
    >
      {({
        handleSubmit,
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
        isValid,
      }) => (
        <form onSubmit={handleSubmit} className={classes.pageMargin}>
          <Box>
            <Box mb={3}>
              <Typography variant={isSmallScreen ? "h3" : "h1"}>
                {t("CreateAccountView.StepThirdHeading")}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="body1" style={{ fontSize: "16px" }}>
                {t("CreateAccountView.StepThirdTitleMessage")}
              </Typography>
            </Box>
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label={t("CreateAccountView.Password")}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              value={values.password}
              variant="standard"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              InputProps={{
                endadornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <br />

            {isSubmitting && <Loader />}

            <Box mt={-1.5}>
              <Button
                disabled={isSubmitting || !isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                <Typography
                  variant="body2"
                  style={{ color: "#ffffff", opacity: "100" }}
                >
                  {t("CreateAccountView.YesCreateAccount")}
                </Typography>
              </Button>
            </Box>

            <Box mt={-2.7}>
              <Button
                disabled={isSubmitLocked}
                onClick={emailButtonClick}
                fullWidth
                size="large"
                variant="outlined"
                type="button"
              >
                <Typography variant="body2">
                  <b>{t("CreateAccountView.NoThanks")}</b>
                </Typography>
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateAccountStep2;
