import { useAuthentication } from "@src/services/auth.service";
import LoggedFollowUpFlow from "@src/views/booking/followUp/LoggedFollowUpFlow";
import NonLoggedFollowUpFlow from "@src/views/booking/followUp/NonLoggedFollowUpFlow";
import { useGetLastConfirmedConsultQuery } from "@src/queries/booking";
import { Navigate } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const FollowUpFlow = () => {
  const { isLoggedInOrTwoFactorPending } = useAuthentication();
  const { t, pathT } = useTranslatedNavigate();

  const { data: lastConsult } = useGetLastConfirmedConsultQuery();

  // If the user is logged in, or has a pending 2FA, we show the logged in flow
  if (isLoggedInOrTwoFactorPending) {
    if (!lastConsult) {
      return (
        <Navigate to={`${pathT("route.booking")}/${t("route.introductory")}`} />
      );
    }
    return <LoggedFollowUpFlow />;
  }
  return <NonLoggedFollowUpFlow />;
};
export default FollowUpFlow;
