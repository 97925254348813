import Helmet from "react-helmet";
import { Outlet } from "react-router-dom";
import withAppInsights from "@src/appInsights/AppInsights";
import { AppInsightsContextProvider } from "@src/appInsights/AppInsightsContext";
import type { FC } from "react";
import DashboardTopBar from "@src/layouts/TopBars/DashboardTopBar";
import FooterBar from "@src/layouts/FooterBar";

interface BasicLayoutProps {
  backgroundColor?: string;
  className?: string;
  isHeaderVisible?: boolean;
  isFooterVisible?: boolean;
}

const BasicLayout = withAppInsights(
  ({ className, isHeaderVisible = true, isFooterVisible = true }) => (
    <AppInsightsContextProvider>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Helmet>
      <div className={`min-h-full ${className}`}>
        {/* @ts-ignore */}
        {isHeaderVisible && <DashboardTopBar />}
        <Outlet />
        {/* @ts-ignore */}
        {isFooterVisible && <FooterBar />}
      </div>
    </AppInsightsContextProvider>
  ),
) as any as FC<BasicLayoutProps>;

export default BasicLayout;
