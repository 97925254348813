import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import colors from "@src/theme/colors";
import ProductCardImage from "@src/components/ProductCardImage";

const useStyles = makeStyles((theme) => ({
  wrapper: ({ backgroundColor, color = theme.palette.primary.main }) => ({
    backgroundColor,
    color,
    padding: "32px 24px",
    display: "flex",
    flexDirection: "column",
    maxHeight: 352,
    height: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 280,
    },
  }),
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    alignItems: "center",
  },
  productTitle: ({ color = theme.palette.primary.main }) => ({
    color,
    fontSize: 18,
    marginBottom: 4,
    lineHeight: "32px",
  }),
  productContent: ({ color = theme.palette.primary.main }) => ({
    color,
    display: "flex",
    position: "relative",
    fontSize: 16,
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 300,
    },
  }),
  productLink: {
    color: colors.primaryBlue,
    fontStyle: "normal",
    opacity: 1,
    display: "flex",
    position: "relative",
    fontWeight: "bold",
  },
  arrow: ({ arrowColor }) => ({
    color: arrowColor,
    position: "absolute",
    fontWeight: "bold",
    bottom: 5,
    right: 0,
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      right: 90,
    },
  }),
  imageContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: 352,
    margin: theme.spacing(-3),
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
  },
  linkContainer: {
    fontSize: 16,
    marginTop: theme.spacing(20),
  },
  textBox: () => ({
    position: "absolute",
  }),
}));
const ProductCard = ({ productKey, titleText, bodyText, linkText, toLink }) => {
  const classesProps = {
    arrowColor: colors.primaryBlue,
    iconColorCode: colors.white,
    iconOutlineColorCode: colors.primaryDarkBlue,
    backgroundColor: colors.trueWhite,
    color: colors.primaryDarkBlue,
  };
  const classes = useStyles(classesProps);

  return (
    <a href={toLink}>
      <Paper className={classes.wrapper}>
        <Box className={classes.textBox}>
          <Typography variant="h6" className={classes.productTitle} wrap="wrap">
            {titleText}
          </Typography>
          <Typography className={classes.productContent} wrap="wrap">
            {bodyText}
          </Typography>
        </Box>
        <div className={classes.linkContainer}>
          <Typography className={classes.productLink} wrap="wrap">
            {linkText}
            <ArrowForwardIcon className={classes.arrow} />
          </Typography>
        </div>
        <div className={classes.imageContainer}>
          <ProductCardImage productKey={productKey} />
        </div>
      </Paper>
    </a>
  );
};

export default ProductCard;
