import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getExternalUser } from "@src/actions/userActions";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { timeSnackbar } from "@src/utils/constants";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { PureComponent } from "react";

class AuthorizedExternalView extends PureComponent {
  async componentDidMount() {
    const { dispatch, navigate, snackbarShowMessage, pathT } = this.props;

    try {
      const response = await dispatch(getExternalUser());

      snackbarShowMessage(response.message, "success");
      setTimeout(() => {
        if (response.isRegistered) {
          navigate(pathT("route.account"), { replace: true });
        } else {
          navigate(pathT("route.register"), { replace: true });
        }
      }, timeSnackbar);
    } catch (error) {
      snackbarShowMessage(error);
    }
  }

  render() {
    return null;
  }
}

AuthorizedExternalView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  pathT: PropTypes.func.isRequired,
};
const WrappedAuthorizedExternalView = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathT } = useTranslatedNavigate();

  return (
    <AuthorizedExternalView
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      navigate={navigate}
      dispatch={dispatch}
      location={location}
      pathT={pathT}
    />
  );
};

export default withSnackbar(WrappedAuthorizedExternalView);
