import {
  Box,
  Container,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMemo, useState } from "react";
import Page from "@src/components/Page";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { BACKGROUND } from "@src/utils/constants";
import MenuList from "@src/components/OpenUpMenuList";
import {
  useQueryQuestionListAnswers,
  useQueryThemesCheckins,
} from "@src/queries/themes";
import { DashboardContext } from "@src/utils/DashboardContext";
import { ChartTile } from "./components/ChartTile";
import ChartFocusedThemesNotCheckedIn from "./components/ChartFocusedThemesNotCheckedIn";
import FocusThemesTiles from "./components/FocusThemesTiles";
import FocusModal from "./components/FocusModal";

const useStyles = makeStyles((theme) => ({
  page: {
    position: "relative",
    overflowX: "hidden",
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  menuGrid: {
    backgroundColor: theme.colors.trueWhite,
    marginTop: theme.spacing(-8),
    marginBottom: theme.spacing(-3.75),
    marginRight: theme.spacing(6),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3.75),
    marginLeft: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(3),
    color: theme.colors.primaryDarkBlue,
    [theme.breakpoints.down("md")]: {
      lineHeight: "48px",
      fontSize: 35,
    },
    [theme.breakpoints.up("sm")]: {
      lineHeight: "64px",
    },
  },
  boxYourCheckinsText: {
    margin: `${theme.spacing(0)} auto`,
  },
  yourCheckinsText: {
    fontWeight: "400",
    color: theme.colors.primaryDarkBlue,
    marginBottom: theme.spacing(6),
  },
  menuItem: {
    color: "#ffffff",
  },
}));

const CheckInsView = () => {
  const { t } = useTranslatedNavigate();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { data: themes = [], refetch: refetchThemes } =
    useQueryThemesCheckins();
  const [showFocusModal, setShowFocusModal] = useState(false);

  const toggleFocusModal = () => setShowFocusModal(!showFocusModal);
  const questionListAnswers = useQueryQuestionListAnswers("general-wellbeing");

  const { focusedThemes, unfocusedThemes } = useMemo(
    () =>
      themes.reduce(
        (acc, theme) => {
          const questionList = theme.questionLists.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          )[0];
          let newTheme = { ...theme };
          if (questionList) {
            newTheme = {
              ...newTheme,
              ...questionList,
              ordinal: theme.ordinal,
              isTrackedTheme: theme.isTrackedTheme,
              isFocused: theme.isFocused,
              key: theme.key,
            };
          } else {
            newTheme = {
              ...newTheme,
              score: 0,
              createdAt: new Date(0),
            };
          }
          if (theme.isFocused) {
            acc.focusedThemes.push(newTheme);
          } else {
            acc.unfocusedThemes.push(newTheme);
          }
          return acc;
        },
        { focusedThemes: [], unfocusedThemes: [] },
      ),
    [themes],
  );

  const chartThemes = useMemo(
    () =>
      Object.keys(themes)
        .map((k) => {
          const questionList = themes[k].questionLists.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          )[0];

          if (questionList) {
            return {
              ...questionList,
              ...{
                ordinal: themes[k].ordinal,
                isTrackedTheme: themes[k].isTrackedTheme,
                isFocused: themes[k].isFocused,
                key: themes[k].key,
              },
            };
          }
          return {
            ...themes[k],
            ...{
              score: 0,
              createdAt: new Date(0),
            },
          };
        })
        .sort((a, b) => {
          if (a.isFocused === b.isFocused) return a.ordinal - b.ordinal;

          return a.isFocused ? -1 : 1;
        }),
    [themes],
  );

  const hasCheckins =
    focusedThemes.some((item) => item.isTrackedTheme) ||
    unfocusedThemes.some((item) => item.isTrackedTheme);
  const hasCheckinsOrNoFocusedThemes =
    hasCheckins || focusedThemes.length === 0;

  return (
    <DashboardContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ refetchThemes, themes, toggleFocusModal }}
    >
      <Page title={t("CheckinsPage.Title")} className={classes.page}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Hidden only={["xs", "sm"]}>
              <Grid item xs className={classes.menuGrid}>
                <MenuList
                  menuItemStyle={classes.menuItem}
                  background={BACKGROUND.GREEN_BACKGROUND}
                />
              </Grid>
            </Hidden>

            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Typography
                variant={isSmallScreen ? "h3" : "h1"}
                className={classes.header}
              >
                {t("CheckinsPage.YourCheckinsTitle")}
              </Typography>
              <Box className={classes.boxYourCheckinsText}>
                <Typography
                  variant="body1"
                  className={classes.yourCheckinsText}
                >
                  {t("CheckinsPage.YourCheckinsText")}
                </Typography>
              </Box>

              {hasCheckinsOrNoFocusedThemes ? (
                <ChartTile hasFocusedThemes={focusedThemes.length > 0} />
              ) : (
                <ChartFocusedThemesNotCheckedIn />
              )}
              {focusedThemes.length > 0 && (
                <FocusThemesTiles
                  questionListAnswers={questionListAnswers}
                  chartThemes={chartThemes}
                  toggleFocusModal={toggleFocusModal}
                  isCompareScoresVisible={false}
                  focusedThemes={focusedThemes}
                  showDefaultTile
                />
              )}
            </Grid>
          </Grid>
          <FocusModal open={showFocusModal} onClose={toggleFocusModal} />
        </Container>
      </Page>
    </DashboardContext.Provider>
  );
};

export default CheckInsView;
