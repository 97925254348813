import {
  Typography,
  Button,
  useMediaQuery,
  Card,
  CardContent,
  CardMedia,
  CardActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as ManFitness } from "@src/resources/images/man_yoga_pose.svg";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useNavigate } from "react-router-dom";
import BookingContainer from "../../components/BookingContainer";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexBasis: 300,
    minHeight: 300,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexBasis: 300,
  },
  cardActions: {
    padding: theme.spacing(3),
    justifyContent: "center",
    borderTop: "solid 2px rgba(21, 35, 97, .05);",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(4),
      position: "fixed",
      bottom: 0,
      right: 0,
      backgroundColor: "#ffffff",
      padding: theme.spacing(2),
      boxShadow: "0px 0 16px rgba(21, 35, 97, 30% )",
    },
  },
  media: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  bookingDetailsMessage: {
    maxWidth: 240,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 296,
    },
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      width: 152,
      height: 152,
    },
  },
  box: {
    marginBottom: theme.spacing(3),
    maxWidth: 240,
  },
  typography: {
    color: "white",
    letterSpacing: "1.25px",
  },
  button: {
    width: 240,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 150,
    },
  },
}));

const PsychologistUnavailableView = ({ firstname }) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const classes = useStyles();
  const navigate = useNavigate();
  const { t, pathT } = useTranslatedNavigate();

  const button = (
    <Button
      className={classes.button}
      onClick={() => navigate(pathT("route.booking"), { replace: true })}
    >
      <Typography variant="subtitle2" className={classes.typography}>
        {t("SharedStrings.CTA.Continue")}
      </Typography>
    </Button>
  );

  return (
    <BookingContainer
      title={t("Booking.PersonalLink.Unavailable.Title", {
        firstname,
      })}
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            variant="subtitle1"
            className={classes.bookingDetailsMessage}
          >
            {t("Booking.PersonalLink.Unavailable", { firstname })}
          </Typography>
          {!isSmallScreen && button}
        </CardContent>
        {isSmallScreen && (
          <CardActions className={classes.cardActions}>{button}</CardActions>
        )}
        <CardMedia className={classes.media}>
          <ManFitness className={classes.image} />
        </CardMedia>
      </Card>
    </BookingContainer>
  );
};

export default PsychologistUnavailableView;
