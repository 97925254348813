import { ReactComponent as ChildBoat } from "@src/resources/images/child_boat.svg";
import { ReactComponent as ManLooking } from "@src/resources/images/man_looking.svg";
import { ReactComponent as ManTexting } from "@src/resources/images/man_texting.svg";
import { ReactComponent as BusinessManRunning } from "@src/resources/images/business_man_running.svg";
import { ReactComponent as BoyFlyingSuitcase } from "@src/resources/images/boy_flying_suitcase.svg";
import { ReactComponent as ManCarryWoman } from "@src/resources/images/man_carry_woman.svg";
import { ReactComponent as WomanYogaPose } from "@src/resources/images/woman_yoga_pose.svg";
import { ReactComponent as WomanWater } from "@src/resources/images/woman_water.svg";
import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { THEME_KEY } from "@src/utils/constants";

const useStyles = makeStyles(() => ({
  image: ({ imageHeight, imageWidth }) => ({
    height: imageHeight,
    width: imageWidth,
    borderBottomRightRadius: "16px",
  }),
}));
const getImageDimensions = (isSmallScreen, imageName) => {
  let imageHeight;
  let imageWidth;
  let viewBoxHeight;
  let viewBoxWidth;
  let viewBoxX;
  let viewBoxY;
  switch (imageName) {
    case THEME_KEY.CONFIDENCE:
      imageHeight = 245;
      imageWidth = 135;
      viewBoxWidth = imageWidth;
      viewBoxHeight = imageHeight / 2;
      viewBoxX = 0;
      viewBoxY = isSmallScreen ? 20 : -60;
      break;
    case THEME_KEY.STRESS:
      imageHeight = 173;
      imageWidth = 151;
      viewBoxWidth = imageWidth;
      viewBoxHeight = imageHeight / 1.6;
      viewBoxX = isSmallScreen ? -10 : 10;
      viewBoxY = isSmallScreen ? 0 : -30;
      break;
    case THEME_KEY.WORK:
      imageHeight = isSmallScreen ? 202 : 173;
      imageWidth = isSmallScreen ? 152 : 160;
      viewBoxWidth = imageWidth;
      viewBoxHeight = imageHeight / 1.6;
      viewBoxX = isSmallScreen ? -10 : 5;
      viewBoxY = isSmallScreen ? 20 : -10;
      break;
    case THEME_KEY.PURPOSE:
      imageHeight = 161;
      imageWidth = 160;
      viewBoxWidth = imageWidth;
      viewBoxHeight = imageHeight / 1.6;
      viewBoxX = isSmallScreen ? -50 : -10;
      viewBoxY = 0;
      break;
    case THEME_KEY.RELATIONSHIPS:
      imageHeight = 161;
      imageWidth = 160;
      viewBoxWidth = imageWidth;
      viewBoxHeight = imageHeight / 1.6;
      viewBoxX = isSmallScreen ? -20 : 10;
      viewBoxY = 0;
      break;
    case THEME_KEY.HEALTH:
      imageHeight = 168;
      imageWidth = 110;
      viewBoxWidth = imageWidth;
      viewBoxHeight = imageHeight / 1.6;
      viewBoxX = 0;
      viewBoxY = -10;
      break;
    case THEME_KEY.SLEEP:
      imageHeight = 189;
      imageWidth = 190;
      viewBoxWidth = imageWidth;
      viewBoxHeight = imageHeight / 1.6;
      viewBoxX = isSmallScreen ? -60 : -30;
      viewBoxY = isSmallScreen ? 0 : -30;
      break;
    case THEME_KEY.MINDFULNESS:
      imageHeight = 132;
      imageWidth = 163;
      viewBoxWidth = imageWidth / 0.4;
      viewBoxHeight = imageHeight / 1.9;
      viewBoxX = isSmallScreen ? -150 : -50;
      viewBoxY = 100;
      break;
    default:
      imageHeight = 0;
      imageWidth = 0;
      viewBoxHeight = 0;
      viewBoxWidth = 0;
      viewBoxX = 0;
      viewBoxY = 0;
  }
  return {
    imageHeight,
    imageWidth,
    viewBoxHeight,
    viewBoxWidth,
    viewBoxX,
    viewBoxY,
  };
};

const FocusThemeTileImage = ({ theme }) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const {
    imageHeight,
    imageWidth,
    viewBoxHeight,
    viewBoxWidth,
    viewBoxX,
    viewBoxY,
  } = getImageDimensions(isSmallScreen, theme.key);

  const classes = useStyles({ imageWidth, imageHeight, isSmallScreen });
  const viewBox = ` ${viewBoxX} ${viewBoxY} ${viewBoxWidth} ${viewBoxHeight}`;
  switch (theme.key) {
    case THEME_KEY.CONFIDENCE:
      return <ManLooking viewBox={viewBox} className={classes.image} />;
    case THEME_KEY.STRESS:
      return <ManTexting viewBox={viewBox} className={classes.image} />;
    case THEME_KEY.WORK:
      return <BusinessManRunning viewBox={viewBox} className={classes.image} />;
    case THEME_KEY.PURPOSE:
      return <BoyFlyingSuitcase viewBox={viewBox} className={classes.image} />;
    case THEME_KEY.RELATIONSHIPS:
      return <ManCarryWoman viewBox={viewBox} className={classes.image} />;
    case THEME_KEY.HEALTH:
      return <WomanYogaPose viewBox={viewBox} className={classes.image} />;
    case THEME_KEY.SLEEP:
      return <WomanWater viewBox={viewBox} className={classes.image} />;
    case THEME_KEY.MINDFULNESS:
      return <ChildBoat viewBox={viewBox} className={classes.image} />;
    default:
      return null;
  }
};

export default FocusThemeTileImage;
