import { useCheckinFormByFormName } from "@src/queries/themes";
import TripettoSurvey from "./index";

const GlobalTripettoSurvey = ({ redirectUri, redirectUriOnNotLoggedIn }) => {
  const query = useCheckinFormByFormName("global");

  return (
    <TripettoSurvey
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...query}
      redirectUri={redirectUri}
      redirectUriOnNotLoggedIn={redirectUriOnNotLoggedIn}
    />
  );
};

export default GlobalTripettoSurvey;
