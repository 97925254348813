import { Grid, Link, useMediaQuery, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles(() => ({
  container: { marginTop: "12px" },
  text: {
    fontWeight: 500,
    letterSpacing: "1.25px",
    opacity: 1,
    textDecoration: "underline",
  },
}));

/**
 * Modal for activating Two Factor Authentication
 */
const BackToMyOpenUpButton = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { t, pathT } = useTranslatedNavigate();

  return (
    <Grid className={classes.container}>
      {/* <Typography variant="subtitle2" className={classes.text}>
        BackToMyOpenUp
      </Typography> */}

      <Link
        component={RouterLink}
        to={pathT("route.login")}
        className={classes.linkActions}
        onClick={() => {
          window.OpenUpMobile?.closeWebView();
          window.webkit?.messageHandlers?.OpenUpMobile?.postMessage(
            "closeWebview",
          );
        }}
        underline="hover"
      >
        <Typography variant="subtitle2" className={classes.text}>
          {isSmallScreen
            ? t("Booking.ConfirmationPage.ToMyOpenUp")
            : t("Booking.ConfirmationPage.BackToMyOpenUp")}
        </Typography>
      </Link>
    </Grid>
  );
};

export default BackToMyOpenUpButton;
