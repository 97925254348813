import { Slide, Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useState } from "react";

export const withSnackbar = (WrappedComponent) => (props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(2000);
  const [severity, setSeverity] = useState("error");

  // eslint-disable-next-line no-shadow
  const showMessage = (
    newMessage,
    newSeverity = "error",
    newDuration = 2000,
  ) => {
    setMessage(newMessage);
    setSeverity(newSeverity);
    setDuration(newDuration);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WrappedComponent {...props} snackbarShowMessage={showMessage} />
      <Snackbar
        id="toaster"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={duration}
        open={open}
        onClose={handleClose}
        TransitionComponent={Slide}
      >
        <Alert variant="filled" onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
