import { Rating, TextField } from "@mui/material";
import { ConsultReview } from "@src/models/ConsultReview";
import { useSubmitConsultReview } from "@src/queries/consults";
import { useFormik } from "formik";
import { FC } from "react";
import { Trans } from "react-i18next";
import {
  StarOutline as StarOutlineIcon,
  Star as StarIcon,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@openup/shared/components/Button/Button";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

interface FeedbackRatingProps {
  index: number;
  question: string;
  value: number;
  onRateChange: (index: number, newValue: number | null) => void;
  maxRate: number;
}

const FeedbackRating: React.FC<FeedbackRatingProps> = ({
  index,
  question,
  value,
  onRateChange,
  maxRate,
}) => {
  return (
    <div className="flex flex-col gap-4 pb-8" key={`Rating_${index}`}>
      <p className="body2">
        <Trans
          i18nKey={question}
          components={[<span className="text-red-500">*</span>]}
        />
      </p>
      <div className="flex flex-row">
        <Rating
          name={`customized-empty-${index}`}
          value={value}
          max={maxRate}
          onChange={(event, newValue) => onRateChange(index, newValue)}
          emptyIcon={
            <StarOutlineIcon className="w-6 h-6 fill-current text-slate-700" />
          }
          icon={<StarIcon className="w-6 h-6 fill-current text-indigo-800" />}
        />
        <p className="pl-2 body2 text-slate-700">
          {value}/{maxRate}
        </p>
      </div>
    </div>
  );
};

const SessionReviewView: FC = () => {
  const { t, pathT } = useTranslatedNavigate();
  const { mutateAsync: submitFeedback } = useSubmitConsultReview();
  const navigate = useNavigate();
  const { consultId } = useParams();

  const maxRate: number = 10;

  const formik = useFormik({
    initialValues: {
      Feedback: "",
      Answer1: maxRate,
      Answer2: maxRate,
      Answer3: maxRate,
    },
    onSubmit: (values: ConsultReview) => {
      submitFeedback({
        consultId: Number(consultId!),
        review: values,
      });

      navigate(pathT("route.mySessions.review.thanks"));
    },
  });
  return (
    <div className="flex justify-center md:-mt-12 z-50 max-sm:p-4">
      <div className="md:w-[594px] w-full h-fit rounded-2xl overflow-hidden bg-white shadow px-8 py-6 md:px-14 md:py-12">
        <h2 className="h2 pb-8">{t("Feedback.Title")}</h2>
        <p className="cap1 text-slate-700 pb-8">
          <Trans
            i18nKey="Feedback.SubTitle"
            components={[<span className="text-red-500">*</span>]}
          />
        </p>
        <div className="flex flex-col gap-4">
          <form onSubmit={formik.handleSubmit}>
            {Array.from(
              { length: 3 },
              (_, questionIndex) => questionIndex + 1,
            ).map((questionIndex) => (
              <FeedbackRating
                key={questionIndex}
                index={questionIndex}
                question={t(`Consult.Review.Question${questionIndex}`)}
                value={formik.values[`Answer${questionIndex}`]}
                onRateChange={(index, newValue) => {
                  if (newValue)
                    formik.setFieldValue(`Answer${index}`, newValue);
                }}
                maxRate={maxRate}
              />
            ))}
            <TextField
              name="Feedback"
              label={t("Consult.Review.Feedback")}
              multiline
              fullWidth
              rows={4}
              variant="outlined"
              value={formik.values.Feedback}
              onChange={formik.handleChange}
              className="mb-2 border-gray-300"
            />
            <p className="text-xs text-right text-gray-600 mb-2">
              {formik.values.Feedback.length} / 10000
            </p>
            <div className="flex-row space-x-6">
              <Button type="submit" variant="primary">
                {t("Feedback.Submit")}
              </Button>
              <Button
                type="button"
                variant="quaternary"
                onClick={() => navigate(pathT("route.mySessions"))}
              >
                {t("Feedback.Skip")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SessionReviewView;
