import { useState } from "react";
import { Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ONBOARDING_OVERVIEW_CURRENT_PAGE } from "@src/utils/constants";
import sessionStorageService from "@src/services/sessionStorage.service";
import WizardContainer from "./WizardContainer";
import WizardActions from "./WizardActions";

const useStyles = makeStyles(({ breakpoints, spacing, customShadows }) => ({
  content: {
    boxShadow: customShadows.bookingContainerBackgroundShadow,
    padding: "0 0 100px 0",
    [breakpoints.up("sm")]: {
      boxShadow: "none",
      padding: spacing(4, 3),
    },
  },
  input: {
    width: "100%",
  },
}));

const NamePage = ({ moveToNextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [userName, setUserName] = useState(
    sessionStorageService.getNameFromStorage(),
  );

  const onSubmit = async (event) => {
    event.preventDefault();
    sessionStorageService.saveNameToStorage(userName);
    moveToNextStep();
  };

  return (
    <WizardContainer
      title={t("OnBoarding.Name.Title")}
      currentPage={ONBOARDING_OVERVIEW_CURRENT_PAGE.NAME}
    >
      <form onSubmit={onSubmit}>
        <Box className={classes.content}>
          <TextField
            variant="standard"
            label={t("OnBoarding.Name.FirstName")}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className={classes.input}
            inputProps={{ autoFocus: true, "data-cy": "first_name" }}
          />
        </Box>
        <WizardActions
          disabled={!userName}
          nextStepButtonLabel={t("OnBoarding.Next")}
        />
      </form>
    </WizardContainer>
  );
};

export default NamePage;
