import { withSnackbar } from "@src/components/SnackBarComponent.jsx";
import { useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CenteredLoader from "@src/components/CenteredLoader.jsx";
import SpaceCancellation from "@src/views/booking/spaces/components/cancel/SpaceCancellation";
import SpaceCancellationConfirmed from "@src/views/booking/spaces/components/cancel/SpaceCancellationConfirmed";
import {
  useGetClientSpace,
  useCancelClientSpaceRegistration,
} from "@src/queries/spaces";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import NotFoundView from "@src/views/errors/NotFoundView";

const STEPS = {
  CANCEL: "",
  CONFIRMATION: "cancelled",
};

const CancelSpaceFlow = ({ ...props }) => {
  const { spaceId = "" } = useParams();
  const { t } = useTranslatedNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setupFlowSteps, step, nextStep } = useOutletContext<{
    step: string;
    setupFlowSteps: Function;
    nextStep: Function;
  }>();

  const onError = () => {
    setIsSubmitting(false);
    props.snackbarShowMessage(t("spaces.errors.error"), "error");
  };

  const onSuccess = () => {
    setIsSubmitting(false);
    nextStep();
  };

  const { data: space } = useGetClientSpace(spaceId, onError);

  const { cancelSpace } = useCancelClientSpaceRegistration(
    spaceId ?? "",
    onSuccess,
    onError,
  );

  const onCancelSpace = async () => {
    setIsSubmitting(true);
    await cancelSpace(spaceId);
  };

  useEffect(() => {
    setupFlowSteps(STEPS);
  });

  const selectStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.CANCEL:
        return (
          <SpaceCancellation
            space={space}
            nextStep={onCancelSpace}
            isSubmitting={isSubmitting}
          />
        );
      case STEPS.CONFIRMATION:
        return <SpaceCancellationConfirmed />;
      default:
        return <CenteredLoader />;
    }
  };

  return space ? <>{selectStep(step)}</> : <NotFoundView />;
};

export default withSnackbar(CancelSpaceFlow);
