import { Container } from "@mui/material";
import LanguageSelector from "@src/components/LanguageSelector";
import Logo from "@src/components/Logo";
import { FC } from "react";

const VideoCallLayoutTopBar: FC = () => {
  return (
    <Container className="bg-white flex items-center w-full max-w-full p-8 max-md:p-4 justify-between">
      <a href="/" aria-label="OpenUp">
        <Logo className="h-8" />
      </a>

      <LanguageSelector />
    </Container>
  );
};

export default VideoCallLayoutTopBar;
