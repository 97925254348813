import { useState, useContext } from "react";
import { TextField, Box, InputAdornment, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ONBOARDING_OVERVIEW_CURRENT_PAGE } from "@src/utils/constants";
import PasswordStrengthMeter from "@src/components/PasswordStrengthMeter";
import { Formik } from "formik";
import { getValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import { ApplicationStateContext } from "@src/utils/ApplicationStateContext";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WizardActions from "./WizardActions";
import WizardContainer from "./WizardContainer";

const useStyles = makeStyles(({ breakpoints, spacing, customShadows }) => ({
  content: {
    boxShadow: customShadows.bookingContainerBackgroundShadow,
    padding: "0 0 100px 0",
    [breakpoints.up("sm")]: {
      boxShadow: "none",
      padding: spacing(4, 3),
    },
  },
}));

const PasswordPage = ({ moveToNextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setPassword } = useContext(ApplicationStateContext);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = (values) => {
    setPassword(values.password);
    moveToNextStep();
  };

  return (
    <WizardContainer
      title={t("OnBoarding.Password.Title")}
      currentPage={ONBOARDING_OVERVIEW_CURRENT_PAGE.PASSWORD}
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          password: "",
        }}
        validationSchema={getValidationSchema(t, {
          password: true,
        })}
      >
        {({
          handleSubmit,
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className={classes.content}>
              <TextField
                className={classes.textFieldPassword}
                error={Boolean(touched.password && errors.password)}
                label={t("RegisterView.SignUp.Password")}
                fullWidth
                helperText={touched.password && errors.password}
                margin="normal"
                name="password"
                onBlur={handleBlur}
                value={values.password}
                variant="standard"
                type={showPassword ? "text" : "password"}
                onChange={handleChange}
                inputProps={{
                  endadornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowPassword} size="large">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoFocus: true,
                  "data-cy": "email",
                }}
              />
              <PasswordStrengthMeter password={values.password} />
            </Box>
            <WizardActions
              disabled={isSubmitting}
              nextStepButtonLabel={t("OnBoarding.Next")}
            />
          </form>
        )}
      </Formik>
    </WizardContainer>
  );
};

export default PasswordPage;
