import axios from "axios";
import { apiPaths } from "@src/utils/constants";
import sessionStorageService from "./sessionStorage.service";

export const pushCurrentMoodsFromStorageToServer = async (
  email,
  unconfirmedDataId,
) => {
  const currentMoodsSet = sessionStorageService.getCurrentMoodsFromStorage();

  if (!currentMoodsSet || currentMoodsSet.size === 0) {
    return;
  }

  if (!email || !unconfirmedDataId) {
    await axios.post(apiPaths.setUserCurrentMoods, [...currentMoodsSet]);
  } else {
    await axios.post(apiPaths.setUnconfirmedUserCurrentMoods, {
      email,
      unconfirmedDataId,
      currentMoods: [...currentMoodsSet],
    });
  }

  sessionStorageService.clearCurrentMoodsFromStorage();
};
