import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { makeStyles } from "@mui/styles";
import PlusIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  addFocusThemeBtn: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    minHeight: 352,
    height: "100%",
    border: "1px dashed #FFFFFF",
    background: theme.colors.primaryGreen,
    borderRadius: 16,
    cursor: "pointer",
  },
  addFocusThemeBtnTitle: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "24px",
    color: theme.palette.common.white,
    opacity: 1,
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
  },
}));

const EmptyFocusThemeTile = ({ toggleFocusModal }) => {
  const { t } = useTranslatedNavigate();
  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={classes.addFocusThemeBtn} onClick={toggleFocusModal}>
      <PlusIcon style={{ color: "white" }} />
      <Typography variant="body1" className={classes.addFocusThemeBtnTitle}>
        {t("DashboardView.AddTheme.Button.Title")}
      </Typography>
    </div>
  );
};

export default EmptyFocusThemeTile;
