import { isAccessRestricted, isClientUser } from "@src/utils/helpers";
import authService from "@src/services/auth.service";
import MagicLinkAccessLimitedView from "@src/views/account/components/MagicLinkAccessLimitedView";
import Sessions from "@src/views/account/mySessions/components/Sessions";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const SessionContainer = () => {
  const currentUser = authService.getUserFromStorage();
  const { t } = useTranslatedNavigate();

  if (isAccessRestricted(currentUser)) {
    return (
      <MagicLinkAccessLimitedView
        message={
          isClientUser(currentUser)
            ? t("MagicLinkAccessLimitedView.MySession.Login.Message")
            : t("MagicLinkAccessLimitedView.MySession.CreateAccount.Message")
        }
      />
    );
  }
  return <Sessions />;
};

export default SessionContainer;
