import { useEffect, useState } from "react";
import Language from "@src/views/booking/components/Language";
import {
  useGetLastCompletedConsultWithPsychologistQuery,
  useGetPsychologistByUrlHandleQuery,
  usePsychologistTagsQuery,
  useSchedulerBookConsultWithPsychologist,
} from "@src/queries/booking";
import authService from "@src/services/auth.service";
import TimeSlotPicker from "@src/views/booking/components/TimeSlotPicker";
import { TAG_TYPES } from "@src/utils/constants";
import BookingConfirmation from "@src/views/booking/components/BookingConfirmation";
import { useGetSessionTypes } from "@src/queries/common";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { IsGuestTwoFactorAuthenticationEnabled } from "@src/queries/twoFactorAuthentication";
import { useOutletContext, useParams } from "react-router-dom";
import { filterTagsOfType, getConsultType } from "@src/utils/helpers";
import SessionTypeCard from "@openup/shared/components/Cards/SessionTypeCard/SessionTypeCard";
import { SessionTypeCardImage } from "@src/views/booking/introductory/components/SessionTypeCardImage";
import { ArrowForward } from "@mui/icons-material";
import PhoneNumberComponentContainer from "@src/views/booking/components/PhoneNumberComponentContainer";
import ValidateLastConsult from "../components/ValidateLastConsult";

const STEPS = {
  SESSION_TYPE: "route.booking.sessionType",
  OTHER_SESSION_TYPES: "route.booking.otherSessionType",
  LANGUAGE: "route.booking.language",
  TIMESLOTS: "route.booking.timeslots",
  PHONE_NUMBER: "route.booking.phoneNumber",
  CONFIRMATION: "route.booking.confirmation",
};

const LoggedPersonalFollowUpFlow = () => {
  const currentUser = authService.getUserFromStorage();
  const { urlHandle } = useParams();
  const { t } = useTranslatedNavigate();
  const {
    step,
    nextStep,
    goToStep,
    addStepToSkip,
    removeStepToSkip,
    setPreventGoingBack,
    setupFlowSteps,
  } = useOutletContext();

  const [language, setLanguage] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState();
  const [consult, setConsult] = useState();
  const [sessionType, setSessionType] = useState();
  const [presetLanguages, setPresetLanguages] = useState();

  const { data: psychologist } = useGetPsychologistByUrlHandleQuery(urlHandle);
  const { data: lastConsult } = useGetLastCompletedConsultWithPsychologistQuery(
    psychologist.id,
  );
  const { data: psychologistTags } = usePsychologistTagsQuery(psychologist.id);
  const { data: allAvailableSessionTypes } = useGetSessionTypes();

  const previousSessionType = getConsultType(lastConsult);
  const otherSessionTypes = allAvailableSessionTypes
    ?.filter(
      (allSessionTypes) =>
        allSessionTypes.name.toLowerCase() !== previousSessionType,
    )
    .map((sessionTypeTag) => sessionTypeTag.name);

  const { mutateAsync: bookConsultQuery } =
    useSchedulerBookConsultWithPsychologist();

  useEffect(() => {
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (step === STEPS.CONFIRMATION) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  useEffect(() => {
    setSessionType(getConsultType(lastConsult));
    setPresetLanguages(
      filterTagsOfType(psychologistTags, TAG_TYPES.PSYCHOLOGIST_LANGUAGE),
    );
  }, [lastConsult, psychologistTags]);

  const bookConsult = () => {
    const tags = [
      {
        tagName: sessionType,
        tagType: TAG_TYPES.SESSION_TYPE,
      },
      {
        tagName: language,
        tagType: TAG_TYPES.SESSION_LANGUAGE,
      },
    ];
    return bookConsultQuery({
      time: selectedDateTime.startTime,
      timezone: selectedDateTime.timezone,
      tags,
      psychologistId: psychologist.id,
    });
  };

  const selectStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.SESSION_TYPE:
        return (
          <ValidateLastConsult
            nextStepFollowUpSession={() => {
              if (lastConsult.consultLanguage) {
                setLanguage(lastConsult.consultLanguage);
                addStepToSkip(STEPS.LANGUAGE);
                addStepToSkip(STEPS.OTHER_SESSION_TYPES);
                goToStep(STEPS.TIMESLOTS);
              } else {
                addStepToSkip(STEPS.OTHER_SESSION_TYPES);
                removeStepToSkip(STEPS.LANGUAGE);
                goToStep(STEPS.LANGUAGE);
              }
            }}
            moveToNextStep={() => {
              removeStepToSkip(STEPS.OTHER_SESSION_TYPES);
              removeStepToSkip(STEPS.LANGUAGE);
              nextStep();
            }}
            lastConsult={lastConsult}
            setSessionType={setSessionType}
          />
        );

      case STEPS.OTHER_SESSION_TYPES:
        return (
          <div className="container mx-auto mb-4">
            <p className="mt-4 md:mt-8 md:mb-12 text-center font-serif font-black text-4xl md:text-5xl md:leading-tight text-indigo-800 w-11/12 md:w-8/12 max-w-3xl mx-auto">
              {t("SessionType.Followup.OtherSessionType.Title")}
            </p>
            <div className="flex flex-wrap w-11/12 mx-auto justify-center items-center gap-8 md:gap-6">
              {otherSessionTypes?.map((sessionTypeName) => (
                <div data-cy="session-type-card" key={sessionTypeName}>
                  <SessionTypeCard
                    onClick={() => {
                      setSessionType(sessionTypeName);
                      if (lastConsult.consultLanguage) {
                        setLanguage(lastConsult.consultLanguage);
                        addStepToSkip(STEPS.LANGUAGE);
                        goToStep(STEPS.TIMESLOTS);
                      } else {
                        removeStepToSkip(STEPS.LANGUAGE);
                        goToStep(STEPS.LANGUAGE);
                      }
                    }}
                    key={sessionTypeName}
                    cardTitle={t(
                      `Booking.SessionType.${sessionTypeName}.Description`,
                    )}
                    cardSubtitle={t(
                      `Booking.SessionType.${sessionTypeName}.Details`,
                    )}
                    icon={<ArrowForward className="text-blue-500" />}
                    image={
                      <SessionTypeCardImage sessionType={sessionTypeName} />
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        );

      case STEPS.LANGUAGE:
        return (
          <Language
            moveToNextStep={nextStep}
            setLanguage={setLanguage}
            firstname={lastConsult.employeeFullName.split(" ")[0]}
            sessionType={sessionType}
            presetLanguages={presetLanguages}
          />
        );

      case STEPS.TIMESLOTS:
        return (
          <TimeSlotPicker
            moveToNextStep={async () => {
              if (
                !!currentUser.hasValidPhoneNumber ||
                IsGuestTwoFactorAuthenticationEnabled(currentUser)
              ) {
                // skipping the next step
                const bookedConsult = await bookConsult();
                setConsult(bookedConsult);
                goToStep(STEPS.CONFIRMATION);
              } else {
                nextStep();
              }
            }}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
            sessionType={sessionType}
            language={language}
            psychologistId={psychologist.id}
          />
        );

      case STEPS.PHONE_NUMBER:
        // this step is skipped when the existing phone number is already valid, or when the user has already enabled 2FA (using any mechanism)
        return (
          <PhoneNumberComponentContainer
            moveToNextStep={async () => {
              const bookedConsult = await bookConsult();
              setConsult(bookedConsult);
              nextStep();
            }}
          />
        );

      case STEPS.CONFIRMATION:
        setPreventGoingBack(true);
        return <BookingConfirmation consult={consult} />;

      default:
        return null;
    }
  };

  return <>{selectStep(step)}</>;
};

export default LoggedPersonalFollowUpFlow;
