import { Container, LinearProgress, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LanguageSelector from "@src/components/LanguageSelector";
import { isMobileAppView } from "@src/utils/mobileHelper";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import Logo from "@src/components/Logo";

const useStyles = makeStyles(
  ({ breakpoints, colors, spacing, palette, noPercentage }) => ({
    header: () => ({
      position: "relative",
      maxWidth: 776,
      paddingTop: noPercentage ? 52 : 44,
      [breakpoints.up("sm")]: {
        paddingTop: noPercentage ? 52 : 44,
      },
    }),
    backButton: {
      position: "absolute",
      left: spacing(2),
      marginTop: 7,
      top: "50%",
      transform: "translateY(-50%)",
      minWidth: "auto",
      width: 48,
      height: 48,
      padding: 0,
      overflow: "hidden",
      backgroundColor: colors.primarySkin,
      [breakpoints.down("sm")]: {
        ".signupMethodPageContainer &": {
          backgroundColor: "rgba(51, 51, 51, 0.1) !important",
        },
      },
      [breakpoints.up("sm")]: () => ({
        marginTop: noPercentage ? 0 : 18,
      }),
      "&:hover": {
        backgroundColor: colors.primarySkin,
      },
    },
    backIcon: {
      position: "relative",
      left: 4,
      color: palette.primary.main,
      height: 18,
      [breakpoints.down("sm")]: {
        ".signupMethodPageContainer &": {
          color: colors.trueWhite,
        },
      },
    },
    logo: {
      height: 20,
      width: 100,
      [breakpoints.up("sm")]: {
        height: 28,
        width: 140,
      },
    },
    progressBar: {
      marginLeft: 68,
      marginRight: 96,
      [breakpoints.down("sm")]: {
        ".signupMethodPageContainer &": {
          backgroundColor: "rgba(255, 255, 255, 0.25) !important",
        },
        ".signupMethodPageContainer & > div": {
          backgroundColor: `${colors.trueWhite} !important`,
        },
      },
    },
    progressBarWide: {
      marginLeft: 0,
    },
    languageSelector: {
      position: "absolute",
      right: spacing(2),
      marginTop: 7,
      top: "50%",
      transform: "translateY(-50%)",
      "&:hover": {
        backgroundColor: colors.primarySkin,
      },
      [breakpoints.up("sm")]: () => ({
        marginTop: noPercentage ? 0 : 18,
      }),
      [breakpoints.down("sm")]: {
        ".signupMethodPageContainer &": {
          backgroundColor: "rgba(51, 51, 51, 0.1) !important",
          color: colors.trueWhite,
        },
        ".signupMethodPageContainer & > div": {
          backgroundColor: "transparent !important",
          color: colors.trueWhite,
        },
        ".signupMethodPageContainer & a": {
          color: colors.trueWhite,
        },
      },
    },
  }),
);

const OnboardingLayoutTopBar = ({ onBackButtonClick, percentage }) => {
  const classes = useStyles({ noPercentage: percentage === undefined });

  return (
    <Container className={classes.header}>
      {onBackButtonClick && (
        <Button className={classes.backButton} onClick={onBackButtonClick}>
          <ArrowBackIcon className={classes.backIcon} />
        </Button>
      )}
      {percentage === undefined ? (
        <a href="/" aria-label="OpenUp">
          <Logo className={classes.logo} />
        </a>
      ) : (
        <LinearProgress
          variant="determinate"
          value={percentage}
          className={classes.progressBar}
        />
      )}
      {!isMobileAppView() && (
        <LanguageSelector className={classes.languageSelector} />
      )}
    </Container>
  );
};

export default OnboardingLayoutTopBar;
