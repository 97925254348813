import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  IconButton,
  Toolbar,
  Hidden,
  Typography,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import OpenUpMenu from "@src/components/OpenUpMenu";
import authService from "@src/services/auth.service";
import Logo from "@src/components/Logo";
import LanguageSelector from "@src/components/LanguageSelector";
import LetsOpenUpCTA from "@src/components/LetsOpenUpCTA";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { isActivateTwoFactorAuthNotificationVisible } from "@src/queries/twoFactorAuthentication";

const useStyles = makeStyles(({ colors, breakpoints, spacing }) => ({
  root: {
    background: "white",
    [breakpoints.up("sm")]: {
      height: 80,
    },
    [breakpoints.down("md")]: {
      height: 64,
    },
    display: "flex",
    alignItems: "center",
    position: "sticky",
    boxShadow: "0px 0px 20px rgba(51, 51, 51, 0.1)",
  },
  toolbar: {
    width: "100%",
    height: "100%",
  },
  container: {
    [breakpoints.down("md")]: {
      paddingLeft: spacing(1),
    },
    [breakpoints.up("md")]: {
      paddingLeft: spacing(2.5),
    },
    paddingBottom: 0,
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    maxHeight: 28,
    height: 28,
    width: "auto",
    marginTop: spacing(1),
  },
  homeLogo: {
    display: "flex",
    alignItems: "center",
  },
  desktopMenu: {
    display: "flex",
    alignItems: "center",
  },
  desktopMenuAccountLink: {
    color: colors.primaryDarkBlue,
    marginRight: spacing(2),
  },
  languageSelector: {
    background: "none",
  },
  cta: {
    marginLeft: spacing(2),
  },
  mobileMenuIcon: {
    color: colors.primaryGreenDark,
  },
}));

const DashboardTopBar = ({ className, onMobileNavOpen, ...restProps }) => {
  const { t, pathT } = useTranslatedNavigate();
  const classes = useStyles();
  const currentUser = authService.getUserFromStorage();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    >
      <Toolbar className={`${classes.toolbar} px-0`}>
        <Container className={classes.container}>
          <RouterLink to={pathT("route.account")} className={classes.homeLogo}>
            <Logo className={classes.logo} showLanguageSpecificIcon />
          </RouterLink>
          <>
            <Hidden lgDown>
              <div className={classes.desktopMenu}>
                <Badge
                  // hide badge when two factor authentication is already enabled, or if it is a guest user
                  badgeContent={" "}
                  invisible={
                    !isActivateTwoFactorAuthNotificationVisible(currentUser)
                  }
                  variant="dot"
                  color="error"
                  overlap="circular"
                >
                  <RouterLink
                    className={classes.desktopMenuAccountLink}
                    to={pathT("route.profile")}
                  >
                    <Typography style={{ paddingTop: 2 }}>
                      {t("SharedStrings.Account")}
                    </Typography>
                  </RouterLink>
                </Badge>
                <LanguageSelector classNameSelect={classes.languageSelector} />
                <LetsOpenUpCTA className={classes.cta} />
              </div>
            </Hidden>
            <Hidden lgUp>
              <IconButton
                onClick={onMobileNavOpen}
                className={classes.mobileMenuIcon}
                edge="end"
                size="large"
              >
                <OpenUpMenu />
              </IconButton>
            </Hidden>
          </>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

DashboardTopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default DashboardTopBar;
