import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TypographyWithIcon from "./TypographyWithIcon";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(4, 3),
    backgroundColor: "#F9F2E7",
    borderRadius: "16px",
  },
}));
const ThemeExternalLinkTile = ({ title, url, text, iconLigature }) => {
  const classes = useStyles();
  return (
    <a href={url}>
      <Box className={classes.root}>
        <TypographyWithIcon mb={2} iconLigature={iconLigature}>
          {title}
        </TypographyWithIcon>
        <Typography variant="body1">{text}</Typography>
      </Box>
    </a>
  );
};

export default ThemeExternalLinkTile;
