import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { ReactComponent as ManShovel } from "@src/resources/images/man_shovel.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { TAG_TYPES } from "@src/utils/constants";
import {
  useSchedulerBookNonConfirmedConsult,
  useSchedulerBookNonConfirmedConsultWithPsychologist,
} from "@src/queries/booking";
import BookingContainer from "../../components/BookingContainer";

const useStyles = makeStyles((theme) => ({
  image: {
    height: 150,
    width: 105,
    [theme.breakpoints.down("sm")]: {
      width: 82,
      height: 118,
      marginRight: theme.spacing(2),
    },
  },
  buttonConfirmSmallScreen: {
    width: "100%",
  },
  buttonConfirm: {
    width: 240,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
    },
  },
  confirmText: {
    opacity: 1,

    [theme.breakpoints.down("sm")]: {
      maxWidth: "none",
    },
  },
  card: {
    boxShadow: theme.customShadows.bookingContainerBackgroundShadow,
    marginBottom: theme.spacing(8.5),
  },
  cardContent: {
    flex: 1,
    paddingBottom: 0,
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 2),
      paddingBottom: 0,
    },
  },
  cardImage: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3),
  },
  textField: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "85%",
    },
  },
  cardActions: {
    padding: theme.spacing(3),
  },
  cardActionsLargeScreen: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      right: 0,
      backgroundColor: "#ffffff",
      padding: theme.spacing(2),
      boxShadow: theme.customShadows.cardActionBackgroundShadow,
      width: "100%",
      justifyContent: "center",
    },
  },
}));

const EmailConfirmation = ({
  moveToNextStep,
  sessionType,
  selectedDateTime,
  language,
  psychologist,
}) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const sessionTypeTagName = sessionType;
  const sessionTypeTagTypeName = TAG_TYPES.SESSION_TYPE;
  const languageTagName = language;
  const languageTypeTagTypeName = TAG_TYPES.SESSION_LANGUAGE;
  const tags = [
    {
      tagName: sessionTypeTagName,
      tagType: sessionTypeTagTypeName,
    },
    {
      tagName: languageTagName,
      tagType: languageTypeTagTypeName,
    },
  ];

  const { mutateAsync: bookConsult } = useSchedulerBookNonConfirmedConsult();
  const { mutateAsync: bookConsultWithPsychologist } =
    useSchedulerBookNonConfirmedConsultWithPsychologist();
  const OnSubmit = (fields, { setStatus, setSubmitting }) => {
    const { email } = fields;
    if (psychologist) {
      bookConsultWithPsychologist({
        email,
        time: selectedDateTime.startTime,
        timezone: selectedDateTime.timezone,
        tags,
        psychologistId: psychologist.id,
      });
    } else {
      bookConsult({
        email,
        time: selectedDateTime.startTime,
        timezone: selectedDateTime.timezone,
        tags,
      });
    }
    setStatus();
    setSubmitting(true);
    moveToNextStep(fields.email);
    setSubmitting(false);
  };

  return (
    <BookingContainer title={t("Booking.EmailConfirmation.Title")}>
      <Formik
        initialValues={{
          email: "",
          acceptTerms: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("Validations.InvalidEmail"))
            .required(t("Validations.RequiredField")),
        })}
        onSubmit={OnSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="subtitle1" className={classes.confirmText}>
                  {t("Booking.EmailConfirmation.ConfirmText")}
                </Typography>
                <TextField
                  className={classes.textField}
                  error={Boolean(touched.email && errors.email)}
                  helperText={(touched.email && errors.email) || " "}
                  label={t("Booking.EmailConfirmation.Email")}
                  name="email"
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="standard"
                />
              </CardContent>

              {isSmallScreen ? (
                <>
                  <CardMedia className={classes.cardImage}>
                    <ManShovel className={classes.image} />
                  </CardMedia>
                  <CardActions className={classes.cardActionsLargeScreen}>
                    <Button
                      disabled={isSubmitting || !values.email}
                      className={classes.buttonConfirmSmallScreen}
                      type="submit"
                    >
                      {t("Booking.EmailConfirmation.ConfirmBookingButton")}
                    </Button>
                  </CardActions>
                </>
              ) : (
                <Box display="flex">
                  <CardActions className={classes.cardActions}>
                    <Button
                      disabled={isSubmitting || !values.email}
                      className={classes.buttonConfirm}
                      type="submit"
                    >
                      {t("Booking.EmailConfirmation.ConfirmBookingButton")}
                    </Button>
                  </CardActions>
                  <CardMedia className={classes.cardImage}>
                    <ManShovel className={classes.image} />
                  </CardMedia>
                </Box>
              )}
            </Card>
          </form>
        )}
      </Formik>
    </BookingContainer>
  );
};

export default EmailConfirmation;
