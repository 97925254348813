import { useState, useEffect } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { TAG_TYPES } from "@src/utils/constants";
import EmailConfirmation from "@src/views/booking/introductory/components/EmailConfirmation";
import MagicLink from "@src/views/booking/components/MagicLink";
import { useOutletContext } from "react-router-dom";
import TimeSlotPicker from "../components/TimeSlotPicker";
import Language from "../components/Language";
import SessionType from "../introductory/components/SessionType";

const STEPS = {
  SESSION_TYPE: "route.booking.sessionType",
  LANGUAGE: "route.booking.language",
  TIMESLOTS: "route.booking.timeslots",
  EMAIL: "route.booking.email",
  MAGIC_LINK: "route.booking.magicLink",
};

const NonLoggedPersonalLinkFlow = ({ psychologist }) => {
  const { step, nextStep, setPreventGoingBack, setupFlowSteps } =
    useOutletContext();
  const { t } = useTranslatedNavigate();

  const [email, setEmail] = useState("");
  const [sessionType, setSessionType] = useState();
  const [language, setLanguage] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState();

  useEffect(() => {
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (step === STEPS.MAGIC_LINK) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  const selectLoggedInStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.SESSION_TYPE:
        return (
          <SessionType
            moveToNextStep={nextStep}
            setSessionType={setSessionType}
            psychologist={psychologist}
          />
        );
      case STEPS.LANGUAGE:
        return (
          <Language
            moveToNextStep={nextStep}
            setLanguage={setLanguage}
            firstname={psychologist.firstName}
            presetLanguages={psychologist.tags.filter(
              (tag) =>
                tag.tagType.toLowerCase() ===
                TAG_TYPES.PSYCHOLOGIST_LANGUAGE.toLowerCase(),
            )}
            sessionType={sessionType}
          />
        );
      case STEPS.TIMESLOTS:
        return (
          <TimeSlotPicker
            moveToNextStep={nextStep}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
            sessionType={sessionType}
            language={language}
            psychologistId={psychologist.id}
          />
        );
      case STEPS.EMAIL:
        return (
          <EmailConfirmation
            moveToNextStep={(newEmail) => {
              setEmail(newEmail);
              nextStep();
            }}
            selectedDateTime={selectedDateTime}
            sessionType={sessionType}
            language={language}
            psychologist={psychologist}
          />
        );
      case STEPS.MAGIC_LINK:
        return (
          <MagicLink
            email={email}
            title={t("Booking.MagicLinkPage.PersonalLink.Title")}
            body={t("Booking.MagicLinkPage.PersonalLink.ConfirmText")}
          />
        );
      default:
        return null;
    }
  };

  return <>{selectLoggedInStep(step)}</>;
};

export default NonLoggedPersonalLinkFlow;
