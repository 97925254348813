import { useState, useEffect } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useOutletContext, useParams } from "react-router-dom";
import { useGetPsychologistByUrlHandleQuery } from "@src/queries/booking";
import MagicLink from "../../components/MagicLink";
import DiggingInformation from "../components/DiggingInformation";

const STEPS = {
  EMAIL: "route.booking.email",
  MAGIC_LINK: "route.booking.magicLink",
};

const NonLoggedPersonalFollowUpFlow = () => {
  const { urlHandle } = useParams();
  const { t } = useTranslatedNavigate();

  const { step, nextStep, setPreventGoingBack, setupFlowSteps } =
    useOutletContext();
  const [email, setEmail] = useState("");
  const { data: psychologist } = useGetPsychologistByUrlHandleQuery(urlHandle);

  useEffect(() => {
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (step === STEPS.MAGIC_LINK) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  const selectNonLoggedInStep = (newStep) => {
    switch (newStep) {
      case STEPS.EMAIL:
        return (
          <DiggingInformation
            moveToNextStep={(newEmail) => {
              setEmail(newEmail);
              nextStep();
            }}
            personalUrlHandle={psychologist.personalUrlHandle}
          />
        );
      case STEPS.MAGIC_LINK:
        setPreventGoingBack(true);
        return (
          <MagicLink
            email={email}
            title={t("Booking.MagicLinkPage.FollowUp.Title")}
            body={t("Booking.MagicLinkPage.FollowUp.ConfirmText")}
          />
        );
      default:
        return null;
    }
  };

  return <>{selectNonLoggedInStep(step)}</>;
};

export default NonLoggedPersonalFollowUpFlow;
