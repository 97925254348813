import { Box, Typography } from "@mui/material";
import colors from "@src/theme/colors";
import { makeStyles } from "@mui/styles";

import { useTranslation } from "react-i18next";
import BottomLeftCornerImage from "./bottom-left-corner-background.svg";
import TopRightCornerImage from "./top-right-corner-background.svg";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    background: `url(${BottomLeftCornerImage}) bottom left no-repeat, url(${TopRightCornerImage}) top right no-repeat`,
    backgroundColor: colors.primaryGreen,
    borderRadius: 8,
    padding: spacing(6, 6, 7, 6),
  },
  text: {
    color: "white",
    fontSize: 35,
    marginBottom: spacing(2),
  },
  name: {
    color: "white",
    fontSize: 14,
  },
  avatar: ({ avatarSize }) => ({
    borderRadius: "50%",
    height: avatarSize,
    width: avatarSize,
    border: "4px solid white",
  }),
}));

const ThemeTestimonial = ({ avatarSize = 200, imageSrc, text, name }) => {
  const classes = useStyles({ avatarSize });
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <img
        src={imageSrc}
        className={classes.avatar}
        alt={t("ThemeViewEducation.Testimonial.ImageAlt")}
      />
      <Box display="flex" flexDirection="column" pl={6}>
        <Typography className={classes.text} variant="h2" align="center">
          {text}
        </Typography>
        <Typography variant="subtitle1" align="center" className={classes.name}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default ThemeTestimonial;
