import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { SESSION_TYPE, CONSULT_TYPE, STATUS } from "@src/utils/constants";
import NoMySessions from "@src/views/account/mySessions/components/NoMySessions";
import { Box, Button, Typography, useMediaQuery, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useGetConsultSpaces } from "@src/queries/spaceConsults";
import { useMemo, useState } from "react";
import SessionsViewTable from "./SessionsViewTable";

const pastConsultStatuses = [STATUS.COMPLETED];
const validConsultTypes = [
  CONSULT_TYPE.GENERAL,
  CONSULT_TYPE.MINDFULNESS,
  CONSULT_TYPE.PHYSICAL_WELLBEING,
];

const useStyle = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  titleBox: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(6),
      justifyContent: "space-between",
    },
  },
  button: {
    width: 170,
    height: 48,
    letterSpacing: 1.25,
  },
  bookSpaceBtn: {
    width: 170,
    height: 48,
    letterSpacing: 1.25,
    marginRight: theme.spacing(1),
    backgroundColor: theme.colors.highlightedBackground,
    color: theme.colors.primaryDarkBlue,
    border: "solid rgba(0, 0, 0, 0.05)",
    borderColor: theme.colors.primaryDarkBlueWithTransparency,
    "&:hover": {
      backgroundColor: theme.colors.highlightedBackground,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
    },
  },
}));

const Sessions = () => {
  const { pathT, t } = useTranslatedNavigate();
  const classes = useStyle();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const [currentPageUpcomingSessions, setCurrentPageUpcomingSessions] =
    useState(0);
  const [currentPagePastSessions, setCurrentPagePastSessions] = useState(0);

  const {
    data: upcomingSessions,
    fetchNextPage: fetchNextPageUpcomingSessions,
    fetchPreviousPage: fetchPreviousPageUpcomingSessions,
    isFetching: isFetchingUpcomingSessions,
    isFetchingNextPage: isFetchingUpcomingSessionsNextPage,
  } = useGetConsultSpaces({ isPastSession: false });
  const {
    data: pastSessions,
    fetchNextPage: fetchNextPagePastSessions,
    fetchPreviousPage: fetchPreviousPagePastSessions,
    isFetching: isFetchingPastSessions,
    isFetchingNextPage: isFetchingPastSessionsNextPage,
  } = useGetConsultSpaces({ isPastSession: true });

  const getPageUpcomingSessions = (newPage) => {
    if (!Number.isInteger(newPage)) return;
    if (newPage < currentPageUpcomingSessions)
      fetchPreviousPageUpcomingSessions();
    else fetchNextPageUpcomingSessions();
    setCurrentPageUpcomingSessions(newPage);
  };

  const getPagePastSessions = (newPage) => {
    if (!Number.isInteger(newPage)) return;
    if (newPage < currentPagePastSessions) fetchPreviousPagePastSessions();
    else fetchNextPagePastSessions();
    setCurrentPagePastSessions(newPage);
  };

  const allUpcomingSessions = useMemo(
    () =>
      upcomingSessions?.pages
        .flatMap((page) => page.data)
        .filter(
          (upcomingSession) =>
            // All spaces allowed, and only upcomingSessionStatuses for consults
            upcomingSession.consultOrSpace === SESSION_TYPE.SPACE ||
            validConsultTypes.includes(upcomingSession.type),
        ),
    [upcomingSessions],
  );
  const upcomingSessionsPaginationData = useMemo(
    () =>
      upcomingSessions?.pages
        .flatMap((page) => page.pagination)
        .find(() => true),
    [upcomingSessions],
  );

  const allPastSessions = useMemo(
    () =>
      pastSessions?.pages
        .flatMap((page) => page.data)
        .filter(
          // All spaces allowed, and only upcomingSessionStatuses for consults
          (pastSession) =>
            pastSession.consultOrSpace === SESSION_TYPE.SPACE ||
            (pastConsultStatuses.includes(pastSession.status) &&
              validConsultTypes.includes(pastSession.type)),
        ),
    [pastSessions],
  );
  const pastSessionsPaginationData = useMemo(
    () =>
      pastSessions?.pages.flatMap((page) => page.pagination).find(() => true),
    [pastSessions],
  );

  const bookSessionButton = () => (
    <Button
      classes={{ root: classes.button }}
      onClick={() => navigate(pathT("route.booking"), { replace: true })}
    >
      {t("Psychologist.Book")}
    </Button>
  );

  const bookSpaceButton = () => (
    <Link
      data-cy="book-space"
      href={t("route.website.spaces")}
      target="_blank"
      underline="hover"
    >
      <Button classes={{ root: classes.bookSpaceBtn }}>
        {t("Spaces.Book")}
      </Button>
    </Link>
  );

  return (
    <>
      <Box className={classes.titleBox}>
        <Typography className={classes.title} variant="h1">
          {t("MySessionsView.Title")}
        </Typography>
        <Box>
          {!isSmallScreen &&
            allUpcomingSessions?.length !== 0 &&
            bookSpaceButton()}
          {!isSmallScreen &&
            allUpcomingSessions?.length !== 0 &&
            bookSessionButton()}
        </Box>
      </Box>

      {allUpcomingSessions?.length === 0 ? (
        <NoMySessions
          hasPastCompleted={allPastSessions.some(
            (session) => session.status === STATUS.COMPLETED,
          )}
        />
      ) : (
        <>
          {isSmallScreen && bookSessionButton()}
          {isSmallScreen && bookSpaceButton()}
          <div data-cy="upcoming-sessions">
            <SessionsViewTable
              titleText={t("MySessionsView.UpcomingSessions.Title")}
              isFetching={
                isFetchingUpcomingSessions || isFetchingUpcomingSessionsNextPage
              }
              allSessions={allUpcomingSessions}
              currentPage={currentPageUpcomingSessions}
              setCurrentPage={(newPage) => getPageUpcomingSessions(newPage)}
              paginationData={upcomingSessionsPaginationData}
            />
          </div>
        </>
      )}
      {allPastSessions?.length > 0 && (
        <div data-cy="past-sessions">
          <SessionsViewTable
            titleText={t("MySessionsView.PastSessions.Title")}
            isFetching={
              isFetchingPastSessions || isFetchingPastSessionsNextPage
            }
            allSessions={allPastSessions}
            currentPage={currentPagePastSessions}
            setCurrentPage={(newPage) => getPagePastSessions(newPage)}
            paginationData={pastSessionsPaginationData}
            isPastSessions
          />
        </div>
      )}
    </>
  );
};

export default Sessions;
