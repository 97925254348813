import axios from "axios";

import { useQuery } from "react-query";
import {
  PhoneTwoFactorCodeRequestMethod,
  AuthenticatorTwoFactorCodeRequestMethod,
  RESPONSE_STATUS,
  apiPaths,
} from "@src/utils/constants";
import { isGuestUser } from "@src/utils/helpers";

const identifier = "twoFactorAuthentication";

/**
 * Helper method to decide if the 2FA notification should be visible for this user
 * It should not be visible for guest users, and should not be visible for users who have activated 2FA
 * @param {object} currentUser - user object
 * @return {bool} True is 2FA notification should be visible, false otherwise
 */
export const get2FAAuthenticationPreference = (userTokenProviders) => {
  if (!userTokenProviders) {
    return "";
  }
  if (userTokenProviders.includes(PhoneTwoFactorCodeRequestMethod))
    return PhoneTwoFactorCodeRequestMethod;
  if (userTokenProviders.includes(AuthenticatorTwoFactorCodeRequestMethod))
    return AuthenticatorTwoFactorCodeRequestMethod;
  return "";
};

/**
 * Helper method to check if the user has enabled phone 2FA
 * @param {object} currentUser - user object
 * @return {bool} True is 2FA notification should be visible, false otherwise
 */
export const isPhoneTwoFactorAuthenticationEnabled = (currentUser) => {
  if (!currentUser) {
    return false;
  }
  if (
    currentUser?.twoFactorEnabled &&
    currentUser?.userTokenProviders &&
    currentUser?.phoneNumberConfirmed &&
    currentUser?.userTokenProviders.includes(PhoneTwoFactorCodeRequestMethod)
  ) {
    return true;
  }
  return false;
};

/**
 * Helper method to check if the user has enabled phone 2FA
 * @param {object} currentUser - user object
 * @return {bool} True is 2FA notification should be visible, false otherwise
 */
export const IsGuestTwoFactorAuthenticationEnabled = (currentUser) =>
  currentUser?.userTokenProviders?.length > 0;

/**
 * Helper method to decide if the 2FA notification should be visible for this user
 * It should not be visible for guest users, and should not be visible for users who have activated 2FA
 * @param {object} currentUser - user object
 * @return {bool} True is 2FA notification should be visible, false otherwise
 */
export const isActivateTwoFactorAuthNotificationVisible = (currentUser) => {
  if (!currentUser) {
    return false;
  }
  if (!currentUser?.isRegistered) {
    return false;
  }
  if (
    !isPhoneTwoFactorAuthenticationEnabled(currentUser) &&
    !isGuestUser(currentUser)
  ) {
    return true;
  }
  return false;
};

// triggers a request to send a 2FA code via SMS to the user's phone number
// and recieves an object indicating the number of 2FA codes pending and a countdown until the next code
export const getTwoFactorAuthenticationCode = async () => {
  try {
    const { data } = await axios.post(apiPaths.twoFactorAuthenticationGenerate);
    return data;
  } catch (error) {
    return error;
  }
};

// send a request to verify user 2FA token and log the user in
export const verifyTwoFactorAuthenticationCode = async (code, method) => {
  try {
    const response = await axios.post(
      apiPaths.twoFactorAuthenticationValidate,
      {
        code,
        method,
      },
    );
    return response;
  } catch (error) {
    return error;
  }
};

// send a request to verify user 2FA token and activate/deactivate 2FA for the user
export const verifyAndToggleTwoFactorAuthentication = async (code, enable) => {
  try {
    const response = await axios.patch(apiPaths.twoFactorAuthenticationToggle, {
      enable,
      code,
      method: PhoneTwoFactorCodeRequestMethod, // from this project, only phone 2FA can be enabled or disabled
    });
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * @summary Sends a request to send an SMS to the user,
 * and it responds with a number of seconds before which the user can make
 * this request again
 * @return {integer} Time in seconds remaining
 */
export const useRequestTwoFactorCode = ({
  errorCallback = (_errorMessage) => {},
  errorCallbackSnackbar = (_errorMessage) => {},
  disabled = false,
}) =>
  useQuery([identifier, "request"], () => getTwoFactorAuthenticationCode(), {
    enabled: !disabled,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 2000,
    onSuccess: (response) => {
      if (
        response.response &&
        response.response.status === RESPONSE_STATUS.UNAUTHORISED
      )
        errorCallbackSnackbar(response.response.data);
      return response;
    },
    onError: (error) => {
      errorCallback(error);
    },
  });

export const TWO_FACTOR_OPTIONS = {
  LOGIN: "login",
  TOGGLE: "toggle",
};

/**
 * @summary Sends a request to send an SMS to the user,
 * and it responds with a number of seconds before which the user can make
 * this request again.
 * If the user is toggling 2FA on/off, on the web project
 * they can only toggle phone 2FA on/off
 * @return {integer} Time in seconds remaining
 */
export const useVerifyTwoFactorCode = ({
  code,
  method,
  enable = true,
  purpose = TWO_FACTOR_OPTIONS.LOGIN,
}) =>
  useQuery(
    [identifier, "verify"],
    () => {
      switch (purpose) {
        case TWO_FACTOR_OPTIONS.TOGGLE:
          return verifyAndToggleTwoFactorAuthentication(code, enable);
        case TWO_FACTOR_OPTIONS.LOGIN:
        default:
          return verifyTwoFactorAuthenticationCode(code, method);
      }
    },
    {
      enabled: false,
      retry: false,
      onSuccess: ({ response }) => {
        if (response?.status === RESPONSE_STATUS.BAD_REQUEST) {
          //
        }
      },
      onError: (error) => error,
    },
  );
