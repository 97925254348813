import { connect } from "react-redux";
import {
  Box,
  Container,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import Page from "@src/components/Page";
import Logo from "@src/components/Logo";
import LanguageSelector from "@src/components/LanguageSelector";
import { ReactComponent as CheckmarkSVG } from "@src/resources/images/checkmark.svg";
import { logout } from "@src/actions/userActions";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles(
  ({ breakpoints, colors, spacing, customShadows }) => ({
    containerWithBackgroundWaveElements: {
      minHeight: "100svh",
      backgroundRepeat: "no-repeat",
      [breakpoints.up("sm")]: {
        backgroundImage: `
        url('/images/onboarding/bg-human-bottom-right.png'),
        url('/images/onboarding/bg-top-right.svg'),
        url('/images/onboarding/bg-human-bottom-left.jpg')
      `,
        backgroundPosition: `right bottom ${spacing(
          12,
        )}, right top, left bottom`,
        backgroundSize: "304px, 288px, 470px",
      },
    },
    header: {
      position: "relative",
      maxWidth: 776,
      marginTop: 40,
      textAlign: "center",
      [breakpoints.up("sm")]: {
        marginTop: 45,
      },
    },
    logo: {
      height: 20,
      width: 100,
      [breakpoints.up("sm")]: {
        height: 28,
        width: 140,
      },
    },
    languageSelector: {
      position: "absolute",
      right: spacing(2),
      top: -15,
    },
    container: {
      maxWidth: 548,
      backgroundColor: colors.trueWhite,
      boxShadow: customShadows.bookingContainerBackgroundShadow,
      borderRadius: spacing(2),
      margin: spacing(0, 2, 4),
      padding: spacing(4, 0),
      [breakpoints.up("sm")]: {
        marginTop: spacing(2),
      },
    },
    content: {
      [breakpoints.down("sm")]: {
        padding: spacing(0, 2),
      },
      [breakpoints.up("sm")]: {
        padding: spacing(0, 4),
      },
    },
    heading: {
      textAlign: "center",
      lineHeight: "48px",
      [breakpoints.down("sm")]: {
        fontSize: "25px",
        letterSpacing: 0.25,
      },
      [breakpoints.up("sm")]: {
        fontSize: "35px",
      },
    },
    listItemIcon: {
      marginTop: 7,
      minWidth: "auto",
      marginRight: spacing(2),
      [breakpoints.up("sm")]: {
        marginTop: 3,
      },
    },
    listItemIconSVG: {
      [breakpoints.down("sm")]: {
        width: 24,
        height: 24,
      },
    },
    listItemText: {
      "& span": {
        fontWeight: 600,
        letterSpacing: 0.15,
        opacity: 1,
        fontSize: 16,
        lineHeight: "24px",
      },
      "& p": {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: 0.5,
      },
      [breakpoints.up("sm")]: {
        "& span": {
          fontSize: 16,
          lineHeight: "24px",
        },
        "& p": {
          fontSize: 16,
          lineHeight: "32px",
        },
      },
    },
    headingResults: {
      [breakpoints.down("sm")]: {
        width: 328,
      },
    },
    hr: {
      display: "none",
      [breakpoints.up("sm")]: {
        display: "block",
        backgroundColor: colors.separator,
        margin: spacing(3, 0),
      },
    },
    getStartedButton: {
      width: "100%",
      letterSpacing: "1.25px",
      [breakpoints.down("sm")]: {
        padding: "12px 30px",
        marginTop: spacing(2),
        marginBottom: spacing(2),
      },
      [breakpoints.up("sm")]: {
        padding: "12px 30px",
        marginTop: 0,
      },
      marginBottom: spacing(3),
    },
    bottomMessage: {
      [breakpoints.down("sm")]: {
        marginTop: spacing(-0.625),
      },
    },
    imageManWomanMobile: {
      marginTop: spacing(5),
      maxWidth: "100%",
      [breakpoints.up("sm")]: {
        display: "none",
      },
    },
  }),
);

const CustomListItem = ({ primary, secondary }) => {
  const classes = useStyles();

  return (
    <ListItem alignItems="flex-start" className={classes.listItem}>
      <ListItemIcon className={classes.listItemIcon}>
        <CheckmarkSVG className={classes.listItemIconSVG} />
      </ListItemIcon>
      <ListItemText
        primary={primary}
        secondary={secondary}
        className={classes.listItemText}
      />
    </ListItem>
  );
};

const WelcomePage = ({ moveToNextStep, logoutUser }) => {
  const { t, pathT } = useTranslatedNavigate();
  const classes = useStyles();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const handleLoginClick = () => {
    logoutUser();
    navigate(pathT("route.login"));
  };

  return (
    <div className={classes.containerWithBackgroundWaveElements}>
      <Page className={classes.root} title={t("HomeView.Title")}>
        <Grid container direction="column" alignItems="center" item>
          <Container className={classes.header}>
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
            <LanguageSelector className={classes.languageSelector} />
          </Container>
          <Box className={classes.container}>
            <Box className={classes.content}>
              <Typography
                variant={isSmallScreen ? "h3" : "h1"}
                className={classes.heading}
              >
                {t("OnBoarding.Welcome.ListTitle")} 🙌
              </Typography>
              <List>
                <CustomListItem
                  primary={t("OnBoarding.Welcome.ListItem1.Title")}
                  secondary={t("OnBoarding.Welcome.ListItem1.Text")}
                />
                <CustomListItem
                  primary={t("OnBoarding.Welcome.ListItem2.Title")}
                  secondary={t("OnBoarding.Welcome.ListItem2.Text")}
                />
              </List>
            </Box>
            <Divider className={classes.hr} />
            <Box className={classes.content}>
              <Box display="flex" justifyContent="center">
                <Button
                  className={classes.getStartedButton}
                  onClick={moveToNextStep}
                >
                  {t("HomeView.GetStarted")}
                </Button>
              </Box>
              <Typography
                color="textSecondary"
                variant="body2"
                className={classes.bottomMessage}
                align="center"
              >
                {t("HomeView.AlreadySignedUp")}{" "}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <span
                  // eslint-disable-next-line react/no-unknown-property
                  underline="always"
                  // eslint-disable-next-line react/no-unknown-property
                  component={RouterLink}
                  onClick={handleLoginClick}
                  style={{ cursor: "pointer" }}
                >
                  <b>
                    <u>{t("HomeView.Login")}</u>
                  </b>
                </span>
              </Typography>
              <img
                className={classes.imageManWomanMobile}
                alt=""
                src="/images/onboarding/home-mobile.jpg"
              />
            </Box>
          </Box>
        </Grid>
      </Page>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => {
    dispatch(logout());
  },
});

export default connect(null, mapDispatchToProps)(WelcomePage);
