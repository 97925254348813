import { useEffect } from "react";

import { isAndroid, isIOS } from "react-device-detect";
import { env } from "@src/env";

const GoToMobileAppStore = () => {
  useEffect(() => {
    if (isAndroid) {
      window.location.href = env.REACT_APP_ANDROID_STORE_URL;
    } else if (isIOS) {
      window.location.href = env.REACT_APP_APPLE_STORE_URL;
    } else {
      window.location.href = env.REACT_APP_APPLE_STORE_URL;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  return <div />;
};

export default GoToMobileAppStore;
