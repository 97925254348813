import { useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";
import Page from "@src/components/Page";
import { useDispatch } from "react-redux";
import Loader from "@src/components/Loader";
import { verifyEmail } from "@src/actions/userActions";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { timeSnackbar } from "@src/utils/constants";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { useEffect } from "react";

const EmailActivateView = ({ ...props }) => {
  const { t, pathT } = useTranslatedNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const email = urlParams.get("email").replace(/ /g, "+");

    (async () => {
      try {
        await dispatch(verifyEmail(code, email));
        props.snackbarShowMessage("", "success");
        setTimeout(() => {
          navigate(pathT("route.login"), { replace: true });
        }, timeSnackbar);
      } catch (error) {
        if (error.response.status === 410) {
          props.snackbarShowMessage(error.response.data, "warning");
          setTimeout(() => {
            navigate(`${pathT("route.emailLinkExpired")}?email=${email}`);
          }, timeSnackbar);
        } else if (error.response.status === 400) {
          props.snackbarShowMessage(error.response.data, "warning");
          setTimeout(() => {
            navigate(`${pathT("route.login")}`);
          }, timeSnackbar);
        } else if (error.response.status === 409) {
          props.snackbarShowMessage(error.response.data, "warning");
          setTimeout(() => {
            navigate(`${pathT("route.login")}`);
          }, timeSnackbar);
        } else {
          props.snackbarShowMessage(error.response.data);
          setTimeout(() => {
            navigate(pathT("route.invalidAction"));
          }, timeSnackbar);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  return (
    <Page title={t("DashboardView.Title")}>
      <Box
        display="flex"
        flexDirection="center"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" mt={10}>
          <div>
            <Loader />
            <h1>{t("SharedStrings.PleaseWait")}</h1>
          </div>
        </Container>
      </Box>
    </Page>
  );
};

export default withSnackbar(EmailActivateView);
