export const isAndroid = () => !!window.OpenUpMobile;

export const isIos = () => !!window.webkit?.messageHandlers?.OpenUpMobile;

export const isMobileAppView = () => isAndroid() || isIos();

export const closeWebview = () => {
  if (isAndroid()) {
    window.OpenUpMobile.closeWebView();
  }

  if (isIos()) {
    window.webkit?.messageHandlers?.OpenUpMobile?.postMessage("closeWebview");
  }
};
