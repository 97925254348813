/* eslint-disable react/no-unstable-nested-components */
import { FC } from "react";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";
import { Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BlockContent from "@sanity/block-content-to-react";
import { imageUrl } from "@src/utils/sanity";
import { useTranslation } from "react-i18next";
import TypographyWithIcon from "./TypographyWithIcon";
import ThemeTestimonial from "./ThemeTestimonial";

const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {
    "& p": {
      marginBottom: spacing(3),
    },
    "& ul": {
      "list-style-position": "inside",
    },
  },
  strong: {
    fontWeight: "bold",
  },
  paper: {
    padding: spacing(4, 3),
    marginBottom: spacing(3),
  },
  icon: {
    marginRight: spacing(2),
  },
  youtubeVideo: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
  },
}));

const CustomBlockContent: FC<{
  serializers?: Record<string, FC<unknown>>;
  blocks: unknown[];
}> = ({ serializers: serializersProp = {}, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const serializers = {
    ...serializersProp,
    types: {
      // @ts-ignore
      ...(serializersProp.types || {}),
      video: ({ node }) => {
        const { url } = node;
        const id = getYouTubeId(url);
        return (
          <Paper className={classes.paper} elevation={15}>
            <TypographyWithIcon iconLigature="smart_display" mb={2}>
              {node.title}
            </TypographyWithIcon>
            <Typography variant="body1">{node.description}</Typography>
            <div
              className="video"
              style={{
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 */,
                height: 0,
              }}
            >
              {id && <YouTube videoId={id} className={classes.youtubeVideo} />}
            </div>
          </Paper>
        );
      },
      testimonial: ({ node }) => {
        const avatarSize = 200;
        const src = imageUrl
          .image(node.image)
          .height(avatarSize)
          .width(avatarSize)
          .url();
        return (
          <Paper className={classes.paper} elevation={15}>
            <TypographyWithIcon iconLigature="format_quote" mb={2}>
              {t("ThemeViewEducation.Testimonial.Title")}
            </TypographyWithIcon>
            <ThemeTestimonial
              avatarSize={200}
              name={node.name}
              text={node.text}
              imageSrc={src}
            />
          </Paper>
        );
      },
    },
    marks: {
      ...(serializersProp.marks || {}),
      strong: (p) => <span className={classes.strong}>{p.children}</span>,
    },
  };
  return (
    <Typography variant="body1" component="div" className={classes.wrapper}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <BlockContent {...props} serializers={serializers} />
    </Typography>
  );
};

export default CustomBlockContent;
