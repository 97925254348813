import ContentCard, {
  ContentCardProps,
} from "@openup/shared/components/Cards/ContentCard/ContentCard";
import { ContentItem } from "@src/queries/contents";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

interface ArticleCardProps {
  article: ContentItem;
  variant?: ContentCardProps["variant"];
  isThemeVisible?: boolean;
  isBackgroundEnabled?: boolean;
  onMouseUp?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}

const ArticleCard = ({
  article: {
    title,
    url,
    thumbnailPath,
    readTime,
    publishedDate,
    // temporary show contenty-type instead of theme (OI-8398)
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    themes: contentTopic,
    type,
  },
  variant = "standard",
  isThemeVisible = true,
  isBackgroundEnabled = true,
  onMouseUp,
  className = "",
}: ArticleCardProps) => {
  const { t } = useTranslation();

  return (
    <ContentCard
      key={`${publishedDate}-${url}`}
      title={title}
      href={url}
      imageSrc={thumbnailPath}
      minsToRead={
        readTime ? t("SharedStrings.EstimatedMinutes", { time: readTime }) : ""
      }
      onMouseUp={onMouseUp}
      topic={
        isThemeVisible
          ? t(`ContentCard.Type.${type}`)
          : // isThemeVisible && topicWithUpperLetter
            //   ? t(`SharedStrings.${topicWithUpperLetter}`)
            undefined
      }
      isLoading={false}
      target="_blank"
      variant={variant}
      isBackgroundEnabled={isBackgroundEnabled}
      className={className}
    />
  );
};

const getLoadingCards = (amount) =>
  Array.from({ length: amount }, (_, index) => (
    <ContentCard
      key={index}
      title={undefined}
      href={undefined}
      imageSrc={undefined}
      isLoading
    />
  ));

export default ArticleCard;
export { getLoadingCards };
