import { Typography, Container, Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as MailIcon } from "@src/resources/images/mail-icon.svg";
import { useTranslation } from "react-i18next";
import BookingContainer from "./BookingContainer";

const useStyles = makeStyles((theme) => ({
  root: { boxShadow: theme.customShadows.bookingContainerBackgroundShadow },
  emailTitleText: {
    textAlign: "center",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    letterSpacing: theme.spacing(0.01875),
  },
  emailSubtitleText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.colors.primaryDarkBlue,
    opacity: 0.7,
    fontWeight: 400,
    letterSpacing: theme.spacing(0.0625),
  },
  emailFooterText: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    opacity: "70%",
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  emailIcon: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  image: {
    marginTop: theme.spacing(12.125),
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      height: 210,
      width: 210,
      top: 60,
      marginTop: theme.spacing(0),
    },
  },
}));

const MagicLink = ({ email, title, body }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <BookingContainer title={title}>
      <Container maxWidth="sm">
        <Card className={classes.root}>
          <CardContent style={{ paddingBottom: 0 }}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <MailIcon className={classes.emailIcon} />
              </Grid>
            </Grid>
            <Typography variant="body1" className={classes.emailTitleText}>
              {body}
            </Typography>
            <Typography variant="body1" className={classes.emailSubtitleText}>
              {email}
            </Typography>
          </CardContent>
        </Card>
        <Typography variant="body2" className={classes.emailFooterText}>
          {t("Booking.MagicLinkPage.TimeLimit")}
        </Typography>
      </Container>
    </BookingContainer>
  );
};

export default MagicLink;
