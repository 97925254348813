import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  TWO_FACTOR_PENDING,
  TWO_FACTOR_SUCCESS,
  LOGOUT,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_FAIL,
  VERIFY_FAIL,
  VERIFY_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  CLEAR_MESSAGE,
  EMAIL_VERIFY_SUCCESS,
  EMAIL_VERIFY_FAIL,
  REGISTER_GUEST_SUCCESS,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  REGISTER_EXTERNAL_SUCCESS,
} from "@src/actions/typeActions";
import authService from "@src/services/auth.service";

const user = authService.getUserFromStorage();

const initialState = user
  ? {
      isLoggedIn: true,
      twoFactorPending: false,
      user,
      error: null,
      successMessage: null,
      warningMessage: null,
    }
  : {
      isLoggedIn: false,
      twoFactorPending: false,
      user: null,
      error: null,
      successMessage: null,
      warningMessage: null,
    };

// eslint-disable-next-line default-param-last
const authReducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_EXTERNAL_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        error: null,
        successMessage: payload.successMessage,
        warningMessage: payload.warningMessage,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        user: payload.user,
        error: null,
        successMessage: payload.successMessage,
        warningMessage: payload.warningMessage,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        user: { ...payload.user },
        error: null,
        successMessage: payload.successMessage,
        warningMessage: payload.warningMessage,
      };
    case REGISTER_GUEST_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        user: payload.user,
        error: null,
        successMessage: payload.successMessage,
        warningMessage: payload.warningMessage,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: payload.user,
        error: payload.error,
      };
    case UPDATE_FAIL:
      authService.saveUserToStorage(payload.user);
      return {
        ...state,
        isLoggedIn: false,
        user: payload.user,
        error: payload.error,
      };
    case LOGIN_SUCCESS:
      authService.saveUserToStorage(payload.user);
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        error: null,
        successMessage: payload.successMessage,
        warningMessage: payload.warningMessage,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: payload?.user,
        error: payload?.error,
      };
    case TWO_FACTOR_PENDING:
      authService.saveUserToStorage(payload?.user);
      return {
        ...state,
        isLoggedIn: false,
        twoFactorPending: true,
        user: payload?.user,
      };
    case TWO_FACTOR_SUCCESS:
      authService.saveUserToStorage(payload?.user);
      return {
        ...state,
        isLoggedIn: true,
        twoFactorPending: false,
        user: payload?.user,
        error: null,
        successMessage: payload.successMessage,
        warningMessage: payload.warningMessage,
      };
    case LOGOUT:
      authService.logout();
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: null,
        twoFactorPending: false,
        successMessage: null,
        warningMessage: null,
      };
    case RESEND_CODE_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        error: null,
        successMessage: payload.successMessage,
        warningMessage: payload.warningMessage,
      };
    case RESEND_CODE_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        error: payload.error,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        user: payload.user,
        error: null,
        successMessage: payload.successMessage,
        warningMessage: payload.warningMessage,
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        error: payload.error,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        error: null,
        successMessage: payload.successMessage,
        warningMessage: payload.warningMessage,
      };
    case VERIFY_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: payload.user,
        error: payload.error,
      };
    case EMAIL_VERIFY_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: null,
        successMessage: payload.successMessage,
        warningMessage: payload.warningMessage,
      };
    case EMAIL_VERIFY_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: payload.user,
        error: payload.error,
        successMessage: payload.successMessage,
        warningMessage: payload.warningMessage,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        error: null,
        successMessage: null,
        warningMessage: null,
      };
    default:
      return state;
  }
};

export const authReducerSelector = (state) => state.authReducers;
export const userSelector = (state) => authReducerSelector(state).user;
export default authReducers;
