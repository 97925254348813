import { ThemeProvider } from "@mui/material";
import theme from "@src/theme";
import VideoCallLayoutTopBar from "@src/layouts/TopBars/VideoCallLayoutTopBar";
import { Outlet } from "react-router-dom";

const VideoCallLayout = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="bg-green-500 h-screen flex flex-col">
        <VideoCallLayoutTopBar />

        <Outlet />
      </div>
    </ThemeProvider>
  );
};

export default VideoCallLayout;
