import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Page from "@src/components/Page";
import MenuList from "@src/components/OpenUpMenuList";
import { useQueryThemesCheckins } from "@src/queries/themes";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import SessionBookingDialog from "@src/views/account/components/SessionBookingDialog";
import ProductCards from "@src/views/account/components/ProductCards";
import LatestArticles from "@src/views/account/components/LatestArticles";
import { DashboardContext } from "@src/utils/DashboardContext";
import ResilienceCheckinContainer from "./components/ResilienceCheckinContainer";

const useStyles = makeStyles((theme) => ({
  page: {
    position: "relative",
    overflowX: "hidden",
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(3),
    color: theme.colors.primaryDarkBlue,
    [theme.breakpoints.down("md")]: {
      lineHeight: "48px",
      fontSize: 35,
    },
    [theme.breakpoints.up("sm")]: {
      lineHeight: "64px",
    },
  },
  menuGrid: {
    backgroundColor: theme.colors.trueWhite,
    marginTop: theme.spacing(-8),
    marginBottom: theme.spacing(-3.75),
    marginRight: theme.spacing(6),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3.75),
    marginLeft: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
  },
  welcomeText: {
    fontWeight: "400",
    lineHeight: "32px",
    letterSpacing: "0.5px",
    opacity: 0.7,
    color: theme.colors.primaryDarkBlue,
    marginBottom: theme.spacing(6),
  },
  boxWelcomeText: {
    margin: `${theme.spacing(0)} auto`,
  },
}));

const DashboardView = ({ authReducers }) => {
  const { t } = useTranslatedNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { data: themes = [], refetch: refetchThemes } =
    useQueryThemesCheckins();
  const classes = useStyles();

  const getLatestCheckinPerTheme = (themesList) => {
    const ret = Object.keys(themesList).map((k) => {
      const questionList = themesList[k].questionLists.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      )[0];

      if (questionList) {
        return {
          ...questionList,
          ...{
            ordinal: themesList[k].ordinal,
            isTrackedTheme: themesList[k].isTrackedTheme,
            isFocused: themesList[k].isFocused,
            key: themesList[k].key,
          },
        };
      }
      return {
        ...themesList[k],
        ...{
          score: 0,
          createdAt: new Date(0),
        },
      };
    });
    ret.sort((a, b) => {
      if (a.isTrackedTheme && b.isTrackedTheme) {
        return a.ordinal - b.ordinal;
      }
      if (a.isTrackedTheme) {
        return -1;
      }
      if (b.isTrackedTheme) {
        return 1;
      }
      return a.ordinal - b.ordinal;
    });
    return ret;
  };

  const trackedThemes = getLatestCheckinPerTheme(
    themes.filter((theme) => theme.isTrackedTheme),
  );
  trackedThemes.sort((a, b) => a.ordinal - b.ordinal);
  const untrackedThemes = getLatestCheckinPerTheme(
    themes.filter((theme) => !theme.isTrackedTheme),
  );
  untrackedThemes.sort((a, b) => a.ordinal - b.ordinal);

  const menuItemStyleCss = {
    color: "#ffffff",
  };

  return (
    <DashboardContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ refetchThemes, themes }}
    >
      <Page title={t("DashboardView.Title")} className={classes.page}>
        <SessionBookingDialog />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Hidden only={["xs", "sm"]}>
              <Grid item xs className={classes.menuGrid}>
                <MenuList menuItemStyle={menuItemStyleCss} isOpen />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Typography
                variant={isSmallScreen ? "h3" : "h1"}
                className={classes.header}
              >
                {`${t("DashboardView.Welcome")} ${
                  authReducers.user?.firstName
                }!`}
              </Typography>
              <ResilienceCheckinContainer />
              <ProductCards className="mt-12" />
              <LatestArticles className="my-12" />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </DashboardContext.Provider>
  );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(DashboardView);
