import { makeStyles } from "@mui/styles";
import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import TypographyWithIcon from "@src/components/TypographyWithIcon";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import ThemeTile from "@src/components/ThemeTile";
import { getCategoryString } from "@src/utils/helpers";
import { Link as RouterLink } from "react-router-dom";
import { ApplicationStateContext } from "@src/utils/ApplicationStateContext";
import EmptyFocusThemeTile from "./EmptyFocusThemeTile";

const useStyles = makeStyles((theme) => ({
  boxThemeOverview: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(6),
    },
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(6),
  },
  themeOverviewTitle: {
    color: theme.colors.primaryDarkBlue,
  },
  boxTypographyIcons: {
    display: "flex",
    "& > div:nth-child(1)": {
      marginRight: theme.spacing(2.625),
    },
  },
  textIcon: {
    color: theme.colors.tertiaryGreen,
    "&:hover": {
      opacity: 0.6,
    },
  },
  icon: {
    marginRight: theme.spacing(1.375),
    fontSize: 18,
    "&>span": {
      fontSize: 18,
    },
  },
  snackbar: {
    [theme.breakpoints.down("sm")]: {
      top: -810,
      maxWidth: 296,
      left: 28,
    },
    width: "100%",
    position: "relative",
    top: -90,
    "& > div": {
      backgroundColor: theme.colors.tertiaryGreen,
    },
  },
  buttonSnackbar: {
    backgroundColor: theme.colors.tertiaryGreen,
    color: "#fff",
  },
  snackbarText: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "1.25px",
  },
  iconTypography: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    cursor: "pointer",
  },
}));

const FocusThemesTiles = ({
  toggleFocusModal,
  isCompareScoresVisible,
  focusedThemes,
  showDefaultTile,
}) => {
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();
  const [open, setOpen] = useState();
  const { themesHaveBeenSuggested } = useContext(ApplicationStateContext);

  useEffect(() => {
    setOpen(themesHaveBeenSuggested);
  }, [themesHaveBeenSuggested]);

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const getFocusedTiles = () => {
    const focusTiles = [];

    for (let i = 0; i < 3; i += 1) {
      if (focusedThemes[i]) {
        focusTiles.push(
          <Grid key={focusedThemes[i].key} item xs={12} sm={6} md={4}>
            <ThemeTile
              titleText={t(`Themes.${focusedThemes[i].key}.Name`)}
              bodyText={t(
                `DashboardView.${
                  focusedThemes[i].key
                }.TrackedTileMessage.${getCategoryString(
                  focusedThemes[i].category,
                )}`,
              )}
              theme={focusedThemes[i]}
            />
          </Grid>,
        );
      } else if (!showDefaultTile) {
        focusTiles.push(
          <Grid key={i} item xs={12} sm={6} md={4}>
            <EmptyFocusThemeTile toggleFocusModal={toggleFocusModal} />
          </Grid>,
        );
      }
    }

    return focusTiles;
  };

  return (
    <section data-cy="focus-themes">
      <Box className={classes.boxThemeOverview}>
        <Typography variant="h3" className={classes.themeOverviewTitle}>
          {themesHaveBeenSuggested
            ? t("DashboardView.Focused.SuggestedThemes")
            : t("DashboardView.Focused.Themes")}
        </Typography>
        <Box className={classes.boxTypographyIcons}>
          <TypographyWithIcon
            typographyClass={classes.textIcon}
            classesIcon={classes.icon}
            boxClass={classes.iconTypography}
            iconLigature="create"
            outlined
            onClick={toggleFocusModal}
            variant="subtitle1"
            mb={2}
          >
            {t("DashboardView.EditThemes")}
          </TypographyWithIcon>
          {isCompareScoresVisible && (
            <RouterLink to={pathT("route.checkins")}>
              <TypographyWithIcon
                typographyClass={classes.textIcon}
                classesIcon={classes.icon}
                boxClass={classes.iconTypography}
                iconLigature="bar_chart"
                outlined
                variant="subtitle1"
                mb={2}
              >
                {t("DashboardView.CompareScores")}
              </TypographyWithIcon>
            </RouterLink>
          )}
        </Box>
      </Box>
      <Grid container spacing={3}>
        {getFocusedTiles()}
        <Snackbar
          className={classes.snackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          ContentProps={{
            className: classes.snackbarText,
          }}
          open={open}
          message={t("DashboardView.Snackbar.Text")}
          onClose={handleClose}
          action={
            <Button
              className={classes.buttonSnackbar}
              color="secondary"
              size="small"
              onClick={handleClose}
            >
              {t("DashboardView.Snackbar.Text2")}
            </Button>
          }
        />
      </Grid>
    </section>
  );
};

export default FocusThemesTiles;
