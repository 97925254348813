import { useRef, useEffect, useContext } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NamePage from "@src/views/onboarding/components/NamePage";
import YearOfBirthPage from "@src/views/onboarding/components/YearOfBirthPage";
import ReferralPage from "@src/views/onboarding/components/ReferralPage";
import EmailPage from "@src/views/onboarding/components/EmailPage";
import PasswordPage from "@src/views/onboarding/components/PasswordPage";
import sessionStorageService from "@src/services/sessionStorage.service";
import { useEmployers } from "@src/queries/common";
import { register, update } from "@src/actions/userActions";
import { useAuthentication } from "@src/services/auth.service";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Loader from "@src/components/Loader";
import { ApplicationStateContext } from "@src/utils/ApplicationStateContext";
import { withSnackbar } from "@src/components/SnackBarComponent";
import CenteredLoader from "@src/components/CenteredLoader";
import VerifyEmailPage from "@src/views/auth/VerifyEmailPage";
import ExplicitConsent from "@src/components/ExplicitConsent";
import CredentialsForm from "./CredentialsForm";

export const STEPS = {
  CREDENTIALS: "",
  EMAIL: "route.register.email",
  PASSWORD: "route.register.password",
  NAME: "route.register.name",
  YEAR_OF_BIRTH: "route.register.year_of_birth",
  REFERRAL: "route.register.referral",
  EXPLICIT_CONSENT: "route.register.explicit-consent",
  VERIFY_EMAIL: "route.register.verify-email",
};

const RegisterView = ({ registerUser, updateUser, ...props }) => {
  const navigate = useNavigate();
  const { t, pathT } = useTranslatedNavigate();
  const { isLoggedIn } = useAuthentication();

  const {
    step,
    goToStep,
    nextStep,
    navigateToUrl,
    setPreventGoingBack,
    setupFlowSteps,
    isFlowSetup,
    addStepToSkip,
    removeStepToSkip,
  } = useOutletContext();

  const { data: employers, isFetching } = useEmployers();
  const activeEmployers = employers.filter((e) => e.active);
  const employerSlug = new URLSearchParams(useLocation().search).get(
    t("route.register.employerQueryPostfix"),
  );
  const employer =
    employerSlug && activeEmployers.find((e) => e.slug === employerSlug);

  const acceptedTermsAndConditions = useRef(false);
  const acceptedMedicalDataProcessing = useRef(false);
  const signUpToNewsletter = useRef(true);
  const { password } = useContext(ApplicationStateContext);

  useEffect(() => {
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (sessionStorageService.getUserEmail()) {
      if (sessionStorageService.getIsUserHasOnboarding()) {
        navigateToUrl(pathT("route.login"));
      }
    }

    if (sessionStorageService.hasSSOFlowInStorage()) {
      sessionStorageService.clearSSOFlowFromStorage();
      nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [isFlowSetup]);

  useEffect(() => {
    if (!!employerSlug && !employer?.id) {
      props.snackbarShowMessage(
        t("RegisterView.EmployerNotFound", { slug: employerSlug }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [employer]);

  useEffect(() => {
    setPreventGoingBack(
      step === STEPS.EXPLICIT_CONSENT || step === STEPS.VERIFY_EMAIL,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  const updateAndRedirect = async () => {
    const firstName = sessionStorageService.getNameFromStorage();
    const lastName = sessionStorageService.getUser()?.lastName;
    const yearOfBirth = sessionStorageService.getYearOfBirthFromStorage();
    const email = sessionStorageService.getUser()?.email;
    const referral = sessionStorageService.getReferralFromStorage();
    const employerId = referral?.referral === "no" ? null : referral?.companyId;
    const isPartner = referral?.referral === "friends";

    try {
      if (isLoggedIn) {
        await updateUser(
          firstName,
          lastName,
          email,
          yearOfBirth,
          signUpToNewsletter.current,
          employerId,
          acceptedTermsAndConditions.current,
          acceptedMedicalDataProcessing.current,
          isPartner,
        );

        if (window.OpenUpMobile) {
          window.OpenUpMobile.authenticateUser();
        }
        window.webkit?.messageHandlers?.OpenUpMobile?.postMessage(
          "authenticateUser",
        );

        sessionStorageService.clearOnboardingFromStorage();

        navigate(pathT("route.account"));
      } else {
        await registerUser(
          firstName,
          lastName,
          email,
          yearOfBirth,
          password,
          employerId,
          acceptedTermsAndConditions.current,
          acceptedMedicalDataProcessing.current,
          isPartner,
        );

        if (window.OpenUpMobile) {
          window.OpenUpMobile.authenticateUser();
        }
        window.webkit?.messageHandlers?.OpenUpMobile?.postMessage(
          "authenticateUser",
        );

        sessionStorageService.clearOnboardingFromStorage();

        goToStep(STEPS.VERIFY_EMAIL);
      }
    } catch (e) {
      if (e.message === "weak_password") {
        props.snackbarShowMessage(t("RegisterView.CompromisedPassword"));
      } else {
        props.snackbarShowMessage(e.request.responseText || e.message);
      }
    }
  };

  const selectStep = () => {
    switch (step) {
      case STEPS.CREDENTIALS:
        return (
          <CredentialsForm
            moveToNextStep={() => {
              if (sessionStorageService.getIsUserHasOnboarding()) {
                // Skip the email, name, year of birth and referral steps if user already has onboarding.
                // Go straight to explicit consent.
                goToStep(STEPS.EXPLICIT_CONSENT);
              } else {
                addStepToSkip(STEPS.EMAIL);
                addStepToSkip(STEPS.PASSWORD);
                nextStep();
              }
            }}
            moveToEmailPage={() => {
              removeStepToSkip(STEPS.EMAIL);
              removeStepToSkip(STEPS.PASSWORD);
              nextStep();
            }}
          />
        );
      case STEPS.EMAIL:
        return <EmailPage moveToNextStep={nextStep} />;
      case STEPS.PASSWORD:
        return (
          <PasswordPage
            moveToNextStep={() => {
              if (sessionStorageService.getIsUserHasOnboarding()) {
                // Skip the email, name, year of birth and referral steps if user already has onboarding.
                // Go straight to explicit consent.
                goToStep(STEPS.EXPLICIT_CONSENT);
              } else {
                nextStep();
              }
            }}
          />
        );
      case STEPS.NAME:
        return <NamePage moveToNextStep={nextStep} />;
      case STEPS.YEAR_OF_BIRTH:
        return (
          <YearOfBirthPage
            moveToNextStep={() => {
              if (sessionStorageService.getReferralFromStorage()) {
                // Skip the  referral step if user registered via sso.
                // Go straight to explicit consent.
                goToStep(STEPS.EXPLICIT_CONSENT);
              } else {
                nextStep();
              }
            }}
          />
        );
      case STEPS.REFERRAL:
        return <ReferralPage moveToNextStep={nextStep} />;
      case STEPS.EXPLICIT_CONSENT:
        return (
          <ExplicitConsent
            moveToNextStep={async (
              termsAndConditions,
              medicalDataProcessing,
            ) => {
              acceptedTermsAndConditions.current = termsAndConditions;
              acceptedMedicalDataProcessing.current = medicalDataProcessing;

              await updateAndRedirect();
            }}
          />
        );
      case STEPS.VERIFY_EMAIL:
        return <VerifyEmailPage />;
      default:
        return <CenteredLoader />;
    }
  };

  return isFetching ? <Loader /> : selectStep();
};

RegisterView.propTypes = {
  registerUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  registerUser: (
    firstName,
    lastName,
    email,
    yearOfBirth,
    password,
    employerId,
    termsAndConditions,
    medicalDataProcessing,
    isPartner,
  ) =>
    dispatch(
      register(
        firstName,
        lastName,
        email,
        yearOfBirth,
        password,
        employerId,
        termsAndConditions,
        medicalDataProcessing,
        isPartner,
      ),
    ),
  updateUser: (
    firstName,
    lastName,
    email,
    yearOfBirth,
    signUpToNewsletter,
    employerId,
    termsAndConditions,
    medicalDataProcessing,
    isPartner,
  ) =>
    dispatch(
      update(
        firstName,
        lastName,
        email,
        yearOfBirth,
        signUpToNewsletter,
        employerId,
        termsAndConditions,
        medicalDataProcessing,
        isPartner,
      ),
    ),
});

const mapStateToProps = (state) => state.authReducers;
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(RegisterView),
);
