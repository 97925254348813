import { Question } from "@src/models/QuestionList";
import { useEffect, useState } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import HeaderText from "@src/components/Typography/HeaderText";
import CheckIcon from "@mui/icons-material/Check";
import sessionStorageService from "@src/services/sessionStorage.service";

interface ResilienceCheckinMultipleChoiceQuestionCardProps {
  question: Question;
  questionNumber: number;
  totalQuestions: number;
  goToNextQuestion: () => void;
  className?: string;
  isDisabled?: boolean;
  saveAnswerToStorage: (question: Question, selectedAnswer: string) => void;
}

const ResilienceCheckinMultipleChoiceQuestionCard: React.FC<
  ResilienceCheckinMultipleChoiceQuestionCardProps
> = ({
  question,
  questionNumber,
  totalQuestions,
  goToNextQuestion,
  className,
  isDisabled = false,
  saveAnswerToStorage,
}) => {
  const { t } = useTranslatedNavigate();
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");

  useEffect(() => {
    const existingAnswer =
      sessionStorageService.getResilienceCheckInAnswerByQuestionKey(
        question.questionKey,
      );
    if (existingAnswer) {
      setSelectedAnswer(existingAnswer.answers[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  return (
    // Parent div to control which question is visible
    <div className={`absolute w-full ${className}`}>
      <div className="bg-white rounded-xl m-4 mt-8 py-6 pb-4 shadow-xl md:max-w-md mx-auto">
        <p className="font-medium leading-[24px] tracking-[0.1px] text-sm px-6 text-indigo-800 opacity-70 mb-1">
          {t("ResilienceCheckin.Question.QuestionXofX", {
            questionNumber,
            totalQuestions,
          })}
        </p>
        <HeaderText text={question?.questionText ?? ""} className="px-6" />

        {/* Options */}
        <div>
          <div className="mt-6 px-6">
            {question?.answers.map((answer) => (
              <button
                key={answer}
                data-cy={question.questionKey + answer}
                disabled={isDisabled}
                type="button"
                className={`flex justify-between items-center w-full border-2 border-solid rounded-3xl  my-2 mb-3 py-2 px-6 transition-all hover:cursor-pointer text-indigo-800 ${
                  selectedAnswer === answer
                    ? "border-blue-500 bg-blue-500 bg-opacity-10 !text-blue-500"
                    : "bg-white border-slate-200 hover:border-slate-300"
                }`}
                onClick={() => {
                  setSelectedAnswer(answer);
                  saveAnswerToStorage(question, answer);
                  setTimeout(() => {
                    if (questionNumber < totalQuestions) goToNextQuestion();
                  }, 100);
                }}
              >
                <p className="font-display font-semibold text-sm leading-[24px] tracking-[0.5px]">
                  {answer}
                </p>
                <div
                  className={`flex rounded-full h-6 w-6 transition-all ${
                    selectedAnswer === answer
                      ? "border-slate-200 bg-blue-500"
                      : "border-2 border-solid border-gray-200 border-inherit"
                  }`}
                  style={{
                    borderWidth: "inherit",
                  }}
                >
                  <div
                    className={`${selectedAnswer === answer ? "" : "hidden"}`}
                  >
                    <CheckIcon
                      className={`scale-75 stroke-0 rounded bg-inherit ${
                        selectedAnswer === answer ? "text-white" : ""
                      }`}
                    />
                  </div>
                </div>
              </button>
            ))}
          </div>
          <div
            className={`${
              questionNumber === totalQuestions ? "hidden md:block" : "hidden"
            }`}
          >
            <hr className="h-px bg-gray-200 border-0" />
            <div className="hidden md:flex justify-center pt-4 px-6">
              <button
                type="button"
                onClick={goToNextQuestion}
                disabled={!selectedAnswer}
                data-cy="finish-checkin"
                className="w-full font-display text-sm tracking-[.031em] font-medium rounded-full py-3 px-6 bg-blue-500 text-white hover:bg-opacity-90 border-0 disabled:hover:bg-blue-500 disabled:opacity-40 transition duration-300 hover:cursor-pointer"
              >
                {t("ResilienceCheckin.Question.Finish")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResilienceCheckinMultipleChoiceQuestionCard;
