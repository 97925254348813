import { useContext, useEffect, useState } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Page from "@src/components/Page";
import { ApplicationStateContext } from "@src/utils/ApplicationStateContext";
import authService, { useAuthentication } from "@src/services/auth.service";
import { useOutletContext, useSearchParams } from "react-router-dom";
import Loader from "@src/components/Loader";
import { ONBOARDING_VIEW_REFERRAL_STATUS } from "@src/utils/constants";
import sessionStorageService from "@src/services/sessionStorage.service";
import { useEmployers } from "@src/queries/common";
import WelcomePage from "./components/WelcomePage";
import ReferralPage from "./components/ReferralPage";
import FeelingsPage from "./components/FeelingsPage";
import SignupMethodPage from "./components/SignupMethodPage";
import ThanksPage from "./components/ThanksPage";
import YearOfBirthPage from "./components/YearOfBirthPage";
import NamePage from "./components/NamePage";

const STEPS = {
  WELCOME: "",
  NAME: "route.onboarding.name",
  YEAR_OF_BIRTH: "route.onboarding.year_of_birth",
  FEELINGS: "route.onboarding.feelings",
  THANKS: "route.onboarding.thanks",
  REFERRAL: "route.onboarding.referral",
  REGISTRATION: "route.onboarding.registration",
};

const STEPS_WITH_SKIPPED_EMPLOYER = {
  WELCOME: "",
  NAME: "route.onboarding.name",
  YEAR_OF_BIRTH: "route.onboarding.year_of_birth",
  FEELINGS: "route.onboarding.feelings",
  THANKS: "route.onboarding.thanks",
  REGISTRATION: "route.onboarding.registration",
};

const OnboardingView = () => {
  const { step, nextStep, navigateToUrl, setupFlowSteps } = useOutletContext();
  const { setPassword } = useContext(ApplicationStateContext);
  const { isLoggedIn } = useAuthentication();
  const [isSsoProvider, setIsSSOProvider] = useState(false);

  const { t, pathT } = useTranslatedNavigate();

  const { data: employers, isFetching } = useEmployers();

  const [queryParameters] = useSearchParams();

  // Save employer to session storage if it is part of the URL
  // This is to skip the employer selection step
  useEffect(() => {
    const referralFromStorage = sessionStorageService.getReferralFromStorage();

    if (
      !employers ||
      !queryParameters.get(t("route.onboarding.queryParam.employer"))
    ) {
      // check if already set in session storage
      if (referralFromStorage) {
        setupFlowSteps(STEPS_WITH_SKIPPED_EMPLOYER);
        return;
      }
      setupFlowSteps(STEPS);
      return;
    }

    const company = employers
      .filter((employer) => employer.slug != null)
      .find(
        (employer) =>
          employer.slug.toLowerCase() ===
          queryParameters
            .get(t("route.onboarding.queryParam.employer"))
            .toLowerCase(),
      );

    if (!company?.id) {
      setupFlowSteps(STEPS);
      return;
    }

    const getSsoProvider = async () => {
      const employerSSO = await authService.getEmployerSso({
        slug: company?.slug,
      });

      if (employerSSO.ssoSupported === true) {
        sessionStorageService.saveSSOFlowProviderToStorage(
          employerSSO.provider,
        );
        setIsSSOProvider(true);
      }
    };

    getSsoProvider();

    sessionStorageService.saveReferralToStorage({
      referral: ONBOARDING_VIEW_REFERRAL_STATUS.COMPANY,
      companyId: company?.id,
      slug: company?.slug,
    });

    setupFlowSteps(STEPS_WITH_SKIPPED_EMPLOYER);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [employers]);

  const selectStep = (newStep) => {
    if (isFetching) {
      return <Loader />;
    }
    switch (newStep) {
      case STEPS.WELCOME:
        return <WelcomePage moveToNextStep={nextStep} />;
      case STEPS.NAME:
        return <NamePage moveToNextStep={nextStep} />;
      case STEPS.YEAR_OF_BIRTH:
        return <YearOfBirthPage moveToNextStep={nextStep} />;
      case STEPS.FEELINGS:
        return <FeelingsPage moveToNextStep={nextStep} />;
      case STEPS.THANKS:
        return <ThanksPage moveToNextStep={nextStep} />;
      case STEPS.REFERRAL:
        return <ReferralPage moveToNextStep={nextStep} />;
      case STEPS.REGISTRATION:
        return (
          <SignupMethodPage
            registerUser={(email, password) => {
              authService.saveUserToStorage({
                email,
              });
              setPassword(password);
            }}
            moveToNextStep={() => {
              if (isLoggedIn) {
                navigateToUrl(pathT("route.register.explicit-consent"));
              } else {
                navigateToUrl(pathT("route.register"));
              }
            }}
            ssoSupported={isSsoProvider}
          />
        );
      default:
        return null;
    }
  };

  return <Page title={t("OnBoarding.Title")}>{selectStep(step)}</Page>;
};

export default OnboardingView;
