/* eslint-disable react/jsx-props-no-spreading */
import { Avatar, Box, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Slider from "react-slick";
import { imageUrl } from "@src/utils/sanity";
import colors from "@src/theme/colors";
import { ChevronRight } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const imageSize = 168;

const useStyles = makeStyles(({ spacing }) => ({
  slider: {
    "& .slick-dots": {
      top: imageSize + spacing(2),
      "& li button::before": {
        content: "'◯'",
        fontSize: 10,
      },
    },
    "& .slick-arrow": {
      color: "black",
      top: imageSize / 2,
    },
    "& .slick-next": {
      right: `calc(50% - ${imageSize / 2 + 32})`,
    },
    "& .slick-prev": {
      left: `calc(50% - ${imageSize / 2 + 32})`,
    },
    "& .slick-dots .slick-active": {
      "& button::before": {
        content: "'⬤'",
      },
    },
  },
  avatar: {
    height: imageSize,
    width: imageSize,
  },
  wrapper: {
    padding: spacing(3),
    paddingBottom: spacing(2),
    backgroundColor: alpha(colors.primaryDarkBlue, 0.05),
    borderRadius: "8px",
  },
  text: {
    marginTop: spacing(6),
  },
}));

const ThemeExercisesSlider = ({ exercises }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Slider
        infinite
        dots
        fade
        className={classes.slider}
        nextArrow={<RightArrow />}
        prevArrow={<LeftArrow />}
      >
        {exercises.entries.map((exercise) => (
          // eslint-disable-next-line no-underscore-dangle
          <Card key={exercise._key} exercise={exercise} />
        ))}
      </Slider>
    </div>
  );
};

const RightArrow = (props) => (
  <ChevronRight size={16} style={{ color: "black" }} {...props} />
);
const LeftArrow = (props) => (
  <ChevronLeftIcon size={16} style={{ color: "black" }} {...props} />
);
const Card = ({ exercise }) => {
  const classes = useStyles();
  const imageSrc = imageUrl
    .image(exercise.image)
    .width(imageSize)
    .height(imageSize)
    .url();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      // eslint-disable-next-line no-underscore-dangle
      key={exercise._key}
    >
      <Avatar src={imageSrc} className={classes.avatar} />
      <Typography
        variant="subtitle1"
        color="primary"
        align="center"
        className={classes.text}
      >
        {exercise.text}
      </Typography>
    </Box>
  );
};

export default ThemeExercisesSlider;
