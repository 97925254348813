/* eslint-disable no-underscore-dangle */
import { FC, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import colors from "@src/theme/colors";
import clsx from "clsx";
import { ExpandMore } from "@mui/icons-material";
import { alpha } from "@mui/material/styles";
import CustomBlockContent from "./CustomBlockContent";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    backgroundColor: alpha(colors.primaryBlue, 0.05),
    marginBottom: spacing(2),
    minHeight: 56,
    borderRadius: 8,
    border: "none",
    "&::before": {
      backgroundColor: "transparent",
    },
    "&.Mui-expanded": {
      marginTop: 0,
    },
  },
  question: {
    fontWeight: 500,
  },
  openedText: {
    fontSize: 16,
    color: colors.primaryBlue,
  },
  closedText: {
    fontSize: 16,
    color: colors.primaryDarkBlue,
  },
}));

const QuestionsAndAnswersAccordion: FC<{ questionsAndAnswers: any }> = ({
  questionsAndAnswers,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean | string>(false);

  const handleChange = (panelKey) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panelKey : false);
  };

  return (
    <>
      {questionsAndAnswers.entries.map((entry) => (
        <Accordion
          key={entry._key}
          elevation={0}
          className={classes.root}
          expanded={expanded === entry._key}
          onChange={handleChange(entry._key)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography
              variant="subtitle1"
              className={clsx(
                expanded === entry._key
                  ? classes.openedText
                  : classes.closedText,
                classes.question,
              )}
              component="div"
            >
              <CustomBlockContent blocks={entry.question} />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              className={classes.openedText}
              component="div"
            >
              <CustomBlockContent blocks={entry.answer} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default QuestionsAndAnswersAccordion;
