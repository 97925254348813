import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TypographyWithIcon from "@src/components/TypographyWithIcon";
import { ReactComponent as ManFitness } from "@src/resources/images/man_fitness.svg";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import moment from "moment-timezone";
import { Icon } from "@src/components/Icon";
import clsx from "clsx";
import { IConsultType } from "@src/utils/constants";
import BookingCard from "../../components/BookingCard";
import BookingContainer from "../../components/BookingContainer";

const useStyles = makeStyles((theme: any) => ({
  bookingDetailsMessage: {
    maxWidth: 240,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 296,
    },
    marginBottom: theme.spacing(3),
  },
  typography: {
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    display: "flex",
    alignItems: "center",
  },
  image: {
    float: "right",
    marginTop: theme.spacing(10.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      width: 152,
      height: 152,
      position: "relative",
      top: theme.spacing(-2.5),
      marginBottom: theme.spacing(-2.5),
      marginRight: theme.spacing(-2.25),
    },
  },
  box: {
    maxWidth: 240,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 296,
    },
    marginBottom: theme.spacing(2),
  },
  boxCalendarWithIcon: {
    maxWidth: 240,
  },
  cancelSessionButton: {
    opacity: 1,
    color: "white",
    fontWeight: 500,
    letterSpacing: theme.spacing(0.15625),
  },
  button: {
    opacity: 1,
    fontWeight: 500,
    width: 240,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0.5),
    },
  },
  rescheduleSessionButton: {
    color: "000000",
    background: "white",
    border: `2px solid ${theme.colors.primaryDarkBlue}`,
    "&:hover": {
      backgroundColor: theme.colors.lightGray,
    },
  },
  rescheduleSessionButtonText: {
    fontWeight: 500,
    letterSpacing: theme.spacing(0.15625),
  },
  boxFooter: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  sessionDetailsText: {
    maxWidth: 296,
    alignItems: "flex-start",
    color: theme.colors.primaryDarkBlue,
  },
  sessionDetailsIcon: {
    fontSize: theme.spacing(3),
    color: theme.colors.primaryDarkBlue,
    marginRight: theme.spacing(2),
  },
}));

const SessionDetailItem = ({ classes, content, iconLigature }) => (
  <TypographyWithIcon
    variant="body2"
    typographyClass={classes.sessionDetailsText}
    classesIcon={classes.sessionDetailsIcon}
    iconLigature={iconLigature}
    outlined
    boxClass={classes.boxCalendarWithIcon}
  >
    {content}
  </TypographyWithIcon>
);

const ConfirmCancellation = ({ moveToNextStep, consult, redirectToRebook }) => {
  const isSmallScreen = useMediaQuery((x: any) => x.breakpoints.down("sm"));
  const classes = useStyles();
  const { t } = useTranslatedNavigate();

  return (
    consult && (
      <BookingContainer title={t("Booking.Cancellation.Title")}>
        <BookingCard
          image={<ManFitness className={classes.image} />}
          actions={
            <Box className={classes.boxFooter}>
              <Button
                className={clsx(
                  classes.button,
                  classes.rescheduleSessionButton,
                )}
                onClick={redirectToRebook}
              >
                <Typography
                  variant="body2"
                  className={classes.rescheduleSessionButtonText}
                >
                  {isSmallScreen
                    ? t("Booking.Cancellation.RescheduleShort")
                    : t("Booking.Cancellation.Reschedule")}
                </Typography>
              </Button>
              <Button className={classes.button} onClick={moveToNextStep}>
                <Typography
                  variant="body2"
                  className={classes.cancelSessionButton}
                >
                  {t("Booking.Cancellation.Cancel")}
                </Typography>
              </Button>
            </Box>
          }
        >
          <Typography
            variant="subtitle1"
            className={classes.bookingDetailsMessage}
          >
            {t("Booking.Cancellation.Message")}
          </Typography>
          <div className="flex mb-4 items-center text-indigo-800 opacity-70 gap-4">
            <Icon iconKey={consult.consultType as IConsultType} />
            <p className="text-sm">
              {t(`Booking.TimeSlot.SessionType.${consult.consultType}`)}
            </p>
          </div>
          <SessionDetailItem
            classes={classes}
            content={t("Booking.TimeSlot.Language", {
              key: t(`SessionLanguage.${consult.consultLanguage}`),
            })}
            iconLigature="flag"
          />
          <SessionDetailItem
            classes={classes}
            content={consult.employeeFullName}
            iconLigature="psychology"
          />
          <SessionDetailItem
            classes={classes}
            content={moment(consult.startedAt).format("dddd Do MMMM")}
            iconLigature="event_available"
          />
        </BookingCard>
      </BookingContainer>
    )
  );
};

export default ConfirmCancellation;
