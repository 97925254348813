import { Space } from "@src/models/Space";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import {
  decodeHtmlSpecialCharacters,
  formatSpaceType,
  formatTimeForGoogleCalendarUrl,
  spaceLengthByType,
} from "@src/utils/formatting";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CenteredLoader from "@src/components/CenteredLoader";
import AddToCalendar from "@openup/shared/components/AddToCalendar/AddToCalendar";
import { createAndDownloadICSEvent } from "@src/utils/helpers";
import { ReactComponent as GoogleIcon } from "@openup/shared/resources/icons/google.svg";
import { ReactComponent as MicrosoftIcon } from "@openup/shared/resources/icons/microsoft.svg";
import { ReactComponent as AppleIcon } from "@openup/shared/resources/icons/apple.svg";
import { parseISO } from "date-fns";
import { Button, Link } from "@mui/material";
import SpacesConfirmationCard from "@openup/shared/components/Web/SpacesConfirmationCard/SpacesConfirmationCard";
import { SPACE_TYPES } from "@openup/shared/constants/spaces";
import moment from "moment";

interface SpacesConfirmationPageProps {
  space: Space;
}

const SpacesConfirmationPage = ({ space }: SpacesConfirmationPageProps) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();

  if (!space) return <CenteredLoader />;

  const addToCalendarItems = [
    {
      title: t("Booking.ConfirmationPage.AddToGoogleCalendar"),
      url: t("Spaces.ConfirmationPage.AddToGoogleCalendarLink", {
        sessionType: formatSpaceType(
          `${space.spaceTheme} ${space.spaceTypeDisplayTitle}`,
        ),
        meetinglink: encodeURIComponent(space.spaceConnectionLink),
        startDate: formatTimeForGoogleCalendarUrl(space.dateTime),
        endDate: formatTimeForGoogleCalendarUrl(
          moment(space.dateTime)
            .add(spaceLengthByType(space.spaceType), "minutes")
            .utc()
            .format("YYYY-MM-DDTHH-mm-ss[Z]"),
        ),
      }),
      icon: <GoogleIcon width={24} height={24} />,
    },
    {
      title: t("Booking.ConfirmationPage.AddToMicrosoftCalendar"),
      url: t("Spaces.ConfirmationPage.AddToMicrosoftCalendarLink", {
        sessionType: formatSpaceType(
          `${space.spaceTheme} ${space.spaceTypeDisplayTitle}`,
        ),
        meetinglink: encodeURIComponent(space.spaceConnectionLink),
        startDate: encodeURIComponent(
          moment(space.dateTime).utc().format("YYYY-MM-DD[T]HH:mm:ss[+00:00]"),
        ),
        endDate: encodeURIComponent(
          moment(space.dateTime)
            .add(spaceLengthByType(space.spaceType), "minutes")
            .utc()
            .format("YYYY-MM-DD[T]HH:mm:ss+00:00"),
        ),
      }),
      icon: <MicrosoftIcon width={24} height={24} />,
    },
    {
      title: t("Booking.ConfirmationPage.AddToAppleCalendar"),
      onClick: async (clickEvent) => {
        clickEvent.preventDefault();

        await createAndDownloadICSEvent({
          title: t("Booking.ConfirmationPage.AddToAppleCalendarTitle", {
            sessionType: `${space.spaceTheme} ${space.spaceTypeDisplayTitle}`,
          }),
          url: space.spaceConnectionLink,
          description: t(
            "Spaces.ConfirmationPage.AddToAppleCalendarDescription",
          ),
          location: space.spaceConnectionLink,
          start: space.dateTime,
          durationMinutes: spaceLengthByType(space.spaceType),
          filenamePrefix: "openup",
        });
      },
      icon: <AppleIcon width={24} height={24} />,
    },
  ];

  return (
    <>
      <div className="px-4">
        <p className="font-serif text-center text-3xl md:text-5xl font-black text-indigo-800 mt-8">
          {space.userName
            ? t("spaces.confirmation.title", {
                firstName: space.userName,
              })
            : t("spaces.confirmation.titleWithoutName")}
        </p>
        <p className="font-display text-center mt-4 opacity-70 font-medium leading-6 tracking-wide text-indigo-800">
          {t("spaces.confirmation.emailSent")} {space.userEmail}
        </p>
        <SpacesConfirmationCard
          className="mt-6"
          title={decodeHtmlSpecialCharacters(space.spaceTitle)}
          theme={space.spaceTheme}
          dateTime={parseISO(space.dateTime)}
          timezone={space.timezone}
          isSpotsAvailable={space.spotsAvailable}
          type={SPACE_TYPES[space.spaceType]}
        >
          <div className="hidden md:flex justify-center pb-4">
            <AddToCalendar
              buttonText={t("Booking.ConfirmationPage.AddToCalendar")}
              items={addToCalendarItems}
              className="w-full z-10 mx-4"
            />
          </div>
        </SpacesConfirmationCard>
        <Button
          type="button"
          className="hidden mx-auto md:flex justify-center items-center my-6 bg-transparent"
          onClick={() => {
            navigate(pathT("route.account"));
          }}
        >
          <ArrowBack className="inline mx-2 scale-90 text-indigo-800" />
          <p className="underline inline font-display font-medium  leading-6 tracking-widest text-indigo-800">
            {t("Booking.ConfirmationPage.BackToMyOpenUp")}
          </p>
        </Button>
      </div>

      <div className="md:hidden">
        <div className="fixed bg-white bottom-0 w-full flex justify-between py-4 border-t-2 border-t-gray-100 shadow-2xl px-4">
          <Link
            href={pathT("route.account")}
            className="tracking-widest leading-6 bg-transparent cursor-pointer"
            variant="body2"
            underline="hover"
          >
            {t("Booking.ConfirmationPage.ToMyOpenUp")}
          </Link>
          <AddToCalendar
            buttonText={t("Booking.ConfirmationPage.AddToCalendar")}
            items={addToCalendarItems}
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default SpacesConfirmationPage;
