import { enGB, nl, de, es, fr } from "date-fns/locale";
import i18n from "./i18n";

const DateFnslocaleMapper = () => {
  switch (i18n.language) {
    case "nl":
    case "nl-NL":
      return nl;
    case "en":
    case "en-GB":
    case "en-US":
      return enGB;
    case "de":
    case "de-DE":
      return de;
    case "fr":
    case "fr-FR":
      return fr;
    case "es":
    case "es-ES":
      return es;
    default:
      return nl;
  }
};

export { DateFnslocaleMapper };
