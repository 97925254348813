import { makeStyles } from "@mui/styles";
import imageMan from "@src/resources/images/man.png";

const useStyles = makeStyles(() => ({
  root: {
    Width: "302px",
    height: "302px",
    "@media (max-width: 599px)": {
      height: "240px",
      position: "absolute",
      border: "0px",
      right: "0px",
    },
    "@media (min-width: 600px)": {
      left: "45.54%",
      position: "absolute",
    },
  },
}));

const ImageManWithChair = (props) => {
  const classes = useStyles();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <img alt="FooterImage" src={imageMan} className={classes.root} {...props} />
  );
};

export default ImageManWithChair;
