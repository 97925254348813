export const validateExternalSignUp = (setFieldError) => {
  setFieldError("email");
  setFieldError("password");
  return true;
};

export const validateGoogleSignUpNoCheckbox = (setFieldError) => {
  setFieldError("email");
  setFieldError("password");
  return true;
};

export const validateUpdateUser = (setFieldError) => {
  setFieldError("firstName");
  setFieldError("yearOfBirth");
  setFieldError("phoneNumber");
  return true;
};
