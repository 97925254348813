import i18n from "i18next";
import * as Sentry from "@sentry/react";

export const OpenupCultureCookieName = "openup_culture";
export const LanguageCodeDutchNl = "nl-NL";
export const LanguageCodeEnglishGb = "en-GB";
export const LanguageCodeGermanDe = "de-DE";
export const LanguageCodeFrenchFr = "fr-FR";
export const LanguageCodeSpanishEs = "es-ES";

export const changeLanguageAfterSignin = (languageCode) => {
  switch (languageCode) {
    case LanguageCodeDutchNl:
    case LanguageCodeFrenchFr:
    case LanguageCodeEnglishGb:
    case LanguageCodeGermanDe:
    case LanguageCodeSpanishEs:
      i18n.changeLanguage(languageCode);
      break;
    default:
      Sentry.captureMessage(
        `Unknown languageCode: "${languageCode}" after signin.`,
        "error",
      );
  }
};
