import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useSchedulerBookConsultWithPsychologist } from "@src/queries/booking";
import { TAG_TYPES } from "@src/utils/constants";
import authService from "@src/services/auth.service";
import { IsGuestTwoFactorAuthenticationEnabled } from "@src/queries/twoFactorAuthentication";
import { updateUserLanguageCode } from "@src/queries/user";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import PhoneNumberComponentContainer from "@src/views/booking/components/PhoneNumberComponentContainer";
import BookingConfirmation from "../components/BookingConfirmation";
import TimeSlotPicker from "../components/TimeSlotPicker";
import Language from "../components/Language";
import SessionType from "../introductory/components/SessionType";

const STEPS = {
  SESSION_TYPE: "route.booking.sessionType",
  LANGUAGE: "route.booking.language",
  TIMESLOTS: "route.booking.timeslots",
  PHONE_NUMBER: "route.booking.phoneNumber",
  CONFIRMATION: "route.booking.confirmation",
};

const LoggedPersonalLinkFlow = ({ psychologist }) => {
  const currentUser = authService.getUserFromStorage();
  const { i18n } = useTranslatedNavigate();
  const { step, nextStep, goToStep, setPreventGoingBack, setupFlowSteps } =
    useOutletContext();
  const { mutateAsync: bookConsultQuery } =
    useSchedulerBookConsultWithPsychologist();
  const updateUserLanguage = (languageCode, logout) =>
    updateUserLanguageCode(languageCode, logout);

  const [consult, setConsult] = useState("");
  const [sessionType, setSessionType] = useState();
  const [language, setLanguage] = useState();
  const [selectedDateTime, setSelectedDateTime] = useState();

  useEffect(() => {
    setupFlowSteps(STEPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (step === STEPS.CONFIRMATION) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  const bookConsult = () => {
    if (!sessionType || !selectedDateTime || !language) {
      return undefined;
    }
    const sessionTypeTagName = sessionType;
    const sessionTypeTagTypeName = TAG_TYPES.SESSION_TYPE;
    const languageTagName = language;
    const languageTypeTagTypeName = TAG_TYPES.SESSION_LANGUAGE;
    const tags = [
      {
        tagName: sessionTypeTagName,
        tagType: sessionTypeTagTypeName,
      },
      {
        tagName: languageTagName,
        tagType: languageTypeTagTypeName,
      },
    ];
    return bookConsultQuery({
      time: selectedDateTime.startTime,
      timezone: selectedDateTime.timezone,
      tags,
      psychologistId: psychologist.id,
    });
  };

  const selectLoggedInStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.SESSION_TYPE:
        return (
          <SessionType
            moveToNextStep={nextStep}
            setSessionType={setSessionType}
            psychologist={psychologist}
          />
        );
      case STEPS.LANGUAGE:
        return (
          <Language
            moveToNextStep={nextStep}
            setLanguage={setLanguage}
            firstname={psychologist.firstName}
            presetLanguages={psychologist.tags.filter(
              (tag) =>
                tag.tagType.toLowerCase() ===
                TAG_TYPES.PSYCHOLOGIST_LANGUAGE.toLowerCase(),
            )}
            sessionType={sessionType}
          />
        );
      case STEPS.TIMESLOTS:
        return (
          <TimeSlotPicker
            moveToNextStep={async () => {
              updateUserLanguage(i18n.language, false);
              if (
                !!currentUser.hasValidPhoneNumber ||
                IsGuestTwoFactorAuthenticationEnabled(currentUser)
              ) {
                // skipping the next step
                const bookedConsult = await bookConsult();
                setConsult(bookedConsult);
                goToStep(STEPS.CONFIRMATION);
              } else {
                nextStep();
              }
            }}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
            sessionType={sessionType}
            language={language}
            psychologistId={psychologist.id}
          />
        );
      case STEPS.PHONE_NUMBER:
        // this step is skipped when the existing phone number is already valid
        return (
          <PhoneNumberComponentContainer
            moveToNextStep={async () => {
              const bookedConsult = await bookConsult();
              setConsult(bookedConsult);
              nextStep();
            }}
          />
        );
      case STEPS.CONFIRMATION:
        return <BookingConfirmation consult={consult} />;
      default:
        return null;
    }
  };

  return <>{selectLoggedInStep(step)}</>;
};

export default LoggedPersonalLinkFlow;
