import { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import sessionStorageService from "@src/services/sessionStorage.service";
import { useAuthentication } from "@src/services/auth.service";
import { pushCurrentMoodsFromStorageToServer } from "@src/services/currentMoods.service";
import { ONBOARDING_OVERVIEW_CURRENT_PAGE } from "@src/utils/constants";
import WizardContainer from "./WizardContainer";
import WizardActions from "./WizardActions";

const useStyles = makeStyles(({ breakpoints, colors, spacing }) => ({
  content: {
    padding: "0 0 100px 0",
    gap: spacing(1),
    [breakpoints.up("sm")]: {
      gap: spacing(2, 1),
      padding: spacing(3, 3),
      justifyContent: "center",
    },
  },
  button: {
    letterSpacing: "0.5px",
    padding: "0 18px",
    border: `${colors.separator} solid 1px`,
    backgroundColor: "#FFF",
    color: colors.primaryDarkBlue,
    fontWeight: 600,
    "&:hover, &:focus": {
      backgroundColor: "rgba(21, 35, 97, 0.05)",
    },
  },
  buttonSelected: {
    color: colors.primaryBlue,
    backgroundColor: "#F6FBFF",
    borderColor: colors.primaryBlue,
  },
}));

const FeelingsPage = ({ moveToNextStep }) => {
  const classes = useStyles();
  const [selectedEmotions, setSelectedEmotions] = useState(new Set());
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthentication();

  const emotions = [
    {
      key: "Happy",
      icon: "😊",
    },
    {
      key: "Sad",
      icon: "😢",
    },
    {
      key: "Playful",
      icon: "😂",
    },
    {
      key: "Anxious",
      icon: "😬",
    },
    {
      key: "Angry",
      icon: "😤",
    },
    {
      key: "Tired",
      icon: "😴",
    },
    {
      key: "Excited",
      icon: "🔥",
    },
    {
      key: "Overwhelmed",
      icon: "🤯",
    },
  ];

  useEffect(() => {
    setSelectedEmotions(sessionStorageService.getCurrentMoodsFromStorage());
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  const toggleEmotion = (key) => {
    if (selectedEmotions.has(key)) {
      setSelectedEmotions((oldSelectedEmotions) => {
        oldSelectedEmotions.delete(key);
        return new Set(oldSelectedEmotions);
      });
    } else {
      setSelectedEmotions((oldSelectedEmotions) => {
        oldSelectedEmotions.add(key);
        return new Set(oldSelectedEmotions);
      });
    }
    sessionStorageService.saveCurrentMoodsToStorage([...selectedEmotions]);
  };

  const onMoveToNextStep = async () => {
    if (isLoggedIn) {
      await pushCurrentMoodsFromStorageToServer();
    }
    moveToNextStep();
  };

  return (
    <WizardContainer
      title={t("OnBoarding.Feelings.Title")}
      subtitle={t("OnBoarding.Feelings.Subtitle")}
      currentPage={ONBOARDING_OVERVIEW_CURRENT_PAGE.FEELING}
    >
      <Grid container direction="row" className={classes.content}>
        {emotions.map((emotion) => (
          <Button
            onClick={() => toggleEmotion(emotion.key)}
            startIcon={
              <span role="img" aria-label="sheep">
                {emotion.icon}
              </span>
            }
            className={clsx(
              classes.button,
              selectedEmotions.has(emotion.key) && classes.buttonSelected,
            )}
            key={emotion.key}
          >
            {t(`OnBoarding.Feelings.${emotion.key}`)}
          </Button>
        ))}
      </Grid>
      <WizardActions
        onMoveToNextStep={onMoveToNextStep}
        disabled={selectedEmotions.size === 0}
        nextStepButtonLabel={t("OnBoarding.Next")}
      />
    </WizardContainer>
  );
};

export default FeelingsPage;
