import moment from "moment-timezone";

export const getClientTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getUtcOffsetInMinutes = (timezone) => {
  const zone = moment.tz(timezone);
  return zone.utcOffset();
};

export const findClosesTimezoneInListForSpecificTimezone = (
  timezones,
  specificTimezone,
) => {
  let closestTimezone = timezones.find((x) => x.zoneId === specificTimezone);
  if (!closestTimezone) {
    // find closest timezone by UtcOffsetInMinutes
    const utcOffsetInMinutes = getUtcOffsetInMinutes(specificTimezone);

    closestTimezone = timezones.reduce((prev, current) =>
      Math.abs(current.utcOffsetInMinutes - utcOffsetInMinutes) <
      Math.abs(prev.utcOffsetInMinutes - utcOffsetInMinutes)
        ? current
        : prev,
    );
  }
  return closestTimezone;
};

export const getDisplayTextOfTimezone = (timezoneName) => {
  const showNameLength = 20;
  if (timezoneName.length > showNameLength) {
    return `${timezoneName.substring(0, showNameLength)} ...`;
  }
  return timezoneName;
};

export const convertDateToDateObject = (date) => {
  return new Date(date.year(), date.month(), date.date());
};
