import { env } from "@src/env";
import { SsoSettings } from "@src/models/SsoSettings";
import { SSO_PROVIDER } from "@src/utils/constants";
import { getLocaleDomain } from "@src/i18n";
import i18n from "i18next";

export const getSsoSettings = (provider: string) => {
  const domain = getLocaleDomain(i18n.language);
  let ssoSettings: any;

  switch (provider) {
    case SSO_PROVIDER.Pwc:
      ssoSettings = {
        authority: env.REACT_APP_PWC_AUTHORITY,
        client_id: env.REACT_APP_PWC_CLIENT_ID,
        redirect_uri: `https://${domain}/${env.REACT_APP_PWC_REDIRECT_URI}`,
        response_type: "code",
        metadata: {
          issuer: `${env.REACT_APP_PWC_AUTHORITY}`,
          authorization_endpoint: `${env.REACT_APP_PWC_AUTHORITY}authorize`,
        },
        disablePKCE: true,
        scope:
          "openid email profile cloudEmail preferredMail GlobalGradeCode pwcGlobalGradeName pwcLocalLOSLevel1 pwcLocalLOSLevel2 pwcLocalLOSLevel3 pwcLocalLOSLevel4",
      } as SsoSettings;
      break;
    case SSO_PROVIDER.IPractice:
      ssoSettings = {
        authority: env.REACT_APP_MICROSOFT_AUTHORITY,
        client_id: env.REACT_APP_MICROSOFT_CLIENT_ID,
        redirect_uri: domain
          ? `https://${domain}`
          : env.REACT_APP_MICROSOFT_REDIRECT_URI,
        response_type: "code",
        disablePKCE: true,
        scope: "openid profile email offline_access",
        prompt: "select_account",
      };
      break;
    case SSO_PROVIDER.Microsoft:
      ssoSettings = {
        authority: env.REACT_APP_MICROSOFT_AUTHORITY,
        client_id: env.REACT_APP_MICROSOFT_CLIENT_ID,
        redirect_uri: domain
          ? `https://${domain}`
          : env.REACT_APP_MICROSOFT_REDIRECT_URI,
        response_type: "code",
        disablePKCE: true,
        scope: "openid profile email offline_access",
        prompt: "select_account",
      };
      break;
    default:
      ssoSettings = null;
      break;
  }

  return ssoSettings;
};
