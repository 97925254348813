import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import FloatingDialog from "@src/components/FloatingDialog";
import { Button, Card, CardActions, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import WomanLaptop from "@src/resources/images/woman_laptop.svg";
import { useNavigate } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useClasses = makeStyles((theme) => ({
  cardContainer: {
    padding: theme.spacing(3),
  },
  contentContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
    },
  },
  continueButton: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "1.25px",
    width: "100%",
    position: "relative",
    color: theme.palette.common.white,
  },
  sessionText: {
    marginRight: theme.spacing(-10),
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(0),
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    right: theme.spacing(-3),
    bottom: theme.spacing(-5),
    [theme.breakpoints.down("sm")]: {
      bottom: theme.spacing(-3),
    },
  },
  cardActions: {
    justifyContent: "center",
    borderTop: "solid 2px rgba(21, 35, 97, .05);",
    width: "100%",
  },
}));

const SessionBookingDialog = () => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { pathT, t } = useTranslatedNavigate();
  const classes = useClasses();
  const [isSessionDialogOpen, setIsSessionDialogOpen] = useState(false);
  const navigate = useNavigate();

  const closeSessionDialog = () => {
    setIsSessionDialogOpen(false);
  };

  const navigateToBooking = () => {
    setIsSessionDialogOpen(false);
    navigate(pathT("route.booking"));
  };

  return (
    <FloatingDialog
      title={t("DashboardView.SessionCard.Title")}
      isOpen={isSessionDialogOpen}
      onClose={closeSessionDialog}
    >
      <Card>
        <CardContent className={classes.cardContainer}>
          <Box className={classes.contentContainer}>
            <Box className={classes.sessionText}>
              <Typography variant="body1">
                {t("DashboardView.SessionCard.Body")}
              </Typography>
              {!isSmallScreen && (
                <Button
                  className={classes.continueButton}
                  onClick={navigateToBooking}
                >
                  {t("DashboardView.SessionCard.Button")}
                </Button>
              )}
            </Box>
            <div className={classes.imageContainer}>
              <img alt="WomanLaptop" src={WomanLaptop} />
            </div>
          </Box>
        </CardContent>
        {isSmallScreen && (
          <CardActions className={classes.cardActions}>
            <Button
              className={classes.continueButton}
              onClick={navigateToBooking}
            >
              {t("DashboardView.SessionCard.Button")}
            </Button>
          </CardActions>
        )}
      </Card>
    </FloatingDialog>
  );
};

export default SessionBookingDialog;
