import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { noop } from "lodash";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Icon } from "@src/components/Icon";
import { formatScore } from "@src/utils/formatting";
import { makeStyles } from "@mui/styles";

const useClasses = makeStyles((theme) => ({
  scoreLabel: {
    lineHeight: "24px",
    fontWeight: "500!important",
    letterSpacing: "0.15px",
  },
  scoreIcon: {
    fill: `${theme.palette.common.white}!important`,
  },
  scoreBox: {
    color: (props) => props.color,
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
}));

const ScoreLabel = ({ onClick = noop, themeKey, color, score, ...props }) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { t } = useTranslation();
  const classes = useClasses({ color });

  return (
    <Box
      pr={2}
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      width="fit-content"
      borderRadius="20px"
      border={`1px solid ${color}`}
      mb={2}
      onClick={onClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Box
        borderRadius="50%"
        width={isSmallScreen ? 32 : 40}
        height={isSmallScreen ? 32 : 40}
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={color}
      >
        {themeKey !== undefined && (
          <Icon iconKey={themeKey} className={classes.scoreIcon} />
        )}
      </Box>
      <Typography
        variant={isSmallScreen ? "body2" : "h6"}
        classes={{ h6: classes.scoreLabel }}
      >
        <div className={classes.scoreBox}>
          {score
            ? t("ThemeViewInsights.Score", { value: formatScore(score) })
            : t("ThemeViewInsights.Score.Untracked")}
        </div>
      </Typography>
    </Box>
  );
};

export default ScoreLabel;
