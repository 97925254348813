import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  paper: ({ minHeight = 225 }) => ({
    [theme.breakpoints.up("sm")]: {
      minHeight,
    },
    padding: theme.spacing(4, 3),
  }),
  icon: {
    marginRight: theme.spacing(2),
    width: "22px",
    height: "22px",
  },
  arrow: {
    fontSize: 20,
    marginBottom: -4,
    marginLeft: theme.spacing(1),
    color: theme.colors.primaryBlue,
  },
}));

const ExternalLinkTile = ({
  minHeight,
  url,
  iconLigature = "bookmarks_border_black_outlined",
  titleText,
  bodyText,
}) => {
  const classes = useStyles({ minHeight });

  const getCorrectIconFormat = (className, ligature) =>
    ligature.includes("_outlined") ? (
      <Icon className={clsx(className, "material-icons-outlined")}>
        {ligature}
      </Icon>
    ) : (
      <Icon className={className}>{ligature}</Icon>
    );

  return (
    <a href={url} target="_blank" rel="noreferrer">
      <Paper className={classes.paper} elevation={15}>
        <Box display="flex" alignItems="center" mb={1}>
          {getCorrectIconFormat(classes.icon, iconLigature)}
          <Typography variant="body1" className={classes.titleText}>
            {titleText}
          </Typography>
        </Box>
        <Typography variant="h6">
          {bodyText}
          <ArrowForwardIcon className={classes.arrow} />
        </Typography>
      </Paper>
    </a>
  );
};

export default ExternalLinkTile;
