import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as Sessions } from "@src/resources/images/products/sessions.svg";
import { ReactComponent as Spaces } from "@src/resources/images/products/spaces.svg";
import { ReactComponent as SelfGuidedCare } from "@src/resources/images/products/self-guided-care.svg";
import { PRODUCTS_KEY } from "@src/utils/constants";

const useStyles = makeStyles(() => ({
  image: ({ imageHeight, imageWidth }) => ({
    height: imageHeight,
    width: imageWidth,
    borderBottomRightRadius: "16px",
  }),
}));

const getImageDimensions = (isSmallScreen, imageName) => {
  let imageHeight;
  let imageWidth;
  let viewBoxHeight;
  let viewBoxWidth;
  let viewBoxX;
  let viewBoxY;
  switch (imageName) {
    case PRODUCTS_KEY.Sessions:
      imageHeight = 173;
      imageWidth = 151;
      viewBoxWidth = isSmallScreen ? imageWidth / 0.7 : imageWidth;
      viewBoxHeight = isSmallScreen ? imageHeight / 2 : imageHeight / 1.6;
      viewBoxX = isSmallScreen ? -60 : 3;
      viewBoxY = isSmallScreen ? 70 : -15;
      break;
    case PRODUCTS_KEY.SelfGuidedCare:
      imageHeight = 173;
      imageWidth = 151;
      viewBoxWidth = isSmallScreen ? imageWidth / 0.68 : imageWidth;
      viewBoxHeight = isSmallScreen ? imageHeight / 2 : imageHeight / 2;
      viewBoxX = isSmallScreen ? -140 : -70;
      viewBoxY = isSmallScreen ? 85 : 13;
      break;
    case PRODUCTS_KEY.Spaces:
      imageHeight = 173;
      imageWidth = 151;
      viewBoxWidth = isSmallScreen ? imageWidth / 0.7 : imageWidth;
      viewBoxHeight = isSmallScreen ? imageWidth / 2.2 : imageHeight / 1.9;
      viewBoxX = isSmallScreen ? -87 : -30;
      viewBoxY = isSmallScreen ? 126 : 41;
      break;
    default:
      imageHeight = 161;
      imageWidth = 160;
      viewBoxWidth = imageWidth;
      viewBoxHeight = imageHeight / 1.6;
      viewBoxX = isSmallScreen ? -50 : -10;
      viewBoxY = 0;
  }
  return {
    imageHeight,
    imageWidth,
    viewBoxHeight,
    viewBoxWidth,
    viewBoxX,
    viewBoxY,
  };
};

const ProductCardImage = ({ productKey }) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const {
    imageHeight,
    imageWidth,
    viewBoxHeight,
    viewBoxWidth,
    viewBoxX,
    viewBoxY,
  } = getImageDimensions(isSmallScreen, productKey);

  const classes = useStyles({ imageWidth, imageHeight, isSmallScreen });
  const viewBox = ` ${viewBoxX} ${viewBoxY} ${viewBoxWidth} ${viewBoxHeight}`;

  switch (productKey) {
    case PRODUCTS_KEY.Sessions:
      return <Sessions viewBox={viewBox} className={classes.image} />;
    case PRODUCTS_KEY.Spaces:
      return <Spaces viewBox={viewBox} className={classes.image} />;
    case PRODUCTS_KEY.SelfGuidedCare:
      return <SelfGuidedCare viewBox={viewBox} className={classes.image} />;
    default:
      return null;
  }
};
export default ProductCardImage;
