import { useState } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import DialogTitle from "@mui/material/DialogTitle";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { Formik, Form } from "formik";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import { changePassword } from "@src/queries/user";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { getChangePasswordValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import PasswordStrengthMeter from "./PasswordStrengthMeter";

const useStyles = makeStyles((theme) => ({
  changePasswordDialogContainer: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  changePasswordDialog: {
    maxWidth: theme.spacing(60),
    alignSelf: "center",
    margin: "auto",
  },
  changePasswordDialogTitle: {
    paddingTop: theme.spacing(1),
    fontWeight: "bold",
    marginTop: theme.spacing(1),
  },
  repeatPasswordText: {
    color: theme.colors.primaryBlue,
  },
  resultMessageText: {
    margin: theme.spacing(2, 0),
    color: theme.colors.errorMain,
  },
  resultMessageSuccess: {
    marginTop: theme.spacing(1),
    color: theme.colors.primaryDarkBlue,
  },
  passwordErrorText: {
    marginLeft: theme.spacing(3),
    color: theme.colors.errorMain,
  },
  cancelButton: {
    fontSize: "16px",
    borderRadius: theme.spacing(0),
    backgroundColor: theme.colors.trueWhite,
    "&:hover": {
      backgroundColor: theme.colors.trueWhite,
    },
    color: theme.colors.primaryBlue,
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  saveButton: {
    fontSize: "16px",
    borderRadius: theme.spacing(0),
    backgroundColor: theme.colors.trueWhite,
    "&:hover": {
      backgroundColor: theme.colors.trueWhite,
    },
    color: theme.colors.primaryBlue,
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 * Password strength meter. Takes one argument that can only be "weak" / "medium" / "strong"
 */
const ChangePasswordModal = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleFormSubmit = async (values, resetForm) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      await changePassword(values.oldPassword, values.newPassword);
      setResultMessage(t("ChangePassword.PasswordChangeSuccess"));
      setTimeout(() => {
        setResultMessage("");
        onClose();
        setIsSubmitting(false);
        resetForm();
      }, 3000);
    } catch (error) {
      setResultMessage(error.message);
      setTimeout(() => {
        setResultMessage("");
        setIsSubmitting(false);
      }, 3000);
    }
  };
  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
      }}
      validationSchema={getChangePasswordValidationSchema(t)}
      onSubmit={(values, { resetForm }) => handleFormSubmit(values, resetForm)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <Dialog
          open={isOpen}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              onClose(event, reason);
            }
          }}
          aria-labelledby="change-password"
          className={classes.changePasswordDialog}
        >
          <DialogTitle
            id="change-password"
            className={classes.changePasswordDialogContainer}
          >
            <Typography className={classes.changePasswordDialogTitle}>
              {t("ChangePassword.Title")}
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Form onSubmit={handleSubmit}>
              <TextField
                variant="standard"
                error={Boolean(touched.oldPassword && errors.oldPassword)}
                fullWidth
                helperText={touched.oldPassword && errors.oldPassword}
                label={t("ChangePassword.OldPasswordPrompt")}
                id="oldPassword"
                name="oldPassword"
                type={showOldPassword ? "text" : "password"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.oldPassword}
                className={classes.textField}
                InputProps={{
                  endadornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        size="large"
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="standard"
                error={Boolean(touched.newPassword && errors.newPassword)}
                fullWidth
                helperText={touched.newPassword && errors.newPassword}
                label={t("ChangePassword.NewPasswordPrompt")}
                id="newPassword"
                name="newPassword"
                type={showNewPassword ? "text" : "password"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.newPassword}
                InputProps={{
                  endadornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        size="large"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <PasswordStrengthMeter password={values.newPassword} />
              <Typography
                variant="body2"
                className={classes.repeatPasswordText}
              >
                {t("ChangePassword.RepeatPasswordHelpText")}
              </Typography>
              <TextField
                variant="standard"
                error={Boolean(
                  touched.newPasswordConfirmation &&
                    errors.newPasswordConfirmation,
                )}
                fullWidth
                helperText={
                  touched.newPasswordConfirmation &&
                  errors.newPasswordConfirmation
                }
                label={t("ChangePassword.RepeatNewPasswordPrompt")}
                id="newPasswordConfirmation"
                name="newPasswordConfirmation"
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.newPasswordConfirmation}
              />
            </Form>
            <Typography variant="body1" className={classes.resultMessageText}>
              {resultMessage}
            </Typography>
          </DialogContent>
          {!isSubmitting && (
            <DialogActions>
              <Button onClick={onClose} className={classes.cancelButton}>
                {t("ChangePassword.CancelButton")}
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                className={classes.saveButton}
              >
                {t("ChangePassword.SaveButton")}
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )}
    </Formik>
  );
};

ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ChangePasswordModal;
