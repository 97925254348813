import { LessonArticleContentItem } from "@openup/shared/components/LessonArticle/LessonArticle";
import {
  ContentItem,
  ContentType,
  Course,
  TextStyle,
} from "@src/models/Course";

export const getLessonIndex = (lessonId: number, course: Course) =>
  course?.lessons.findIndex((lesson) => lesson.id === lessonId);

export const convertAPIContent = (
  contents: ContentItem,
): LessonArticleContentItem => {
  const convertedContent: LessonArticleContentItem = {
    type: "text",
    items: contents.contents
      ? contents.contents.map(convertAPIContent)
      : undefined,
    src: contents.src || undefined,
    text: contents.text || undefined,
  };

  switch (contents.type) {
    case ContentType.Image:
      convertedContent.type = "image";
      break;
    case ContentType.Text:
      switch (contents.textStyle) {
        case TextStyle.H1:
          convertedContent.type = "heading1";
          break;
        case TextStyle.Body:
          convertedContent.type = "paragraph";
          break;
        case TextStyle.H2:
          convertedContent.type = "heading2";
          break;
        default:
          convertedContent.type = "text";
          break;
      }
      break;
    case ContentType.CtaSection:
      convertedContent.type = "text";
      break;
    default:
      break;
  }

  return convertedContent;
};
