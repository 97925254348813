import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Hidden,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddModeratorOutlinedIcon from "@mui/icons-material/AddModeratorOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { useMemo, useRef, useState } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import { withSnackbar } from "@src/components/SnackBarComponent";
import TwoFactorActivationModal from "@src/components/TwoFactorActivationModal";
import authService from "@src/services/auth.service";
import { BACKGROUND } from "@src/utils/constants";
import {
  isAccessRestricted,
  isClientUser,
  isGuestUser,
} from "@src/utils/helpers";
import { isPhoneTwoFactorAuthenticationEnabled } from "@src/queries/twoFactorAuthentication";
import Autocomplete from "@src/components/Autocomplete";
import ChangePasswordModal from "@src/components/ChangePasswordModal";
import MenuList from "@src/components/OpenUpMenuList";
import Page from "@src/components/Page";
import { useEmployers } from "@src/queries/common";
import {
  useMutationUserTheme,
  useQueryThemesCheckins,
} from "@src/queries/themes";
import { useMutationUser, useUserProfile } from "@src/queries/user";
import { getValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import MagicLinkAccessLimitedView from "@src/views/account/components/MagicLinkAccessLimitedView";
import PhoneNumberComponent from "../booking/components/PhoneNumberComponent";
import ThemeFocusItem from "./components/ThemeFocusItem";

const ProfileView = ({ ...props }) => {
  const { t } = useTranslatedNavigate();
  const formRef = useRef();
  const currentUser = authService.getUserFromStorage();
  const [editMode, setEditMode] = useState(false);
  const [
    showTwoFactorAuthenticationModal,
    setShowTwoFactorAuthenticationModal,
  ] = useState(false);
  const [changePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false);
  const { data: employers } = useEmployers();
  const { data: themes, refetch } = useQueryThemesCheckins();
  const { mutateAsync: updateTheme } = useMutationUserTheme(refetch);
  const { data: profile, refetch: refetchProfile } = useUserProfile();
  const activeEmployers = employers.filter((e) => e.active);

  const employersOptions = useMemo(
    () =>
      (activeEmployers || []).map(({ id, name, active }) => ({
        value: id,
        text: name,
        active,
      })),
    [activeEmployers],
  );
  const allEmployers = useMemo(
    () =>
      (employers || []).map(({ id, name, active }) => ({
        value: id,
        text: name,
        active,
      })),
    [employers],
  );
  const toggleEditMode = () => setEditMode(!editMode);
  const handleRefetchProfile = async () => {
    await refetchProfile();
    toggleEditMode();
  };
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { mutateAsync: updateUser } = useMutationUser(handleRefetchProfile);

  const openPasswordChangeModal = () => {
    setChangePasswordModalVisible(true);
  };
  const closePasswordChangeModal = () => {
    setChangePasswordModalVisible(false);
  };

  const onTwoFactorAuthenticationSuccess = () => {
    props.snackbarShowMessage(
      t("TwoFactorAuthentication.ToggleTwoFactorAuthenticationStatusSuccess"),
      "success",
    );
  };

  const handleSubmit = async (values) => {
    try {
      const { data } = await updateUser({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        yearOfBirth: values.yearOfBirth,
        signUpToNewsletter: values.signUpToNewsletter,
        employerId: values.employerId,
        phoneNumber: values.phoneNumber,
      });
      authService.saveUserToStorage(data);
    } catch (error) {
      props.snackbarShowMessage(error.response.data, "error");
    }
  };

  const handleChangeFocus = (key) => async () => {
    const themeToChange = themes.find((theme) => theme.key === key);
    if (themeToChange) {
      const response = await updateTheme({
        ...themeToChange,
        isFocused: !themeToChange.isFocused,
      });
      if (!response || response.status !== 200) {
        props.snackbarShowMessage(t("DashboardView.Focused.Update.Error"));
      } else {
        props.snackbarShowMessage(
          t("DashboardView.Focused.Update.Success"),
          "success",
        );
      }
    }
  };

  const profileValidationSchema = getValidationSchema(t, {
    firstName: true,
    yearOfBirth: true,
    phoneNumber: isPhoneTwoFactorAuthenticationEnabled(currentUser),
  });

  return (
    <Page title={t("ProfileView.Title")}>
      <Container maxWidth="lg" className="pt-12 pb-0">
        <Grid container spacing={6}>
          <Hidden only={["xs", "sm"]}>
            <Grid item xs className="bg-white p-6 pr-0">
              <MenuList
                menuItemStyle="text-white"
                background={BACKGROUND.GREEN_BACKGROUND}
                isOpen
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            {isAccessRestricted(currentUser) ? (
              <MagicLinkAccessLimitedView
                message={
                  isClientUser(currentUser)
                    ? t("MagicLinkAccessLimitedView.Profile.Login.Message")
                    : t(
                        "MagicLinkAccessLimitedView.Profile.CreateAccount.Message",
                      )
                }
              />
            ) : (
              <>
                <Typography
                  className="text-indigo-800 mb-12"
                  align="center"
                  variant="h1"
                >
                  {t("ProfileView.Title")}
                </Typography>
                <Card>
                  <CardHeader
                    className="pt-8"
                    avatar={<AccountCircleOutlinedIcon />}
                    title={
                      <Typography className="text-lg font-medium">
                        {" "}
                        {t("ProfileView.Card.Title")}
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Formik
                      innerRef={formRef}
                      initialValues={{
                        yearOfBirth: profile.yearOfBirth || "",
                        email: profile.email || "",
                        firstName: profile.firstName || "",
                        lastName: profile.lastName || "",
                        employerId: profile.employerId || "",
                        phoneNumber: profile.phoneNumber || "",
                        signUpToNewsletter: profile.signUpToNewsletter || false,
                      }}
                      validationSchema={profileValidationSchema}
                    >
                      {({
                        setFieldValue,
                        setFieldError,
                        setFieldTouched,
                        handleChange,
                        values,
                        touched,
                        errors,
                        handleBlur,
                        resetForm,
                      }) => (
                        <Form>
                          <Grid container spacing={isSmallScreen ? 0 : 6}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                error={Boolean(
                                  touched.firstName && errors.firstName,
                                )}
                                fullWidth
                                helperText={
                                  touched.firstName && errors.firstName
                                }
                                label={t("RegisterView.Firstname")}
                                margin="normal"
                                name="firstName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.firstName}
                                variant="standard"
                                disabled={!editMode}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                error={Boolean(
                                  touched.lastName && errors.lastName,
                                )}
                                fullWidth
                                helperText={touched.lastName && errors.lastName}
                                label={t("RegisterView.Lastname")}
                                margin="normal"
                                name="lastName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.lastName}
                                variant="standard"
                                disabled={!editMode}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={isSmallScreen ? 0 : 6}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label="Email"
                                margin="normal"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                                variant="standard"
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                error={Boolean(
                                  touched.yearOfBirth && errors.yearOfBirth,
                                )}
                                fullWidth
                                helperText={
                                  touched.yearOfBirth && errors.yearOfBirth
                                }
                                label={t("RegisterView.YearOfBirth")}
                                margin="normal"
                                name="yearOfBirth"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.yearOfBirth}
                                variant="standard"
                                disabled={!editMode}
                                type="number"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={isSmallScreen ? 0 : 6}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1">
                                {t("RegisterView.Employer")}
                              </Typography>
                              <Autocomplete
                                id="employerId"
                                options={employersOptions}
                                disabled={!editMode}
                                value={
                                  allEmployers.find(
                                    (eo) => eo.value === values.employerId,
                                  ) || null
                                }
                                onChange={(_, newValue) => {
                                  setFieldValue(
                                    "employerId",
                                    newValue?.value || "",
                                  );
                                }}
                                placeholder={t("RegisterView.WhoIsEmployer")}
                              />
                              <Box mb={isSmallScreen ? 2 : 0} mt={2}>
                                <Typography variant="subtitle1">
                                  {t("RegisterView.Newsletter")}
                                </Typography>
                                <FormControlLabel
                                  className="items-start"
                                  control={
                                    <Checkbox
                                      disabled={!editMode}
                                      checked={values.signUpToNewsletter}
                                      name="signUpToNewsletter"
                                      onChange={handleChange}
                                      id="edit-mode"
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant="subtitle1"
                                      className="text-indigo-800 tracking-wide"
                                    >
                                      {t("RegisterView.MonthlyNewLetter")}
                                    </Typography>
                                  }
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <>
                                <PhoneNumberComponent
                                  disabled={
                                    !editMode ||
                                    isPhoneTwoFactorAuthenticationEnabled(
                                      currentUser,
                                    )
                                  }
                                  isTouched={touched.phoneNumber}
                                  isError={errors.phoneNumber}
                                  handleBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                  phoneNumberValue={values.phoneNumber}
                                  setErrors={setFieldError}
                                  setFieldTouched={setFieldTouched}
                                />
                                {currentUser &&
                                  !isGuestUser(currentUser) &&
                                  (isPhoneTwoFactorAuthenticationEnabled(
                                    currentUser,
                                  ) ? (
                                    <Button
                                      className="mt-2 mb-2 bg-white text-indigo-800 hover:bg-slate-100 hover:bg-opacity-50 disabled:bg-slate-200 w-full justify-between"
                                      disabled={
                                        !currentUser?.hasValidPhoneNumber
                                      }
                                      onClick={() =>
                                        setShowTwoFactorAuthenticationModal(
                                          true,
                                        )
                                      }
                                      data-cy="activate-2fa"
                                    >
                                      <GppGoodIcon />
                                      <Typography className="text-left leading-6">
                                        {t(
                                          "TwoFactorAuthentication.Deactivation.Button",
                                        )}
                                      </Typography>
                                      <DeleteForeverIcon />
                                    </Button>
                                  ) : (
                                    <Button
                                      className="mt-2 mb-2 gap-2 bg-white text-indigo-800 hover:bg-slate-100 hover:bg-opacity-50 disabled:bg-slate-200 w-full align-middle"
                                      disabled={
                                        !currentUser?.hasValidPhoneNumber
                                      }
                                      onClick={() =>
                                        setShowTwoFactorAuthenticationModal(
                                          true,
                                        )
                                      }
                                    >
                                      <AddModeratorOutlinedIcon />
                                      <Typography className="text-left leading-6">
                                        {t(
                                          "TwoFactorAuthentication.Activation.Button",
                                        )}
                                      </Typography>
                                      <Badge
                                        variant="dot"
                                        color="error"
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                        }}
                                        classes={{
                                          badge: "top-3",
                                        }}
                                      >
                                        <ChevronRightOutlinedIcon />
                                      </Badge>
                                    </Button>
                                  ))}
                                {!currentUser?.hasValidPhoneNumber && (
                                  <Typography
                                    variant="subtitle2"
                                    className="mt-2 ml-8 sm:mb-8"
                                  >
                                    {t(
                                      "TwoFactorAuthentication.InvalidProfilePhoneNumber",
                                    )}
                                  </Typography>
                                )}
                              </>
                            </Grid>
                          </Grid>
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {editMode ? (
                            <div className="sm:flex justify-between gap-4">
                              <Button
                                fullWidth
                                className="bg-white text-indigo-800 border-solid border-2 border-indigo-800 border-opacity-90 hover:bg-slate-100 hover:bg-opacity-50 mb-2"
                                onClick={() => {
                                  resetForm();
                                  toggleEditMode();
                                }}
                              >
                                {t("ThemeViewInsights.FocusModal.Cancel")}
                              </Button>
                              <Button
                                disabled={
                                  !profileValidationSchema.isValidSync(values)
                                }
                                fullWidth
                                className="bg-blue-500 hover:bg-indigo-800 bg-opacity-90 text-white tracking-wide mb-2"
                                onClick={() => {
                                  handleSubmit(values, setFieldError);
                                }}
                              >
                                {t(
                                  isSmallScreen
                                    ? "ProfileView.SaveChanges.Btn.SmallScreen"
                                    : "ProfileView.SaveChanges.Btn",
                                )}
                              </Button>
                            </div>
                          ) : currentUser?.isRegisteredWithPassword ? (
                            <div className="sm:flex justify-between gap-4">
                              <Button
                                fullWidth
                                className="bg-white text-indigo-800 border-2 border-solid border-indigo-800 border-opacity-90 hover:bg-slate-100 hover:bg-opacity-50 mb-2"
                                onClick={openPasswordChangeModal}
                              >
                                {t("ChangePassword.Button")}
                              </Button>
                              <Button
                                fullWidth
                                className="bg-blue-500 hover:bg-indigo-800 hover:bg-opacity-90 text-white tracking-wide mb-2"
                                onClick={toggleEditMode}
                              >
                                {t("ProfileView.EditDetails.Btn")}
                              </Button>
                            </div>
                          ) : (
                            <Button
                              fullWidth
                              className="bg-indigo-800 bg-opacity-90 hover:bg-opacity-100 mx-auto py-0 px-1.5 tracking-wider sm:w-full"
                              onClick={toggleEditMode}
                            >
                              {t("ProfileView.EditDetails.Btn")}
                            </Button>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </CardContent>
                </Card>
                <Card className="my-6">
                  <CardHeader
                    className="pt-8"
                    avatar={<AccountCircleOutlinedIcon />}
                    title={
                      <Typography className="text-lg font-medium">
                        {" "}
                        {t("ProfileView.Card.FocusThemes.Title")}
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Box maxWidth={360}>
                      {themes.map(({ key, isFocused }) => (
                        <ThemeFocusItem
                          checked={!!isFocused}
                          key={key}
                          onChange={handleChangeFocus(key)}
                          id={key}
                        />
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      {!isAccessRestricted(currentUser) && (
        <>
          <ChangePasswordModal
            onClose={closePasswordChangeModal}
            isOpen={changePasswordModalVisible}
          />
          {showTwoFactorAuthenticationModal && (
            <TwoFactorActivationModal
              activation={isPhoneTwoFactorAuthenticationEnabled(currentUser)}
              isOpen={showTwoFactorAuthenticationModal}
              onClose={() => setShowTwoFactorAuthenticationModal(false)}
              onTwoFactorAuthenticationSuccess={() =>
                onTwoFactorAuthenticationSuccess()
              }
              userPhoneNumber={currentUser?.phoneNumber}
            />
          )}
        </>
      )}
    </Page>
  );
};

export default withSnackbar(ProfileView);
