import { useState, useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {
  ONBOARDING_OVERVIEW_CURRENT_PAGE,
  MINIMAL_AGE,
  MAXIMUM_AGE,
} from "@src/utils/constants";
import sessionStorageService from "@src/services/sessionStorage.service";
import WizardContainer from "./WizardContainer";
import WizardActions from "./WizardActions";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  content: {
    padding: "0 0 100px 0",
    [breakpoints.up("sm")]: {
      padding: spacing(4, 3),
    },
  },
  input: {
    width: "100%",
  },
}));

const YearOfBirthPage = ({ moveToNextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [userYearOfBirth, setUserYearOfBirth] = useState("");
  const [userName, setUserName] = useState("");
  const [isError, setIsError] = useState(false);
  const [isIncorrect, setIsIncorrect] = useState(false);
  const [isTooYoung, setIsTooYoung] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    sessionStorageService.saveYearOfBirthToStorage(userYearOfBirth);
    moveToNextStep();
  };

  useEffect(() => {
    const yearOfBirth = sessionStorageService.getYearOfBirthFromStorage();
    const name = sessionStorageService.getNameFromStorage();
    if (yearOfBirth) {
      setUserYearOfBirth(yearOfBirth);
    }
    if (name) {
      setUserName(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (!userYearOfBirth) {
      setIsError(false);
      setIsIncorrect(false);
      setIsTooYoung(false);
      return;
    }

    const currentYear = new Date().getFullYear();
    const age = currentYear - userYearOfBirth;

    const isCorrectYear = age > 0 && age < MAXIMUM_AGE;
    if (!isCorrectYear) {
      setIsIncorrect(true);
    } else {
      setIsIncorrect(false);
      setIsTooYoung(age < MINIMAL_AGE);
    }
  }, [userYearOfBirth]);

  useEffect(() => {
    if ((isTooYoung || isIncorrect) && userYearOfBirth.length === 4) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [isTooYoung, isIncorrect, userYearOfBirth]);

  return (
    <WizardContainer
      title={t("OnBoarding.YearOfBirth.Title", { name: userName })}
      currentPage={ONBOARDING_OVERVIEW_CURRENT_PAGE.YEAR_OF_BIRTH}
    >
      <form onSubmit={onSubmit}>
        <Box className={classes.content}>
          <TextField
            variant="standard"
            label={t("OnBoarding.YearOfBirth.AgeLabel")}
            value={userYearOfBirth}
            onChange={(e) => setUserYearOfBirth(e.target.value)}
            className={classes.input}
            inputProps={{ autoFocus: true, "data-cy": "year_of_birth" }}
          />
          {isError && (
            <Typography
              variant="subtitle2"
              color="error"
              className={classes.errorMessage}
            >
              {isIncorrect && t("OnBoarding.YearOfBirth.Incorrect")}
              {isTooYoung && t("OnBoarding.YearOfBirth.TooYoung")}
            </Typography>
          )}
        </Box>
        <WizardActions
          disabled={!userYearOfBirth || userYearOfBirth.length !== 4 || isError}
          nextStepButtonLabel={t("OnBoarding.Next")}
        />
      </form>
    </WizardContainer>
  );
};

export default YearOfBirthPage;
