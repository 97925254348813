import { Box, CircularProgress } from "@mui/material";

const CenteredLoader = ({ className = "" }) => (
  <Box
    display="flex"
    width="100%"
    height="100vh"
    alignItems="center"
    justifyContent="center"
    className={className}
  >
    <CircularProgress />
  </Box>
);

export default CenteredLoader;
