import { useCheckinFormByFormName } from "@src/queries/themes";
import { Export } from "tripetto-runner-foundation";
import TripettoSurvey from "./index";

const GlobalTripettoSurvey = ({ redirectUri, redirectUriOnNotLoggedIn }) => {
  const query = useCheckinFormByFormName("medical");

  const onData = (instance) => {
    const answers = Export.values(instance);
    const answered = Object.values(answers)
      .filter((a) => Object.keys(a.values).length > 0)
      .map((a) => ({
        id: a.node.id,
        value: a.values["*"].value,
      }));
    window.Gender = answered.find(
      (r) =>
        r.id ===
        "d041eec30148c47cb5a9ae7b200db63066e883aff74a5028221b5a1834e2da4e",
    )?.value;
  };

  return (
    <TripettoSurvey
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...query}
      redirectUri={redirectUri}
      redirectUriOnNotLoggedIn={redirectUriOnNotLoggedIn}
      onData={onData}
    />
  );
};

export default GlobalTripettoSurvey;
