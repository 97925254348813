import { alpha } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import typography from "./typography";

import "slick-carousel/slick/slick-theme.css";

declare module "@mui/styles/defaultTheme" {
  interface Theme {
    colors: any;
    customShadows: { [key: string]: string } | string[];
    spacing: (...props: number[]) => number | string;
    breakpoints: any;
  }

  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles/createTheme" {
  interface ThemeOptions {
    colors: typeof colors & any;
    customShadows: typeof customShadows & any;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    dark: string;
  }
}

export const colors = {
  primaryDarkGrayWithTransparency: "rgba(51, 51, 51, 0.2)",
  primaryDarkBlue: "#152361",
  primaryDarkBlueWithTransparency: "rgba(21, 35, 97, 0.9)",
  primaryBlue: "#48B4FF",
  primaryGreen: "#00A885",
  primaryGreenTransparency: alpha("#00A885", 0.05),
  primaryGreenDark: "#0C8E77",
  primarySkin: "#FFF8ED",
  tertiaryGreen: "#187363",
  secondarySkin: "#FFFDFB",
  errorMain: "#870000",
  trueWhite: "#ffffff",
  white: "#F4F6F8",
  primaryOffWhite: "#FFF8ED",
  highlightedBackground: "rgba(51,51,51,.03)",
  darkCyan: "#249985",
  lightGrayishCyan: "#cee8e4",
  lightGrayishBlue: "#edf6fb",
  lightGray: "#e3e4ea",
  secondaryBlue: "#3BA5EE",
  veryPaleBlue: "#EDF8FF",
  primaryBlack: "#333333",
  lightGrayinshOrange: "#F9F2E7",
  secondaryGreen: "#0C8E77",
  separator: "#DADCE6",
};

export const customShadows = {
  bookingContainerBackgroundShadow: "0px 10px 20px 0px rgba(51, 51, 51, 0.1)",
  cardActionBackgroundShadow: "0px 0px 20px rgba(0, 0, 0, 25% )",
};

const theme = createTheme({
  palette: {
    background: {
      dark: colors.white,
      default: colors.white,
      paper: colors.white,
    },
    primary: {
      main: colors.primaryDarkBlue,
    },
    secondary: {
      main: colors.primaryDarkBlue,
    },
    text: {
      primary: colors.primaryDarkBlue,
      secondary: colors.primaryDarkBlue,
    },
    error: {
      main: colors.errorMain,
    },
  },
  colors,
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          paddingTop: 10,
          marginTop: -20,
          "@media (min-width: 600px)": {
            backgroundColor: colors.primarySkin,
          },
          "@media (max-width: 599px)": {
            backgroundColor: colors.primarySkin,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.primaryDarkBlue,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        // @ts-ignore
        root: {
          opacity: 1,
          "&:hover, &:focus": {
            backgroundColor: "transparent",
          },
        },
        select: {
          borderRadius: 30,
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 12,
          paddingBottom: 12,
        },
        icon: {
          color: colors.primaryDarkBlue,
          right: 11,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          height: 8,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: `${colors.veryPaleBlue}`,
            color: colors.secondaryBlue,
          },
          "&:focus": {
            backgroundColor: "unset",
          },
        },
        button: {
          "&:hover": {
            backgroundColor: `${colors.veryPaleBlue}`,
            color: colors.secondaryBlue,
          },
        },
        gutters: {
          "padding-left": 0,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
        elevation8: {
          boxShadow: "none",
        },
        rounded: {
          borderRadius: "16px",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          "@media (max-width: 599px)": {
            paddingBottom: 30,
            paddingLeft: 16,
            paddingRight: 16,
          },
          "@media (min-width: 600px)": {
            paddingBottom: 30,
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Montserrat",
          fontSize: "14px",
          height: 48,
          fontWeight: 400,
          background: colors.primaryBlue,
          "&:hover": {
            backgroundColor: colors.primaryBlue,
          },
          "&:disabled": {
            backgroundColor: alpha(colors.primaryBlue, 0.5),
          },
          borderRadius: 30,
          textTransform: "none",
          color: "white",
        },

        outlined: {
          border: `2px solid ${colors.primaryDarkBlue}`,
          padding: "5px 15px",
          backgroundColor: "white",
          color: colors.primaryDarkBlue,
          "&:hover": {
            backgroundColor: "white",
          },
        },

        contained: {
          backgroundColor: colors.primaryBlue,
          "&:hover": {
            backgroundColor: colors.primaryBlue,
          },
          color: "#ffffff",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "18px",
          alignItems: "center",
        },
        containedSizeLarge: {
          "&:hover": {
            backgroundColor: colors.primaryBlue,
          },
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "18px",
          alignItems: "center",
        },
        containedSizeSmall: {
          "&:hover": {
            backgroundColor: colors.primaryBlue,
          },
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "14px",
          alignItems: "center",
        },
        fullWidth: {
          marginTop: 20,
          marginBlock: 20,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: 20,
          marginBottom: 20,
        },
      },
    },
    MuiTextField: {},
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Name of the rule
          color: colors.primaryDarkBlue,
          "&.Mui-focused": {
            // increase the specificity for the pseudo class
            color: colors.primaryDarkBlue,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: 500,
          color: colors.primaryDarkBlue,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        formControl: {
          marginTop: 10,
        },
      },
    },

    // Labels
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          lineHeight: "24px",
          opacity: 0.7,
          fontWeight: 300,
        },
      },
    },
    // Error messages
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          lineHeight: "16px",
          fontWeight: 400,
        },
      },
    },
    MuiCssBaseline: {
      // "@global": {},
    },
    MuiToolbar: {
      // gutters: {
      //   "@media (max-width: 599px)": {},
      // },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginRight: "12px",
          marginLeft: "12px",
          marginTop: "12px",
          marginBottom: "12px",
        },
      },
    },
  },
  shadows,
  typography,
  customShadows,
});

export default theme;
