import Layout from "@src/layouts/Layout";
import { ThemeProvider } from "@mui/material";
import medicalTheme from "@src/theme/medicalTheme";
import theme from "@src/theme";

const DashboardLayout = ({ showFooter = true, isMedical = false }) => {
  const currentTheme = isMedical ? medicalTheme : theme;

  return (
    <ThemeProvider theme={currentTheme}>
      <Layout
        backgroundColor={theme.colors.primaryOffWhite}
        showFooter={showFooter}
        isMedical={isMedical}
      />
    </ThemeProvider>
  );
};

export default DashboardLayout;
