import Page from "@src/components/Page";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useLocation, useOutletContext } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import { ReactComponent as WomanSpeaking } from "@src/resources/images/cards/desktop/woman-speaking.svg";
import { ReactComponent as WomanSpeakingMobile } from "@src/resources/images/cards/mobile/woman-speaking.svg";
import { ReactComponent as MenReading } from "@src/resources/images/cards/desktop/men-reading.svg";
import { ReactComponent as MenReadingMobile } from "@src/resources/images/cards/mobile/men-reading.svg";

import SessionTypeCard from "@openup/shared/components/Cards/SessionTypeCard/SessionTypeCard";
import { verifyLanguage, verifySessionType } from "@src/utils/helpers";
import { useMediaQuery } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const NonLoggedBookingFlow = () => {
  const { t, pathT } = useTranslatedNavigate();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const URLSessionType = verifySessionType(
    urlSearch.get(t("route.query.session_type")),
  );
  const URLLanguage = verifyLanguage(urlSearch.get(t("route.query.language")));

  const { navigateToUrl } = useOutletContext();
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const setFlowType = (flowType) => {
    switch (flowType) {
      case t("route.introductory"):
        if (URLSessionType || URLLanguage) {
          navigateToUrl(`${pathT("route.booking")}/${flowType}?${urlSearch}`);
        } else {
          navigateToUrl(`${pathT("route.booking")}/${flowType}`);
        }
        break;
      case t("route.follow_up"):
        navigateToUrl(`${pathT("route.booking")}/${flowType}`);
        break;
      default:
        break;
    }
  };

  return (
    <Page title={t("Booking.Title")}>
      <div className="container mx-auto">
        <p className="mt-4 md:mt-8 mb-4 md:mb-12 text-center font-serif font-black	text-4xl md:text-5xl text-indigo-800 w-11/12 md:w-5/12 max-w-3xl mx-auto leading-snug md:leading-snug">
          {t("Booking.IntroductoryFlow.Title")}
        </p>
        <div className="flex flex-wrap w-11/12 mx-auto justify-center items-center gap-8 md:gap-6 pb-6">
          <SessionTypeCard
            onClick={() => setFlowType(t("route.introductory"))}
            cardTitle={t("Booking.IntroductoryFlow.Message1")}
            cardSubtitle={t("Booking.IntroductoryFlow.Subtitle")}
            icon={<ArrowForward className="text-blue-500" />}
            image={isSmallScreen ? <WomanSpeakingMobile /> : <WomanSpeaking />}
          />
          <SessionTypeCard
            onClick={() => setFlowType(t("route.follow_up"))}
            cardTitle={t("Booking.IntroductoryFlow.Message2")}
            cardSubtitle={t("Booking.IntroductoryFlow.FollowUp.Subtitle")}
            icon={<ArrowForward className="text-blue-500" />}
            image={isSmallScreen ? <MenReadingMobile /> : <MenReading />}
          />
        </div>
      </div>
    </Page>
  );
};

export default NonLoggedBookingFlow;
