import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Container, Button, darken } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import LogoMedical from "@src/components/LogoMedical";
import { CONSULT_TYPE } from "@src/utils/constants";
import { getSessionBookingUrl } from "@src/utils/urlHelpers.js";

const useStyles = makeStyles(({ colors, breakpoints, spacing }) => ({
  root: {
    background: colors.trueWhite,
    [breakpoints.up("sm")]: {
      height: 80,
    },
    [breakpoints.down("md")]: {
      height: 64,
    },
    display: "flex",
    alignItems: "center",
    position: "sticky",
  },
  toolbar: {
    width: "100%",
    height: "100%",
  },
  container: {
    [breakpoints.down("md")]: {
      paddingLeft: spacing(1),
    },
    [breakpoints.up("md")]: {
      paddingLeft: spacing(2.5),
    },
    paddingBottom: 0,
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    maxHeight: 28,
    height: 28,
    width: "auto",
    marginTop: spacing(1),
  },
  homeLogo: {
    display: "flex",
    alignItems: "center",
  },
  ctaButton: {
    color: colors.primaryDarkBlue,
    background: colors.trueWhite,
    padding: spacing(1, 3),
    "&:hover": {
      backgroundColor: darken(colors.trueWhite, 0.1),
    },
    minWidth: 167,
    borderWidth: 2,
    border: `solid ${colors.primaryDarkBlue}`,
    borderStyle: "solid",
    transition: "all 0.3s",
  },
}));

const MedicalDashboardTopBar = ({ className, ...restProps }) => {
  const { t, pathT } = useTranslatedNavigate();
  const classes = useStyles();

  const redirect = () => {
    const url = pathT(getSessionBookingUrl(CONSULT_TYPE.PHYSICAL_WELLBEING));
    window.open(url, "_blank");
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    >
      <Toolbar className={`${classes.toolbar} px-0`}>
        <Container className={classes.container}>
          <a
            href="https://openupmedical.com/"
            target="_blank"
            rel="noreferrer"
            className={classes.homeLogo}
            aria-label="OpenUp Medical"
          >
            <LogoMedical className={classes.logo} />
          </a>
          <Button className={classes.ctaButton} onClick={redirect}>
            {t("Doctor.Book")}
          </Button>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

MedicalDashboardTopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default MedicalDashboardTopBar;
