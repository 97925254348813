import { Link } from "react-router-dom";
import { Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Logo from "@src/components/Logo";
import LanguageSelector from "@src/components/LanguageSelector";

export const topbarHeight = 96;

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  toolbar: {
    height: "auto",
    justifyContent: "space-between",
    marginTop: spacing(10),
    marginBottom: spacing(5),
    minHeight: "48px",
    [breakpoints.down("md")]: {
      marginTop: spacing(5),
      marginBottom: spacing(3),
    },
  },
  centeredLogoLink: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    [breakpoints.down("md")]: {
      height: 20,
    },
    [breakpoints.up("sm")]: {
      height: 28,
    },
    width: "auto",
  },
}));

const TopBar = () => {
  const classes = useStyles();

  return (
    <Toolbar className={`${classes.toolbar} px-0`}>
      <Link className={classes.centeredLogoLink} to="/">
        <Logo className={classes.logo} />
      </Link>
      <LanguageSelector />
    </Toolbar>
  );
};

export default TopBar;
