import { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import Moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import clsx from "clsx";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import useTheme from "@mui/styles/useTheme";
import AddIcon from "@mui/icons-material/Add";
import _ from "lodash";

import { getMedicalScoreLayout, getScoreLayout } from "@src/utils/helpers";
import { VALUE_LABEL_HEIGHT } from "@src/utils/constants";
import colors from "@src/theme/colors";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { BarGroup } from "./BarGroup";
import { YAxisLabels } from "./YAxisLabels";

const formatDate = (date) => {
  const moment = Moment(date);
  if (moment.unix() > 0) {
    return moment.format("MMM. DD");
  }
  return "";
};

const addValueToObject = (hasValue, value) => {
  return hasValue ? value : {};
};

const useStyles = makeStyles((theme) => ({
  sliderContainer: ({
    labelPosition,
    bodyHeight,
    xAxisLabelHeight,
    count,
    hasInnerFlex,
    isSmallScreen,
  }) => {
    let width =
      (labelPosition === "right" && "fit-content") ||
      (labelPosition === "left" && "100%");
    if (!hasInnerFlex && labelPosition === "left") {
      width = "fit-content";
    }
    return {
      display: "inline-flex",
      paddingLeft:
        labelPosition === "left" &&
        (isSmallScreen ? theme.spacing(6) : theme.spacing(3)),
      paddingRight: labelPosition === "right" && theme.spacing(1),
      position: "relative",
      zIndex: 2,
      width,
      justifyContent:
        labelPosition === "left" ? "space-between" : "space-around",
      height: bodyHeight + xAxisLabelHeight + 30,
      marginLeft: labelPosition === "right" && count > 4 && "30px",
    };
  },
  themeBarChart: ({ labelPosition }) => ({
    display: "flex",
    height: "100%",
    width:
      (labelPosition === "right" && "fit-content") ||
      (labelPosition === "left" && "100%"),
  }),
  arrow: ({ bodyHeight }) => ({
    position: "absolute",
    top: bodyHeight + theme.spacing(3),
    zIndex: 10,
    fontSize: 16,
    color: theme.palette.primary,
  }),
  previousArrow: {
    left: 0,
  },
  nextArrow: {
    right: 0,
  },
  fixPaddingLeft20: {
    paddingLeft: "14px",
  },
  inner: ({ centerContent, hasInnerFlex }) => ({
    overflowX: "hidden",
    ...addValueToObject(hasInnerFlex, {
      width: "100%",
      display: "flex",
      justifyContent: centerContent ? "center" : "flex-start",
    }),
  }),
  outer: ({ labelPosition }) => ({
    position: "relative",
    width: "100%",
    paddingRight: labelPosition === "right" && "30px",
  }),
  emptyBarContainer: {
    width: 32,
    height: 160,
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: 30,
    position: "relative",
  },
  emptyBarLabelBtn: {
    width: 110,
    transform: "translateX(-40%)",
    bottom: (props) => (props.addExtraPositionBottom ? -98 : -68),
    position: "absolute",
    padding: 0,
    background: "transparent",
    "&:hover": {
      background: "transparent",
    },
  },
  emptyBarLabelBtnText: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

export const EmptyBarComponent = ({ onLabelClick, addExtraPositionBottom }) => {
  const classes = useStyles({ addExtraPositionBottom });
  const { t } = useTranslatedNavigate();
  return (
    <div className={classes.emptyBarContainer}>
      <Button
        startIcon={<AddIcon style={{ color: colors.primaryDarkBlue }} />}
        className={classes.emptyBarLabelBtn}
        onClick={onLabelClick}
      >
        <Typography className={classes.emptyBarLabelBtnText}>
          {t("DashboardView.AddTheme.Button.Title")}
        </Typography>
      </Button>
    </div>
  );
};

const MedicalThemeBarChart = ({
  data,
  bodyHeight = 160,
  barSpacingWidth,
  centerContent = false,
  labelPosition = "left",
  xAxisLabelHeight = 107,
  rotateXAxisLabels = false,
  LabelComponent,
  classNames = {},
  themeKey,

  hasEmptyBars = false,
  onEmptyLabelClick = _.noop,
  isFocusedTab = false,
  onBarClick = _.noop,
  maxBarsDisplayed,
}) => {
  const { t } = useTranslatedNavigate();
  const themeBarChartRef = useRef();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  useEffect(() => {
    if (themeBarChartRef.current && labelPosition === "right") {
      themeBarChartRef.current.scrollLeft =
        themeBarChartRef.current.scrollWidth;
    }
  }, [themeBarChartRef.current?.scrollWidth, labelPosition]);

  const { colors: themeColors, breakpoints } = useTheme();
  const barWidth = useMediaQuery(breakpoints.only("xs")) ? 24 : 32;
  const [maxBarSpacingWidth, setMaxBarSpacingWidth] = useState(barSpacingWidth);

  useEffect(() => {
    if (maxBarsDisplayed && themeBarChartRef.current) {
      return setMaxBarSpacingWidth(
        (themeBarChartRef.current.clientWidth / maxBarsDisplayed - barWidth) /
          2,
      );
    }

    setMaxBarSpacingWidth(barSpacingWidth);
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [maxBarsDisplayed, barWidth]);

  const [canScroll, setCanScroll] = useState(false);
  const [showBackArrow, setShowBackArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(false);
  const onScroll = (direction) => {
    if (themeBarChartRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = themeBarChartRef.current;
      const offset = barWidth + 2 * maxBarSpacingWidth;
      const newPos = scrollLeft + (direction === "left" ? -offset : offset);
      themeBarChartRef.current.scroll({ left: newPos, behavior: "smooth" });
      setShowBackArrow(newPos > 0);
      setShowNextArrow(newPos < scrollWidth - clientWidth);
    }
  };

  const scrollWidth = themeBarChartRef.current?.scrollWidth;
  const clientWidth = themeBarChartRef.current?.clientWidth;

  const updateArrowDisplayState = () => {
    if (clientWidth + 2 < scrollWidth) {
      setCanScroll(true);
      if (labelPosition === "left") {
        setShowNextArrow(true);
      } else {
        setShowBackArrow(true);
      }
    } else {
      setCanScroll(false);
      setShowBackArrow(false);
      setShowNextArrow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateArrowDisplayState);
    return () => {
      window.removeEventListener("resize", updateArrowDisplayState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    updateArrowDisplayState();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [scrollWidth, clientWidth, data.length, maxBarSpacingWidth]);

  const yAxisLabels = [
    { key: "Satisfied", color: themeColors.medicalGreenLight },
    { key: "Neutral", color: themeColors.medicalGreenMiddle },
    { key: "Dissatisfied", color: themeColors.medicalGreenDark },
  ];

  const count = data?.length || 0;

  const barColor = useMemo(() => {
    if (!data[data.length - 1].isEmptyBar) {
      const { barColorFromMedical } = getMedicalScoreLayout({
        score: data[data.length - 1].score,
        maxOffset: bodyHeight - VALUE_LABEL_HEIGHT,
      });
      return barColorFromMedical;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [data[data.length - 1]]);

  const classes = useStyles({
    barColor,
    bodyHeight,
    xAxisLabelHeight,
    labelPosition,
    centerContent,
    count,
    hasInnerFlex: !(maxBarsDisplayed && data?.length > maxBarsDisplayed),
    isSmallScreen,
  });

  return (
    <Box className={classes.outer}>
      <Box className={classes.inner} ref={themeBarChartRef}>
        <div className={classes.themeBarChart}>
          <YAxisLabels
            labels={yAxisLabels}
            height={bodyHeight}
            labelPosition={labelPosition}
          />
          <div
            className={clsx(
              classes.sliderContainer,
              classNames.sliderContainer,
            )}
          >
            {data.map((item, idx) => {
              const addExtraPositionBottom = isFocusedTab && idx === 1;
              // if a theme item object is marked as an emptyBar by adding a property
              // isEmptyBar true it will display the EmptyBarComponent
              if (hasEmptyBars && item.isEmptyBar) {
                return (
                  <EmptyBarComponent
                    addExtraPositionBottom={addExtraPositionBottom}
                    onLabelClick={onEmptyLabelClick}
                    key={`${item.key}-${item.createdAt}-${Math.random()}`}
                  />
                );
              }

              const OpenUpStartingValue = 1.0;
              const scoreText =
                parseFloat(item.score) >= OpenUpStartingValue
                  ? parseFloat(item.score).toFixed(1)
                  : "?";
              const props = getScoreLayout({
                maxOffset: bodyHeight - VALUE_LABEL_HEIGHT,
                score: item.score,
              });
              const labelText = rotateXAxisLabels
                ? t(`Themes.${item.key}.Name`)
                : formatDate(item.createdAt);
              let labelComponent;
              if (LabelComponent) {
                labelComponent = (
                  <LabelComponent
                    addExtraPositionBottom={addExtraPositionBottom}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    label={labelText}
                    theme={item}
                    key={`${item.key}-${item.createdAt}-${Math.random()}`}
                  />
                );
              }

              return (
                <BarGroup
                  key={`${item.key}-${item.createdAt}-${Math.random()}`}
                  item={item}
                  barHeight={bodyHeight}
                  barSpacingWidth={maxBarSpacingWidth}
                  xAxisLabelHeight={xAxisLabelHeight}
                  barWidth={barWidth}
                  rotateXAxisLabels={rotateXAxisLabels}
                  labelText={labelText}
                  scoreText={scoreText}
                  labelPosition={labelPosition}
                  labelComponent={labelComponent}
                  onClick={onBarClick}
                  themeKey={themeKey}
                />
              );
            })}
          </div>
        </div>
      </Box>
      {Boolean(canScroll && showBackArrow) && (
        <ArrowBackIcon
          className={clsx(classes.arrow, classes.previousArrow)}
          onClick={() => onScroll("left")}
        />
      )}
      {Boolean(canScroll && showNextArrow) && (
        <ArrowForwardIcon
          className={clsx(classes.arrow, classes.nextArrow)}
          onClick={() => onScroll("right")}
        />
      )}
    </Box>
  );
};

MedicalThemeBarChart.propTypes = {
  bodyHeight: PropTypes.number,
  barSpacingWidth: PropTypes.number,
  xAxisScrollable: PropTypes.bool,
  labelPosition: PropTypes.string,
  rotateXAxisLabels: PropTypes.bool,
  xaxisLabelHeight: PropTypes.number,
};

export default MedicalThemeBarChart;
