import { useTranslation } from "react-i18next";
import { THEME_KEY } from "@src/utils/constants";

const useTranslatedNavigate = () => {
  const { t, i18n } = useTranslation();
  const pathT = (pathKey, values) => {
    const path = `/${t(pathKey, values)}`;
    return path;
  };

  return {
    t: t as unknown as (...args: any) => string,
    i18n: i18n as unknown as { language: string },
    pathT: pathT as unknown as (...args: any) => string,
  };
};

export default useTranslatedNavigate;

export const convertThemeNameToThemeKey = (themeName, t) =>
  Object.values(THEME_KEY).find(
    (themeKey) =>
      t(`route.ThemeKey.${themeKey}`).toLowerCase() === themeName.toLowerCase(),
  );
