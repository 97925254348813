import Button from "@openup/shared/components/Button/Button";
import { useTranslation } from "react-i18next";
import { ArrowForward, ArrowBack } from "@mui/icons-material";

type LessonNavigationProps = {
  goToPrevious: () => void;
  goToNext: () => void;
  isFirstLessonInCourse?: boolean;
  isLastLessonInCourse?: boolean;
};

export const LessonNavigation = ({
  goToPrevious,
  goToNext,
  isFirstLessonInCourse = false,
  isLastLessonInCourse = false,
}: LessonNavigationProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`
        px-4 sm:px-0 py-4 sm:py-0 flex justify-between mt-10
        bg-white sm:bg-transparent border-[0] border-t border-solid border-gray-200 sm:border-t-0
        fixed bottom-0 left-0 right-0 sm:static
      `}
    >
      <Button onClick={goToPrevious} className="flex gap-1" variant="tertiary">
        <ArrowBack className="hidden sm:inline-block" />
        {isFirstLessonInCourse
          ? t("CourseProgress.Back")
          : t("CourseProgress.PreviousLesson")}
      </Button>
      <Button onClick={goToNext} className="flex gap-1">
        {isLastLessonInCourse
          ? t("CourseProgress.CompleteCourse")
          : t("CourseProgress.NextLesson")}
        <ArrowForward className="hidden sm:inline-block" />
      </Button>
    </div>
  );
};
