import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { env } from "@src/env";

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    connectionString: env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    isRetryDisabled: true,
  },
});
ai.loadAppInsights();

const telemetryVersionInitializer = (envelope) => {
  if (envelope.data) {
    envelope.data.version = env.REACT_APP_VERSION;
  }
  if (envelope.baseData) {
    envelope.baseData.name += ` - ${window.location.pathname}`;
  }
  envelope.tags = envelope.tags || [];
  envelope.tags["ai.cloud.role"] = "OpenUp.Web";
};
ai.addTelemetryInitializer(telemetryVersionInitializer);
ReactPlugin.prototype.trackEvent = (event, customProperties) => {
  // eslint-disable-next-line no-underscore-dangle
  this._analyticsPlugin.trackEvent(event, customProperties);
};

const ComponentWithTracking = (Component) =>
  withAITracking(reactPlugin, Component);

const setAuthenticatedUserContext = (userId) => {
  ai.setAuthenticatedUserContext(userId, "", true);
};
const clearAuthenticatedUserContext = () => {
  ai.clearAuthenticatedUserContext();
};

export default ComponentWithTracking;
export const { appInsights } = ai;
export { reactPlugin };
export { setAuthenticatedUserContext, clearAuthenticatedUserContext };
