import { useState, useMemo, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import BarChartIcon from "@mui/icons-material/BarChart";
import { makeStyles } from "@mui/styles";

import MedicalThemeBarChart from "@src/components/MedicalThemeBarChart";
import { ReactComponent as ImageWomanCelebrate } from "@src/resources/images/woman_celebrate.svg";
import { MAX_FOCUSED_THEMES } from "@src/utils/constants";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles((theme) => ({
  chartOverview: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(8),
    },
  },
  text1: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    marginBottom: theme.spacing(2),
  },
  barChartIcon: {
    marginRight: theme.spacing(1),
  },
  text2: {
    marginBottom: theme.spacing(3),
    color: theme.colors.primaryDarkBlue,
    lineHeight: "32px",
    letterSpacing: "0.5px",
    fontSize: "16px",
  },
  dancingWoman: ({ isFocusedTabWithNoFocusedThemes }) => ({
    height: isFocusedTabWithNoFocusedThemes ? 385 : 273,
    width: 146,
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  }),
  globalCheckinText: {
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  globalCheckinBtn: {
    padding: `${theme.spacing(0)} ${theme.spacing(1.5)}`,
    letterSpacing: 1.25,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  divider: {
    margin: `${theme.spacing(4)} 0 ${theme.spacing(4)}`,
  },
  focusBarLabelContainer: {
    position: "absolute",
    padding: theme.spacing(0.6),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: (props) => props.barColor,
    borderRadius: 12,
    bottom: (props) => (props.addExtraPositionBottom ? -86 : -56),
  },
  focusBarLabelText: {
    lineHeight: "15px",
    color: (props) => props.labelColor,
    whiteSpace: "nowrap",
    fontSize: 14,
    fontWeight: 500,
  },
  focusBarLabelBtn: {
    padding: 0,
    background: (props) => props.btnBackgroundColor,
    marginLeft: theme.spacing(1),
    "&:hover": {
      background: (props) => props.btnBackgroundColor,
    },
  },
  sliderContainer: {
    justifyContent: "space-around!important",
  },
}));

const CHART_TABS = {
  FOCUSED: 0,
  ALL_THEMES: 1,
};

export const MedicalChartTile = ({ hasFocusedThemes, themes }) => {
  const { t } = useTranslatedNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const [selectedTab, setSelectedTab] = useState(CHART_TABS.FOCUSED);

  const scoredThemes = [
    "SleepSatisfaction",
    "Breathing",
    "DailyEnergy",
    "Intoxications",
    "MentalHealth",
    "Nutrition",
    "PhysicalActivity",
    "Overall",
  ];

  const chartThemes = useMemo(() => {
    const themeScores = Object.keys(themes)
      .map((k) => {
        const questionList = themes[k].questionLists.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        )[0];

        if (questionList) {
          return {
            score: questionList.score + 3,
            ...{
              ordinal: themes[k].ordinal,
              isTrackedTheme: themes[k].isTrackedTheme,
              isFocused: themes[k].isFocused,
              key: themes[k].key,
            },
          };
        }
        return {
          ...themes[k],
          ...{
            score: 0,
            createdAt: new Date(0),
          },
        };
      })
      .filter((theme) => scoredThemes.includes(theme.key))
      .sort((a, b) => a.ordinal - b.ordinal);

    const averageScore =
      themeScores.reduce((agg, item) => {
        const update = agg + item.score;
        return update;
      }, 0) / themeScores.length;

    themeScores.push({
      score: averageScore,
      ...{
        ordinal: themeScores.length,
        isTrackedTheme: false,
        isFocused: false,
        key: "Overall",
      },
    });

    return themeScores;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [themes]);

  const { focusedThemes } = useMemo(() => {
    const result = chartThemes.reduce(
      (acc, chartTheme) => {
        if (chartTheme.isFocused) {
          acc.focusedThemes.push(chartTheme);
        } else {
          acc.unfocusedThemes.push(chartTheme);
        }
        return acc;
      },
      { focusedThemes: [], unfocusedThemes: [] },
    );
    if (result.focusedThemes.length < MAX_FOCUSED_THEMES) {
      const emptyBarsNo = MAX_FOCUSED_THEMES - result.focusedThemes.length;
      const emptyBars = Array.from({ length: emptyBarsNo }).map(() => ({
        isEmptyBar: true,
      }));
      result.focusedThemes = [...result.focusedThemes, ...emptyBars];
    }
    return result;
  }, [chartThemes]);

  useEffect(() => {
    setSelectedTab(
      focusedThemes.filter((theme) => !theme.isEmptyBar).length > 0
        ? CHART_TABS.FOCUSED
        : CHART_TABS.ALL_THEMES,
    );
  }, [focusedThemes]);

  const isFocusedTab = selectedTab === CHART_TABS.FOCUSED;
  const isFocusedTabWithNoFocusedThemes = !isFocusedTab || hasFocusedThemes;
  const classes = useStyles({ isFocusedTabWithNoFocusedThemes });

  return (
    <Paper className={classes.chartOverview}>
      <Typography className={classes.text1} variant="h6" color="textPrimary">
        <BarChartIcon className={classes.barChartIcon} />
        {t("DashboardView.MedicalTile.Title")}
      </Typography>
      <Typography className={classes.text2}>
        {t("DashboardView.MedicalTile.Message")}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box width="100%" mb={4}>
          <MedicalThemeBarChart
            data={chartThemes}
            barSpacingWidth={isSmallScreen ? 4 : 10}
            xAxisLabelHeight={150}
            xAxisScrollable
            rotateXAxisLabels
            classNames={{ sliderContainer: classes.sliderContainer }}
            onEmptyLabelClick={() => {}}
            maxBarsDisplayed={undefined}
            labelPosition="left"
          />
        </Box>
        <ImageWomanCelebrate
          viewBox={
            isFocusedTabWithNoFocusedThemes ? "0 0 146 256.6" : "0 0 146 256.6"
          }
          className={classes.dancingWoman}
        />
      </Box>
    </Paper>
  );
};
