import Link from "@mui/material/Link";

const BoldLink = ({ href, error, children, isTargetBlank }) => (
  <Link
    target={isTargetBlank ? "_blank" : "_self"}
    color={error ? "error" : "textPrimary"}
    style={{ fontWeight: 500 }}
    underline="always"
    href={href}
  >
    {children}
  </Link>
);

export default BoldLink;
