import { Container, Box, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useQueryCheckinsResults } from "@src/queries/themes";
import { ReactComponent as SleepSatisfaction } from "@src/resources/images/SleepSatisfaction.svg";
import { ReactComponent as Breathing } from "@src/resources/images/Breathing.svg";
import { ReactComponent as DailyEnergy } from "@src/resources/images/DailyEnergy.svg";
import { ReactComponent as Intoxications } from "@src/resources/images/Intoxications.svg";
import { ReactComponent as MentalHealth } from "@src/resources/images/MentalHealth.svg";
import { ReactComponent as Nutrition } from "@src/resources/images/Nutrition.svg";
import { ReactComponent as Overall } from "@src/resources/images/Overall.svg";
import { ReactComponent as PhysicalActivity } from "@src/resources/images/PhysicalActivity.svg";
import { MedicalChartTile } from "./components/MedicalChartTile";

const useStyles = makeStyles((theme) => ({
  checkInPage: {
    width: "100%",
    height: "100%",
    opacity: "1",
    backgroundColor: theme.palette.background.default,
  },
  container: {
    flexDirection: "column",
    paddingTop: theme.spacing(9),
    paddingBottom: "137px",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  checkInPageTitle: {
    color: theme.palette.common.white,
  },
  checkInPageMessage: {
    fontWeight: 400,
    color: theme.palette.common.white,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
  resultsContainer: {
    width: "100%",
    flexDirection: "column",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderRadius: "16px",
    marginTop: 64,
    boxShadow: theme.customShadows[0],
    padding: theme.spacing(4, 3, 4, 3),
  },
  resultsTitle: {
    opacity: 1,
    paddingBottom: theme.spacing(2),
  },
  resultsDescription: {
    fontWeight: 400,
    opacity: 0.7,
    paddingBottom: theme.spacing(4),
  },
  themeWrapper: {
    paddingBottom: "56px",
    width: "inherit",
  },
  themeTitle: {
    padding: theme.spacing(1.5, 2, 1.5, 2),
    boxShadow: theme.customShadows[0],
    borderRadius: "20px",
    width: "fit-content",
    textTransform: "capitalize",
    fontWeight: 400,
    lineHeight: "36px",
    letterSpacing: "0px",
    mixBlendMode: "normal",
  },
  responseWrapper: {
    alignItems: "flex-start",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    gap: theme.spacing(1),
    boxSizing: "border-box",
    borderBottom: "1px solid rgba(6, 74, 71, 0.12)",
  },
  question: {
    fontWeight: 400,
  },
  answer: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  answers: {
    fontSize: "16px",
    lineHeight: "30px",
    fontWeight: 700,
    paddingLeft: 30,
  },
  themeIcon: {
    verticalAlign: "middle",
  },
}));

const MedicalCheckInResultsView = () => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const { data: checkinsResults } = useQueryCheckinsResults();
  // const {data: medicalThemeCheckins} = useQueryMedicalThemesCheckins()

  const getIcon = (name) => {
    switch (name) {
      case "SleepSatisfaction":
        return <SleepSatisfaction className={classes.themeIcon} />;
      case "Breathing":
        return <Breathing className={classes.themeIcon} />;
      case "DailyEnergy":
        return <DailyEnergy className={classes.themeIcon} />;
      case "Intoxications":
        return <Intoxications className={classes.themeIcon} />;
      case "MentalHealth":
        return <MentalHealth className={classes.themeIcon} />;
      case "Nutrition":
        return <Nutrition className={classes.themeIcon} />;
      case "PhysicalActivity":
        return <PhysicalActivity className={classes.themeIcon} />;
      case "Overall":
        return <Overall className={classes.themeIcon} />;
      default:
        return <Overall className={classes.themeIcon} />;
    }
  };
  if (!checkinsResults) return null;

  return (
    <div className={classes.checkInPage}>
      <Container className={classes.container}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            flex={1}
            className={classes.resultWrapper}
          >
            <Typography variant="h1" className={classes.checkInPageTitle}>
              {t("Medical.Doctor.Checkin")}
            </Typography>
            <Typography variant="body1" className={classes.checkInPageMessage}>
              {t("Medical.Doctor.Checkin.Message")}
            </Typography>
            <MedicalChartTile
              hasFocusedThemes={false}
              themes={checkinsResults}
            />
            <Paper className={classes.resultsContainer}>
              {Object.values(checkinsResults).map((theme) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  key={theme.key}
                  className={classes.themeWrapper}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.themeTitle}
                    key={theme.key}
                  >
                    {getIcon(theme.key)} {t(`Themes.${theme.key}.Name`)}
                  </Typography>
                  {theme.questionLists[0]?.questions
                    .filter((question) => question.questionType !== 22)
                    .map((question) => (
                      <Box
                        display="flex"
                        flexDirection="column"
                        key={question.key}
                        className={classes.responseWrapper}
                      >
                        <Typography
                          variant="subtitle1"
                          className={classes.question}
                        >
                          {question.text}
                        </Typography>
                        {question.answers?.length > 1 ? (
                          <ul>
                            {question.answers.map((answer) => (
                              <Typography
                                variant="body2"
                                className={classes.answers}
                                key={question.text}
                              >
                                <li>{answer.textValue}</li>
                              </Typography>
                            ))}
                          </ul>
                        ) : (
                          <Typography
                            variant="body2"
                            className={classes.answer}
                            key={question.text}
                          >
                            {" "}
                            {question.answers[0].textValue}
                          </Typography>
                        )}
                      </Box>
                    ))}
                </Box>
              ))}
            </Paper>
          </Box>
          {/* <MedicalDrawer/> */}
        </Box>
      </Container>
    </div>
  );
};

export default MedicalCheckInResultsView;
