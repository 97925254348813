import { useState, useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { noop, get as getDeepObjectValue } from "lodash";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment-timezone";

import { displayLogic } from "@src/utils/constants";
import ScoreLabel from "./ScoreLabel";
import ScoreProgress from "./ScoreProgress";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 552,
    },
  },
  dialogTitle: {},
  dialogContent: {
    padding: 0,
  },
  button: {
    color: theme.colors.primaryBlue,
    background: "transparent",
    lineHeight: "24px",
    fontWeight: 500,
    textTransform: "uppercase",
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "24px",
  },
  checkinInfo: {
    background: "#f8f8f8",
    padding: `0 ${theme.spacing(3)}`,
    height: 70,
  },
  scoreProgressWrapper: {
    width: 220,
    margin: `0 ${theme.spacing(1)}`,
    [theme.breakpoints.down("md")]: {
      width: "inherit",
      flex: 1,
    },
  },
  question: {
    flex: 1,
    maxWidth: 300,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "16px",
    marginRight: theme.spacing(1),
  },
  themeKey: {
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
}));

const TITLE_DATE_FORMAT = "D MMMM YYYY";

const AllQuestionsModal = ({
  open = false,
  onClose = noop,
  themesSorted = [],
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selectedCheckin, setSelectedCheckin] = useState(0);
  const disableLeftArrowButton =
    themesSorted.length === 0 || selectedCheckin === 0;
  const disableRightArrowButton =
    themesSorted.length === 0 || selectedCheckin === themesSorted.length - 1;
  const selectedThemeCheckin = useMemo(() => {
    if (Array.isArray(themesSorted) && themesSorted.length > 0) {
      const selected = themesSorted[selectedCheckin];
      return selected || themesSorted[themesSorted.length - 1];
    }

    return { createdAt: new Date() };
  }, [themesSorted, selectedCheckin]);
  const { color: iconBackgroundColor } =
    displayLogic[selectedThemeCheckin?.category || 0];

  useEffect(() => {
    if (Array.isArray(themesSorted) && themesSorted.length > 0) {
      setSelectedCheckin(themesSorted.length - 1);
    }
  }, [themesSorted]);

  const questions = useMemo(
    () => (selectedThemeCheckin?.questions || []).filter((stc) => stc.isScored),
    [selectedThemeCheckin],
  );

  const handleClose = () => {
    onClose();
  };

  const handleLeftArrowClick = () => {
    if (selectedCheckin !== 0) {
      setSelectedCheckin(selectedCheckin - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (selectedCheckin !== themesSorted.length - 1) {
      setSelectedCheckin(selectedCheckin + 1);
    }
  };
  const handleTime = (timeCreated) => {
    moment.locale(i18n.language);
    return moment(timeCreated).format(TITLE_DATE_FORMAT);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="all-questions-dialog"
      open={open}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle
        id="all-questions-dialog-title"
        onClose={handleClose}
        classes={{ root: classes.dialogTitle }}
      >
        <Box display="flex" justifyContent="space-between">
          <IconButton
            onClick={handleLeftArrowClick}
            disabled={disableLeftArrowButton}
            aria-label="forward"
            size="small"
          >
            <ArrowBackIosIcon fontSize="inherit" />
          </IconButton>
          <Typography>{handleTime(selectedThemeCheckin.createdAt)}</Typography>
          <IconButton
            onClick={handleRightArrowClick}
            disabled={disableRightArrowButton}
            aria-label="forward"
            size="small"
          >
            <ArrowForwardIosIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers classes={{ root: classes.dialogContent }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.checkinInfo}
        >
          <Typography className={classes.themeKey}>
            {t(`Themes.${selectedThemeCheckin.key}.Name`)}
          </Typography>
          <ScoreLabel
            mb={0}
            score={selectedThemeCheckin.score || 0}
            themeKey={selectedThemeCheckin.key}
            color={iconBackgroundColor}
          />
        </Box>
        {questions.length > 0 && (
          <Box p={3}>
            {questions.map((question, idx) => (
              <Box
                key={question.key}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pt={1.5}
                pb={1.5}
              >
                <Typography className={classes.question}>{`${idx + 1}. ${
                  question.text
                }`}</Typography>
                <ScoreProgress
                  classes={{ wrapper: classes.scoreProgressWrapper }}
                  score={getDeepObjectValue(question, "answers[0].intValue", 0)}
                />
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="text"
          color="primary"
          onClick={handleClose}
          className={classes.button}
        >
          {t("ThemeViewInsights.AllQuestions.Modal.Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AllQuestionsModal;
