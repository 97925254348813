import { getSpotifyEmbedUrl } from "@src/utils/getSpotifyEmbedUrl";
import React from "react";

interface LessonAudioProps {
  title: string;
  url: string;
}

const LessonAudio: React.FC<LessonAudioProps> = ({ title, url }) => {
  return (
    <iframe
      title={title}
      className="rounded-lg overflow-hidden p-4 sm:p-0"
      src={getSpotifyEmbedUrl(url) || undefined}
      width="100%"
      frameBorder="0"
      height="352"
      allowFullScreen
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    />
  );
};

export default LessonAudio;
