import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import { apiPaths } from "@src/utils/constants";
import { TagType } from "@src/models/Tag";
import { hours } from "./staletimes";

export const useEmployers = () =>
  useQuery(
    [apiPaths.employers],
    async ({ queryKey: [url] }) => {
      const { data } = await axios.get(url);
      return data;
    },
    {
      select: (data) =>
        data.sort((a, b) =>
          a.name ? a.name.localeCompare(b.name, { sensitivity: "base" }) : 0,
        ),
      staleTime: hours(1),
    },
  );

export const useGetSessionTypes = (): UseQueryResult<TagType[]> =>
  useQuery(
    [apiPaths.sessionType],
    async ({ queryKey: [url] }) => {
      const { data } = await axios.get<TagType[]>(url);
      return data;
    },
    {
      staleTime: hours(1),
      placeholderData: [],
    },
  );
