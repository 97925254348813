import { useState, useEffect, forwardRef } from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { connect } from "react-redux";
import {
  Badge,
  Box,
  Typography,
  List,
  Divider,
  Button,
  ListItem,
  ListItemText,
  Collapse,
  Container,
} from "@mui/material";
import LocalPhone from "@mui/icons-material/PhoneOutlined";
import ChatBubble from "@mui/icons-material/ChatBubbleOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToAppOutlined";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useQueryThemesCheckins } from "@src/queries/themes";
import { logout } from "@src/actions/userActions";
import clsx from "clsx";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { isActivateTwoFactorAuthNotificationVisible } from "@src/queries/twoFactorAuthentication";
import authService from "@src/services/auth.service";
import { withSnackbar } from "./SnackBarComponent";
import LanguageSelector from "./LanguageSelector";
import ThemeMenuListItem from "./ThemeMenuListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    position: "fixed",
    top: "64px !important",
    backgroundColor: theme.colors.primaryOffWhite,
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    minHeight: "100%",
  },
  item: {
    color: "#FFFFFF",
  },
  top: {
    position: "absolute",
    top: 0,
    paddingLeft: 0,
    paddingRight: 0,
    height: "initial",
    minHeight: "100%",
    "overflow-x": "hidden",
    backgroundColor: theme.colors.primaryOffWhite,
  },
  bottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    marginTop: "auto",
    width: "100%",
    backgroundColor: theme.colors.primaryOffWhite,
  },
  bookSessionButton: {
    width: 250,
  },
  divider: {
    width: "100%",
  },
  menuItemList: {
    "&:hover": {
      backgroundColor: theme.colors.lightGrayishBlue,
      borderRadius: "4px",
    },
  },
  menuItemListText: {
    opacity: 1,
    fontSize: 16,
    color: theme.colors.primaryDarkBlue,
    marginLeft: theme.spacing(1.5),
  },
  icon: {
    marginLeft: theme.spacing(1.5),
    color: theme.colors.primaryDarkBlue,
    paddingRight: 5,
  },
  subMenuItemListText: {
    opacity: 1,
    fontSize: 14,
    fontWeight: "500",
    color: theme.colors.primaryDarkBlue,
    marginLeft: theme.spacing(1.5),
  },
  listItem: {
    "&:hover": {
      backgroundColor: theme.colors.lightGrayishBlue,
      borderRadius: "4px",
    },
  },
  listItemText: {
    letterSpacing: "0.25px",
    fontWeight: "500",
    color: theme.colors.primaryDarkBlueWithTransparency,
    marginLeft: theme.spacing(1.5),
  },
}));

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction="down" ref={ref} {...props} />
));

const OpenUpMenuListMobile = ({
  logout: logoutUser,
  isOpen,
  onClose,
  ...props
}) => {
  const { t, pathT } = useTranslatedNavigate();
  const classes = useStyles();
  const navigate = useNavigate();
  const currentUser = authService.getUserFromStorage();
  const { data: theme = [], error } = useQueryThemesCheckins();
  const [open, setOpen] = useState(true);
  const [menuOpen, setMenuOpen] = useState(isOpen);

  useEffect(() => {
    setMenuOpen(isOpen);
  }, [isOpen]);

  if (error) {
    props.snackbarShowMessage(error);
  }

  const logoutAndRedirect = () => {
    logoutUser();
    navigate(pathT("route.login"));
  };

  const handleClose = () => {
    setMenuOpen(false);
    onClose();
  };

  const toggleThemes = () => setOpen(!open);

  return (
    <Dialog
      fullScreen
      open={menuOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      classes={{ paper: classes.top, root: classes.root }}
      hideBackdrop
    >
      <Container>
        <List
          component="nav"
          aria-label="main mailbox folders"
          className={classes.menu}
        >
          <RouterLink to={pathT("route.account")}>
            <ListItem
              button
              onClick={handleClose}
              className={classes.menuItemList}
            >
              <Box>
                <Typography
                  variant="body2"
                  className={classes.menuItemListText}
                >
                  <b>{t("SharedStrings.Home")}</b>
                </Typography>
              </Box>
            </ListItem>
          </RouterLink>
          <Divider className={classes.divider} />

          <RouterLink to={pathT("route.library")}>
            <ListItem
              button
              onClick={handleClose}
              className={classes.menuItemList}
            >
              <Typography variant="body2" className={classes.menuItemListText}>
                <b>{t("SharedStrings.Library")}</b>
              </Typography>
            </ListItem>
          </RouterLink>
          <Divider className={classes.divider} />

          <RouterLink to={pathT("route.mySessions")}>
            <ListItem
              button
              onClick={handleClose}
              className={classes.menuItemList}
            >
              <Typography variant="body2" className={classes.menuItemListText}>
                <b>{t("SharedStrings.MySessions")}</b>
              </Typography>
            </ListItem>
          </RouterLink>
          <Divider className={classes.divider} />

          <RouterLink to={pathT("route.checkins")}>
            <ListItem
              button
              onClick={handleClose}
              className={classes.menuItemList}
            >
              <Typography variant="body2" className={classes.menuItemListText}>
                <b>{t("SharedStrings.Chekins")}</b>
              </Typography>
            </ListItem>
          </RouterLink>
          <Divider className={classes.divider} />

          <ListItem
            button
            onClick={toggleThemes}
            className={classes.menuItemList}
          >
            <ListItemText>
              <Typography variant="body2" className={classes.menuItemListText}>
                <b>{t("SharedStrings.Themes")}</b>
              </Typography>
            </ListItemText>
            {open ? (
              <ExpandLess className={classes.icon} />
            ) : (
              <ExpandMore className={classes.icon} />
            )}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button={false}>
                <Typography variant="caption" className={classes.listItemText}>
                  {t("SharedStrings.Focus")}
                </Typography>
              </ListItem>
              {theme
                .filter((themeItem) => themeItem.isFocused)
                .map((item) => (
                  <ThemeMenuListItem
                    key={item.key}
                    themeItem={item}
                    listItemClass={classes.menuItemList}
                    typographyClass={classes.subMenuItemListText}
                    subMenuClass={classes.subMenuItemListText}
                    handleClose={handleClose}
                  />
                ))}
              <ListItem button={false}>
                <Typography variant="body2" className={classes.listItemText}>
                  {t("SharedStrings.Other")}
                </Typography>
              </ListItem>
              {theme
                .filter((themeItem) => !themeItem.isFocused)
                .map((item) => (
                  <ThemeMenuListItem
                    key={item.key}
                    themeItem={item}
                    listItemClass={classes.menuItemList}
                    typographyClass={classes.subMenuItemListText}
                    subMenuClass={classes.subMenuItemListText}
                    handleClose={handleClose}
                  />
                ))}
            </List>
          </Collapse>
          <Divider className={classes.divider} />

          <Badge
            // hide badge when two factor authentication is already enabled, or if it is a guest user
            badgeContent={" "}
            invisible={!isActivateTwoFactorAuthNotificationVisible(currentUser)}
            variant="dot"
            color="error"
            overlap="circular"
          >
            <RouterLink to={pathT("route.profile")}>
              <ListItem
                button
                onClick={handleClose}
                className={classes.menuItemList}
              >
                <Box>
                  <Typography
                    variant="body2"
                    className={classes.menuItemListText}
                  >
                    <b>{t("SharedStrings.Account")}</b>
                  </Typography>
                </Box>
              </ListItem>
            </RouterLink>
          </Badge>
          <Divider className={classes.divider} />
          <List>
            <ListItem
              button
              onClick={logoutAndRedirect}
              className={classes.menuItemList}
            >
              <ExitToAppIcon className={classes.icon} />
              <Typography variant="body2" className={classes.menuItemListText}>
                <b>{t("SharedStrings.Logout")}</b>
              </Typography>
            </ListItem>

            <a
              href={t("OpenUpCta.BtnBookSessionPhoneNumber")}
              target="_blank"
              rel="noreferrer"
            >
              <ListItem button className={classes.menuItemList}>
                <LocalPhone className={classes.icon} />
                <Typography
                  variant="body2"
                  className={classes.menuItemListText}
                >
                  <b>{t("OpenUpCta.BtnBookSessionPhone")}</b>
                </Typography>
              </ListItem>
            </a>

            <a
              href={t("OpenUpCta.BtnBookSessionChatLink")}
              target="_blank"
              rel="noreferrer"
            >
              <ListItem button className={classes.menuItemList}>
                <ChatBubble className={classes.icon} />
                <Typography
                  variant="body2"
                  className={classes.menuItemListText}
                >
                  <b>{t("OpenUpCta.BtnBookSessionChat")}</b>
                </Typography>
              </ListItem>
            </a>
          </List>
        </List>
      </Container>
      <Box className={classes.bottom}>
        <LanguageSelector />
        <a
          href={t("OpenUpCta.BtnBookSessionLink")}
          target="_blank"
          rel="noreferrer"
          className={classes.bookSessionButton}
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            style={{ width: 250, height: 42 }}
          >
            <Typography
              variant="body2"
              className={clsx(
                classes.menuItemListText,
                classes.subMenuItemListText,
              )}
            >
              {t("OpenUpCta.BtnBookSessionLinkText")}
            </Typography>
          </Button>
        </a>
      </Box>
    </Dialog>
  );
};

OpenUpMenuListMobile.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
});

export default withSnackbar(
  connect(null, mapDispatchToProps)(OpenUpMenuListMobile),
);
