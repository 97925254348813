import { Formik } from "formik";
import { Button, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import BookingContainer from "@src/views/booking/components/BookingContainer";
import BookingCard from "@src/views/booking/components/BookingCard";
import { ReactComponent as ManTrumpet } from "@src/resources/images/man_trumpet.svg";

const useStyles = makeStyles((theme) => ({
  fillDetails: {
    opacity: 1,
    maxWidth: 240,
  },
  image: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(-0.5),
    [theme.breakpoints.down("sm")]: {
      width: 208,
      height: 240,
      marginTop: theme.spacing(-3),
      marginLeft: "40%",
      marginBottom: theme.spacing(-5),
    },
  },
  buttonConfirm: {
    width: 264,
  },
}));

const MedicalCheckInDetails = ({ moveToNextStep }) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const onSubmit = (values) => {
    moveToNextStep(values);
  };

  return (
    <BookingContainer title={t("Medical.CheckIn.Post.Title")}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
        }}
        validationSchema={getValidationSchema(t, {
          firstName: true,
          lastName: true,
          email: true,
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <BookingCard
              image={<ManTrumpet className={classes.image} />}
              actions={
                <Button className={classes.buttonConfirm} type="submit">
                  <Typography
                    variant="subtitle1"
                    className={classes.fillDetails}
                  >
                    {t("Medical.CheckIn.Post.CTA")}
                  </Typography>
                </Button>
              }
            >
              <Typography variant="subtitle1" className={classes.fillDetails}>
                {t("Medical.CheckIn.Post.SubTitle")}
              </Typography>

              <TextField
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                helperText={touched.firstName && errors.firstName}
                label={t("CreateAccountView.FirstName")}
                margin="normal"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                variant="standard"
              />
              <TextField
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                helperText={touched.lastName && errors.lastName}
                label={t("CreateAccountView.LastName")}
                margin="normal"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                variant="standard"
              />
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={t("CreateAccountView.EmailMedical")}
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="standard"
              />
            </BookingCard>
          </form>
        )}
      </Formik>
    </BookingContainer>
  );
};

export default MedicalCheckInDetails;
