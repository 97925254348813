import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { CircularProgress } from "@mui/material";
import { ReactComponent as WomanMeditating } from "@src/resources/images/woman, meditation, meditate, fitness.svg";
import { useEffect } from "react";

interface ResilienceCheckinCalculatingScoreProps {
  onSaveQuestionList: () => void;
  goToNextStep: () => void;
}

const ResilienceCheckinCalculatingScore: React.FC<
  ResilienceCheckinCalculatingScoreProps
> = ({ onSaveQuestionList, goToNextStep }) => {
  const { t } = useTranslatedNavigate();
  useEffect(() => {
    onSaveQuestionList();
    // Use setTimeout to execute the method after 3 seconds
    const timeoutId = setTimeout(goToNextStep, 3000);

    // Clean up the timeout if the component unmounts before 3 seconds
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <div className="my-8">
        <CircularProgress />
      </div>
      <div>
        <p className="text-xl my-2 text-center font-medium leading-6 tracking-[0.15px] text-indigo-800">
          {t("ResilienceCheckin.Score.CalculatingScore")}
        </p>
        <p className="font-medium w-5/6 mx-auto text-center leading-6 tracking-[0.15px] text-gray-500">
          {t("ResilienceCheckin.Score.Mechanism")}
        </p>
      </div>
      <WomanMeditating />
    </div>
  );
};
export default ResilienceCheckinCalculatingScore;
