import { Card, Container, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { color } from "tripetto-runner-autoscroll";

const useStyles = makeStyles(({ breakpoints, spacing, customShadows }) => ({
  root: {
    maxWidth: 583,
  },
  container: () => ({
    position: "relative",
    [breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: 0,
      margin: 0,
      boxShadow: "none",
      borderRadius: 0,
    },
    [breakpoints.up("sm")]: {
      boxShadow: customShadows.bookingContainerBackgroundShadow,
      backgroundColor: color.trueWhite,
      borderRadius: 16,
    },
  }),
  titleBox: ({ isTitleCentered }) => ({
    textAlign: isTitleCentered ? "center" : "left",
    marginBottom: spacing(2),
    [breakpoints.up("sm")]: {
      marginTop: spacing(4),
    },
  }),
  title: {
    fontSize: 25,
    lineHeight: "32px",
    [breakpoints.up("sm")]: {
      fontSize: 35,
      letterSpacing: "0.25px",
      lineHeight: "48px",
    },
  },
  subtitle: {
    marginTop: spacing(2),
    opacity: 0.7,
  },
  messageText: {
    [breakpoints.down("sm")]: {
      lineHeight: "32px",
    },
    textAlign: "center",
    fontSize: 16,
  },
}));

const WizardContainer = ({
  title,
  subtitle,
  children,
  currentPage,
  isTitleCentered = true,
  className = "",
}) => {
  const classes = useStyles({ currentPage, isTitleCentered });

  return (
    <Container className={`${classes.root} ${className}`}>
      {title && (
        <Box className={classes.titleBox}>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="subtitle1" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
      <Card className={classes.container}>{children}</Card>
    </Container>
  );
};

export default WizardContainer;
