/* eslint-disable react/jsx-props-no-spreading */
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import {
  LanguageCodeDutchNl,
  LanguageCodeEnglishGb,
  LanguageCodeFrenchFr,
  LanguageCodeGermanDe,
} from "@src/utils/languageHelpers";

import { ReactComponent as LogoSVGEnglish } from "@src/resources/images/openUpLogoEnglish.svg";
import { ReactComponent as LogoSVGDutch } from "@src/resources/images/openUpLogoDutch.svg";
import { ReactComponent as LogoSVGGerman } from "@src/resources/images/openUpLogoGerman.svg";
import { ReactComponent as LogoSVGDefault } from "@src/resources/images/logotype.svg";

const Logo = ({ showLanguageSpecificIcon = false, ...props }) => {
  const { i18n } = useTranslatedNavigate();

  if (showLanguageSpecificIcon) {
    switch (i18n.language) {
      case LanguageCodeEnglishGb:
      case LanguageCodeFrenchFr:
        return <LogoSVGEnglish {...props} />;
      case LanguageCodeDutchNl:
        return <LogoSVGDutch {...props} />;
      case LanguageCodeGermanDe:
        return <LogoSVGGerman {...props} />;

      default:
        return <LogoSVGDefault {...props} />;
    }
  }

  return <LogoSVGDefault {...props} />;
};

export default Logo;
