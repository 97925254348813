import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { env } from "@src/env";

import { Suspense } from "react";
import App from "./App";
import webVitalsReporter from "./appInsights/webVitalsReporter";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

import "@openup/shared/tailwind.css";

if (env.REACT_APP_HOSTING_ENV === "production") {
  Sentry.init({
    dsn: "https://aaa8b206cb354fb594ec2ddae13413f1@o289881.ingest.sentry.io/5751785",
    release: env.REACT_APP_GIT_COMMIT_HASH,
    environment: env.REACT_APP_HOSTING_ENV,
  });
}

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <App />
      </Suspense>
    </BrowserRouter>
  </Provider>,
);

reportWebVitals(webVitalsReporter);
