/* eslint-disable camelcase */
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Page from "@src/components/Page";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandableTypography from "@src/components/ExpandableText";
import { useQuerySanityTheme } from "@src/queries/themes";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import { displayLogic } from "@src/utils/constants";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatScore } from "@src/utils/formatting";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import BlockContent from "@sanity/block-content-to-react";

const useStyle = makeStyles((theme) => ({
  pageTheme: {
    lineHeight: "48px",
    letterSpacing: "0.25px",
  },
  toolkitOuterContainer: {
    borderRadius: 16,
    backgroundColor: "white",
    width: 840,
    minHeight: 344,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: 328,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  toolkitInnerContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    alignItems: "center",
  },
  toolkitHorizontalContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  toolkitTitle: {
    variant: "h2",
    paddingLeft: theme.spacing(1),
  },
  toolkitImageContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    position: "absolute",
  },
  toolkitImage: {
    width: "100%",
  },
  toolkitTextContainer: {
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 0),
    },
  },
  toolkitText: {
    display: "inline-block",
    fontWeight: 400,
    opacity: 1,
    letterSpacing: 0.5,
    "& > div > ol": {
      marginLeft: theme.spacing(2),
    },
  },
  toolkitPageContainer: {
    display: "flex",
    position: "absolute",
  },
  toolkitMediaContainer: {
    display: "flex",
    position: "relative",
    alignItems: "end",
    justifyContent: "center",
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    width: 216,
    height: 216,
  },
  pageIndicator: {
    zIndex: 1,
    marginBottom: theme.spacing(3),
    alignSelf: "flex-end",
  },
}));

const ThemeViewBestPractices = ({ themeKey, lastCheckin }) => {
  const classes = useStyle();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { t } = useTranslatedNavigate();
  const { data: sanityThemeContent } = useQuerySanityTheme(themeKey);
  const { theme_exercises } = sanityThemeContent;
  const [currentPage, setCurrentPage] = useState(0);
  const maxPages = theme_exercises ? theme_exercises.length : 0;
  const currentImage = theme_exercises[currentPage].imageUrl;

  const pagesList = () => {
    const pageList = [];
    for (let i = 0; i < maxPages; i += 1) {
      if (i === currentPage) {
        pageList.push(
          <FiberManualRecordIcon className={classes.pageIndicator} key={i} />,
        );
      } else {
        pageList.push(
          <FiberManualRecordOutlinedIcon
            className={classes.pageIndicator}
            key={i}
          />,
        );
      }
    }
    return pageList;
  };

  const nextPage = () => {
    if (currentPage + 1 === maxPages) {
      setCurrentPage(0);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage - 1 < 0) {
      setCurrentPage(maxPages - 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const { category } =
    displayLogic[lastCheckin?.isTrackedTheme ? lastCheckin.category : 0];

  return (
    <Page
      id="ThemeViewBestPractices"
      title={t("ThemeViewBestPractices.PageTitle", {
        themeName: t(`Themes.${themeKey}.Name`),
      })}
    >
      <Grid container>
        <Typography variant="h2">
          {t(`ThemeViewBestPractices.${themeKey}.PageHeader.${category}`)}
        </Typography>
        <ExpandableTypography
          text={t(
            `ThemeViewBestPractices.${themeKey}.MainFeedbackText.${category}`,
            {
              key: lastCheckin?.score ? formatScore(lastCheckin?.score) : 5,
            },
          )}
          variant={isSmallScreen ? "h6" : "body1"}
        />
        <Box className={classes.toolkitOuterContainer}>
          <Box className={classes.toolkitHorizontalContainer}>
            <div>
              <MedicalServicesOutlinedIcon />
            </div>
            <div>
              <Typography variant="h5" className={classes.toolkitTitle}>
                Toolkit
              </Typography>
            </div>
          </Box>
          <Box className={classes.toolkitInnerContainer}>
            <Box className={classes.toolkitHorizontalContainer}>
              <div>
                <NavigateBeforeIcon onClick={previousPage} />
              </div>
              <Box className={classes.toolkitMediaContainer}>
                <Box className={classes.toolkitImageContainer}>
                  <img
                    alt="Exercise_Image"
                    src={currentImage}
                    className={classes.toolkitImage}
                  />
                </Box>
                <Box className={classes.toolkitPageContainer}>{pagesList}</Box>
              </Box>
              <div>
                <NavigateNextIcon onClick={nextPage} />
              </div>
            </Box>
            <Box className={classes.toolkitTextContainer}>
              <Typography variant="body2" className={classes.toolkitText}>
                <BlockContent
                  blocks={theme_exercises[currentPage].exercises.exercise.text}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Page>
  );
};

export default ThemeViewBestPractices;
