import { Metric } from "web-vitals";
import { appInsights } from "./AppInsights";

export default function webVitalsReporter(metric: Metric) {
  appInsights.trackMetric(
    {
      name: metric.name,
      average: Math.round(
        metric.name === "CLS" ? metric.value * 1000 : metric.value,
      ),
    },
    { rating: metric.rating },
  );
}
