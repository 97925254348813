/* eslint-disable camelcase */
import { makeStyles } from "@mui/styles";
import { Trans } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ExpandableTypography from "@src/components/ExpandableText";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import WomanBoard from "@src/resources/images/woman_board.svg";
import WomanInnovation from "@src/resources/images/woman-innovation.png";
import WomanReading from "@src/resources/images/woman_reading.svg";
import IllustrationWomen from "@src/resources/images/ilustation_women.svg";
import ChildBoat from "@src/resources/images/child_boat.svg";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { HOVER_TYPE } from "@src/utils/constants";
import { useQuerySanityTheme } from "@src/queries/themes";
import BlockContent from "@sanity/block-content-to-react";

const useStyle = ({ isHover }) =>
  makeStyles((theme) => ({
    boxTip: {
      marginTop: theme.spacing(4),
      maxWidth: 340,
    },
    insightsCardText: {
      opacity: 0.7,
      marginTop: theme.spacing(1),
    },
    insightsCardTitle: {
      opacity: 1,
    },
    buttonText: {
      letterSpacing: "1.25px",
      color: "white",
      minWidth: 180,
    },
    button: {
      marginTop: theme.spacing(4),
      minWidth: 180,
    },
    imgIllustrationWomen: {
      width: 296,
      height: 237,
    },
    imgWomanInnovation: {
      width: 114,
      height: 187,
      transform: "scaleX(-1)",
      float: "right",
      marginBottom: theme.spacing(-4),
    },
    outerBox: {
      display: "flex",
      flexDirection: "column",
      borderRadius: "0px",
      borderTopRightRadius: "16px",
      borderBottomRightRadius: "16px",
      backgroundColor:
        isHover === HOVER_TYPE.NONE
          ? theme.colors.lightGrayinshOrange
          : "white",
      alignItems: isHover === HOVER_TYPE.NONE ? "flex-end" : "normal",
      padding: theme.spacing(4, 3),
      boxShadow: "none",
      width: 435,
    },
    contentBox: {
      alignItems: "center",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
    },
    imageNoHover: {
      borderBottomRightRadius: "16px",
      marginBottom: theme.spacing(-4),
      marginRight: theme.spacing(-3),
    },
    imageContainer: {
      display: "flex",
      height: "100%",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    imgWomanReading: {
      float: "right",
      marginBottom: theme.spacing(-4),
    },
    textContainer: {
      width: 339,
      marginTop: theme.spacing(3),
    },
    bestPracticesText: {
      opacity: "0.7",
    },
    checkMark: {
      color: "#48B4FF",
      display: "inline-flex",
      padding: 2,
      marginRight: theme.spacing(1),
    },
  }));

const HoverContent = ({
  isHover,
  themeKey,
  lastCheckin,
  firstThreeWorstQuestions,
}) => {
  const classes = useStyle({ isHover })();
  const { t, pathT } = useTranslatedNavigate(isHover);
  const { data: sanityThemeContent } = useQuerySanityTheme(themeKey);
  const { theme_exercises, translated_content } = sanityThemeContent;
  const navigate = useNavigate();

  const navigateThemeAction = () => {
    const themeRoute = t("route.themes", {
      key: t(`route.ThemeKey.${themeKey}`),
    });
    navigate(`/${themeRoute}/${t("route.checkin")}`);
  };

  switch (isHover) {
    case HOVER_TYPE.INSIGHTS:
      return (
        <Box className={classes.outerBox}>
          <Box className={classes.contentBox}>
            <Trans>
              <Typography variant="h5">
                {t("ThemeViewOverview.HoverMenuInsights.Title")}
              </Typography>
            </Trans>
            {/* eslint-disable-next-line no-nested-ternary */}
            {lastCheckin.isTrackedTheme ? (
              firstThreeWorstQuestions.length ? (
                firstThreeWorstQuestions.map((question) => (
                  <Box className={classes.boxTip}>
                    <Typography
                      variant="subtitle2"
                      className={classes.insightsCardTitle}
                    >
                      <div className={classes.checkMark}>✓</div>
                      {question.goal}
                    </Typography>
                    <ExpandableTypography
                      variant="caption"
                      className={classes.insightsCardText}
                      text={question.tip}
                    />
                  </Box>
                ))
              ) : (
                <Box className={classes.boxTip}>
                  <Typography
                    variant="subtitle2"
                    className={classes.insightsCardTitle}
                  >
                    {t(
                      `ThemeViewInsights.${themeKey}.TitleTextImprovementTopScore`,
                    )}
                  </Typography>
                  <ExpandableTypography
                    variant="caption"
                    className={classes.insightsCardText}
                    text={t(
                      `ThemeViewInsights.${themeKey}.DescriptionTextImprovementTopScore1`,
                    )}
                  />
                </Box>
              )
            ) : (
              <>
                <ExpandableTypography
                  variant="body2"
                  className={classes.insightsCardTitle}
                  text={t("ThemeViewOverview.HoverMenuFirstThick.Text")}
                />
                <Box className={classes.imageContainer}>
                  <img
                    alt="Woman Innovation"
                    className={classes.imgIllustrationWomen}
                    src={IllustrationWomen}
                  />
                </Box>
                <Button
                  className={classes.button}
                  onClick={navigateThemeAction}
                >
                  <Typography
                    variant="subtitle2"
                    className={classes.buttonText}
                  >
                    {t("DashboardView.Chart.CheckinButton")}
                  </Typography>
                </Button>
              </>
            )}
          </Box>
          {lastCheckin.isTrackedTheme && (
            <Box className={classes.imageContainer}>
              <img
                alt="Woman Innovation"
                className={classes.imgWomanInnovation}
                src={WomanInnovation}
              />
            </Box>
          )}
        </Box>
      );
    case HOVER_TYPE.EDUCATION:
      return (
        <Box className={classes.outerBox}>
          <Box className={classes.contentBox}>
            <Typography variant="h5">
              {t("ThemeViewOverview.HoverMenuEducation.Title", {
                key: themeKey,
              })}
            </Typography>
            <Box className={classes.textContainer}>
              <Typography
                variant="caption"
                className={classes.bestPracticesText}
              >
                <BlockContent
                  blocks={translated_content.questions_and_answers.description}
                />
              </Typography>
            </Box>
            <RouterLink
              to={pathT("route.education", {
                key: t(`route.ThemeKey.${themeKey}`),
              })}
            >
              <Button className={classes.button}>
                <Typography variant="subtitle2" className={classes.buttonText}>
                  {t("ThemeViewOverview.HoverMenu.MoreEducation")}
                </Typography>
              </Button>
            </RouterLink>
          </Box>
          <Box className={classes.imageContainer}>
            <img
              alt="Woman Reading"
              className={classes.imgWomanReading}
              src={WomanBoard}
            />
          </Box>
        </Box>
      );
    case HOVER_TYPE.BEST_PRACTICES:
      return (
        <Box className={classes.outerBox}>
          <Box className={classes.contentBox}>
            <Typography variant="h5">
              {t("ThemeViewOverview.HoverMenuBestPractices.Title", {
                key: themeKey,
              })}
            </Typography>
            <Box className={classes.textContainer}>
              <Typography
                variant="caption"
                className={classes.bestPracticesText}
              >
                <BlockContent
                  blocks={theme_exercises[0].exercises.exercise.text}
                />
              </Typography>
            </Box>
            <RouterLink to={pathT("route.bestPractices", { key: themeKey })}>
              <Button className={classes.button}>
                <Typography variant="subtitle2" className={classes.buttonText}>
                  {t("ThemeViewOverview.HoverMenu.MoreBestPractices")}
                </Typography>
              </Button>
            </RouterLink>
          </Box>
          <Box className={classes.imageContainer}>
            <img
              alt="Woman Reading"
              className={classes.imgWomanReading}
              src={WomanReading}
            />
          </Box>
        </Box>
      );
    default: {
      return (
        <Box className={classes.outerBox}>
          <Box className={classes.imageContainer}>
            <img
              alt="Child boat"
              className={classes.imageNoHover}
              src={ChildBoat}
            />
          </Box>
        </Box>
      );
    }
  }
};

export default HoverContent;
