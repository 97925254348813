import { createContext, useMemo, useState } from "react";
import {
  Link as RouterLink,
  Outlet,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { alpha, Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";

import { BACKGROUND, displayLogic } from "@src/utils/constants";
import useTranslatedNavigate, {
  convertThemeNameToThemeKey,
} from "@src/services/useTranslateNavigate";
import colors from "@src/theme/colors";
import { useQueryThemeCheckins } from "@src/queries/themes";
import MenuList from "@src/components/OpenUpMenuList";
import ScoreLabel from "@src/views/account/components/ScoreLabel";
import AllQuestionsModal from "@src/views/account/components/AllQuestionsModal";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: ({ backgroundColor }) => ({
    backgroundColor,
    backgroundAttachment: "fixed, fixed",
    backgroundPosition: "left bottom, right top",
    backgroundRepeat: "no-repeat",

    [breakpoints.down("sm")]: {
      backgroundSize: 140,
    },
  }),
  navigationMenuRoot: {
    marginBottom: spacing(6),
    overflowY: "auto",
    height: 34,
    whiteSpace: "nowrap",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& > * + *": {
      marginLeft: spacing(2),
    },
  },
  subMenuProperties: {
    backgroundColor: colors.lightGrayinshOrange,
    borderRadius: "20px",
    padding: spacing(1, 2),
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: `${alpha(colors.primaryBlack, 0.2)}`,
    },
  },
  subMenuSelected: {
    backgroundColor: `${alpha(colors.primaryBlack, 0.2)}`,
  },
  menuGrid: {
    backgroundColor: colors.secondarySkin,
    marginRight: spacing(6),
    marginTop: spacing(-9),
    marginBottom: spacing(-3.75),
    paddingTop: spacing(9),
    paddingLeft: spacing(2),
    paddingRight: spacing(1),
    marginLeft: spacing(-2),
  },
}));

export const AllQuestionsModalContext = createContext({
  toggleFocusModal: () => undefined,
});

const LayoutThemePage = ({ backgroundColor, className }) => {
  const classes = useStyles({ backgroundColor });
  const { t, pathT } = useTranslatedNavigate();
  const { themeName } = useParams();
  const { pathname } = useLocation();

  const [openQuestionsModal, setOpenQuestionsModal] = useState(false);
  const toggleFocusModal = () => {
    setOpenQuestionsModal((value) => !value);
  };
  const themeKey = convertThemeNameToThemeKey(themeName, t);
  const { data: themeCheckins = [] } = useQueryThemeCheckins(themeKey);

  const themesSorted = useMemo(() => {
    if (!themeKey) return null;

    let sortedThemes = [];
    if (Array.isArray(themeCheckins.questionLists)) {
      const questionLists = [...themeCheckins.questionLists].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      );
      if (questionLists.length > 0)
        sortedThemes = questionLists.map((list) => ({
          ...list,
          ...{
            ordinal: themeCheckins.ordinal,
            isDefaultTheme: themeCheckins.isDefaultTheme,
            isTrackedTheme: themeCheckins.isTrackedTheme,
            key: themeCheckins.key,
          },
        }));
      else {
        sortedThemes = [
          {
            ...{
              ordinal: themeCheckins.ordinal,
              isDefaultTheme: themeCheckins.isDefaultTheme,
              isTrackedTheme: themeCheckins.isTrackedTheme,
              key: themeCheckins.key,
            },
            ...{
              score: 0,
              category: 0,
              createdAt: new Date(0),
            },
          },
        ];
      }
    }
    return sortedThemes;
  }, [themeKey, themeCheckins]);

  if (!themeKey) return <Navigate to={pathT("route.account")} />;

  const lastCheckin = themesSorted.length
    ? themesSorted[themesSorted.length - 1]
    : undefined;
  const { color: iconBackgroundColor } =
    displayLogic[lastCheckin ? lastCheckin.category : 0];

  const isFocused = (url) => url === pathname;

  const overviewPath = pathT("route.themes", { key: themeName });
  const insightsPath = pathT("route.insights", { key: themeName });
  const bestPracticesPath = pathT("route.bestPractices", { key: themeName });
  const educationPath = pathT("route.education", { key: themeName });

  return (
    <div className={clsx(classes.root, className)}>
      <Box
        pt={9}
        display="flex"
        flexDirection="flex"
        height="100%"
        justifyContent="center"
        overflow="hidden"
        paddingTop="36px"
      >
        <Container maxWidth="lg">
          <Grid container>
            <Hidden mdDown>
              <Grid item xs className={classes.menuGrid}>
                <MenuList background={BACKGROUND.SKIN_BACKGROUND} isOpen />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <Typography className={classes.navigationMenuRoot}>
                <Link
                  component={RouterLink}
                  to={overviewPath}
                  className={clsx(
                    classes.subMenuProperties,
                    isFocused(overviewPath) && classes.subMenuSelected,
                  )}
                  underline="hover"
                >
                  {t(`ThemeViewInsights.${themeKey}.Overview`)}
                </Link>
                <Link
                  component={RouterLink}
                  to={insightsPath}
                  className={clsx(
                    classes.subMenuProperties,
                    isFocused(insightsPath) && classes.subMenuSelected,
                  )}
                  underline="hover"
                >
                  {t(`ThemeViewInsights.${themeKey}.Insights`)}
                </Link>
                <Link
                  component={RouterLink}
                  to={bestPracticesPath}
                  className={clsx(
                    classes.subMenuProperties,
                    isFocused(bestPracticesPath) && classes.subMenuSelected,
                  )}
                  underline="hover"
                >
                  {t(`ThemeViewInsights.${themeKey}.BestPractices`)}
                </Link>
                <Link
                  component={RouterLink}
                  to={educationPath}
                  className={clsx(
                    classes.subMenuProperties,
                    isFocused(educationPath) && classes.subMenuSelected,
                  )}
                  underline="hover"
                >
                  {t(`ThemeViewInsights.${themeKey}.Education`)}
                </Link>
              </Typography>
              <ScoreLabel
                onClick={toggleFocusModal}
                color={iconBackgroundColor}
                score={lastCheckin?.score}
                themeKey={themeKey}
              />
              {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
              <AllQuestionsModalContext.Provider value={{ toggleFocusModal }}>
                <Outlet />
              </AllQuestionsModalContext.Provider>
              <AllQuestionsModal
                themeKey={themeKey}
                open={openQuestionsModal}
                onClose={toggleFocusModal}
                themesSorted={themesSorted}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default LayoutThemePage;
