import * as Yup from "yup";

const getEmailValidation = (t) =>
  Yup.string()
    .email(t("Validations.InvalidEmail"))
    .max(255)
    .required(t("Validations.RequiredField"));

export const getValidationSchema = (t, { email = false }) =>
  Yup.object().shape({
    email: email && getEmailValidation(t),
  });
