import { useNavigate } from "react-router-dom";
import { Box, Container, Button, Typography } from "@mui/material";
import Page from "@src/components/Page";

import Loader from "@src/components/Loader";
import ImageManWithChair from "@src/components/ImageManWithChair";
import AuthService from "@src/services/auth.service";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { timeSnackbar } from "@src/utils/constants";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { useState } from "react";

const LinkExpiredView = ({ ...props }) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email").replace(/ /g, "+");

  const onSubmit = async () => {
    if (!email) {
      props.snackbarShowMessage("LinkExpiredView.SessionExpired");
      setLoading(false);
      setTimeout(() => {
        navigate(pathT("route.register"));
      }, timeSnackbar);
    } else {
      try {
        await AuthService.resendVerificationEmail(email);
        props.snackbarShowMessage("Success", "success");
        setTimeout(() => {
          navigate(pathT("route.login"));
        }, timeSnackbar);
      } catch (error) {
        props.snackbarShowMessage(error.response.data);
        setTimeout(() => {
          navigate(pathT("route.login"));
        }, timeSnackbar);
      }
    }
  };

  const handleClick = () => {
    if (!loading) {
      setLoading(true);
      onSubmit();
    }
  };

  return (
    <Page title={t("LinkExpiredView.Title")}>
      <Box
        display="flex"
        flexDirection="flex"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box>
            <Typography variant="h3">{t("LinkExpiredView.Title")}</Typography>
          </Box>
          <Box mt={5}>
            <Typography variant="h4">
              {t("LinkExpiredView.ShallWeSendNewEmail")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="top" ml={1}>
            <Typography color="textSecondary" variant="body1" />
          </Box>
          <Box alignItems="center" display="flex" ml={0} mt={5}>
            <Button
              disabled={loading}
              fullWidth
              size="large"
              type="Button"
              onClick={handleClick}
              variant="contained"
            >
              {t("LinkExpiredView.SendMeNewEmail")}
            </Button>
            {loading && <Loader />}
          </Box>
          <ImageManWithChair />
        </Container>
      </Box>
    </Page>
  );
};

LinkExpiredView.propTypes = {};

export default withSnackbar(LinkExpiredView);
