import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ManChairTable } from "@src/resources/images/man_chair_table.svg";
import { logout } from "@src/actions/userActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isClientUser } from "@src/utils/helpers";
import authService from "@src/services/auth.service";

const useStyle = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    borderRadius: spacing(2),
    marginTop: spacing(4),
    width: "90%",
    [breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  container: {
    padding: spacing(4, 6),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [breakpoints.down("sm")]: {
      padding: spacing(3, 2),
    },
  },
  button: {
    letterSpacing: 1.25,
    marginTop: spacing(4),
    width: 296,
    [breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  image: {
    height: 218,
    width: 218,
  },
  text: {
    textAlign: "center",
  },
}));

const MagicLinkAccessLimitedView = ({ logoutUser, message }) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const classes = useStyle();
  const currentUser = authService.getUserFromStorage();

  const handleRedirect = () => {
    logoutUser();

    if (isClientUser(currentUser)) {
      navigate(pathT("route.login"));
    } else {
      navigate(pathT("route.register"));
    }
  };

  return (
    <Paper className={classes.root}>
      <Box className={classes.container} data-cy="no-access">
        <ManChairTable className={classes.image} />
        <Typography variant="subtitle1" className={classes.text}>
          {message}
        </Typography>
        <Button classes={{ root: classes.button }} onClick={handleRedirect}>
          {isClientUser(currentUser)
            ? t("MagicLinkAccessLimitedView.Login")
            : t("MagicLinkAccessLimitedView.SignUp")}
        </Button>
      </Box>
    </Paper>
  );
};

MagicLinkAccessLimitedView.propTypes = {
  logoutUser: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => {
    dispatch(logout());
  },
});

export default connect(null, mapDispatchToProps)(MagicLinkAccessLimitedView);
