import { useState, useContext, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "@src/components/Page";
import PropTypes from "prop-types";
import authService from "@src/services/auth.service";
import sessionStorageService from "@src/services/sessionStorage.service";
import { registerGuest } from "@src/actions/userActions";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { ApplicationStateContext } from "@src/utils/ApplicationStateContext";
import CenteredLoader from "@src/components/CenteredLoader";
import CreateAccountStep1 from "@src/views/auth/CreateAccount/CreateAccountStep1";
import CreateAccountStep2 from "@src/views/auth/CreateAccount/CreateAccountStep2";
import ExplicitConsent from "@src/components/ExplicitConsent";
import { isPasswordRegisterProhibited } from "@src/utils/helpers";
import MessageCheckInResultView from "./MessageCheckInResultView";

const useStyles = makeStyles(() => ({
  pageMargin: {
    "@media (max-width: 599px)": {},
    "@media (min-width: 600px)": {
      width: "583px !important",
      margin: "auto",
    },
  },
}));

export const STEPS = {
  CREATE_ACCOUNT_STEP_1: "step_1",
  CREATE_ACCOUNT_STEP_2: "step_2",
  EXPLICIT_CONSENT: "explicit_consent",
  EMAIL_SENT: "results_sent",
};

const CreateAccountView = ({ registerGuest: registerNewGuest }) => {
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();

  const { step, nextStep, setPreventGoingBack, setupFlowSteps } =
    useOutletContext();

  const firstName = useRef("");
  const email = useRef("");
  const acceptedMedicalDataProcessing = useRef(false);
  const acceptedTermsAndConditions = useRef(false);

  const { setPassword } = useContext(ApplicationStateContext);

  const [isSubmitLocked, setIsSubmitLocked] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setupFlowSteps(STEPS), []);

  useEffect(() => {
    if (step === STEPS.EMAIL_SENT) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  const emailResults = async () => {
    setIsSubmitLocked(true);
    await registerNewGuest(
      firstName.current,
      email.current,
      acceptedTermsAndConditions.current,
      acceptedMedicalDataProcessing.current,
    );
  };

  const registerUser = () => {
    sessionStorageService.saveNameToStorage(firstName.current);
    authService.saveUserToStorage({
      email: email.current,
    });
    navigate(pathT("route.register"), {
      replace: true,
    });
  };

  const selectStep = () => {
    switch (step) {
      case STEPS.CREATE_ACCOUNT_STEP_1:
        return (
          <CreateAccountStep1
            moveToNextStep={(values) => {
              firstName.current = values.firstName;
              sessionStorageService.saveNameToStorage(values.firstName);
              email.current = values.email;

              if (isPasswordRegisterProhibited(values.email)) {
                navigate(
                  `${pathT("route.singleSignOn")}?IsSignUp=true&email=${values.email}`,
                );
              } else {
                nextStep();
              }
            }}
          />
        );
      case STEPS.CREATE_ACCOUNT_STEP_2:
        return (
          <CreateAccountStep2
            isSubmitLocked={isSubmitLocked}
            moveToNextStep={(values, wantsToRegister) => {
              if (wantsToRegister) {
                setPassword(values.password);
                registerUser();
              } else {
                nextStep();
              }
            }}
          />
        );
      case STEPS.EXPLICIT_CONSENT:
        return (
          <ExplicitConsent
            moveToNextStep={async (
              termsAndConditionsValue,
              medicalDataProcessingValue,
            ) => {
              acceptedMedicalDataProcessing.current =
                medicalDataProcessingValue;
              acceptedTermsAndConditions.current = termsAndConditionsValue;
              await emailResults();
              nextStep();
            }}
          />
        );
      case STEPS.EMAIL_SENT:
        return <MessageCheckInResultView />;
      default:
        return <CenteredLoader />;
    }
  };

  return (
    <Page title={t("CreateAccountView.Title")} style={{ textAlign: "left" }}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" className={classes.pageMargin}>
          {selectStep()}
        </Container>
      </Box>
    </Page>
  );
};

CreateAccountView.propTypes = {
  registerGuest: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    registerGuest: (
      firstName,
      email,
      termsAndConditions,
      medicalDataProcessing,
    ) =>
      dispatch(
        registerGuest(
          firstName,
          "",
          email,
          null,
          null,
          null,
          termsAndConditions,
          medicalDataProcessing,
          true,
          false,
          false,
        ),
      ),
  };
}

const mapStateToProps = (state) => ({ authReducers: state.authReducers });

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountView);
