import type {
  FC,
  FormEvent,
  KeyboardEvent,
  ReactElement,
  RefObject,
} from "react";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";

interface CodeInputProps {
  code: string;
  codeLength: number;
  onInput: (inputCode: string) => void;
  isErrorState: boolean;
  onFocus: () => void;
}

const CodeInput: FC<CodeInputProps> = ({
  code,
  codeLength,
  onInput,
  isErrorState,
  onFocus,
}) => {
  const [inputElements, setInputElements] = useState<ReactElement[]>([]);
  const inputRefList = useRef<RefObject<HTMLInputElement>[]>([]);

  const moveToNextInputField = useCallback(
    (currentInputIndex: number) => {
      inputRefList.current?.[currentInputIndex + 1]?.current?.focus();
    },
    [inputRefList],
  );

  const moveToPreviousInputField = useCallback(
    (currentInputIndex: number) => {
      inputRefList.current?.[currentInputIndex - 1]?.current?.focus();
    },
    [inputRefList],
  );

  const handleInput = useCallback(
    (event: FormEvent<HTMLDivElement>, index: number) => {
      onInput(inputRefList.current?.map((ref) => ref.current?.value).join(""));
      if ((event.target as HTMLInputElement).value) {
        moveToNextInputField(index);
      }
    },
    [inputRefList, moveToNextInputField, onInput],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>, index: number) => {
      if (inputRefList) {
        onInput(
          inputRefList.current?.map((ref) => ref.current?.value).join(""),
        );
      }

      if (
        event.code === "Backspace" &&
        !(event.target as HTMLInputElement).value
      ) {
        moveToPreviousInputField(index);
      }
    },
    [moveToPreviousInputField, onInput],
  );

  useEffect(() => {
    const onPaste = (pasteEvent: ClipboardEvent) => {
      const pastedCode = pasteEvent.clipboardData?.getData("text")?.trim();
      // check if pasted code is a number
      if (pastedCode && /[0-9]/.test(pastedCode)) {
        onInput(pastedCode.slice(0, codeLength));
      } else {
        pasteEvent.preventDefault();
      }
    };

    window.addEventListener("paste", onPaste);

    return () => {
      window.removeEventListener("paste", onPaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (!code) {
      return;
    }

    [...code].forEach((char, index) => {
      const inputRef = inputRefList.current?.[index];
      if (inputRef?.current) {
        inputRef.current.value = char;
      }
    });

    const focusIndex = code.length < codeLength ? code.length : codeLength - 1;
    inputRefList.current?.[focusIndex].current?.focus();
  }, [code, codeLength]);

  useEffect(() => {
    if (!isErrorState) {
      return;
    }
    inputRefList.current?.forEach((inputRef) => {
      if (inputRef?.current) {
        inputRef.current.value = "";
      }
    });
  }, [inputRefList, isErrorState]);

  useEffect(() => {
    const initialInputRefList: RefObject<HTMLInputElement>[] = [];
    const initialInputElements: JSX.Element[] = [];

    for (let index = 0; index < codeLength; index += 1) {
      const inputRef = createRef<HTMLInputElement>();

      initialInputRefList.push(inputRef);
      initialInputElements.push(
        <TextField
          error={isErrorState}
          onKeyDown={(event) => handleKeyDown(event, index)}
          onInput={(event) => handleInput(event, index)}
          onFocus={onFocus}
          type="text"
          inputProps={{
            maxLength: 1,
            "data-cy": `two-fa-input-${index}`,
            className: "text-center py-4",
          }}
          autoFocus={index === 0}
          variant="standard"
          inputRef={inputRef}
          autoComplete="off"
          key={index}
        />,
      );
    }

    inputRefList.current = initialInputRefList;
    setInputElements(initialInputElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  return <div className="flex justify-center gap-4 my-6">{inputElements}</div>;
};

export default CodeInput;
