import { Box, Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { ReactComponent as WomanReading } from "@src/resources/images/woman_reading.svg";
import { ReactComponent as WomanJumping } from "@src/resources/images/woman_jumping_complete.svg";
import { useNavigate } from "react-router-dom";
import { formatConsultHour, formatWeekWithYear } from "@src/utils/formatting";
import moment from "moment/moment";

const useStyle = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    width: "110%",
  },
  typography: {
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
    letterSpacing: 1.25,
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  imageReading: {
    width: 137,
    height: 137,
    marginTop: theme.spacing(6.5),
    marginRight: theme.spacing(-2.5),
  },
  imageJumping: {
    width: 102,
    height: 193,
    marginTop: theme.spacing(1),
  },
}));

const FollowUpSessionPaper = ({ consult, followUpSession }) => {
  const classes = useStyle();
  const { pathT, t } = useTranslatedNavigate();
  const navigate = useNavigate();

  const onClick = (event) => {
    event.stopPropagation();
    navigate(pathT("route.booking"));
  };

  const hasNoFollowUp = () => (
    <>
      <Typography className={classes.typography} variant="h5">
        {t("MySessionsView.Notes.FollowUp.Title2")}
      </Typography>
      <Typography className={classes.typography} variant="subtitle2">
        {t("MySessionsView.Notes.FollowUp.NotBooked", {
          psychologistFullname: consult.employeeFullName,
        })}
      </Typography>
      <Button className={classes.button} onClick={onClick}>
        {t("Psychologist.Book")}
      </Button>
      <Box className={classes.imageContainer}>
        <WomanReading className={classes.imageReading} />
      </Box>
    </>
  );

  const hasFollowUp = () => (
    <>
      <Typography className={classes.typography} variant="h5">
        {t("MySessionsView.Notes.FollowUp.Title1")}
      </Typography>
      <Typography className={classes.typography} variant="subtitle2">
        {t("MySessionsView.Notes.FollowUp.Booked", {
          sessionDate: formatWeekWithYear(moment(followUpSession.startedAt)),
          sessionHour: formatConsultHour(followUpSession),
        })}
      </Typography>
      <Box className={classes.imageContainer}>
        <WomanJumping className={classes.imageJumping} />
      </Box>
    </>
  );

  return (
    <Paper className={classes.paper}>
      {followUpSession ? hasFollowUp() : hasNoFollowUp()}
    </Paper>
  );
};

export default FollowUpSessionPaper;
