import { Box, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { SingleSignOnButton } from "@src/components/SingleSignOnButton";
import GoogleLoginRegisterButton from "@src/views/auth/GoogleLoginRegisterButton";
import { validateExternalSignUp } from "@src/utils/validationHelpers";
import AppleSignInSignUpButton from "@src/components/AppleSignInSignUpButton";
import MicrosoftSignInSignUpButton from "@src/components/MicrosoftSignInSignUpButton";
import { Formik } from "formik";
import WizardContainer from "@src/views/onboarding/components/WizardContainer";
import Button from "@openup/shared/components/Button/Button";
import { upperCase } from "lodash";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import MailButton from "@src/resources/images/mail-button-icon.svg";

const useStyles = makeStyles(({ breakpoints, colors, spacing }) => ({
  content: {
    // padding: "0 0 230px 0",
    [breakpoints.up("sm")]: {
      padding: spacing(3),
    },
  },
  buttonSelected: {
    color: colors.primaryBlue,
    backgroundColor: "#F6FBFF",
    borderColor: colors.primaryBlue,
  },
  textFieldEmail: {
    marginTop: 0,
  },
  googleLoginButton: {
    borderWidth: "2px",
    borderColor: "lightgray",
    margin: "0 !important",
    // add hover styles
    "&:hover": {
      borderWidth: "2px",
      borderColor: "gray",
    },
  },
  singleSignOnButton: {
    borderWidth: "2px",
    borderColor: "lightgray",
    margin: "0 !important",
  },
}));

const CredentialsForm = ({ moveToNextStep, moveToEmailPage }) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const onSubmit = () => {
    moveToNextStep();
  };

  return (
    <WizardContainer
      title={t("RegisterView.SignUp")}
      subtitle={t("RegisterView.SignUp.Subtitle")}
      isTitleCentered={false}
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          termsCheckbox: false,
        }}
      >
        {({ handleSubmit, values, setFieldError, setFieldTouched }) => (
          <form onSubmit={handleSubmit}>
            <Box className={classes.content}>
              <div className="grid gap-3 my-3">
                <GoogleLoginRegisterButton
                  className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
                  isSignUpForm
                  onValidate={() =>
                    validateExternalSignUp(
                      setFieldError,
                      setFieldTouched,
                      values.termsCheckbox,
                    )
                  }
                  onLoginCallback={moveToNextStep}
                />
                <AppleSignInSignUpButton
                  className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
                  isSignUpForm
                  buttonText={t("RegisterView.AppleRegister")}
                  onValidate={() =>
                    validateExternalSignUp(
                      setFieldError,
                      setFieldTouched,
                      values.termsCheckbox,
                    )
                  }
                  onLoginCallback={moveToNextStep}
                />
                <MicrosoftSignInSignUpButton
                  className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
                  isSignUpForm
                  buttonText={t("RegisterView.MicrosoftRegister")}
                  onValidate={() =>
                    validateExternalSignUp(
                      setFieldError,
                      setFieldTouched,
                      values.termsCheckbox,
                    )
                  }
                  onLoginCallback={moveToNextStep}
                />
                <SingleSignOnButton
                  fullWidth
                  className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
                  text={t("RegisterView.SingleSignOn")}
                  onClick={() =>
                    navigate(`${pathT("route.singleSignOn")}?IsSignUp=true`)
                  }
                />
              </div>

              {isSmallScreen ? (
                <Typography
                  variant="body2"
                  align="center"
                  className="my-3 font-semibold"
                >
                  {upperCase(t("RegisterView.Or"))}
                </Typography>
              ) : (
                <hr className="w-full h-px my-4 bg-gray-200 border-0" />
              )}

              <Button
                className="w-full !outline-gray-300 !hover:outline-gray-600 !hover:bg-gray-50 transition-all font-semibold"
                variant="tertiary"
                onClick={moveToEmailPage}
              >
                <img alt="" src={MailButton} className="mr-2" />
                {t("OnBoarding.Registration.SignupEmail")}
              </Button>

              <div className="flex text-sm text-gray-500 w-full justify-center mt-4">
                <p>{t("RegisterView.AlreadySignedUp")}</p>
                <p className="ml-1">
                  <Link
                    href={pathT("route.login")}
                    className="text-gray-500 font-medium underline"
                  >
                    {t("LoginView.Login")}
                  </Link>
                </p>
              </div>
            </Box>
          </form>
        )}
      </Formik>
    </WizardContainer>
  );
};

export default CredentialsForm;
