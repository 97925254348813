import { Dialog, DialogContent } from "@mui/material";

import { makeStyles } from "@mui/styles";

import { TWO_FACTOR_OPTIONS } from "@src/queries/twoFactorAuthentication";
import { withSnackbar } from "@src/components/SnackBarComponent";
import TwoFactorCodeRequestView from "@src/views/auth/TwoFactorCodeRequestView";

const useStyles = makeStyles((theme) => ({
  twoFactorActivationDialogPaper: {
    minWidth: "45vw",
    [theme.breakpoints.down("md")]: {
      minWidth: 0,
    },
    padding: 0,
  },
  twoFactorActivationDialog: {
    alignSelf: "center",
    margin: "auto",
  },
  twoFactorActivationDialogTitle: {
    overflow: "hidden",
    paddingTop: theme.spacing(1),
    fontWeight: "bold",
    marginTop: theme.spacing(1),
  },
}));

/**
 * Modal for activating Two Factor Authentication
 */

const TwoFactorActivationModal = ({
  isOpen,
  onClose,
  onTwoFactorAuthenticationSuccess = () => {},
}) => {
  const classes = useStyles();

  const onSuccess = () => {
    onClose();
    onTwoFactorAuthenticationSuccess();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event, reason);
        }
      }}
      aria-labelledby="change-password"
      className={classes.twoFactorActivationDialog}
      classes={{ paper: classes.twoFactorActivationDialogPaper }}
    >
      <DialogContent style={{ paddingBottom: 0 }}>
        <TwoFactorCodeRequestView
          onSuccess={onSuccess}
          insideModal
          viewPurpose={TWO_FACTOR_OPTIONS.TOGGLE}
          onCloseModal={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withSnackbar(TwoFactorActivationModal);
