import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LanguageSelector from "@src/components/LanguageSelector";
import ProgressBar from "@openup/shared/components/ProgressBar/ProgressBar";

interface ResilienceCheckinProgressBarProps {
  goToPreviousQuestion: () => void;
  totalNumberOfQuestions: number;
  currentQuestionIndex: number;
}

const ResilienceCheckinProgressBar = ({
  goToPreviousQuestion,
  totalNumberOfQuestions,
  currentQuestionIndex,
}: ResilienceCheckinProgressBarProps) => {
  return (
    <div className="flex pt-4 justify-center items-center mx-4 gap-4 md:max-w-3xl md:mx-auto">
      <button
        type="button"
        onClick={goToPreviousQuestion}
        className="flex items-center justify-center rounded-full border-none h-[48px] w-[48px] bg-white shadow-xl hover:cursor-pointer text-indigo-800"
        aria-label="Go to previous question"
      >
        <ChevronLeftIcon />
      </button>
      <div className="grow">
        <ProgressBar
          percentage={(currentQuestionIndex * 100) / totalNumberOfQuestions}
        />
      </div>
      <div className="shadow-xl rounded-3xl">
        <LanguageSelector
          classNameSelect="bg-white"
          classNameDropdown="bg-white"
        />
      </div>
    </div>
  );
};

export default ResilienceCheckinProgressBar;
