import { Box, Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  typography: {
    opacity: 1,
  },
}));

const NoAvailableTimeSlots = () => {
  const { t } = useTranslatedNavigate();
  const classes = useStyles();
  return (
    <Paper className={classes.paperRoot}>
      <Box className={classes.box}>
        <Typography className={classes.typography} variant="subtitle2">
          {t("Booking.TimeSlot.NoAvailableSlots.Day")}
        </Typography>
        <Typography className={classes.typography} variant="subtitle2">
          {t("Booking.TimeSlot.ChooseAnotherDay")}
        </Typography>
      </Box>
    </Paper>
  );
};

export default NoAvailableTimeSlots;
