import { FC, useEffect, useState } from "react";

import Button from "@openup/shared/components/Button/Button";
import ResilienceCheckinResults from "@openup/shared/components/Web/ResilienceCheckinResults/ResilienceCheckinResults";
import type { ResilienceCheckinResult } from "@openup/shared/components/Web/ResilienceCheckinResults/ResilienceCheckinResult";

import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useGetQuestionListScores } from "@src/queries/themes";

const CustomButton: FC<{ url: string; className: string }> = ({
  url,
  className = "",
}) => {
  const { t } = useTranslatedNavigate();

  return (
    <Button className={`shrink-0 ${className}`} href={url}>
      {t("ResilienceCheckin.Results.Button")}
    </Button>
  );
};

const ResilienceCheckinContainer: FC<{ className: string }> = ({
  className = "",
}) => {
  const { t, pathT } = useTranslatedNavigate();
  const buttonUrl = pathT("route.ResilienceCheckin.Entry");
  const { data } = useGetQuestionListScores({
    questionListName: "resilience-checkin",
  });
  const [formattedResults, setFormattedResults] = useState<
    ResilienceCheckinResult[] | undefined
  >();

  useEffect(() => {
    if (!data?.data) {
      return;
    }

    setFormattedResults(
      data.data
        .map((result) => ({
          score: result.score,
          date: new Date(result.createdAt),
          comment: "",
        }))
        .sort((a, b) => a.date.getTime() - b.date.getTime()),
    );
  }, [data, setFormattedResults]);

  return (
    <section className={className}>
      <div className="flex justify-between items-center">
        <h2 className="h3">{t("ResilienceCheckin.Dashboard.Title")}</h2>
        {formattedResults?.length !== 0 && (
          <CustomButton url={buttonUrl} className="hidden sm:block" />
        )}
      </div>

      <ResilienceCheckinResults
        buttonUrl={buttonUrl}
        results={formattedResults}
        className="mt-6"
      />

      {formattedResults?.length !== 0 && (
        <CustomButton url={buttonUrl} className="block sm:hidden mt-6 w-full" />
      )}
    </section>
  );
};

export default ResilienceCheckinContainer;
