import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LogoMedical from "@src/components/LogoMedical";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  centeredContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    [breakpoints.up("sm")]: {
      paddingTop: spacing(6.25),
      marginTop: 0,
    },
    [breakpoints.down("sm")]: {
      paddingTop: spacing(3),
      marginTop: 0,
    },
  },
  container: {
    justifyContent: "space-between",
    width: "50%",
    [breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  homeLogo: {
    display: "flex",
    alignItems: "center",
  },
}));

const MedicalFlowTopBar = () => {
  const classes = useStyles();

  return (
    <Box className={`${classes.centeredContent} ${classes.root}`}>
      <Box className={`${classes.centeredContent} ${classes.container}`}>
        <Box />
        <a
          href="https://openupmedical.com/"
          target="_blank"
          rel="noreferrer"
          className={classes.homeLogo}
          aria-label="OpenUp Medical"
        >
          <LogoMedical className={classes.logo} />
        </a>
        <Box />
      </Box>
    </Box>
  );
};

export default MedicalFlowTopBar;
