const unconfirmedDataIdentifier = "unconfirmedDataSignature";

const setUnconfirmedDataSignature = (unconfirmedDataId) =>
  localStorage.setItem(
    unconfirmedDataIdentifier,
    JSON.stringify(unconfirmedDataId),
  );
const getUnconfirmedDataSignature = () =>
  localStorage.getItem(unconfirmedDataIdentifier);

const service = {
  setUnconfirmedDataSignature,
  getUnconfirmedDataSignature,
};

export default service;
