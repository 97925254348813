import { useRef, useEffect, useState } from "react";
import { get as getDeepObjectValue } from "lodash";
import { makeStyles } from "@mui/styles";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { displayLogicAllQuestions } from "@src/utils/constants";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    backgroundColor: "rgba(51,51,51,.03)",
    borderRadius: 20,
    height: 24,
  },
  progress: {
    backgroundColor: (props) => props.color || "transparent",
    width: (props) => props.width || 0,
    height: 24,
    borderRadius: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const FACE_ICONS = {
  0: <SentimentDissatisfiedOutlinedIcon style={{ color: "white" }} />,
  1: <SentimentSatisfiedIcon style={{ color: "white" }} />,
  2: <SentimentSatisfiedOutlinedIcon style={{ color: "white" }} />,
  3: <SentimentVerySatisfiedIcon style={{ color: "white" }} />,
};

const ScoreProgress = ({ score, classes }) => {
  const progressWrapperRef = useRef(null);
  const [progressWidth, setProgressWidth] = useState(0);
  const color = getDeepObjectValue(
    displayLogicAllQuestions,
    `[${score}].color`,
  );
  const width = progressWidth ? progressWidth / 4 : 0;
  const styles = useStyles({ width: width * (score + 1), color });

  useEffect(() => {
    if (progressWrapperRef) {
      const style = getComputedStyle(progressWrapperRef.current);
      const margin =
        parseFloat(style.marginLeft) + parseFloat(style.marginRight);
      const wrapperWidth = progressWrapperRef.current.offsetWidth;
      setProgressWidth(wrapperWidth + margin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  return (
    <div ref={progressWrapperRef} className={classes.wrapper}>
      <div className={styles.progress}>{FACE_ICONS[score]}</div>
    </div>
  );
};

export default ScoreProgress;
