import { useState, useMemo, useEffect, useContext } from "react";

import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import BarChartIcon from "@mui/icons-material/BarChart";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/ClearRounded";
import IconButton from "@mui/material/IconButton";

import ThemeBarChart from "@src/components/ThemeBarChart";
import { ReactComponent as ImageWomanCelebrate } from "@src/resources/images/woman_celebrate.svg";
import { useMutationUserTheme } from "@src/queries/themes";
import { MAX_FOCUSED_THEMES } from "@src/utils/constants";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { DashboardContext } from "@src/utils/DashboardContext";

const useStyles = makeStyles((theme) => ({
  chartOverview: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(8),
    },
  },
  text1: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    marginBottom: theme.spacing(2),
  },
  barChartIcon: {
    marginRight: theme.spacing(1),
  },
  text2: {
    marginBottom: theme.spacing(3),
    color: theme.colors.primaryDarkBlue,
    lineHeight: "32px",
    letterSpacing: "0.5px",
    fontSize: "16px",
  },
  dancingWoman: ({ isFocusedTabWithNoFocusedThemes }) => ({
    height: isFocusedTabWithNoFocusedThemes ? 385 : 273,
    width: 146,
    marginBottom: theme.spacing(-8.75),
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  }),
  tabs: {
    marginBottom: theme.spacing(4),
    width: 528,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  tab: {
    lineHeight: "16px",
    opacity: 0.5,
    fontSize: "14px",
    letterSpacing: "1.25px",
    [theme.breakpoints.up("sm")]: {
      minWidth: 264,
    },
  },
  tabSelected: {
    opacity: 1,
    color: theme.palette.primary.main,
    fontSize: "14px",
    letterSpacing: "1.25px",
    [theme.breakpoints.up("sm")]: {
      minWidth: 264,
    },
  },
  globalCheckinText: {
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  globalCheckinBtn: {
    padding: `${theme.spacing(0)} ${theme.spacing(1.5)}`,
    letterSpacing: 1.25,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  divider: {
    margin: `${theme.spacing(4)} 0 ${theme.spacing(4)}`,
  },
  focusBarLabelContainer: {
    position: "absolute",
    padding: theme.spacing(0.6),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: (props) => props.barColor,
    borderRadius: 12,
    bottom: (props) => (props.addExtraPositionBottom ? -86 : -56),
  },
  focusBarLabelText: {
    lineHeight: "15px",
    color: (props) => props.labelColor,
    whiteSpace: "nowrap",
    fontSize: 14,
    fontWeight: 500,
  },
  focusBarLabelBtn: {
    padding: 0,
    background: (props) => props.btnBackgroundColor,
    marginLeft: theme.spacing(1),
    "&:hover": {
      background: (props) => props.btnBackgroundColor,
    },
  },
  sliderContainer: {
    justifyContent: "space-around!important",
  },
}));

const CHART_TABS = {
  FOCUSED: 0,
  ALL_THEMES: 1,
};

export const FocusBarLabel = ({
  label,
  barColor,
  svgColor,
  textColor,
  theme,
  addExtraPositionBottom,
}) => {
  const { refetchThemes } = useContext(DashboardContext);
  const { mutateAsync: updateTheme } = useMutationUserTheme(refetchThemes);
  const classes = useStyles({
    barColor,
    labelColor: textColor,
    btnBackgroundColor: textColor,
    addExtraPositionBottom,
  });
  const handleFocus = async (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    await updateTheme({
      ...theme,
      isFocused: false,
    });
  };

  return (
    <div className={classes.focusBarLabelContainer}>
      <Typography className={classes.focusBarLabelText}>{label}</Typography>
      <IconButton
        className={classes.focusBarLabelBtn}
        onClick={handleFocus}
        size="large"
      >
        <CloseIcon style={{ color: svgColor || barColor, fontSize: "1rem" }} />
      </IconButton>
    </div>
  );
};

export const ChartTile = ({ hasFocusedThemes }) => {
  const { t, pathT } = useTranslatedNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const [selectedTab, setSelectedTab] = useState(CHART_TABS.FOCUSED);
  const { themes, toggleFocusModal } = useContext(DashboardContext);

  const chartThemes = useMemo(
    () =>
      Object.keys(themes)
        .map((k) => {
          const questionList = themes[k].questionLists.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          )[0];

          if (questionList) {
            return {
              ...questionList,
              ...{
                ordinal: themes[k].ordinal,
                isTrackedTheme: themes[k].isTrackedTheme,
                isFocused: themes[k].isFocused,
                key: themes[k].key,
              },
            };
          }
          return {
            ...themes[k],
            ...{
              score: 0,
              createdAt: new Date(0),
            },
          };
        })
        .sort((a, b) => {
          if (a.isFocused === b.isFocused) return a.ordinal - b.ordinal;

          return a.isFocused ? -1 : 1;
        }),
    [themes],
  );

  const handleTabChange = (_, value) => setSelectedTab(value);

  const { focusedThemes } = useMemo(() => {
    const result = chartThemes.reduce(
      (acc, chartTheme) => {
        if (chartTheme.isFocused) {
          acc.focusedThemes.push(chartTheme);
        } else {
          acc.unfocusedThemes.push(chartTheme);
        }
        return acc;
      },
      { focusedThemes: [], unfocusedThemes: [] },
    );
    if (result.focusedThemes.length < MAX_FOCUSED_THEMES) {
      const emptyBarsNo = MAX_FOCUSED_THEMES - result.focusedThemes.length;
      const emptyBars = Array.from({ length: emptyBarsNo }).map(() => ({
        isEmptyBar: true,
      }));
      result.focusedThemes = [...result.focusedThemes, ...emptyBars];
    }
    return result;
  }, [chartThemes]);

  useEffect(() => {
    setSelectedTab(
      focusedThemes.filter((theme) => !theme.isEmptyBar).length > 0
        ? CHART_TABS.FOCUSED
        : CHART_TABS.ALL_THEMES,
    );
  }, [focusedThemes]);

  const isFocusedTab = selectedTab === CHART_TABS.FOCUSED;
  const isFocusedTabWithNoFocusedThemes = !isFocusedTab || hasFocusedThemes;
  const classes = useStyles({ isFocusedTabWithNoFocusedThemes });

  return (
    <Paper className={classes.chartOverview}>
      <Typography className={classes.text1} variant="h6" color="textPrimary">
        <BarChartIcon className={classes.barChartIcon} />
        {t("DashboardView.Tile.Title")}
      </Typography>
      <Typography className={classes.text2}>
        {t("DashboardView.Tile.Message")}
      </Typography>
      <Tabs
        variant="fullWidth"
        className={classes.tabs}
        value={selectedTab}
        onChange={handleTabChange}
      >
        <Tab
          label={t("DashboardView.ChartTabs.Focus")}
          classes={{
            selected: classes.tabSelected,
            textColorInherit: classes.tab,
          }}
        />
        <Tab
          label={t("DashboardView.ChartTabs.All")}
          classes={{
            selected: classes.tabSelected,
            textColorInherit: classes.tab,
          }}
        />
      </Tabs>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box width="100%" mb={4}>
          {isFocusedTab && (
            <ThemeBarChart
              data={focusedThemes}
              barSpacingWidth={isSmallScreen ? 4 : 10}
              xAxisLabelHeight={150}
              xAxisScrollable
              rotateXAxisLabels
              LabelComponent={FocusBarLabel}
              classNames={{ sliderContainer: classes.sliderContainer }}
              hasEmptyBars
              onEmptyLabelClick={toggleFocusModal}
              isFocusedTab={isFocusedTab}
            />
          )}
          {!isFocusedTab && (
            <ThemeBarChart
              data={chartThemes}
              barSpacingWidth={isSmallScreen ? 4 : 10}
              xAxisLabelHeight={150}
              xAxisScrollable
              rotateXAxisLabels
              classNames={{ sliderContainer: classes.sliderContainer }}
              onEmptyLabelClick={toggleFocusModal}
              maxBarsDisplayed={isSmallScreen ? 5 : undefined}
              labelPosition="left"
            />
          )}

          {isFocusedTabWithNoFocusedThemes && (
            <>
              <Divider className={classes.divider} />
              <Box
                display={isSmallScreen ? "block" : "flex"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography className={classes.globalCheckinText}>
                  {isFocusedTab
                    ? t("DashboardView.Chart.FocusedCheckin")
                    : t("DashboardView.Chart.GlobalCheckin")}
                </Typography>
                <Link
                  to={
                    isFocusedTab
                      ? pathT("route.themesFocusCheckin")
                      : `${pathT("route.themes")}${t("route.checkin")}`
                  }
                >
                  <Button classes={{ root: classes.globalCheckinBtn }}>
                    {t("DashboardView.Chart.CheckinButton")}
                  </Button>
                </Link>
              </Box>
            </>
          )}
        </Box>
        <ImageWomanCelebrate
          viewBox={
            isFocusedTabWithNoFocusedThemes
              ? "0 -70 146 256.6"
              : "0 0 146 256.6"
          }
          className={classes.dancingWoman}
        />
      </Box>
    </Paper>
  );
};
