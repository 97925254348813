import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  checkInBox: {
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: theme.spacing(4),
  },
  allCheckIn: {
    padding: theme.spacing(0, 3.25),
    letterSpacing: 1.25,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: theme.spacing(0),
      marginBottom: theme.spacing(3),
    },
  },
}));

const BottomCTAWithNoFocusedThemes = ({ isSmallScreen }) => {
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();

  return (
    <Box
      display={isSmallScreen ? "block" : "flex"}
      className={classes.checkInBox}
    >
      <Link to={`${pathT("route.themes")}/${pathT("route.checkin")}`}>
        <Button classes={{ root: classes.allCheckIn }}>
          {t(
            "Dashboard.FocusedChart.Tile.FocusedCheckIn.CheckinButtonAllThemes",
          )}
        </Button>
      </Link>
    </Box>
  );
};

export default BottomCTAWithNoFocusedThemes;
