import BookingContainer from "@src/views/booking/components/BookingContainer";
import { IConsultType } from "@src/utils/constants";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { getConsultType } from "@src/utils/helpers";
import SessionTypeCard from "@openup/shared/components/Cards/SessionTypeCard/SessionTypeCard";
import { ArrowForward } from "@mui/icons-material";
import { SessionTypeCardImage } from "@src/views/booking/introductory/components/SessionTypeCardImage";

const ValidateLastConsult = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  nextStepFollowUpSession = (_) => {},
  lastConsult,
  moveToNextStep,
  setSessionType,
}) => {
  const { t } = useTranslatedNavigate();

  const handleFollowUpSession = (sessionTypeToBook) => {
    setSessionType(sessionTypeToBook);
    nextStepFollowUpSession(sessionTypeToBook);
  };

  const handleNewSession = () => {
    moveToNextStep();
  };

  const sessionTypeLastConsult: IConsultType = getConsultType(lastConsult);

  return (
    <BookingContainer
      title={t("Booking.LastSession.Title")}
      isMediumContainerWanted
    >
      <div className="flex flex-wrap w-11/12 mx-auto justify-center items-center gap-8 md:gap-6 pb-6">
        <SessionTypeCard
          className="my-4"
          onClick={() => handleFollowUpSession(sessionTypeLastConsult)}
          cardTitle={
            <div className="w-full font-medium text-primaryDarkBlue text-xl leading-6 tracking-wide mr-1">
              {t("Booking.Language.Title.With")}
              <strong className="font-semibold">
                {" "}
                {lastConsult.employeeFullName}
              </strong>
            </div>
          }
          cardSubtitle={`${t(`SessionType.${sessionTypeLastConsult}`)}, ${t(
            "SessionType.1to1Session.Duration",
          )}`}
          icon={<ArrowForward className="text-blue-500" />}
          image={
            <SessionTypeCardImage
              sessionType={sessionTypeLastConsult as IConsultType}
            />
          }
        />
        <SessionTypeCard
          className="my-4"
          onClick={() => handleNewSession()}
          cardTitle={t("Booking.LastSession.Subtitle2")}
          icon={<ArrowForward className="text-blue-500" />}
          image={<SessionTypeCardImage sessionType={null} />}
        />
      </div>
    </BookingContainer>
  );
};

export default ValidateLastConsult;
