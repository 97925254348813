/* eslint-disable react/jsx-props-no-spreading */
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

import { ReactComponent as LogoSVGDefault } from "@src/resources/images/logotype.svg";

const LogoMedical = ({ showLanguageSpecificIcon = false, ...props }) => {
  const { i18n } = useTranslatedNavigate();

  if (showLanguageSpecificIcon) {
    switch (i18n.language) {
      default:
        return <LogoSVGDefault {...props} />;
    }
  }

  return <LogoSVGDefault {...props} />;
};

export default LogoMedical;
