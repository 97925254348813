import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { ReactComponent as WomanPhone } from "@src/resources/images/woman-phone.svg";

import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useUpdateUserPhoneNumber } from "@src/queries/user";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { getValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import { hasErrorsOrEmptyValues } from "@src/utils/helpers";
import { useState } from "react";
import BookingContainer from "./BookingContainer";
import BookingCard from "./BookingCard";
import PhoneNumberComponent from "./PhoneNumberComponent";

const useStyles = makeStyles((theme) => ({
  buttonConfirm: {
    width: 264,
  },
  image: {
    float: "right",
    marginTop: theme.spacing(10.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      width: 152,
      height: 152,
    },
  },
  fillDetails: {
    opacity: 1,
    fontWeight: 500,
    maxWidth: 240,
    letterSpacing: "0.15px",
    marginBottom: theme.spacing(6),
  },
}));

const PhoneNumberComponentContainer = ({
  moveToNextStep = undefined,
  token = undefined,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutateAsync: updateUserPhoneNumber } =
    useUpdateUserPhoneNumber(token);

  const OnSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      await updateUserPhoneNumber(values.phoneNumber);
      moveToNextStep();
    } catch (_) {
      setIsSubmitting(false);
      props.snackbarShowMessage(t("Validations.InvalidPhoneNumber"));
    }
  };

  return (
    <BookingContainer title={t("Booking.PhoneNumberPage.Title")}>
      <Formik
        initialValues={{
          phoneNumber: "",
        }}
        validationSchema={getValidationSchema(t, { phoneNumber: true })}
        onSubmit={OnSubmit}
      >
        {({
          errors,
          setFieldTouched,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <BookingCard
              image={<WomanPhone className={classes.image} />}
              actions={
                <Button
                  disabled={
                    hasErrorsOrEmptyValues(errors, values) || isSubmitting
                  }
                  className={classes.buttonConfirm}
                  type="submit"
                >
                  {t("Booking.PhoneNumberPage.ConfirmButton")}
                </Button>
              }
            >
              <Typography variant="subtitle1" className={classes.fillDetails}>
                {t("Booking.PhoneNumberPage.FillDetails")}
              </Typography>

              <PhoneNumberComponent
                isTouched={touched.phoneNumber}
                isError={errors.phoneNumber}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                phoneNumberValue={values.phoneNumber}
              />
            </BookingCard>
          </form>
        )}
      </Formik>
    </BookingContainer>
  );
};

export default withSnackbar(PhoneNumberComponentContainer);
