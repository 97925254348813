import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Container,
  Typography,
  Button,
  useMediaQuery,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "@src/components/Page";
import PropTypes from "prop-types";
import Loader from "@src/components/Loader";
import ImageManWithChair from "@src/components/ImageManWithChair";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles(() => ({
  pageMargin: {
    "@media (max-width: 599px)": {},
    "@media (min-width: 600px)": {
      width: "360px !important",
      margin: "auto",
    },
    link: {
      textDecoration: "none",

      "&:hover": {
        textDecoration: "none",
      },
    },
  },
}));

const MessageCheckInResultView = ({ authReducers }) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const [loading, setLoading] = useState(false);
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  useEffect(() => {
    if (authReducers) {
      if (authReducers.successMessage) {
        setLoading(false);
      }
      if (authReducers.error?.message) {
        setLoading(false);
      }
    }
  }, [authReducers]);

  return (
    <Page title={t("MessageCheckInResultView.Title")}>
      <Box
        display="flex"
        flexDirection="flex"
        height="100%"
        justifyContent="center"
      >
        <Container
          maxWidth="sm"
          className={classes.pageMargin}
          style={{ textAlign: "left" }}
        >
          <Box className={classes.pageMargin}>
            <Typography variant={isSmallScreen ? "h3" : "h1"}>
              {t("MessageCheckInResultView.Heading")}
            </Typography>
          </Box>
          <Box className={classes.pageMargin}>
            <Box display="flex" alignItems="top" ml={1} mt={4}>
              <Typography color="textSecondary" variant="body1">
                {t("MessageCheckInResultView.Message")}
                {"  "}
                <b>{authReducers?.user?.email}</b>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.pageMargin} alignItems="center">
            <Box ml={1} mt={4}>
              <Button
                fullWidth
                variant="outlined"
                disabled={loading}
                type="button"
              >
                <Link
                  href={t("MessageCheckInResultView.HomepageLink")}
                  className={classes.link}
                  underline="hover"
                >
                  {t("MessageCheckInResultView.HomepageButton")}
                </Link>
              </Button>
              {loading && <Loader />}
            </Box>
          </Box>
          <ImageManWithChair />
        </Container>
      </Box>
    </Page>
  );
};

MessageCheckInResultView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  authReducers: PropTypes.any,
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps, null)(MessageCheckInResultView);
