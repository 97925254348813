import { useState, useEffect } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useGetAvailableLanguagesQuery } from "@src/queries/booking";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { colors } from "@src/theme";
import { withSuspense } from "@src/components/wrappers/Suspendable";
import AutoCompleteLanguage from "./AutoCompleteLanguage";
import LanguageCard from "./LanguageCard";
import BookingContainer from "./BookingContainer";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(-3),
  },
  gridItem: {
    cursor: "pointer",
  },
  subtitleMessage: {
    color: colors.primaryBlue,
    marginTop: theme.spacing(4),
  },
}));

const Language = ({
  moveToNextStep,
  setLanguage,
  available = true,
  firstname,
  presetLanguages,
  sessionType,
}) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const [availableLanguages, setAvailableLanguages] = useState([]);

  const { data: languageQueryData } = useGetAvailableLanguagesQuery({
    sessionType,
    hasPresetLanguages: !!presetLanguages,
  });

  useEffect(() => {
    let formattedResults = [];

    if (languageQueryData) {
      formattedResults = languageQueryData.map((result) => ({
        code: result.name,
        name: result.description,
        translations: result.translations,
      }));
    }

    if (presetLanguages) {
      formattedResults = presetLanguages.map((result) => ({
        code: result.tagName,
        name: result.description,
        translations: result.translations,
      }));
    }

    setAvailableLanguages(formattedResults);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [languageQueryData, presetLanguages]);

  const handlePressLanguageCard = (language) => {
    setLanguage(language);
    moveToNextStep();
  };

  const title = () => {
    // A given psychologist is not available
    if (!available)
      return t("Booking.Language.Title.NotAvailable", {
        psychologistName: firstname,
      });

    // A given psychologist is available
    if (firstname) {
      return `${t("Booking.Language.Title.With")} ${firstname}?`;
    }

    // No psychologist has been specified
    return t("Booking.Language.Title");
  };

  const message = () =>
    !available ? (
      <Typography variant="subtitle1" className={classes.subtitleMessage}>
        {t("Booking.Language.Title")}
      </Typography>
    ) : null;

  return (
    <BookingContainer
      title={title()}
      message={message()}
      isMediumContainerWanted
    >
      <AutoCompleteLanguage
        availableLanguages={availableLanguages}
        handlePressLanguageCard={handlePressLanguageCard}
      />
      <Grid
        container
        spacing={isSmallScreen ? 0 : 3}
        justifyContent="center"
        className={classes.gridContainer}
      >
        {Object.values(availableLanguages).map((language) => (
          <Grid
            item
            xs={12}
            sm={4}
            className={classes.gridItem}
            data-cy="language"
            onClick={() => handlePressLanguageCard(language.code)}
            key={language.code}
          >
            <LanguageCard languageCode={language.code} />
          </Grid>
        ))}
      </Grid>
    </BookingContainer>
  );
};

export default withSuspense(Language);
