import { alpha, darken } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import typography from "./typography";
import colors from "./colors";
import shadows from "./shadows";

import "slick-carousel/slick/slick-theme.css";

export const themeColors = {
  primaryGreen: "#C4F1AF",
  primaryGreenDark: "#075450",
  secondaryGreen: "#24736F",
  tertiaryGreen: "#064A47",
};

export const customShadows = [
  `0px 12px 20px ${colors.primaryDarkGrayWithTransparency}`,
];

const medicalTheme = createTheme({
  palette: {
    background: {
      default: themeColors.secondaryGreen,
      paper: colors.white,
    },
    primary: {
      main: themeColors.primaryGreenDark,
    },
    secondary: {
      main: themeColors.primaryGreenDark,
    },
    text: {
      primary: themeColors.tertiaryGreen,
      secondary: themeColors.tertiaryGreen,
    },
    error: {
      main: colors.errorMain,
    },
  },
  colors: {
    ...colors,
    ...themeColors,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: themeColors.tertiaryGreen,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: colors.tertiaryGreen,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
        },
        elevation8: {
          boxShadow: "none",
        },
        rounded: {
          borderRadius: "16px",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          "@media (max-width: 599px)": {
            paddingBottom: 30,
            paddingLeft: 16,
            paddingRight: 16,
          },
          "@media (min-width: 600px)": {
            paddingBottom: 30,
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "font-family": "Montserrat",
          fontSize: "14px",
          lineHeight: "24px",
          height: 48,
          fontWeight: 500,
          background: themeColors.primaryGreen,
          "&:hover": {
            backgroundColor: darken(themeColors.primaryGreen, 0.1),
          },
          "&:disabled": {
            backgroundColor: alpha(themeColors.primaryGreen, 0.5),
          },
          borderRadius: 28,
          textTransform: "none",
          color: themeColors.tertiaryGreen,
          paddingTop: 20,
        },

        outlined: {
          border: `2px solid ${themeColors.primaryGreenDark}`,
          padding: "5px 15px",
          backgroundColor: "white",
          color: themeColors.primaryGreenDark,
          "&:hover": {
            backgroundColor: "white",
          },
        },

        contained: {
          backgroundColor: themeColors.primaryGreen,
          "&:hover": {
            backgroundColor: themeColors.primaryGreenDark,
          },
          color: "#ffffff",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "18px",
          alignItems: "center",
        },
        containedSizeLarge: {
          "&:hover": {
            backgroundColor: themeColors.primaryGreenDark,
          },
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "18px",
          alignItems: "center",
        },
        containedSizeSmall: {
          "&:hover": {
            backgroundColor: themeColors.primaryGreenDark,
          },
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "14px",
          alignItems: "center",
        },
        fullWidth: {
          marginTop: 20,
          marginBlock: 20,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: 20,
          marginBottom: 20,
        },
      },
    },
    MuiTextField: {},
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: 24,
          fontSize: 16,
          lineHeight: 24,
          fontWeight: 500,
          color: themeColors.primaryGreenDark,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        formControl: {
          marginTop: "10px",
        },
      },
    },

    // Labels
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          lineHeight: "24px",
          opacity: 0.7,
          fontWeight: 300,
        },
      },
    },
    // Error messages
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          lineHeight: "16px",
          fontWeight: 400,
        },
      },
    },
    MuiCssBaseline: {
      // "@global": {},
    },
    MuiToolbar: {
      // gutters: {
      //   "@media (max-width: 599px)": {},
      // },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginRight: "12px",
          marginLeft: "12px",
          marginTop: "12px",
          marginBottom: "12px",
        },
      },
    },
  },
  shadows,
  customShadows,
  typography,
});

export default medicalTheme;
