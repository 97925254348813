import { useLocation, Navigate } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const WrappedConfirmConsultView = () => {
  const location = useLocation();
  const { t, pathT } = useTranslatedNavigate();

  const urlSearch = new URLSearchParams(location.search);

  const token = urlSearch.get("consultToken");
  const email = urlSearch.get("email");

  return (
    <Navigate
      to={`${pathT("route.booking")}/${t(
        "route.confirmConsult",
      )}/${token}/${email}`}
    />
  );
};

export default WrappedConfirmConsultView;
