import { CircularProgress } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  circularProgress: {
    zIndex: -1,
    top: "50%",
    paddingLeft: "40%",
  },
}));
const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.circularProgress}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
