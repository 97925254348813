import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "@src/components/Page";
import PropTypes from "prop-types";

import ImageManWithChair from "@src/components/ImageManWithChair";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles(() => ({
  pageMargin: {
    "@media (max-width: 599px)": {},
    "@media (min-width: 600px)": {
      width: "360px !important",
      margin: "auto",
    },
  },
}));

const SentPasswordResetEmail = ({ authReducers }) => {
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const handleClick = () => {
    navigate(pathT("route.login"));
  };

  return (
    <Page title={t("ForgotPasswordSentView.Title")}>
      <Box
        display="flex"
        flexDirection="flex"
        height="100%"
        justifyContent="center"
        textAlign="left"
      >
        <Container maxWidth="sm" className={classes.pageMargin}>
          <Box className={classes.pageMargin}>
            <Typography variant={isSmallScreen ? "h3" : "h1"}>
              {t("ForgotPasswordSentView.Title")} {authReducers.user?.firstName}
            </Typography>
          </Box>
          <Box mt={4}>
            <Box
              display="flex"
              alignItems="top"
              ml={1}
              className={classes.pageMargin}
            >
              <Typography color="textSecondary" variant="body1">
                {t("ForgotPasswordSentView.ContentPart1")}
                {"  "}
                <b>{authReducers.user.email}</b>
                <br />
                {"  "}
                {t("ForgotPasswordSentView.ContentPart2")}
              </Typography>
            </Box>
          </Box>
          <Box mt={4}>
            <Box ml={1} className={classes.pageMargin}>
              <Button variant="outlined" onClick={handleClick}>
                <Typography variant="body2">
                  <b>{t("ForgotPasswordSentView.Button")}</b>
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box>
            <ImageManWithChair />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

SentPasswordResetEmail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  authReducers: PropTypes.any,
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(SentPasswordResetEmail);
