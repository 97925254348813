import { useQuerySanityTheme } from "@src/queries/themes";
import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import CustomBlockContent from "@src/components/CustomBlockContent";
import ThemeExercisesSlider from "@src/components/ThemeExercisesSlider";
import TypographyWithIcon from "@src/components/TypographyWithIcon";
import QuestionsAndAnswersAccordion from "@src/components/QuestionsAndAnswersAccordion";
import ThemeExternalLinkTile from "@src/components/ThemeExternalLinkTile";
import Loader from "@src/components/Loader";

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    padding: spacing(4, 3),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    marginRight: spacing(2),
  },
  title: {
    marginBottom: spacing(3),
  },
  questionsAndAnswersDescription: {
    marginBottom: spacing(4),
    fontSize: 16,
  },
}));

const paperElevation = 15;

const ThemeViewEducation = ({ themeKey }) => {
  const { data } = useQuerySanityTheme(themeKey);
  const { t } = useTranslation();

  const classes = useStyles();

  if (!data) {
    return <Loader />;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Typography variant="h2">
          {data.translated_content?.education_title}
        </Typography>
      </Grid>
      {data.translated_content?.education_description && (
        <Grid item>
          <CustomBlockContent
            blocks={data.translated_content?.education_description}
          />
        </Grid>
      )}
      {data.exercises && (
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper} elevation={paperElevation}>
            <TypographyWithIcon iconLigature="emoji_objects">
              {t("ThemeViewEducation.Exercises.Title")}
            </TypographyWithIcon>
            <ThemeExercisesSlider exercises={data.exercises} />
          </Paper>
        </Grid>
      )}

      {data.translated_content?.questions_and_answers && (
        <Grid item xs={12} md={12}>
          <Paper className={classes.paper} elevation={paperElevation}>
            <TypographyWithIcon iconLigature="psychology">
              {t("ThemeViewEducation.QuestionsAndAnswers.Title")}
            </TypographyWithIcon>
            {data.translated_content?.questions_and_answers.description && (
              <Typography
                className={classes.questionsAndAnswersDescription}
                variant="body2"
                component="div"
              >
                <CustomBlockContent
                  blocks={
                    data.translated_content?.questions_and_answers.description
                  }
                />
              </Typography>
            )}
            <QuestionsAndAnswersAccordion
              questionsAndAnswers={
                data.translated_content?.questions_and_answers
              }
            />
          </Paper>
        </Grid>
      )}
      {data.translated_content?.education_main_content && (
        <Grid item xs={12} md={12}>
          <CustomBlockContent
            blocks={data.translated_content?.education_main_content}
          />
        </Grid>
      )}
      <Grid container item spacing={3} xs={12} md={12}>
        {data.translated_content?.external_links_education?.title && (
          <Grid item sm={12} md={12}>
            <Typography variant="h5">
              {data.translated_content?.external_links_education?.title}
            </Typography>
          </Grid>
        )}
        {data.translated_content?.external_links_education?.text && (
          <Grid item sm={12} md={12}>
            <CustomBlockContent
              blocks={data.translated_content?.external_links_education?.text}
            />
          </Grid>
        )}
        {data.translated_content?.external_links_education?.entries.map(
          (externalLink) => (
            // eslint-disable-next-line no-underscore-dangle
            <Grid item md={6} key={externalLink._key}>
              <ThemeExternalLinkTile
                title={externalLink.title}
                iconLigature={externalLink.icon?.name}
                text={externalLink.text}
                url={externalLink.url}
              />
            </Grid>
          ),
        )}
      </Grid>
    </Grid>
  );
};

export default ThemeViewEducation;
