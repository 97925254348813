import { useCheckinFormFocusThemes } from "@src/queries/themes";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import TripettoSurvey from "./index";

const FocusThemesTripettoSurvey = ({ redirectUri }) => {
  const query = useCheckinFormFocusThemes();
  const { pathT } = useTranslatedNavigate();

  return (
    <TripettoSurvey
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...query}
      redirectUri={redirectUri || pathT("route.account")}
    />
  );
};

export default FocusThemesTripettoSurvey;
