import Button from "@openup/shared/components/Button/Button";
import { ReactComponent as ParkReading } from "@src/resources/images/Park-Reading.svg";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const SessionReviewThanksView: React.FC = () => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  return (
    <div className="flex justify-center md:-mt-12 z-50 max-sm:p-4">
      <div className="md:w-[594px] w-full h-fit rounded-2xl overflow-hidden bg-white shadow">
        <ParkReading className="h-auto w-full" />

        <div className="px-8 py-6 md:px-14 md:py-12 text-center">
          <h2 className="h2 pb-6">{t("ThankYou.Title")}</h2>
          <p className="subtitle1 pb-6 text-slate-700">
            {t("ThankYou.SubTitle")}
          </p>
          <Button
            variant="tertiary"
            className="max-sm:w-full"
            onClick={() => navigate(pathT("route.mySessions"))}
          >
            <ChevronLeftIcon className="mr-1" />
            {t("ThankYou.Button")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SessionReviewThanksView;
