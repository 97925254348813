// https://gist.github.com/TrevorJTClarke/a14c37db3c11ee23a700?permalink_comment_id=3664792#gistcomment-3664792

/**
 * This function takes a Spotify URL or URI and returns the corresponding Spotify embed URL.
 * It works with tracks, albums, and playlists.
 * @param {string} url - The Spotify URL or URI.
 * @returns {string | null} - The Spotify embed URL or null if the URL doesn't match the Spotify format.
 */
export const getSpotifyEmbedUrl = (url: string): string | null => {
  // This regex matches both Spotify URLs and URIs for tracks, albums, and playlists.
  const spotifyRegex =
    /https?:\/\/(?:embed\.|open\.)(?:spotify\.com\/)(?:(album|track|playlist|episode)\/|\?uri=spotify:track:)((\w|-){22})|spotify:(?:(album|track|playlist|episode):|\?uri=spotify:track:)((\w|-){22})/;

  const match = url.match(spotifyRegex);

  if (match) {
    const [, spotifyType, spotifyId] = match;
    return `https://open.spotify.com/embed/${spotifyType}/${spotifyId}`;
  }

  return null;
};
