import { Container, Grid, Hidden } from "@mui/material";
import { makeStyles } from "@mui/styles";
import OpenUpMenuList from "@src/components/OpenUpMenuList";
import Page from "@src/components/Page";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { Outlet } from "react-router-dom";
import { withSuspense } from "@src/components/wrappers/Suspendable";

const useStyle = makeStyles((theme) => ({
  page: {
    position: "relative",
    overflowX: "hidden",
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  menuGrid: {
    backgroundColor: theme.colors.trueWhite,
    marginTop: theme.spacing(-8),
    marginBottom: theme.spacing(-3.75),
    marginRight: theme.spacing(6),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3.75),
    marginLeft: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
  },
  title: {
    color: theme.colors.primaryDarkBlue,
    marginBottom: 48,
  },
}));

const MySessionsView = () => {
  const classes = useStyle();
  const { t } = useTranslatedNavigate();

  return (
    <Page title={t("MySessionsView.Title")} className={classes.page}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container>
          <Hidden only={["xs", "sm"]}>
            <Grid item xs className={classes.menuGrid}>
              <OpenUpMenuList menuItemStyle={classes.menuItem} isOpen />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9}>
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default withSuspense(MySessionsView);
