import { VideoCallingToken } from "@src/models/VideoCallingToken";
import { apiPaths } from "@src/utils/constants";
import axios from "axios";

export const getToken = async (roomId: string) => {
  if (!roomId) {
    return null;
  }
  const response = await axios.get<VideoCallingToken>(
    `${apiPaths.videoCalling}/token?roomId=${roomId}`,
  );
  return response.data;
};

export const isExpertInCall = async (roomId: string) => {
  if (!roomId) {
    return null;
  }
  const response = await axios.get<boolean>(
    `${apiPaths.videoCalling}/expert-in-call?roomId=${roomId}`,
  );
  return response.data;
};
