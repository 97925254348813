/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";

import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ReactComponent as SleepSatisfaction } from "@src/resources/images/SleepSatisfaction.svg";
import { ReactComponent as Breathing } from "@src/resources/images/Breathing.svg";
import { ReactComponent as DailyEnergy } from "@src/resources/images/DailyEnergy.svg";
import { ReactComponent as PsychiatryIcon } from "@src/resources/images/icons/psychiatry.svg";
import { ReactComponent as Intoxications } from "@src/resources/images/Intoxications.svg";
import { ReactComponent as MentalHealth } from "@src/resources/images/MentalHealth.svg";
import { ReactComponent as Nutrition } from "@src/resources/images/Nutrition.svg";
import { ReactComponent as Overall } from "@src/resources/images/Overall.svg";
import { ReactComponent as PhysicalActivity } from "@src/resources/images/PhysicalActivity.svg";
import { ReactComponent as SleepSatisfactionWhite } from "@src/resources/images/SleepSatisfaction_white.svg";
import { ReactComponent as BreathingWhite } from "@src/resources/images/Breathing_white.svg";
import { ReactComponent as DailyEnergyWhite } from "@src/resources/images/DailyEnergy_white.svg";
import { ReactComponent as IntoxicationsWhite } from "@src/resources/images/Intoxications_white.svg";
import { ReactComponent as MentalHealthWhite } from "@src/resources/images/MentalHealth_white.svg";
import { ReactComponent as NutritionWhite } from "@src/resources/images/Nutrition_white.svg";
import { ReactComponent as OverallWhite } from "@src/resources/images/Overall_white.svg";
import { ReactComponent as PhysicalActivityWhite } from "@src/resources/images/PhysicalActivity_white.svg";

import SvgIcon from "@mui/material/SvgIcon";
import {
  MEDICAL_THEME_KEY,
  THEME_KEY,
  IConsultType,
  ISpaceType,
} from "@src/utils/constants";

export const Icon = ({ iconKey, inverted = false, ...props }) => {
  switch (iconKey) {
    case THEME_KEY.STRESS:
      return (
        <SvgIcon {...props}>
          <path d="M11 21h-1l1-7H7.5c-.58 0-.57-.32-.38-.66c.19-.34.05-.08.07-.12C8.48 10.94 10.42 7.54 13 3h1l-1 7h3.5c.49 0 .56.33.47.51l-.07.15C12.96 17.55 11 21 11 21z" />
        </SvgIcon>
      );
    case THEME_KEY.SLEEP:
      return (
        <SvgIcon {...props}>
          <path d="M14 4c.34 0 .68.02 1.01.07C13.1 6.23 12 9.05 12 12s1.1 5.77 3.01 7.93c-.33.05-.67.07-1.01.07c-4.41 0-8-3.59-8-8s3.59-8 8-8m0-2C8.48 2 4 6.48 4 12s4.48 10 10 10c1.82 0 3.53-.5 5-1.35c-2.99-1.73-5-4.95-5-8.65s2.01-6.92 5-8.65A9.973 9.973 0 0 0 14 2z" />
        </SvgIcon>
      );
    case THEME_KEY.RELATIONSHIPS:
      return (
        <SvgIcon {...props}>
          <path d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5zM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5S5.5 6.57 5.5 8.5S7.07 12 9 12zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35c.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z" />
        </SvgIcon>
      );
    case THEME_KEY.CONFIDENCE:
      return <ThumbUpAltOutlinedIcon {...props} />;
    case THEME_KEY.WORK:
      return <WorkOutlineOutlinedIcon {...props} />;
    case THEME_KEY.PURPOSE:
      return <VpnKeyOutlinedIcon {...props} />;
    case THEME_KEY.HEALTH:
      return <FavoriteBorderOutlinedIcon {...props} />;
    case THEME_KEY.MINDFULNESS:
      return <SpaOutlinedIcon {...props} />;
    case "Satisfied":
      return <SentimentSatisfiedAltIcon {...props} />;
    case "Neutral":
      return <SentimentDissatisfiedIcon {...props} />;
    case "Dissatisfied":
      return <SentimentVeryDissatisfiedIcon {...props} />;
    case "ArrowRight":
      return <ArrowForwardIcon {...props} />;
    case IConsultType.GENERAL:
      return <GroupOutlinedIcon />;
    case ISpaceType.MASTERCLASS:
      return <LocalLibraryOutlinedIcon />;
    case ISpaceType.GROUPSESSION:
    case ISpaceType.GROUPSESSION75MINS:
      return <GroupsOutlinedIcon />;
    case IConsultType.MINDFULNESS:
    case ISpaceType.MINDFULNESS:
      return <SpaOutlinedIcon />;
    case IConsultType.PHYSICAL_WELLBEING:
      return <PsychiatryIcon />;
    case MEDICAL_THEME_KEY.SLEEP_SATISFACTION:
      return inverted ? <SleepSatisfactionWhite /> : <SleepSatisfaction />;
    case MEDICAL_THEME_KEY.BREATHING:
      return inverted ? <BreathingWhite /> : <Breathing />;
    case MEDICAL_THEME_KEY.DAILY_ENERGY:
      return inverted ? <DailyEnergyWhite /> : <DailyEnergy />;
    case MEDICAL_THEME_KEY.INTOXICATION:
      return inverted ? <IntoxicationsWhite /> : <Intoxications />;
    case MEDICAL_THEME_KEY.MENTAL_HEALTH:
      return inverted ? <MentalHealthWhite /> : <MentalHealth />;
    case MEDICAL_THEME_KEY.NUTRITION:
      return inverted ? <NutritionWhite /> : <Nutrition />;
    case MEDICAL_THEME_KEY.PHYSICAL_ACTIVITY:
      return inverted ? <PhysicalActivityWhite /> : <PhysicalActivity />;
    case "Overall":
      return inverted ? <OverallWhite /> : <Overall />;
    default:
      return (
        <SvgIcon {...props}>
          <path d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5zM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5S5.5 6.57 5.5 8.5S7.07 12 9 12zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35c.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z" />
        </SvgIcon>
      );
  }
};
Icon.propTypes = {
  iconKey: PropTypes.string,
};
