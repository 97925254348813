import { forwardRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  backgroundColor?: string;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  (
    {
      children,
      title = " ",
      backgroundColor,
      className = "",
      ...rest
    }: PageProps,
    ref,
  ) => {
    return (
      <div
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        className={`min-h-full ${className}`}
        style={{ backgroundColor: backgroundColor ?? "" }}
      >
        <Helmet title={title} />
        {children}
      </div>
    );
  },
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
