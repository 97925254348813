import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { ReactComponent as ManShovel } from "@src/resources/images/man_shovel.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSendLoginMailToRedirectQuery } from "@src/queries/booking";
import BookingContainer from "../../components/BookingContainer";

const useStyles = makeStyles((theme) => ({
  image: {
    height: 150,
    width: 105,
    [theme.breakpoints.down("sm")]: {
      width: 82,
      height: 118,
      marginRight: theme.spacing(5),
    },
  },
  buttonConfirm: {
    width: "95%",
    "&:hover": {
      backgroundColor: theme.colors.primaryDarkBlue,
      color: "white",
    },
  },
  confirmText: {
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
  },
  card: {
    marginBottom: theme.spacing(8.5),
    boxShadow: theme.customShadows.bookingContainerBackgroundShadow,
  },
  cardContent: {
    flex: 1,
    paddingBottom: 0,
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 2),
      paddingBottom: 0,
    },
  },
  cardImage: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(0),
      float: "right",
      position: "relative",
      top: theme.spacing(0.25),
      paddingBottom: theme.spacing(0),
    },
  },
  textField: {
    width: "100%",
  },
  cardActions: {
    padding: theme.spacing(3),
    flex: 1,
  },
  cardActionsSmallScreen: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      right: 0,
      backgroundColor: theme.colors.trueWhite,
      padding: theme.spacing(2),
      boxShadow: "0px 0 20px rgba(0, 0, 0, 25% )",
      width: "100%",
      justifyContent: "center",
    },
  },
  confirmButtonText: {
    color: "white",
    fontWeight: 700,
  },
}));

const DiggingInformation = ({ moveToNextStep, personalUrlHandle }) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const classes = useStyles();
  const { t } = useTranslatedNavigate();

  const { mutateAsync: sendEmail } = useSendLoginMailToRedirectQuery();

  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    setStatus();
    setSubmitting(true);
    await sendEmail({ email: fields.email, personalUrlHandle }).finally(() => {
      moveToNextStep(fields.email);
    });
  };

  return (
    <BookingContainer title={t("Booking.DiggingInformation.Title")}>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("Validations.InvalidEmail"))
            .required(t("Validations.RequiredField")),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="subtitle1" className={classes.confirmText}>
                  {t("Booking.DiggingInformation.ConfirmText")}
                </Typography>
                <TextField
                  className={classes.textField}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth={isSmallScreen && true}
                  helperText={(touched.email && errors.email) || " "}
                  label={t("Booking.DiggingInformation.Email")}
                  name="email"
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="standard"
                />
              </CardContent>

              {isSmallScreen ? (
                <>
                  <CardMedia className={classes.cardImage}>
                    <ManShovel className={classes.image} />
                  </CardMedia>
                  <CardActions className={classes.cardActionsSmallScreen}>
                    <Button
                      disabled={isSubmitting || !values.email}
                      className={classes.buttonConfirm}
                      type="submit"
                    >
                      <Typography
                        variant="body1"
                        className={classes.confirmButtonText}
                      >
                        {t("Booking.DiggingInformation.ConfirmBookingButton")}
                      </Typography>
                    </Button>
                  </CardActions>
                </>
              ) : (
                <Box display="flex">
                  <CardActions className={classes.cardActions}>
                    <Button
                      disabled={isSubmitting || !values.email}
                      className={classes.buttonConfirm}
                      type="submit"
                    >
                      <Typography
                        variant="body1"
                        className={classes.confirmButtonText}
                      >
                        {t("Booking.DiggingInformation.ConfirmBookingButton")}
                      </Typography>
                    </Button>
                  </CardActions>
                  <CardMedia className={classes.cardImage}>
                    <ManShovel className={classes.image} />
                  </CardMedia>
                </Box>
              )}
            </Card>
          </form>
        )}
      </Formik>
    </BookingContainer>
  );
};

export default DiggingInformation;
