import { Typography } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    caption1: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    caption1?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    caption1: true;
  }
}

const typography = {
  fontFamily: "Montserrat",
  h1: {
    fontSize: "49px",
    fontFamily: ['"Noto Serif JP"', "serif"].join(","),
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "64px",
  },
  h2: {
    fontSize: "35px",
    fontFamily: ['"Noto Serif JP"', "serif"].join(","),
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "48px",
    letterSpacing: "0.25px",
  },
  h3: {
    fontSize: "25px",
    fontFamily: ['"Noto Serif JP"', "serif"].join(","),
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "32px",
  },
  h4: {
    fontSize: "21px",
    fontFamily: ['"Noto Serif JP"', "serif"].join(","),
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "32px",
  },
  h5: {
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  h6: {
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  subtitle1: {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    mixBlendMode: "normal",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  subtitle2: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    mixBlendMode: "normal",
    opacity: 0.7,
    lineHeight: "24px",
    letterSpacing: "0.1px",
  },
  overline: {
    fontWeight: 500,
    fontFamily: ['"Noto Serif JP"', "serif"].join(","),
  },
  body1: {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    opacity: 0.7,
    lineHeight: "32px",
    letterSpacing: "0.5px",
  },
  body2: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    opacity: 0.7,
    lineHeight: "24px",
    letterSpacing: "0.25px",
  },
  caption1: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.4px",
  },
  caption: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "16px",
    letterSpacing: "0.4px",
  },
} as unknown as Typography;

export default typography;
