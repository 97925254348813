import { Box, Select, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { Icon } from "@src/components/Icon";
import TypographyWithIcon from "@src/components/TypographyWithIcon";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useGetTimezones } from "@src/queries/booking";
import {
  findClosesTimezoneInListForSpecificTimezone,
  getDisplayTextOfTimezone,
} from "@src/utils/timezone";

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "unset",
    },
  },
  textBox: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "unset",
    },
  },
  boxTypography: {
    marginBottom: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2.5),
    },
  },
  boxTypographyFlag: {
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(2.625),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0),
      marginBottom: theme.spacing(2.5),
    },
  },
  icon: {
    fontSize: "20px",
    marginRight: theme.spacing(1.25),
    "&>span": {
      fontSize: "20px",
    },
  },
  menuItem: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    paddingLeft: theme.spacing(1),
  },
  selectEmpty: {
    borderRadius: "16px",
    height: 32,
    width: 280,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
    "&>div": {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
      backgroundColor: "rgba(51, 51, 51, 0.03)",
      "&:focus": {
        backgroundColor: "#E6E6E6",
      },
    },
  },
  selectPlaceholderText: {
    color: theme.colors.primaryDarkBlue,
    "&::placeholder": {
      opacity: 1,
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  select: {
    "& ul": {
      marginTop: theme.spacing(-2),
      paddingBottom: 0,
    },
  },
  sessionIconClass: {
    marginRight: theme.spacing(2),
  },
  arrowIcon: {
    marginLeft: "50px",
  },
}));

const TimeZoneSelector = ({
  sessionType,
  language,
  timeZoneValue,
  setTimeZoneValue,
  psychologistName,
}) => {
  const { t } = useTranslatedNavigate();
  const classes = useStyles();
  const { data: timezones } = useGetTimezones();

  const handleChange = (event) => {
    setTimeZoneValue(event.target.value);
  };

  const getTimeZoneName = () => {
    const timezone = findClosesTimezoneInListForSpecificTimezone(
      timezones,
      timeZoneValue,
    );
    return getDisplayTextOfTimezone(timezone.name);
  };

  return (
    <Box className={classes.timeSlotContent}>
      <Box className={classes.details}>
        <Box className={classes.textBox}>
          <div className="flex items-center text-indigo-800 gap-4">
            <Icon iconKey={sessionType} />
            <p className="text-sm">
              {t(`Booking.TimeSlot.SessionType.${sessionType}`)}
            </p>
          </div>
          <TypographyWithIcon
            iconLigature="flag"
            outlined
            boxClass={classes.boxTypographyFlag}
            classesIcon={classes.sessionIconClass}
            mb={2}
          >
            {t("Booking.TimeSlot.Language", {
              key: t(`SessionLanguage.${language}`),
            })}
          </TypographyWithIcon>
          {!!psychologistName && (
            <TypographyWithIcon
              iconLigature="psychology"
              outlined
              boxClass={classes.boxTypographyFlag}
              mb={2}
            >
              {psychologistName}
            </TypographyWithIcon>
          )}
        </Box>

        <Select
          variant="standard"
          data-cy="time-zone-selector"
          disableUnderline
          value={timeZoneValue}
          className={classes.selectEmpty}
          onChange={handleChange}
          inputProps={{
            classes: {
              icon: classes.arrowIcon,
            },
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "center",
              horizontal: "left",
            },
          }}
          renderValue={() => (
            <Box sx={{ display: "flex", paddingTop: 0 }}>
              <AccessTimeIcon />
              <Typography
                variant="body2"
                className={classes.selectPlaceholderText}
                data-cy="time-zone-selector-text"
              >
                {getTimeZoneName()}
              </Typography>
            </Box>
          )}
        >
          {timezones.map((timezone) => (
            <MenuItem
              data-cy="time-zone-selector-options"
              value={timezone.zoneId}
              className={classes.menuItem}
              key={timezone.zoneId}
            >
              {timezone.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default TimeZoneSelector;
