import { Button, TextField, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { ReactComponent as ManMoney } from "@src/resources/images/Man_money.svg";
import { getValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import PhoneNumberComponent from "@src/views/booking/components/PhoneNumberComponent";
import BookingCard from "../../components/BookingCard";
import BookingContainer from "../../components/BookingContainer";

const useStyles = makeStyles((theme) => ({
  fillDetails: {
    opacity: 1,
    fontWeight: 500,
    maxWidth: 240,
    letterSpacing: "0.15px",
    marginBottom: theme.spacing(4),
  },
  image: {
    marginTop: theme.spacing(40),
    [theme.breakpoints.down("sm")]: {
      width: 152,
      height: 114,
      marginTop: theme.spacing(0),
      display: "block",
      marginLeft: "auto",
      marginRight: 0,
    },
  },
  buttonConfirm: ({ isSmallScreen }) => ({
    width: isSmallScreen ? "100%" : 264,
  }),
  textField: {
    margin: theme.spacing(0),
    marginBottom: theme.spacing(6),
  },
  textFieldPhone: {
    margin: theme.spacing(0),
  },
}));

const CompleteBookingPersonalInfo = ({ moveToNextStep }) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const classes = useStyles({ isSmallScreen });
  const { t } = useTranslatedNavigate();

  const onSubmit = (values) => {
    moveToNextStep(values);
  };

  return (
    <>
      <BookingContainer
        title={t("Booking.CompleteBooking.Title")}
        message={t("Booking.CompleteBooking.Step", { key: 1 })}
      >
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            yearOfBirth: "",
            phoneNumber: "",
          }}
          validationSchema={getValidationSchema(t, {
            firstName: true,
            lastName: true,
            yearOfBirth: true,
            phoneNumber: true,
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldTouched,
            setFieldValue,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <BookingCard
                image={<ManMoney className={classes.image} />}
                overflowAllowed
                actions={
                  <Button
                    disabled={!isValid}
                    className={classes.buttonConfirm}
                    type="submit"
                  >
                    {t("Booking.CompleteBooking.ConfirmButton")}
                  </Button>
                }
              >
                <Typography variant="subtitle1" className={classes.fillDetails}>
                  {t("Booking.CompleteBooking.FillDetails")}
                </Typography>

                <TextField
                  focused
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth={isSmallScreen && true}
                  helperText={(touched.firstName && errors.firstName) || ""}
                  label={t("Booking.CompleteBooking.FirstName")}
                  name="firstName"
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="standard"
                  className={classes.textField}
                  inputProps={{ "data-cy": "first-name" }}
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth={isSmallScreen && true}
                  helperText={(touched.lastName && errors.lastName) || ""}
                  label={t("Booking.CompleteBooking.LastName")}
                  name="lastName"
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="standard"
                  className={classes.textField}
                  inputProps={{ "data-cy": "last-name" }}
                />
                <TextField
                  error={Boolean(touched.yearOfBirth && errors.yearOfBirth)}
                  fullWidth={isSmallScreen && true}
                  helperText={(touched.yearOfBirth && errors.yearOfBirth) || ""}
                  label={t("Booking.CompleteBooking.YearOfBirth")}
                  name="yearOfBirth"
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.yearOfBirth}
                  variant="standard"
                  className={classes.textField}
                  inputProps={{ "data-cy": "year-of-birth" }}
                />
                <PhoneNumberComponent
                  isTouched={touched.phoneNumber}
                  isError={errors.phoneNumber}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  phoneNumberValue={values.phoneNumber}
                />
              </BookingCard>
            </form>
          )}
        </Formik>
      </BookingContainer>
      )
    </>
  );
};

export default CompleteBookingPersonalInfo;
