import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BookingContainer from "@src/views/booking/components/BookingContainer";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useNavigate } from "react-router-dom";
import ClickableCard from "@src/views/booking/components/ClickableCard";

const useStyles = makeStyles(() => ({
  gridItem: {
    maxWidth: 360,
    cursor: "pointer",
  },
}));

const PsychologistNotAcceptingView = ({ psychologist }) => {
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();

  const goToPersonalFollowUpFlow = () => {
    navigate(
      `${pathT("route.booking")}/${psychologist.personalUrlHandle}/${t(
        "route.follow_up",
      )}`,
      { replace: true },
    );
  };

  const goToOtherBookingOptions = () => {
    navigate(pathT("route.booking"), { replace: true });
  };

  return (
    <BookingContainer
      title={t("Booking.PsychologistNotAccepting.Title", {
        firstname: psychologist.firstName,
      })}
      isMediumContainerWanted
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
          className={classes.gridItem}
          onClick={goToOtherBookingOptions}
        >
          <ClickableCard
            messageText={t("Booking.PsychologistNotAccepting.OtherSession")}
            imageName="ManRocket"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={classes.gridItem}
          onClick={goToPersonalFollowUpFlow}
        >
          <ClickableCard
            messageText={t("Booking.PsychologistNotAccepting.FollowUp", {
              firstname: psychologist.firstName,
            })}
            imageName="Astronaut"
          />
        </Grid>
      </Grid>
    </BookingContainer>
  );
};

export default PsychologistNotAcceptingView;
