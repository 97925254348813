/* eslint-disable default-case */
/* eslint-disable no-undef */
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { L10n, Export } from "tripetto-runner-foundation";
import { namespace, AutoscrollRunner } from "tripetto-runner-autoscroll";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "@src/services/auth.service";
import { GTMProvider, useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { ApplicationStateContext } from "@src/utils/ApplicationStateContext";
import CenteredLoader from "../CenteredLoader";

const TripettoSurvey = ({
  data: { definition, styles, translations, locale },
  isFetching,
  submit,
  redirectUri,
  redirectUriOnNotLoggedIn,
  callback,
  onData,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get("lang");
  const sendDataToGTM = useGTMDispatch();

  const { setHasDonePreregistrationCheckin } = useContext(
    ApplicationStateContext,
  );

  const navigate = useNavigate();
  const { isLoggedIn } = useAuthentication();
  const { i18n } = useTranslation();
  const l10nNamespace = L10n.Namespace.create(namespace);
  l10nNamespace.load(translations);
  l10nNamespace.locale.load(locale);

  if (lang && lang !== i18n.language) {
    i18n.changeLanguage(lang);
  }

  l10nNamespace.current = i18n.language;

  const onSubmit = async (instance) => {
    try {
      const answers = Export.values(instance);
      await submit(answers);

      if (callback) {
        callback();
      } else if (!isLoggedIn) {
        setHasDonePreregistrationCheckin(true);
        navigate(redirectUriOnNotLoggedIn);
      } else {
        navigate(redirectUri);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  if (isFetching) {
    return <CenteredLoader />;
  }
  const gtmParams = {
    id: env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
    dataLayerName: "dataLayer",
  };

  if (definition && styles) {
    return (
      <GTMProvider state={gtmParams}>
        <AutoscrollRunner
          l10nNamespace={l10nNamespace}
          definition={definition}
          styles={styles}
          onSubmit={onSubmit}
          display="page"
          onData={onData}
          onAction={(event, form, block) => {
            switch (event) {
              case "start":
                sendDataToGTM({
                  event: "tripetto_start",
                  description: "Started.",
                  path: window.location.pathname,
                });
                break;
              case "complete":
                sendDataToGTM({
                  event: "tripetto_complete",
                  description: "Completed.",
                  path: window.location.pathname,
                });
                break;
              case "stage":
                sendDataToGTM({
                  event: "tripetto_question",
                  description: block?.name,
                  path: window.location.pathname,
                });
                break;
            }
          }}
        />
      </GTMProvider>
    );
  }
  return <div />;
};

export default TripettoSurvey;
