import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useGetSessionTypes } from "@src/queries/common";
import Loader from "@src/components/Loader";
import { IConsultType, TAG_TYPES } from "@src/utils/constants";
import SessionTypeCard from "@openup/shared/components/Cards/SessionTypeCard/SessionTypeCard";
import { ArrowForward } from "@mui/icons-material";
import { SessionTypeCardImage } from "@src/views/booking/introductory/components/SessionTypeCardImage";

const SessionType = ({ moveToNextStep, setSessionType, psychologist }) => {
  const { t } = useTranslatedNavigate();
  const { data: sessionTypes } = useGetSessionTypes();

  const handlePressCard = (sessionType) => {
    setSessionType(sessionType);
    moveToNextStep();
  };

  if (!sessionTypes) {
    return (
      <div className="container mx-auto">
        <p className="mt-4 md:mt-8 mb-4 md:mb-12 text-center font-serif font-black	text-4xl md:text-5xl text-indigo-800 w-11/12 md:w-5/12 max-w-3xl mx-auto leading-snug md:leading-snug">
          {t("Booking.SessionType.Title")}
        </p>
        <div className="flex justify-center">
          <div>
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  if (psychologist) {
    return (
      <div className="container mx-auto">
        <p className="mt-4 md:mt-8 mb-4 md:mb-12 text-center font-serif font-black	text-4xl md:text-5xl text-indigo-800 w-11/12 md:w-5/12 max-w-3xl mx-auto leading-snug md:leading-snug">
          {t("Booking.SessionType.Title.Psychologist", {
            firstname: psychologist.firstName,
          })}
        </p>
        <div className="flex flex-wrap w-11/12 mx-auto justify-center items-center gap-8 md:gap-6 pb-6">
          {sessionTypes
            ?.filter((sessionType) =>
              psychologist?.tags?.find(
                (tag) =>
                  tag.tagType === TAG_TYPES.SESSION_TYPE &&
                  tag.tagName.toLowerCase() === sessionType.name.toLowerCase(),
              ),
            )
            .map((sessionType) => (
              <SessionTypeCard
                key={sessionType.name}
                onClick={() => handlePressCard(sessionType.name)}
                cardTitle={
                  <p className="w-full font-semibold text-lg leading-6 tracking-wide mr-1">
                    {t(`Booking.SessionType.${sessionType.name}.Psychologist`, {
                      firstname: psychologist.firstName,
                    })}
                  </p>
                }
                cardSubtitle={t(
                  `Booking.SessionType.${sessionType.name}.Details`,
                )}
                icon={<ArrowForward className="text-blue-500" />}
                image={
                  <SessionTypeCardImage
                    sessionType={sessionType.name as IConsultType}
                  />
                }
              />
            ))}
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto">
      <p className="mt-4 md:mt-8 mb-4 md:mb-12 text-center font-serif font-black text-4xl md:text-5xl text-indigo-800 w-11/12 md:w-6/12 max-w-3xl mx-auto leading-snug md:leading-snug">
        {t("Booking.SessionType.Title")}
      </p>
      <div className="flex flex-wrap w-11/12 mx-auto justify-center items-center gap-8 md:gap-6 pb-6">
        {sessionTypes?.map((sessionType) => (
          <SessionTypeCard
            key={sessionType.name}
            onClick={() => handlePressCard(sessionType.name)}
            cardTitle={
              <p className="w-full font-semibold text-lg leading-6 tracking-wide mr-1">
                {t(`Booking.SessionType.${sessionType.name}.Description`)}
              </p>
            }
            cardSubtitle={t(`Booking.SessionType.${sessionType.name}.Details`)}
            icon={<ArrowForward className="text-blue-500" />}
            image={
              <SessionTypeCardImage
                sessionType={sessionType.name as IConsultType}
              />
            }
          />
        ))}
      </div>
    </div>
  );
};

export default SessionType;
