import * as Yup from "yup";
import PasswordValidator from "password-validator";
import libphonenumber from "google-libphonenumber";

const weakPasswordValidator = new PasswordValidator();
const strongPasswordValidator = new PasswordValidator();

weakPasswordValidator.is().min(1).is().max(100);
strongPasswordValidator
  .is()
  .min(12)
  .is()
  .max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

const getStrongPasswordValidation = (t) =>
  Yup.string()
    .max(100)
    .test("strength", t("Validations.InvalidPassword"), (value) =>
      strongPasswordValidator.validate(value),
    )
    .required(t("Validations.RequiredField"));

const getWeakPasswordValidation = (t) =>
  Yup.string()
    .test("strength", t("Validations.InvalidPassword"), (value) =>
      weakPasswordValidator.validate(value),
    )
    .required(t("Validations.RequiredField"));

const getFirstNameValidation = (t) =>
  Yup.string()
    .trim()
    .max(20, t("Validations.FirstnameLength"))
    .min(1, t("Validations.RequiredField"))
    .required(t("Validations.RequiredField"));

const getLastNameValidation = (t) =>
  Yup.string()
    .max(20, t("Validations.LastnameLength"))
    .required(t("Validations.RequiredField"));

const getYearOfBirthValidation = (t) =>
  Yup.number()
    .integer()
    .typeError(t("Validations.YearOfBirthNumeric"))
    .max(9999, t("Validations.YearOfBirthMoreThan4Chars"))
    .min(1901, t("Validations.YearOfBirthRange"))
    .required(t("Validations.RequiredField"))
    .test("yearOfBirth", t("Validations.YearOfBirthAbove18"), (value) => {
      const today = new Date();
      return parseInt(today.getFullYear(), 10) - parseInt(value, 10) >= 16;
    })
    .test("is-birth-year", t("RegisterView.YearOfBirth.NotValid"), (value) => {
      const yearOfBirth = parseInt(value, 10);
      return !(
        Number.isNaN(yearOfBirth) ||
        yearOfBirth < 1900 ||
        yearOfBirth > new Date().getFullYear()
      );
    });

const getEmailValidation = (t) =>
  Yup.string()
    .email(t("Validations.InvalidEmail"))
    .max(255)
    .required(t("Validations.RequiredField"));

const getIsValidPhoneNumberValidation = (phoneNumberValue) => {
  if (!phoneNumberValue) {
    return false;
  }
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  let isValid = false;
  try {
    isValid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumberValue));
  } catch {
    //
  }
  return isValid;
};

const getPhoneNumberValidation = (t) =>
  Yup.string()
    .required(t("Validations.RequiredField"))
    .test(
      "is-valid-phone-number",
      t("Validations.InvalidPhoneNumber"),
      getIsValidPhoneNumberValidation,
    );

export const getChangePasswordValidationSchema = (t) =>
  Yup.object().shape({
    oldPassword: getWeakPasswordValidation(t),
    newPassword: getStrongPasswordValidation(t).test({
      name: "same-as-old-password",
      params: {},
      message: "New password cannot be the same as your old password.",
      test(value) {
        return value !== this.parent.oldPassword;
      },
    }),
    newPasswordConfirmation: Yup.string().test({
      name: "passwords-not-matching",
      params: {},
      message: "Your new passwords are not matching.",
      test(value) {
        return value === this.parent.newPassword;
      },
    }),
  });

export const getValidationSchema = (
  t,
  {
    firstName = false,
    lastName = false,
    yearOfBirth = false,
    password = false,
    email = false,
    phoneNumber = false,
  },
) =>
  Yup.object().shape({
    firstName: firstName && getFirstNameValidation(t),
    lastName: lastName && getLastNameValidation(t),
    yearOfBirth: yearOfBirth && getYearOfBirthValidation(t),
    password: password && getStrongPasswordValidation(t),
    email: email && getEmailValidation(t),
    phoneNumber: phoneNumber && getPhoneNumberValidation(t),
  });
