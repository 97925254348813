import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import MedicalDashboardTopBar from "@src/layouts/TopBars/MedicalDashboardTopBar";
import withAppInsights from "@src/appInsights/AppInsights";
import { AppInsightsContextProvider } from "@src/appInsights/AppInsightsContext";
import FooterBar from "./FooterBar";
import DashboardTopBar from "./TopBars/DashboardTopBar";

const useStyles = makeStyles(({ breakpoints }) => ({
  root: ({ backgroundColor }) => ({
    backgroundColor,
    backgroundAttachment: "fixed, fixed",
    backgroundPosition: "left bottom, right top",
    backgroundRepeat: "no-repeat",

    [breakpoints.down("sm")]: {
      backgroundSize: 140,
    },
  }),
}));
const Layout = withAppInsights(
  ({ backgroundColor, className, showFooter, isMedical = false }) => {
    const classes = useStyles({ backgroundColor });

    return (
      <AppInsightsContextProvider>
        <Helmet>
          <meta
            name="viewport"
            content={
              isMedical
                ? "width=device-width, initial-scale=1, maximum-scale=1"
                : "width=device-width, initial-scale=1, shrink-to-fit=no"
            }
          />
        </Helmet>
        <div className={clsx(classes.root, className)}>
          {isMedical ? <MedicalDashboardTopBar /> : <DashboardTopBar />}
          <Outlet />
          {showFooter && <FooterBar isMedical={isMedical} />}
        </div>
      </AppInsightsContextProvider>
    );
  },
);

export default Layout;
