import { makeStyles } from "@mui/styles";
import Layout from "@src/layouts/Layout";
import backgroundImageRightTop from "@src/resources/backgrounds/neutral-top-right.svg";
import backgroundImageLeftBottom from "@src/resources/backgrounds/neutral-bottom-left.svg";
import colors from "@src/theme/colors";

const useStyles = makeStyles(({ breakpoints }) => ({
  themeRoot: {
    "& .MuiTypography-h3": {
      fontSize: "35px",
      [breakpoints.down("sm")]: {
        fontSize: "25px",
      },
    },
    "& .MuiTypography-h1": {
      [breakpoints.down("sm")]: {
        fontSize: "35px",
      },
    },
    "& .MuiTypography-h6": {
      fontSize: "16px",
      "letter-spacing": "0.5px",
      opacity: 0.7,
    },
  },
}));

const ThemeLayout = ({ showFooter = true }) => {
  const classes = useStyles();
  return (
    <Layout
      className={classes.themeRoot}
      backgroundColor={colors.primarySkin}
      bottomLeftImageUrl={backgroundImageLeftBottom}
      topRightImageUrl={backgroundImageRightTop}
      showFooter={showFooter}
    />
  );
};

export default ThemeLayout;
